import get from "lodash.get";

export const normaliseUser = (amplifyUser) => {
  const attributes = get(amplifyUser, "signInUserSession.idToken.payload");
  const firstName = get(attributes, "given_name");
  const lastName = get(attributes, "family_name");
  const email = get(attributes, "email");
  const fullName = `${firstName} ${lastName}`;
  const role = get(attributes, "custom:role");
  const tenantId = get(attributes, "custom:tenantId");
  const subTenantIds = JSON.parse(get(attributes, "custom:subTenantIds", "[]"));
  const id = get(attributes, "sub");

  return {
    email,
    firstName,
    fullName,
    id,
    lastName,
    role,
    subTenantIds,
    tenantId,
  };
};

import { UMR } from "./umr";

/**
 * wording
 */
export const consortiumWording =
  "Convex Insurance UK Limited - 33.34%, Houston Casualty Company (UK Branch 42374) - 33.33%, IQUW Syndicate 1856 - 33.33%";

/**
 * Consortium Unique Market Reference
 * @param umr
 * @returns
 */
export const getConsortiumUMR = (umr?: string): string => {
  if (umr === undefined) {
    return "";
  }

  const list = UMR as Record<string, { ID: string[]; CONSORTIUM_ID: string }>;

  for (const key of Object.keys(list)) {
    if (list?.[key]?.CONSORTIUM_ID && list?.[key]?.ID?.includes(umr?.toUpperCase())) {
      const result = list?.[key]?.CONSORTIUM_ID;
      if (result === undefined) {
        if (!process.env.CI) {
          console.log(`Unable to find Consortium Unique Market Reference for: ${umr}`);
        }
        throw new Error("Unable to find Consortium Unique Market Reference");
      }
      return result;
    }
  }

  if (!process.env.CI) {
    console.log(`Unable to find Consortium Unique Market Reference for: ${umr}`);
  }
  throw new Error("Unable to find Consortium Unique Market Reference");
};

/**
 * Consortium default commission
 * @param umr
 * @returns
 */
export const getConsortiumCommission = (umr?: string): number | undefined => {
  if (umr === undefined) {
    return undefined;
  }

  const list = UMR as Record<string, { ID: string[]; CONSORTIUM_ID: string; CONSORTIUM_COMMISSION: number }>;

  for (const key of Object.keys(list)) {
    if (list?.[key]?.CONSORTIUM_ID && list?.[key]?.ID?.includes(umr?.toUpperCase())) {
      const result = list?.[key]?.CONSORTIUM_COMMISSION;
      if (result === undefined) {
        if (!process.env.CI) {
          console.log(`Unable to find Consortium Commission for: ${umr}`);
        }
        throw new Error("Unable to find Consortium Commission");
      }
      return result;
    }
  }

  if (!process.env.CI) {
    console.log(`Unable to find Consortium Commission for: ${umr}`);
  }
  throw new Error("Unable to find Consortium Commission");
};

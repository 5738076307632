import PropTypes from "prop-types";
import React from "react";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { useContractQuery } from "../hooks";

const ProcessReferralModal = ({ handleClose }) => {
  const { updateContract, isUpdating, productRef, contractId, endorsementId } = useContractQuery();

  const handleProcessReferral = async () => {
    await updateContract({
      contractId,
      data: { type: ACTIONS.PROCESS_REFERRAL },
      endorsementId,
      productRef,
    });

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Pass to broker" clearParamsOnClose>
      <div className="p-6">
        <p className="mb-8">
          This referral will be sent back to the broker. Are you sure you want to continue?
        </p>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={isUpdating}
            kind="primary"
            onClick={handleProcessReferral}
          >
            Continue
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

ProcessReferralModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ProcessReferralModal;

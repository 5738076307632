import React from "react";

const DiffBlock = ({ prev, next }) => {
  if (prev === next) {
    return next;
  }

  return (
    <>
      {prev && (
        <div>
          <div className="p-2 inline-flex bg-red-100 rounded line-through mb-2">{prev}</div>
        </div>
      )}

      {next && (
        <div>
          <div className="p-2 inline-flex bg-green-100 rounded">{next}</div>
        </div>
      )}
    </>
  );
};

export default DiffBlock;

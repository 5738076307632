import { compare } from "fast-json-patch";
import pointer from "json-pointer";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import mergeWith from "lodash.mergewith";
import { Contract, Section } from "../@types/types";
import getFormat from "./getFormat";
import { resolveSchema } from "./resolveSchema";
import { sortProps } from "./sortProps";

type Snapshots = Contract[];

export const patchIncludes =
  (path: string[]) =>
  (patch: { path: string }): boolean =>
    patch.path.includes(pointer.compile(path));

export const getChangeHistory =
  (snapshots: Snapshots) =>
  (path: string[], schema: any): { date: string; value: string; isCreation?: boolean }[] => {
    const result = snapshots
      .flatMap((snapshot, index) => {
        const prev = get(snapshots?.[index - 1]?.submission, path);
        const prevFormatted = prev ? getFormat(prev, schema) : null;
        const current = get(snapshot.submission, path);
        const currentFormatted = current ? getFormat(current, schema) : null;
        const isCreation = index === 0;

        if (currentFormatted === prevFormatted) {
          return [];
        }

        return {
          date: snapshot?.updatedAt,
          value: currentFormatted,
          ...(isCreation && { isCreation }),
        };
      })
      .reverse();

    if (result.length === 1) {
      return [];
    }

    return result;
  };

export const prepareContractDetails = ({
  contract,
  prevContract,
  generateHistory,
  schema,
  changesOnly,
  snapshots = [],
}: {
  contract: Contract;
  prevContract?: Contract;
  schema: any;
  generateHistory?: boolean;
  changesOnly?: boolean;
  snapshots?: Snapshots;
}): Section[] => {
  const patchData =
    changesOnly && contract && prevContract ? compare(prevContract.submission, contract.submission) : [];
  const getHistoryFromSnapshots = getChangeHistory(snapshots);
  const submissionSchema = cloneDeep(schema.properties.SubmissionForm);

  resolveSchema(submissionSchema, contract.submission);

  const sortedSectionKeys = sortProps(submissionSchema.properties).filter((sectionKey) => {
    const sectionPath = [sectionKey];
    const sectionHasValue = Boolean(get(contract.submission, sectionPath));

    return sectionHasValue;
  });

  const filteredSectionKeys = sortedSectionKeys.filter((sectionKey) => {
    const sectionPath = [sectionKey];
    const isSectionChanged = patchData.some(patchIncludes(sectionPath));

    return changesOnly && isSectionChanged;
  });

  const sectionKeys = changesOnly ? filteredSectionKeys : sortedSectionKeys;

  return sectionKeys.map((sectionKey) => {
    const sectionSchema = submissionSchema.properties[sectionKey];
    const sectionPath = [sectionKey];
    const sortedDatapointKeys = sortProps(sectionSchema.properties);
    const filteredDatapointKeys = sortedDatapointKeys.filter((datapointKey) => {
      const datapointPath = [...sectionPath, datapointKey];
      const datapointValue = get(contract.submission, datapointPath);
      const isChanged = patchData.some(patchIncludes(datapointPath));

      if (!datapointValue && datapointValue !== 0) {
        return false;
      }

      if (changesOnly && !isChanged) {
        return false;
      }

      return datapointKey;
    });

    return {
      sectionKey,
      sectionTitle: sectionSchema.title,
      data: filteredDatapointKeys.map((datapointKey) => {
        const datapointSchema = sectionSchema.properties[datapointKey];
        const datapointPath = [...sectionPath, datapointKey];
        const prevValue = get(prevContract?.submission, datapointPath);
        const currValue = get(contract.submission, datapointPath);
        const datapointComponent = datapointSchema["ui:component"];

        if (datapointComponent === "InputMatrix") {
          const firstKey = Object.keys(datapointSchema.properties)[0] as string;
          const columnsSchema = datapointSchema.properties[firstKey].properties;

          const sortedFilteredRows = sortProps(datapointSchema.properties)
            .map((key) => ({ key, value: get(contract.submission, [...datapointPath, key]) }))
            .filter((row) => row.value || row.value === 0);

          const columns = sortProps(columnsSchema).map((column) => ({
            key: column,
            title: columnsSchema[column].title,
          }));

          return {
            datapointComponent: datapointSchema["ui:component"],

            datapointKey,
            datapointPath,
            datapointTitle: datapointSchema.title,
            columns: [{ key: "category", title: "Category" }, ...columns],
            rows: sortedFilteredRows.map((row) => {
              const title = datapointSchema?.properties?.[row.key]?.title;
              const mapped = columns.map((column) => {
                const schema = columnsSchema[column.key];
                const path = [...datapointPath, row.key, column.key];
                const changeHistory = generateHistory ? getHistoryFromSnapshots(path, schema) : [];
                const prevInputValue = prevValue?.[row.key]?.[column.key];

                return {
                  currFormattedValue: getFormat(row.value[column.key], schema),
                  prevFormattedValue:
                    prevInputValue || prevInputValue === 0 ? getFormat(prevInputValue, schema) : undefined,
                  changeHistory: generateHistory ? changeHistory : undefined,
                  datapointTitle: schema["ui:alt:title"],
                };
              });

              return [{ currFormattedValue: title, prevFormattedValue: title }, ...mapped];
            }),
          };
        }

        if (datapointComponent === "SectionRepeater" || datapointComponent === "ControlledSectionRepeater") {
          // handle repeaters with dependencies in their items, temporarily in cargo only
          if (schema.$id.includes("cargo-us")) {
            Object.keys(datapointSchema?.items?.properties).forEach((key) => {
              datapointSchema?.items?.dependencies?.[key]?.oneOf?.forEach((item: any) =>
                mergeWith(datapointSchema?.items?.properties, item.properties),
              );
            });
          }

          const columnsSchema = datapointSchema?.items?.properties;
          const columns = sortProps(columnsSchema).map((column) => ({
            key: column,
            title: columnsSchema[column]?.["ui:alt:title"] || columnsSchema[column]?.title,
          }));

          const rows = currValue.map((row: any, rowIndex: number) =>
            columns.map((column) => {
              const schema = columnsSchema[column.key];
              const path = [...datapointPath, rowIndex.toString(), column.key];
              const changeHistory = generateHistory ? getHistoryFromSnapshots(path, schema) : [];
              const prevInputValue = prevValue?.[rowIndex]?.[column.key];

              return {
                currFormattedValue: getFormat(row[column.key], schema),
                prevFormattedValue:
                  prevInputValue || prevInputValue === 0 ? getFormat(prevInputValue, schema) : undefined,
                changeHistory: generateHistory ? changeHistory : undefined,
              };
            }),
          );

          return {
            datapointComponent: datapointSchema["ui:component"],
            datapointLayout: datapointSchema["ui:layout"],
            datapointKey,
            datapointPath,
            datapointTitle: datapointSchema.title || datapointSchema?.items?.title,
            columns,
            rows,
          };
        }

        const changeHistory = generateHistory ? getHistoryFromSnapshots(datapointPath, datapointSchema) : [];

        return {
          currValue,
          currFormattedValue: currValue || currValue === 0 ? getFormat(currValue, datapointSchema) : undefined,
          datapointKey,
          datapointPath,
          datapointTitle: datapointSchema.title,
          prevFormattedValue: prevValue || prevValue === 0 ? getFormat(prevValue, datapointSchema) : undefined,
          changeHistory: generateHistory ? changeHistory : undefined,
          ...(datapointComponent && { datapointComponent }),
        };
      }),
    };
  });
};

import PropTypes from "prop-types";
import React, { useState } from "react";
import { CargoEvent } from "../../projects/cargo-us/types";
import Button from "../components/Button";
import H3 from "../components/H3";
import InputPercentage from "../components/InputPercentage";
import Modal from "../components/Modal";
import Form from "../components/Form";
import { useContractQuery, useQuoteQuery } from "../hooks";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";

const UpdateQuoteCommissionModal = ({ handleClose }) => {
  const { contractData } = useContractQuery();
  const { quoteData } = useQuoteQuery();
  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();
  const [formValues, setFormValues] = useState({
    commission: quoteData?.commission ?? contractData.brokerCommissions.commission,
  });

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const action: Extract<CargoEvent, { type: "Update quote commission" }> = {
      type: "Update quote commission",
      payload: { commission: formValues.commission },
    };

    await updateQuote({ contractId, data: action, endorsementId, productRef, quoteId });

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Amend commission" isLoading={false}>
      <Form onSubmit={handleSubmit}>
        <div className="p-6">
          <H3>Broker commission split</H3>
          <div className="text-gray-700 text-sm mb-6">
            Total may not exceed {contractData.brokerCommissions.commissionCap}%
          </div>
          <InputPercentage
            className="mb-8"
            decimalScale={2}
            isAllowed={({ floatValue, value }) => {
              return value === "" || floatValue <= contractData.brokerCommissions.commissionCap;
            }}
            isRequired
            labelText={"Commission"}
            name="commission"
            onChange={handleChange}
            value={formValues.commission}
          />
          <div className="flex">
            <Button className="mr-4" isDisabled={isUpdating || isNaN(formValues.commission)} kind="primary" type="submit">
              Submit
            </Button>

            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

UpdateQuoteCommissionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default UpdateQuoteCommissionModal;

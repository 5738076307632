import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";

const variants = {
  green: "text-white bg-green-600 hover:bg-green-800 active:bg-green-1000 disabled:bg-green-300",
  primary: "text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-1000 disabled:bg-blue-300",
  secondary:
    "active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 shadow-sm hover:bg-gray-100 text-gray-900",
  tertiary: "active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-blue-800",
  "danger-secondary":
    "active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-red-800",
  danger: "text-white bg-red-800 hover:bg-red-900 active:bg-red-1000 disabled:bg-red-500",
};

const Button = ({ as: Component, children, className, iconName, isDisabled, kind, size, ...props }) => (
  <Component
    className={clsx(
      "inline-flex items-center justify-center relative transition duration-75 ease-in font-medium text-sm rounded disabled:cursor-not-allowed focus:outline-none focus:shadow-outline",
      !clsx(className).match(/(p[xy]?)-(\d+)/) && "px-4",
      !clsx(className).match(/(h)-(\d+)/) && "h-12",
      variants[kind],
      className,
    )}
    disabled={isDisabled}
    type="button"
    {...props}
  >
    {iconName && <Icon name={iconName} className={clsx("w-4 mr-2 ")} />}
    {children}
  </Component>
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  iconName: PropTypes.string,
  isDisabled: PropTypes.bool,
  kind: PropTypes.oneOf(["danger", "danger-secondary", "primary", "secondary", "tertiary", "green"]),
  size: PropTypes.oneOf(["normal", "small"]),
};

Button.defaultProps = {
  as: "button",
  children: undefined,
  className: undefined,
  iconName: undefined,
  isDisabled: undefined,
  kind: "secondary",
  size: "normal",
};

export default Button;

import DOMPurify from "dompurify";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { v4 as uuid } from "uuid";
import { CLAUSE_TYPES } from "../../constants/standardClauses";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import InputSelect from "../components/InputSelect";
import InputText from "../components/InputText";
import Modal from "../components/Modal";
import RichText from "../components/RichText";
import { useClauseQuery } from "../hooks";

const CreateClauseForm = ({ handleClose, clauseData, contractClauseIds, endorsementId }) => {
  const {
    contractId,
    createClause,
    createContractClause,
    isCreating,
    productRef,
  } = useClauseQuery();

  const [body, setBody] = useState("");
  const [selectedClause, setSelectedClause] = useState("");
  const [shouldShare, setShouldShare] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isManualInput, setIsManualInput] = useState(false);

  const handleClauseChange = (id) => {
    const clause = clauseData.find((c) => c.data.reference === id);
    setSelectedClause(id);
    setIsFormVisible(true);
    setBody(clause.data.body);
    setFormValues({
      ...formValues,
      ...{
        reference: clause.data.reference,
        title: clause.data.title,
        type: clause.data.type,
        locked: clause.data.locked,
      },
    });
  };

  const handleChange = (...args) => {
    if (args.length === 1) {
      const [event] = args;

      setFormValues({ ...formValues, ...{ [event.target.name]: event.target.value } });
    }

    if (args.length === 2) {
      const [value, name] = args;

      setFormValues({ ...formValues, ...{ [name]: value } });
    }
  };

  const handleManualInputClick = () => {
    setIsFormVisible(true);
    setIsManualInput(true);
  };

  const handleSubmit = async () => {
    const params = {
      data: { ...formValues, body, reference: uuid(), sourceReference: formValues.reference },
      productRef,
    };
    await createContractClause({ ...params, ...{ contractId, endorsementId } });

    if (shouldShare) {
      await createClause(params);
    }

    handleClose();
  };

  return (
    <div className="p-6 w-full">
      {!isManualInput && (
        <InputSelect
          isSearchable
          options={clauseData
            .filter((c) => !contractClauseIds.includes(c.data.reference))
            .map(({ data }) => ({
              name: data.reference,
              value: data.reference,
            }))}
          onChange={handleClauseChange}
          value={selectedClause}
          labelText="Select from clause library"
          className="mb-8"
          formatter={(value) =>
            clauseData.find((clause) => clause.data.reference === value).data.title
          }
        />
      )}

      {!isFormVisible && (
        <Button kind="primary" onClick={handleManualInputClick}>
          Create new
        </Button>
      )}

      {isFormVisible && (
        <Form onSubmit={handleSubmit}>
          <InputText
            labelText="Description"
            name="title"
            value={formValues.title}
            isRequired
            className="mb-8"
            onChange={handleChange}
          />

          <InputSelect
            labelText="Type"
            name="type"
            value={formValues.type}
            options={CLAUSE_TYPES.map((value) => ({ name: value, value }))}
            isRequired
            className="mb-8"
            onChange={handleChange}
          />

          {formValues.locked && (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} className="mb-4" />
          )}
          {!formValues.locked && <RichText value={body} onChange={setBody} className="mb-4" />}

          {isManualInput && (
            <Banner className="mb-8" color="orange" headingText="Visibility settings">
              <InputCheckbox
                labelText="Share with wider clause library"
                name="share_clause"
                onChange={() => setShouldShare(!shouldShare)}
                isChecked={shouldShare}
              />
            </Banner>
          )}

          <div className="flex justify-end">
            <Button onClick={handleClose} className="mr-4">
              Cancel
            </Button>

            <Button kind="primary" isDisabled={isCreating} onClick={handleSubmit}>
              {isManualInput ? "Create" : "Add"}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

const CreateClauseModal = ({ handleClose, endorsementId }) => {
  const { clauseData, contractClauseData, isLoading } = useClauseQuery();
  const contractClauseIds = contractClauseData
    .flatMap(({ data }) => [data.reference, data.sourceReference])
    .filter((r) => r);

  return (
    <Modal handleClose={handleClose} headingText="Create clause" isLoading={isLoading}>
      <CreateClauseForm
        clauseData={clauseData}
        contractClauseIds={contractClauseIds}
        endorsementId={endorsementId}
        handleClose={handleClose}
      />
    </Modal>
  );
};

export default CreateClauseModal;

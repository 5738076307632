import Auth from "@aws-amplify/auth";
import { compile } from "path-to-regexp";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { ROUTES } from "../../../constants";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import Form from "../../components/Form";
import InputText from "../../components/InputText";
import useForm from "../../hooks/useForm";
import AuthLayout from "./AuthLayout";

const defaultProductRef = process.env.DEFAULT_PRODUCT_REF;

const mapFormError = (message) => {
  if (message === "Temporary password has expired and must be reset by an administrator.") {
    return {
      headingText: "Password Expired",
      text: "Your temporary password has expired. Please contact an administrator to request a new password.",
    };
  }

  return { headingText: message };
};

const Login = () => {
  const { push } = useHistory();

  const { getFieldProps, canSubmit, onSubmit, formError } = useForm({
    initialState: { email: "", password: "" },
    onSubmit: async (formValues) => {
      const user = await Auth.signIn(formValues.email.trim(), formValues.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return push(ROUTES.SETUP_PASSWORD, formValues);
      }

      if (defaultProductRef === "mc") {
        return push(compile(ROUTES.DRAFTS)({ productRef: defaultProductRef }));
      } else {
        return push(compile(ROUTES.INBOX)({ productRef: defaultProductRef }));
      }
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
  });

  return (
    <AuthLayout>
      {formError && <Banner className="mb-8" color="red" {...mapFormError(formError)} />}

      <Form onSubmit={onSubmit}>
        <InputText className="mb-6" labelText="Email" placeholder="email@example.com" {...getFieldProps("email")} />

        <InputText
          className="mb-6"
          labelText="Password"
          link={
            <Link className="text-blue-600 font-medium text-sm" to={ROUTES.FORGOT_PASSWORD}>
              Forgot password?
            </Link>
          }
          type="password"
          {...getFieldProps("password")}
        />

        <Button kind="primary" type="submit" isDisabled={!canSubmit} className="w-full">
          Sign in
        </Button>
      </Form>
      <br />
      {typeof process.env.EXTERNAL_IDENTITY_PROVIDER !== "undefined" && process.env.EXTERNAL_IDENTITY_PROVIDER && (
        <>
          <p
            className="w-full text-center border-b text-gray-600 border-gray-400 font-medium text-sm mt-2.5 mb-5"
            style={{ lineHeight: "0.1em" }}
          >
            <span className="bg-white px-3">Or</span>
          </p>
          <Button
            type="submit"
            className="w-full"
            onClick={() => Auth.federatedSignIn({ customProvider: process.env.EXTERNAL_IDENTITY_PROVIDER })}
          >
            Sign in with {process.env.CLIENT_REF === "chaucer" ? "Azure AD" : "JumpCloud"}
          </Button>
        </>
      )}
    </AuthLayout>
  );
};

export default Login;

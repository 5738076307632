import groupBy from "lodash.groupby";
import React from "react";
import { ACTIONS } from "../../../constants";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Table from "../../components/Table";
import Tag from "../../components/Tag";

const types = {
  wording: "border-yellow-1000 bg-yellow-100 text-yellow-900",
  condition: "border-green-1000 bg-green-100 text-green-900",
  warranty: "border-indigo-1000 bg-indigo-100 text-indigo-900",
  exclusion: "border-blue-1000 bg-blue-100 text-blue-900",
  subjectivity: "border-orange-1000 bg-orange-100 text-orange-900",
};

const order = ["wording", "condition", "warranty", "exclusion", "subjectivity"];

const AccordionPanelClauses = ({
  clauseData,
  handleActions,
  isCurrent,
  checkPermissions,
  ...props
}) => {
  const mapped = clauseData.map((c) => c.data);
  const grouped = groupBy(mapped, (c) => c.type);
  const sorted = order.reduce(
    (prev, next) => (grouped[next] ? [...prev, ...grouped[next]] : prev),
    []
  );

  return (
    <Accordion.Panel tag={<Tag text={clauseData.length} className="border-gray-400" />} {...props}>
      <Table className="mb-6">
        <Table.Body>
          {sorted.map((clause) => (
            <Table.Row key={clause.reference} className="border-b border-gray-500 last:border-0">
              <Table.Data className="w-32 capitalize">
                <Tag className={types[clause.type]} text={clause.type} />
              </Table.Data>

              <Table.Data>
                <p>
                  {clause.title} {clause.updatedAt && " [AMENDED]"}
                </p>
              </Table.Data>

              {isCurrent && (
                <Table.Data>
                  <Icon
                    name="details"
                    className="cursor-pointer w-6 fill-current text-gray-700"
                    onClick={() => handleActions({ type: ACTIONS.EDIT_CLAUSE, clause })}
                  />
                </Table.Data>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {checkPermissions(ACTIONS.CREATE_CLAUSE) && isCurrent && (
        <div className="flex justify-end m-4">
          <Button name="newClause" onClick={() => handleActions({ type: ACTIONS.CREATE_CLAUSE })}>
            Add clause
          </Button>
        </div>
      )}
    </Accordion.Panel>
  );
};

export default AccordionPanelClauses;

import clsx from "clsx";
import React, { Children, cloneElement, forwardRef, useEffect, useRef, useState } from "react";
import { ACTION_TEXT_MAP } from "../../constants";
import Icon from "./Icon";

const iconKinds = {
  default: "text-gray-600",
  inverted: "text-white",
};

const buttonKinds = {
  default: "text-gray-800 bg-white hover:bg-gray-100",
  inverted: "text-white bg-blue-600 hover:bg-blue-800",
};

const Button = forwardRef(({ className, children, iconName, kind, onClick, isDisabled }, ref) => (
  <button
    type="button"
    disabled={isDisabled}
    ref={ref}
    onClick={onClick}
    className={clsx(
      "px-3 h-10 inline-flex items-center font-semibold",
      "focus:outline-none focus:shadow-outline",
      buttonKinds[kind],
      className,
      isDisabled && "cursor-not-allowed",
    )}
  >
    {iconName && <Icon name={iconName} className={clsx("w-4 mr-2", iconKinds[kind])} />}
    {children}
  </button>
));

const Dropdown = ({ className, labelText, checkPermissions, name, children }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState();
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const outerRef = useRef();

  const handleOutsideClick = (event) => {
    const { target } = event;
    const buttonNode = dropdownButtonRef.current;
    const menuNode = dropdownMenuRef.current;

    if (!buttonNode?.contains(target) && !menuNode?.contains(target)) {
      setIsDropdownVisible(false);
    }
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  });

  const shouldRender = Children.toArray(children).some((child) => {
    const { action } = child?.props;

    return checkPermissions && action && checkPermissions(action);
  });

  if (!shouldRender) {
    return false;
  }

  return (
    <div className={clsx("flex items-center", className)} ref={outerRef}>
      <Button
        name={name}
        onClick={handleButtonClick}
        ref={dropdownButtonRef}
        iconName={isDropdownVisible ? "chevron-up" : "chevron-down"}
      >
        {labelText}
      </Button>

      {isDropdownVisible && (
        <div
          ref={dropdownMenuRef}
          className="absolute top-0 z-10 bg-transparent"
          style={{ left: outerRef?.current?.offsetLeft }}
        >
          <ul className="text-gray-800 text-sm mt-10 border-gray-500 border bg-white rounded max-h-56 min-w-64 overflow-hidden focus:outline-none shadow-lg">
            {Children.toArray(children).map((child) => {
              const { action } = child?.props;

              if (checkPermissions && action && !checkPermissions(action)) {
                return false;
              }

              return cloneElement(child, {
                children: action ? ACTION_TEXT_MAP[action] || action : child.props.children,
              });
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ children, className, iconName, onClick }) => (
  <li className={clsx("px-3 h-10 flex items-center hover:bg-gray-100 cursor-pointer", className)} onClick={onClick}>
    {iconName && <Icon name={iconName} className={clsx("w-4 mr-2 text-gray-600")} />}
    {children}
  </li>
);

const ButtonGroup = ({ children, className, checkPermissions, ...props }) => (
  <div className="relative">
    <div
      className={clsx("inline-flex border border-gray-500 rounded overflow-hidden text-sm font-semibold", className)}
      {...props}
    >
      {Children.toArray(children).map((child) => {
        const { action } = child?.props;

        if (checkPermissions && action && !checkPermissions(action)) {
          return false;
        }

        return cloneElement(child, {
          className: clsx(child?.props?.className, "border-r border-gray-500 last:border-0"),
          children: action ? ACTION_TEXT_MAP[action] || action : child.props.children,
          checkPermissions,
        });
      })}
    </div>
  </div>
);

Button.defaultProps = {
  kind: "default",
};

ButtonGroup.Button = Button;
ButtonGroup.Dropdown = Dropdown;
ButtonGroup.DropdownItem = DropdownItem;

export default ButtonGroup;

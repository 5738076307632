import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputTemperature = ({
  className,
  defaultValue,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  labelText,
  name,
  noErrorLabel,
  onChange,
  value,
}) => {
  // TODO: °F
  const unit = "°C";
  const val = value?.value;

  const handleChange = ({ floatValue }) => {
    if (onChange) {
      onChange(floatValue || floatValue === 0 ? { value: floatValue, unit } : "", name);
    }
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      noErrorLabel={noErrorLabel}
    >
      {!isReadOnly && (
        <div className="relative">
          <div className="absolute inset-y-0 right-0 flex items-center justify-center w-8">°C</div>

          <NumberFormat
            allowNegative
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className="aui-input aui-input-temp"
            customInput={Input}
            decimalScale={0}
            defaultValue={defaultValue && defaultValue.value}
            errors={errors}
            fixedDecimalScale
            id={name}
            isNumericString
            maxLength="22"
            name={name}
            onValueChange={handleChange}
            thousandSeparator
            value={val}
            isRequired={isRequired}
          />
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly
          key={JSON.stringify(value)}
          id={id || name}
          name={name}
          errors={errors}
          value={(value.amount || value.amount === 0) && value}
        />
      )}
    </InputField>
  );
};

InputTemperature.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

InputTemperature.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  value: {},
};

export default InputTemperature;

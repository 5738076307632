import pointer from "json-pointer";
import traverse from "json-schema-traverse";
import mergeWith from "lodash.mergewith";
import unset from "lodash.unset";
import ajv from "./ajv";

const keywords = ["allOf", "anyOf", "oneOf", "not", "if", "then", "else"];

const customizer = (key) => (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    // HACK: for cargo-us, change customizer to display different sets of cargo types
    // https://trello.com/c/JApXoBju/86-change-temperature-controlled
    if (key === "temperature_control") {
      return srcValue;
    }

    return objValue.concat(srcValue.filter((item) => objValue.indexOf(item) === -1));
  }
};

const isKeyword = (item) => keywords.includes(item);

export const resolveRefs = (schema) => {
  traverse(schema, {
    cb: (currSchema) => {
      if ("$ref" in currSchema) {
        const pathToDefinition = currSchema?.["$ref"]?.split("#")?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, "$ref");
      }
    },
  });
};

export const resolveSchema = (parentSchema, formValues = {}) => {
  const hasProperties = "properties" in parentSchema;
  const hasDependencies = "dependencies" in parentSchema;
  const dependencies = hasDependencies ? parentSchema.dependencies : {};
  const hasIf = "if" in parentSchema;
  const hasThen = "then" in parentSchema;
  const hasElse = "else" in parentSchema;

  Object.keys(formValues).forEach((key) => {
    const fieldValue = formValues[key];
    const fieldDeps = dependencies[key];
    const fieldHasValue = fieldValue !== "" && fieldValue !== undefined;
    const fieldHasDeps = Boolean(fieldDeps);
    const fieldHasKeywordDeps = fieldHasDeps && Object.keys(fieldDeps).some(isKeyword);
    const fieldHasOneOfDeps = fieldHasKeywordDeps && "oneOf" in fieldDeps;

    if (fieldHasDeps && fieldHasValue) {
      if (fieldHasOneOfDeps) {
        const extendedItem = fieldDeps.oneOf.find((item) => item.properties[key].enum[0] === fieldValue);

        unset(extendedItem.properties, key);
        mergeWith(parentSchema, extendedItem, customizer(key));
      }

      if (!fieldHasOneOfDeps) {
        mergeWith(parentSchema, fieldDeps, customizer(key));
      }

      unset(parentSchema, "dependencies");

      return resolveSchema(parentSchema, formValues);
    }
  });

  if (hasIf && hasProperties) {
    const isSubschemaValid = ajv.compile(parentSchema.if)(formValues);

    if (isSubschemaValid && hasThen) {
      mergeWith(parentSchema, parentSchema.then, customizer());
    }

    if (!isSubschemaValid && hasElse) {
      mergeWith(parentSchema, parentSchema.else, customizer());
    }

    unset(parentSchema, "if");

    return resolveSchema(parentSchema, formValues);
  }

  if (hasProperties) {
    Object.keys(parentSchema.properties).forEach((key) => {
      if (typeof formValues[key] === "object" && formValues[key] !== null) {
        return resolveSchema(parentSchema.properties[key], formValues[key]);
      }
    });
  }

  return parentSchema;
};

export default resolveSchema;

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ACTIONS } from "../../constants";
import ajv from "../../helpers/ajv";
import { resolveSchema } from "../../helpers/resolveSchema";
import Button from "../components/Button";
import Form from "../components/Form";
import Modal from "../components/Modal";
import mapChildren from "../helpers/mapChildren";
import { useContractQuery } from "../hooks";
import { useMutateContractQuery } from "../hooks/useMutateContractQuery";
import CancelEndorsementModal_cargo from "./CancelEndorsementModal_cargo";

const CancelEndorsementForm = ({ handleClose, schemaData, contractData, setIsCreating }) => {
  const { createContract, contractId, productRef, isCreating } = useMutateContractQuery();
  useEffect(() => {
    setIsCreating(isCreating);
  }, [isCreating]);
  const [formValues, setFormValues] = useState({});
  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;
      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;
      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await createContract({
      productRef,
      contractId,
      data: { type: ACTIONS.CANCEL_ENDORSEMENT, payload: formValues },
    });

    handleClose();
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
          ctx: { contractData },
        })}

        <div className="flex">
          <Button className="mr-4" isDisabled={!isValid || isCreating} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const CancelEndorsementModal = ({ handleClose }) => {
  const { contractData, isLoading, schemaData } = useContractQuery();
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && `Cancel policy - #${contractData.ref}`}
      isLoading={isLoading || isCreating}
    >
      <CancelEndorsementForm
        contractData={contractData}
        handleClose={handleClose}
        schemaData={schemaData.properties.CancelEndorsementForm}
        setIsCreating={setIsCreating}
      />
    </Modal>
  );
};

CancelEndorsementModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? CancelEndorsementModal_cargo : CancelEndorsementModal;

import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const FlagPolicyModal = ({ handleClose, handleConfirm, isLoading }) => (
  <Modal handleClose={handleClose} headingText="Flag policy">
    <div className="p-6 w-160">
      <p className="mb-6">Are you sure you want to flag this policy?</p>

      <div className="flex">
        <Button kind="primary" onClick={handleConfirm} className="mr-4" isDisabled={isLoading}>
          Flag policy
        </Button>

        <Button kind="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

FlagPolicyModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FlagPolicyModal;

import React from "react";
import InputPercentage from "../InputPercentage";

const InputPercentageSchema = ({ item, ...props }) => (
  <InputPercentage
    {...props}
    isAllowed={({ floatValue, value }) => {
      if (value === "" || (!item?.maximum && !item?.minimum)) {
        return true;
      }

      if (item?.maximum) {
        return floatValue <= item?.maximum;
      }

      if (item?.minimum) {
        return floatValue >= item?.minimum;
      }
    }}
  />
);

export default InputPercentageSchema;

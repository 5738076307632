import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const EditQuoteLockedWarningModal = ({ handleClose }) => (
  <Modal handleClose={handleClose} className="max-w-sm">
    <div className="p-10">
      <div className="flex justify-center mb-6">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
          <rect width="48" height="48" rx="24" fill="#FEE2E2" />
          <path
            d="M28 23V24H29V23H28ZM20 23H19V24H20V23ZM25 27C25 26.4477 24.5523 26 24 26C23.4477 26 23 26.4477 23 27H25ZM23 29C23 29.5523 23.4477 30 24 30C24.5523 30 25 29.5523 25 29H23ZM18 24H30V22H18V24ZM31 25V31H33V25H31ZM30 32H18V34H30V32ZM17 31V25H15V31H17ZM18 32C17.4477 32 17 31.5523 17 31H15C15 32.6569 16.3431 34 18 34V32ZM31 31C31 31.5523 30.5523 32 30 32V34C31.6569 34 33 32.6569 33 31H31ZM30 24C30.5523 24 31 24.4477 31 25H33C33 23.3431 31.6569 22 30 22V24ZM18 22C16.3431 22 15 23.3431 15 25H17C17 24.4477 17.4477 24 18 24V22ZM27 19V23H29V19H27ZM28 22H20V24H28V22ZM21 23V19H19V23H21ZM24 16C25.6569 16 27 17.3431 27 19H29C29 16.2386 26.7614 14 24 14V16ZM24 14C21.2386 14 19 16.2386 19 19H21C21 17.3431 22.3431 16 24 16V14ZM23 27V29H25V27H23Z"
            fill="#DC2626"
          />
        </svg>
      </div>

      <p className="text-xl text-center mb-6 font-semibold">Submission locked</p>

      <p className="mb-6 text-center">
        You can not currently edit the submission. The submission has been locked and referred to our underwriters for
        review.
      </p>

      <Button onClick={handleClose} className="w-full">
        Go back
      </Button>
    </div>
  </Modal>
);

EditQuoteLockedWarningModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default EditQuoteLockedWarningModal;

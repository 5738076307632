import React from "react";
import ContractDetails from "../ContractDetails";

const ContractViewDetails = ({ schemaData, contractData, parentContractData, snapshotData, clauseData }) => (
  <ContractDetails
    allowHistoryTooltip
    clauseData={clauseData}
    contractData={contractData}
    parentContractData={parentContractData}
    isCurrent
    schemaData={schemaData}
    snapshotData={snapshotData}
  />
);

export default ContractViewDetails;

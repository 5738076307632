import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation } from "react-query";
import * as api from "../api";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCalendar from "../components/InputCalendar";
import InputText from "../components/InputText";
import Modal from "../components/Modal";
import PageLoader from "../components/PageLoader";
import DownloadBdxModalCargoUs from "./DownloadBdxModal_CargoUs";

const DownloadBdxModal = ({ handleClose, productRef }) => {
  const { mutateAsync: createBdx, ...createBdxQuery } = useMutation(api.createBdx);
  const [formValues, setFormValues] = useState({});

  const onChange = (...args) => {
    if (args.length === 1) {
      const [event] = args;

      setFormValues({ ...formValues, ...{ [event.target.name]: event.target.value } });
    }

    if (args.length === 2) {
      const [value, name] = args;

      setFormValues({ ...formValues, ...{ [name]: value } });
    }
  };

  const handleSubmit = async () => {
    const res = await createBdx({ productRef, data: formValues });
    const url = window.URL.createObjectURL(new Blob([res.data.data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `bordereau-${formValues.period}.csv`);
    document.body.appendChild(link);
    link.click();
    handleClose();
  };

  if (createBdxQuery.isLoading) return <PageLoader />;

  return (
    <Modal handleClose={handleClose} headingText="Download bordereau">
      <div className="p-6 w-160">
        <Form onSubmit={handleSubmit}>
          <InputText
            labelText="UMR"
            name="umr"
            value={formValues.umr}
            isRequired
            className="mb-8"
            onChange={onChange}
          />
          <InputCalendar
            className="mb-4"
            dayPickerProps={{
              fromMonth: moment("2020-12-01").toDate(),
              toMonth: moment().endOf("month").toDate(),
              month: moment().subtract(1, "months").endOf("month").toDate(),
              disabledDays: [{ before: moment("2020-12-01").toDate() }],
            }}
            isRequired
            labelText="Choose a date"
            placeholder="MMM/YYYY"
            format="MMM/YYYY"
            onChange={onChange}
            value={formValues.period}
            name="period"
          />

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={createBdxQuery.isLoading || !formValues.period || !formValues.umr}
              kind="primary"
              type="submit"
            >
              Download
            </Button>

            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

DownloadBdxModal.propTypes = {
  handleClose: PropTypes.func,
};

DownloadBdxModal.defaultProps = {
  handleClose: undefined,
};

const defaultProductRef = process.env.DEFAULT_PRODUCT_REF;

export default defaultProductRef === "cargo-us" ? DownloadBdxModalCargoUs : DownloadBdxModal;

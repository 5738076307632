import get from "lodash.get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import pickCommaSeparate from "../../helpers/pickCommaSeparate";
import useScript from "../hooks/useScript";
import Input from "./Input";
import InputField from "./InputField";

/*
  If this component is written in functional style, there's a problem with `onPlaceChange` giving
  stale results, because it's inside event handler. Normally you'd use useEffect to refresh values
  when they change, but Google Autocomplete won't cleanup extra dropown nodes which leads to some
  odd behaviour, multiple dropdowns being renderd etc. Beware.
*/

class InputAddress extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
  }

  componentDidMount() {
    const node = this.ref.current;

    this.autocomplete = new window.google.maps.places.Autocomplete(node, {
      types: ["geocode"],
      componentRestrictions: {
        country: process.env.DEFAULT_PRODUCT_REF === "mel" ? "us" : this.props.allowedCountries,
      },
    });

    this.listener = this.autocomplete.addListener("place_changed", this.handlePlaceChange);
  }

  componentWillUnmount() {
    window.google.maps.event.removeListener(this.listener);
  }

  handlePlaceChange() {
    const { onPlaceChange } = this.props;
    const place = this.autocomplete.getPlace();
    const address = get(place, "address_components", []).reduce(
      (prev, next) => ({ ...prev, [next.types[0]]: next.long_name }),
      {},
    );

    const line1elements = ["street_number", "route"];
    const line2elements = ["sublocality", "neighborhood"];

    const newPlace = {
      address_line_1: pickCommaSeparate(address, line1elements),
      address_line_2: pickCommaSeparate(address, line2elements) || undefined,
      city: address.postal_town || address.locality,
      zipcode: address.postal_code,
      state: address.administrative_area_level_1,
      country: address.country,
    };

    onPlaceChange(newPlace);
  }

  render() {
    const {
      className,
      defaultValue,
      descriptionText,
      errors,
      id,
      isReadOnly,
      isRequired,
      labelText,
      name,
    } = this.props;

    return (
      <InputField
        className={className}
        descriptionText={descriptionText}
        errors={errors}
        id={id || name}
        isRequired={isRequired}
        labelText={labelText}
        name={name}
      >
        <Input
          defaultValue={defaultValue}
          id={id || name}
          isReadOnly={isReadOnly}
          name={name}
          ref={this.ref}
          required={isRequired}
          errors={errors}
        />
      </InputField>
    );
  }
}

const key = process.env.GOOGLE_PLACES_API_KEY;
const url = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;

const InputAddressWrapper = (props) => {
  const [loaded] = useScript(url);

  if (!loaded || !window.google) {
    return false;
  }

  return <InputAddress {...props} />;
};

InputAddress.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onPlaceChange: PropTypes.func.isRequired,
  allowedCountries: PropTypes.array,
};

InputAddress.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  labelText: undefined,
  allowedCountries: [],
};

export default InputAddressWrapper;

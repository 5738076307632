import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import { compile } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ProductRef, Stage } from "../../../@types/types";
import { ACTIONS, MODALS, ROUTES, STATUSES } from "../../../constants";
import { CONTRACT_TYPES } from "../../../constants/contractTypes";
import ajv from "../../../helpers/ajv";
import { resolveSchema } from "../../../helpers/resolveSchema";
import { useCargoEffects } from "../../../projects/cargo-us/useCargoEffects";
import { useMCEffects } from "../../../projects/mc/useMCEffects";
import { useMelEffects } from "../../../projects/mel/useMelEffects";
import Debugger from "../../components/Debugger";
import Form from "../../components/Form";
import mapChildren from "../../helpers/mapChildren";
import { toBase64 } from "../../helpers/toBase64";
import { useAuth, useModal } from "../../hooks";
import { useHistoryBlock } from "../../hooks/useHistoryBlock";
import { useMagicLink } from "../../hooks/useMagicLink";
import { useMutateContractQuery } from "../../hooks/useMutateContractQuery";
import SubmitBespokeEndorsementModal from "../../modals/SubmitBespokeEndorsementModal";
import DemoSubmissionForm from "./DemoSubmissionForm";
import FormNavigation from "./FormNavigation";

const defaultProductRef = process.env.DEFAULT_PRODUCT_REF as ProductRef;

const byFileType = (node) => node.type === "file";

// const clearValues = (formValues) => {
//   const clonedValues = cloneDeep(formValues);
//   const dolKey = "loss_record.loss_details";
//   const isDolArray = Array.isArray(get(clonedValues, dolKey));

//   if (isDolArray) {
//     const filteredDol = get(clonedValues, dolKey, []).filter((item) => !isEmpty(item));

//     /* Remove empty array items from DOL */
//     set(clonedValues, dolKey, filteredDol);
//   }

//   return clonedValues;
// };

// set(parsed, "diving.type_of_diving", parsed?.diving?.type_of_diving || {});

// if (parsed?.loss_record?.has_insured_had_jones_act_claims === "Yes (provide details)") {
//   set(parsed, "loss_record.loss_details", parsed?.loss_record?.loss_details || [{}]);
// }

const SubmissionForm = ({
  contractData,
  currentContractData,
  parentContractData,
  isDebuggerVisible,
  isEditEndorsementRoute,
  isEditing,
  isNewBespokeEndorsementRoute,
  isNewEndorsementRoute,
  isRenewalRoute,
  isUploading,
  schemaData,
  setDebuggerVisible,
  showDraftButton,
  uploadAttachment,
  schemaId,
  schemaRef,
  schemaVersion,
}) => {
  const [showBespokeModal, setShowBespokeModal] = useState(isNewBespokeEndorsementRoute);
  const params = useParams<{ productRef: ProductRef; contractId: string; endorsementId: string }>();
  const { productRef, contractId, endorsementId } = params;
  const [bespokeEndoFormValues, setBespokeEndoFormValues] = useState({});
  const { push } = useHistory();
  const { createContract, updateContract, isCreating, isUpdating } = useMutateContractQuery();
  const { showModal } = useModal();
  const queryClient = useQueryClient();
  const { isInternalEnv, isUatEnv, tenant } = useAuth();
  const prevSubmission = currentContractData.submission;
  const { initialFormValues, formValues, setFormValues } = useMagicLink(prevSubmission);

  const [formErrors, setFormErrors] = useState();
  const isFormDirty = JSON.stringify(initialFormValues) !== JSON.stringify(formValues);
  const clonedSchema = cloneDeep(schemaData);
  const validate = ajv.compile(schemaData);
  const isValid = validate(formValues);
  // const isInsuredValid = formValues?.general_information?.insured_name?.trim().length > 0;
  const isRenewal = currentContractData.type === CONTRACT_TYPES.RENEWAL;
  const isSubmitting = isCreating || isUpdating || isUploading;

  const forcePush = (path) => push(path, { isForced: true });

  const handleChange = (...args) => {
    const nextFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(nextFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(nextFormValues, name, value);
    }

    setFormValues(nextFormValues);
  };

  const maybeUploadFile = async (inputFile, id) => {
    const file = inputFile?.files?.[0];

    if (file) {
      const content = await toBase64(file);

      await uploadAttachment({
        contractId: id,
        productRef,
        data: {
          content,
          fileName: file.name,
          documentType: "submission_attachment",
          subType: "loss_run",
          documentId: inputFile.dataset.uuid,
        },
      });
    }
  };

  const handleSubmit = async (formData, bespokeSubmission) => {
    const inputFile = Array.from(document.forms.submissionForm.elements).find(byFileType);
    const submission = formData;
    const submit = isEditing ? updateContract : createContract;
    const type = isEditing ? ACTIONS.UPDATE_SUBMISSION : ACTIONS.CREATE_SUBMISSION;
    const renewedFrom = isRenewalRoute ? contractId : (isRenewal ? contractData?.renewedFrom : null);
    let referralReasons = null;
    if (isRenewalRoute) {
      referralReasons = currentContractData.referralReasons;
    } else if (isRenewal) {
      referralReasons =
        currentContractData.status === STATUSES.DRAFT
          ? parentContractData.referralReasons
          : currentContractData.referralReasons;
    }
    const data = { type, renewedFrom, referralReasons, payload: { submission, ...bespokeSubmission, schemaId } };

    try {
      const res = await submit(
        { productRef, contractId, endorsementId, data },
        {
          onSuccess: () => {
            queryClient.resetQueries("contract", { productRef, contractId });
            queryClient.resetQueries("endorsements", { productRef, contractId });
          },
        },
      );

      const nextId = res?.data?.data?.id;

      await maybeUploadFile(inputFile, nextId);

      if (isNewEndorsementRoute || isEditEndorsementRoute) {
        return forcePush(compile(ROUTES.CONTRACT_VIEW_ENDORSEMENTS)(params));
      }

      return forcePush(compile(ROUTES.CONTRACT_VIEW_DETAILS)({ ...params, contractId: nextId }));
    } catch (error) {
      if (["sanctionsCheckInsuredFail", "sanctionsCheckAssociatedCompanyFail", "sanctionsCheckSubsidiaryCompanyFail"].includes(error?.response?.data?.errorType)) {
        showModal(MODALS.SANCTIONS_CHECK_FAIL, { message: error?.response?.data?.errorMessage });
      }
    }
  };

  const handleSubmitDraft = async (formData, bespokeSubmission) => {
    const inputFile = Array.from(document.forms.submissionForm.elements).find(byFileType);
    const submission = formData;
    const submit = isEditing ? updateContract : createContract;
    const type = isEditing ? ACTIONS.UPDATE_DRAFT_SUBMISSION : ACTIONS.CREATE_DRAFT_SUBMISSION;
    const renewedFrom = isRenewalRoute ? contractId : (isRenewal ? contractData?.renewedFrom : null);
    const data = { type, renewedFrom, payload: { submission, ...bespokeSubmission, schemaId } };
    const res = await submit({ productRef, contractId, endorsementId, data });
    const nextId = res?.data?.data?.id;

    await maybeUploadFile(inputFile, nextId);

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return forcePush(compile(ROUTES.ENDORSEMENT_EDIT)({ ...params, endorsementId: nextId }));
    }

    return forcePush(compile(ROUTES.CONTRACT_EDIT)({ ...params, contractId: nextId }));
  };

  const handleBeforeSubmit = () => {
    // const parsed = Object.keys(schemaData.properties).reduce(
    //   (prev, next) => ({ ...prev, [next]: prev[next] || {} }),
    //   formValues || {},
    // );

    const validateForm = ajv.compile(schemaData);
    const isFormValid = validateForm(formValues);
    const errors = validateForm?.errors
      ?.map((error) => {
        const { missingProperty } = error?.params;
        const dataPath = error.dataPath.substring(1);

        return { ...error, path: missingProperty ? `${dataPath}.${missingProperty}` : dataPath };
      })
      .filter((error) => error.keyword !== "enum");

    if (!isFormValid) {
      return setFormErrors(errors);
    }

    if (isNewBespokeEndorsementRoute) {
      return showModal(MODALS.SUBMIT_BESPOKE_ENDORSEMENT, {
        bespokeEndoFormValues,
        formData: formValues,
        handleSubmitForm: handleSubmit,
        setBespokeEndoFormValues,
        isConfirmation: true,
      });
    }

    if (isNewEndorsementRoute || isEditEndorsementRoute) {
      return showModal(MODALS.SUBMIT_ENDORSEMENT, { formData: formValues, handleSubmitForm: handleSubmit });
    }

    if (
      (isRenewalRoute && contractData?.bespoke_endorsements?.length) ||
      (isRenewal && parentContractData?.bespoke_endorsements?.length > 0)
    ) {
      return showModal(MODALS.SUBMIT_RENEWAL_BESPOKE, { formData: formValues, handleConfirm: handleSubmit });
    }

    return handleSubmit(formValues);
  };

  const handleBeforeSubmitDraft = () => {
    // const parsed = Object.keys(schemaData.properties).reduce(
    //   (prev, next) => ({ ...prev, [next]: prev[next] || {} }),
    //   formValues || {},
    // );

    const validateForm = ajv.compile(schemaData);
    validateForm(formValues);

    const errors = validateForm?.errors?.map((error) => {
      const { missingProperty } = error?.params;
      const dataPath = error.dataPath.substring(1);

      return { ...error, path: missingProperty ? `${dataPath}.${missingProperty}` : dataPath };
    });

    const insuredErrors = errors?.filter((error) => error?.params?.missingProperty === "insured_name");

    if (insuredErrors?.length > 0) {
      return setFormErrors(insuredErrors);
    }

    if (
      (isRenewalRoute && contractData?.bespoke_endorsements?.length) ||
      (isRenewal && parentContractData?.bespoke_endorsements?.length > 0)
    ) {
      return showModal(MODALS.SUBMIT_RENEWAL_BESPOKE, { formData: formValues, handleConfirm: handleSubmitDraft });
    }

    return handleSubmitDraft(formValues);
  };

  const handleSaveTemplateClick = () => {
    showModal(MODALS.DEBUGGER_CREATE_TEMPLATE, { submissionForm: formValues, schemaRef, schemaVersion });
  };


  resolveSchema(clonedSchema, formValues);
  useHistoryBlock({ isDirty: isFormDirty });

  if (defaultProductRef === "mel") useMelEffects(formValues, setFormValues, tenant);
  if (defaultProductRef === "cargo-us") useCargoEffects(initialFormValues, formValues, setFormValues, clonedSchema, schemaRef);
  if (defaultProductRef === "mc") useMCEffects(formValues, setFormValues, clonedSchema, schemaRef);


  useEffect(() => {
    const errors = document.querySelectorAll("[data-error=true]");

    if (errors.length > 0) {
      window.scrollTo({ top: errors?.[0]?.offsetTop - 80, behavior: "smooth" });
    }
  }, [formErrors]);

  return (
    <div>
      {showBespokeModal && (
        <SubmitBespokeEndorsementModal
          bespokeEndoFormValues={bespokeEndoFormValues}
          formData={formValues}
          handleClose={() => setShowBespokeModal(false)}
          setBespokeEndoFormValues={setBespokeEndoFormValues}
          handleSubmitForm={handleSubmit}
        />
      )}

      {(isInternalEnv || isUatEnv) && isDebuggerVisible && (
        <Debugger
          schemaRef={schemaRef}
          handleClose={() => setDebuggerVisible(false)}
          formValues={formValues}
          validationErrors={validate.errors || []}
          tenant={contractData.placingBroker || tenant}
        />
      )}

      <div className="mx-10 pb-24 flex items-start mt-12">
        <Form className="max-w-3xl w-full" id="submissionForm">
          {mapChildren({
            formValues,
            onChange: handleChange,
            parentKey: "",
            parentSchema: clonedSchema,
            setFormValues,
            validationErrors: formErrors,
            showQuestionKey: isInternalEnv,
          })}
        </Form>

        <FormNavigation
          canSaveDraft={!isSubmitting}
          canSubmit={!isSubmitting}
          formValues={formValues}
          handleSubmit={handleBeforeSubmit}
          handleSubmitDraft={handleBeforeSubmitDraft}
          schema={clonedSchema}
          showDraftButton={showDraftButton}
          handleSaveTemplateClick={handleSaveTemplateClick}
          isRenewal={isRenewal || isRenewalRoute}
        />
      </div>
    </div>
  );
};

export default process.env.DEFAULT_PRODUCT_REF === "demo" ? DemoSubmissionForm : SubmissionForm;

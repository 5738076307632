import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const H2 = ({ className, children }) => (
  <h2 className={clsx("text-2xl leading-9 font-semibold", className)}>{children}</h2>
);

H2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

H2.defaultProps = {
  children: undefined,
  className: undefined,
};

export default H2;

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const variants = {
  blue: " border-blue-600",
  green: "border-green-700",
  orange: "border-orange-700",
  red: "border-red-1000",
  white: "border-gray-400",
};

const Banner = ({ className, children, color, headingText, cb }) => {
  useEffect(() => {
    const timeout = setTimeout(cb, 3000);

    return () => clearTimeout(timeout);
  });

  return (
    <div
      className={clsx(
        "py-4 px-4 rounded border-solid shadow border-t-4 bg-white z-10",
        variants[color],
        className
      )}
    >
      {headingText && <p className="font-semibold text-gray-900">{headingText}</p>}

      <div className="mt-2 text-gray-800">{children}</div>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(["blue", "green", "orange", "red", "white"]),
  headingText: PropTypes.string,
};

Banner.defaultProps = {
  children: undefined,
  className: undefined,
  color: "green",
  headingText: undefined,
};

export default Banner;

import Auth from "@aws-amplify/auth";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import { ROUTES } from "../../../constants";
import { parseParams } from "../../../helpers/parseParams";
import Button from "../../components/Button";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import Icon from "../../components/Icon";
import InputText from "../../components/InputText";
import useForm from "../../hooks/useForm";
import AuthLayout from "./AuthLayout";

const PasswordCondition = ({ isValid, description }) => (
  <div className="flex items-center mb-3">
    {!isValid && <Icon name="cross" className="fill-current mr-2 w-3 text-red-900" />}

    {isValid && <Icon name="checkmark" className="fill-current mr-2 w-3 text-green-900" />}

    <p>{description}</p>
  </div>
);

const checkMatch = function (value) {
  return value.length >= 10 && value === this.parent.confirmNewPassword;
};

const conditions = {
  minimumCharacters: "Must be a minimum of 10 characters.",
  uppercaseLowercase: "Must contain upper and lower case letters.",
  numberMinimum: "Must contain at least 1 number (between 0-9)",
  specialCharacter: "Must contain at least 1 special character (@$!%*?&)",
  passwordMatch: "Password confirmation must match password",
};

const PasswordForm = () => {
  const { push, location } = useHistory();
  const { type } = parseParams(location.search);
  const isReset = useRouteMatch(ROUTES.RESET_PASSWORD);
  const isSetup = useRouteMatch(ROUTES.SETUP_PASSWORD);
  const isRegister = useRouteMatch(ROUTES.REGISTER_USER);
  const isExternal = isRegister && type !== "internal";

  const { getFieldProps, onSubmit, validationErrors, canSubmit } = useForm({
    initialState: { newPassword: "", confirmNewPassword: "" },
    onSubmit: async (formValues) => {
      if (isReset) {
        const { email, code } = parseParams(location.search);

        await Auth.forgotPasswordSubmit(email, code, formValues.newPassword);

        return push(ROUTES.LOGIN);
      }

      if (isSetup) {
        const signInFormData = get(location, "state");
        const user = await Auth.signIn(signInFormData.email.trim(), signInFormData.password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(user, formValues.newPassword);

          return push(ROUTES.LOGIN);
        }
      }

      if (isRegister) {
        const { inviteCode, userId } = parseParams(location.search);
        const user = await Auth.signIn(userId, inviteCode);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(user, formValues.newPassword);

          return push(ROUTES.LOGIN);
        }
      }

      return false;
    },
    validationOptions: { abortEarly: false },
    validationSchema: yup.object().shape({
      newPassword: yup
        .string()
        .min(10, conditions.minimumCharacters)
        .matches(/^(?=.*[A-Z])(?=.*[a-z])/, conditions.uppercaseLowercase)
        .matches(/^(?=.*[0-9])/, conditions.numberMinimum)
        .matches(/^(?=.*[!@#$%^&*()_+\-[\]{};':"\\|,.<>/?])/, conditions.specialCharacter)
        .test("match", conditions.passwordMatch, checkMatch)
        .required(),
      confirmNewPassword: yup.string(),
    }),
  });

  if (
    isExternal &&
    typeof process.env.EXTERNAL_IDENTITY_PROVIDER !== "undefined" &&
    process.env.EXTERNAL_IDENTITY_PROVIDER
  ) {
    return (
      <AuthLayout>
        <Button
          type="submit"
          className="w-full"
          kind="primary"
          onClick={() => Auth.federatedSignIn({ customProvider: process.env.EXTERNAL_IDENTITY_PROVIDER })}
        >
          Sign up with {process.env.CLIENT_REF === "chaucer" ? "Azure AD" : "JumpCloud"}
        </Button>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Form onSubmit={onSubmit}>
        <H3 className="mb-4">{isRegister ? "Register your account" : "Create new password"}</H3>

        <p className="mb-6">For security we require that your password matches all of these requirements:</p>

        <div className="mb-6">
          {Object.keys(conditions).map((key) => {
            const condition = conditions[key];

            return (
              <PasswordCondition key={key} description={condition} isValid={!validationErrors.includes(condition)} />
            );
          })}
        </div>

        <InputText
          className="mb-6"
          labelText={isRegister ? "Password" : "New Password"}
          placeholder="*********"
          type="password"
          {...getFieldProps("newPassword")}
        />

        <InputText
          className="mb-6"
          labelText={isRegister ? "Confirm Password" : "Confirm New Password"}
          placeholder="*********"
          type="password"
          {...getFieldProps("confirmNewPassword")}
        />

        <Button kind="primary" type="submit" isDisabled={!canSubmit}>
          {isRegister ? "Register" : "Create New Password"}
        </Button>
      </Form>
    </AuthLayout>
  );
};

PasswordCondition.defaultProps = {
  isValid: undefined,
};

PasswordCondition.propTypes = {
  isValid: PropTypes.bool,
  description: PropTypes.string.isRequired,
};

export default PasswordForm;

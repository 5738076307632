import clsx from "clsx";
import React, { Children, FC } from "react";
import { SchemaRef } from "../../@types/types";
import { checkDeclined as checkCargoDeclined } from "../../projects/cargo-us/declinatures";
import { PolicyDocument } from "../../projects/cargo-us/documents";
import { parseSubmission as parseCargoSubmission } from "../../projects/cargo-us/parseSubmission";
import { getRates as getCargoRates } from "../../projects/cargo-us/rater";
import { checkReferred as checkCargoReferred } from "../../projects/cargo-us/referrals";
import { CargoBroker, CargoContract, CargoQuote } from "../../projects/cargo-us/types";
import { parseSubmission as parseSureSubmission } from "../../projects/mc/businessLogic";
import { parseSubmission as parseMelSubmission } from "../../projects/mel/helpers";
import { getDeductibleOptions as getMelDeductibleOptions, getRates as getMelRates } from "../../projects/mel/rater";
import { checkReferred as checkMelReferred } from "../../projects/mel/referrals";
import useStickyState from "../hooks/useStickyState";
import Icon from "./Icon";

type Column = {
  name: string;
  path?: string;
  extractValue?: any;
};

type Row = any;

const Table = ({
  columns,
  rows,
  className,
  header,
}: {
  columns: Column[];
  rows: Row[];
  className?: string;
  header?: any;
}) => {
  return (
    <table className={clsx("min-w-full divide-y divide-gray-200", className)}>
      <thead className="bg-gray-50">
        {header && (
          <tr>
            <th
              className="px-3 py-1 text-left text-xs font-bold text-gray-800 uppercase tracking-wider"
              colSpan={columns.length}
            >
              {header}
            </th>
          </tr>
        )}

        <tr>
          {columns.map((column) => (
            <th
              key={column.name}
              className="px-3 py-1 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex.toString()} className="bg-white">
            {columns.map((column, columnIndex) => (
              <td
                key={`${rowIndex.toString()}${columnIndex.toString()}`}
                className="px-3 py-1 whitespace-nowrap text-sm text-gray-800"
              >
                {column?.extractValue ? column?.extractValue(row, rowIndex) : row[column?.path]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const getRaterOutput = (schemaRef: SchemaRef, submission: any) => {
  try {
    if (schemaRef === "cargo-us-annual" || schemaRef === "cargo-us-single") {
      return getCargoRates(submission, schemaRef);
    }

    if (schemaRef === "mel") {
      const parsedSubmission = parseMelSubmission(submission);

      return getMelDeductibleOptions(parsedSubmission.payroll).map((option) => getMelRates(parsedSubmission, option));
    }

    if (schemaRef === "sure-mc-surgeons") {
      return []; // TODO
    }

    if (schemaRef === "sure-mc-hospitals-and-clinics") {
      return []; // TODO
    }
  } catch (error) {
    return error;
  }
};

const getReferred = (schemaRef: SchemaRef, formValues: any): string[] => {
  try {
    if (schemaRef === "mel") {
      return checkMelReferred({ submission: formValues })?.reasons;
    }

    if (schemaRef === "cargo-us-annual" || schemaRef === "cargo-us-single") {
      return checkCargoReferred(formValues, schemaRef)?.reasons;
    }

    return [];
  } catch (error) {
    return error;
  }
};

const getDeclined = (schemaRef: SchemaRef, formValues: any): string[] => {
  try {
    if (schemaRef === "cargo-us-annual" || schemaRef === "cargo-us-single") {
      return checkCargoDeclined(formValues, schemaRef)?.reasons;
    }

    return [];
  } catch (error) {
    return error;
  }
};

const getParsedSubmission = (schemaRef: SchemaRef, formValues: any) => {
  try {
    if (schemaRef === "cargo-us-annual" || schemaRef === "cargo-us-single") {
      return parseCargoSubmission(formValues, schemaRef);
    }

    if (schemaRef === "mel") {
      return parseMelSubmission(formValues);
    }

    if (schemaRef === "sure-mc-surgeons") {
      return parseSureSubmission(formValues);
    }

    if (schemaRef === "sure-mc-hospitals-and-clinics") {
      return parseSureSubmission(formValues);
    }
  } catch (error) {
    return error;
  }
};

const Dot: FC = ({ value }) => (
  <div
    className={clsx(
      "ml-1 flex items-center justify-center text-white w-4 rounded text-semibold",
      value ? "bg-red-900" : "bg-green-900",
    )}
    style={{ fontSize: "10px" }}
  >
    {value}
  </div>
);

interface TabProps {
  id: string;
  isSelected?: boolean;
  render: any;
  value: any;
  onClick?: any;
}

const Tab: FC<TabProps> = ({ id, isSelected, value, onClick }) => (
  <div
    className={clsx(
      "cursor-pointer border-b-2 border-transparent px-3 py-2 flex -my-px",
      isSelected && "border-blue-500",
    )}
    onClick={onClick}
  >
    {id} {(value || value === 0) && <Dot className="w-4" value={value} />}
  </div>
);

const Tabs: FC<{ size: DebuggerSize }> = ({ children, size }) => {
  const first = Children.toArray(children).map((child) => child.props.id)[0];
  const [selectedTab, setSelectedTab] = useStickyState(first, "debuggerTabs");
  const selectedChild =
    Children.toArray(children).find((c) => selectedTab === c?.props?.id) || Children.toArray(children)?.[0];
  const render = selectedChild?.props?.render;
  const isFn = render && render instanceof Function;

  return (
    <div className="text-xs ">
      <div className="font-semibold border-b border-gray-500">
        <div className="bg-gray-200 flex">
          {Children.toArray(children).map((child) => {
            const isSelected = selectedTab === child.props.id;

            return React.cloneElement(child, {
              ...child.props,
              isSelected,
              key: child.props.id,
              onClick: () => setSelectedTab(child.props.id),
            });
          })}
        </div>
      </div>
      <div
        className="relative p-4 overflow-y-scroll"
        style={{ height: (size === DebuggerSize.default && "50vh") || "80vh" }}
      >
        {isFn ? render() : render}
      </div>
    </div>
  );
};

interface Props {
  schemaRef: SchemaRef;
  formValues: any;
  handleClose: any;
  validationErrors: any[];
  tenant: any;
}

enum DebuggerSize {
  default = "default",
  maximized = "maximized",
}

const Debugger: FC<Props> = ({ formValues, handleClose, validationErrors = [], schemaRef, tenant }) => {
  const [size, setSize] = useStickyState(DebuggerSize.default, "debuggerSize");
  const rater = getRaterOutput(schemaRef, formValues);
  const parsedSubmission = getParsedSubmission(schemaRef, formValues);
  const referrals = getReferred(schemaRef, formValues);
  const declinatures = getDeclined(schemaRef, formValues);
  const mappedValidationErrors = validationErrors.map((e) => e.message);

  return (
    <div
      className={clsx(
        "fixed right-8 bottom-0 pb-4 border-gray-500 border z-10 bg-white rounded-t shadow-lg z-20",
        size === DebuggerSize.default && "w-1/3",
        size === DebuggerSize.maximized && "w-1/2",
      )}
    >
      <div>
        <Tabs size={size}>
          <Tab
            id="Referred"
            value={referrals.length}
            render={
              <pre className="mb-6 whitespace-pre-wrap">
                <p className="font-bold mb-4">Referral reasons</p>
                {referrals instanceof Error ? referrals?.message : JSON.stringify(referrals, null, 2)}
              </pre>
            }
          />

          {process.env.DEFAULT_PRODUCT_REF !== "mel" && (
            <Tab
              id="Declined"
              value={declinatures?.length}
              render={
                <pre className="mb-6 whitespace-pre-wrap">
                  <p className="font-bold mb-4">Decline reasons</p>
                  {declinatures instanceof Error ? declinatures?.message : JSON.stringify(declinatures, null, 2)}
                </pre>
              }
            />
          )}

          <Tab
            id="Rater"
            value={rater instanceof Error ? "1" : undefined}
            render={
              <pre className="mb-6 whitespace-pre-wrap">
                <p className="font-bold mb-4">Rater output</p>

                {rater instanceof Error ? (
                  rater?.message
                ) : process.env.DEFAULT_PRODUCT_REF === "cargo-us" ? (
                  <Table
                    className="mb-6"
                    rows={rater?.breakdown}
                    columns={[
                      { name: "Name", extractValue: (row) => row[0] },
                      { name: "Value", extractValue: (row) => row[1] },
                    ]}
                  />
                ) : (
                  JSON.stringify(rater, null, 2)
                )}
              </pre>
            }
          />

          <Tab
            id="Form"
            value={mappedValidationErrors.length}
            render={
              <pre className="mb-6 whitespace-pre-wrap">
                <p className="font-bold mb-4">Validation errors</p>
                <div className="mb-4">{JSON.stringify(mappedValidationErrors, null, 2)}</div>

                <p className="font-bold mb-4">Form values</p>
                <div className="mb-4">{JSON.stringify(formValues, null, 2)}</div>

                <p className="font-bold mb-4">Parsed form values</p>
                <div className="mb-4">{JSON.stringify(parsedSubmission, null, 2)}</div>
              </pre>
            }
          />

          {process.env.DEFAULT_PRODUCT_REF !== "mc" && (
            <Tab
              id="Docs"
              render={
                <div className="w-full h-full">
                  <PolicyDocument
                    contract={{ placingBroker: tenant as CargoBroker } as CargoContract}
                    quote={{ rates: rater, submission: formValues } as CargoQuote}
                  />
                </div>
              }
            />
          )}
        </Tabs>

        {size === DebuggerSize.default && (
          <Icon
            name="unfold-more"
            className="cursor-pointer w-3 m-3 absolute right-6 top-0 fill-current text-gray-800"
            onClick={() => setSize(DebuggerSize.maximized)}
          />
        )}

        {size === DebuggerSize.maximized && (
          <Icon
            name="unfold-less"
            className="cursor-pointer w-3 m-3 absolute right-6 top-0 fill-current text-gray-800"
            onClick={() => setSize(DebuggerSize.default)}
          />
        )}

        <Icon
          name="cross"
          className="cursor-pointer w-3 m-3 absolute right-0 top-0 fill-current text-gray-800"
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default Debugger;

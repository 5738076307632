import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import React from "react";
import Button from "../Button";

const Repeater = ({
  className,
  errors,
  formValues,
  value,
  id,
  isAddButtonEnabled,
  isReadOnly,
  isRemoveButtonEnabled,
  isRequired,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  validationErrors,
  showQuestionKey,
}) => {
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const initialItems = hasInitialValue ? value : [""];

  const handleAdd = () => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, ""]);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex) => () => {
    const cloned = cloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  const schemaItem =
    item.items.type === "object" ? item.items : { properties: { [id]: item.items } };

  const isButtonEnabled = (len, readOnly, enabled) => {
    if (len > 1) {
      if (enabled === true) {
        return true;
      }
      if (readOnly === true) {
        return false;
      }
      if (!readOnly && enabled === undefined) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  return (
    <div className={className}>
      {initialItems.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;

        return (
          <div className="flex" key={index.toString()}>
            {mapChildren({
              errors,
              formValues,
              isRequired: index === 0 ? isRequired : false,
              onChange,
              parentKey: key,
              parentSchema: schemaItem,
              setFormValues,
              showQuestionKey,
              useParentKey: true,
              validationErrors,
            })}

            {isButtonEnabled(initialItems.length, isReadOnly, isRemoveButtonEnabled) && (
              <Button kind="danger-secondary" onClick={handleRemove(index)} className="mt-8 ml-8">
                Remove
              </Button>
            )}
          </div>
        );
      })}

      {isButtonEnabled(999, isReadOnly, isAddButtonEnabled) && <Button onClick={handleAdd}>Add</Button>}
    </div>
  );
};

export default Repeater;

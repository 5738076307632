import { compile } from "path-to-regexp";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../constants";

export const useProductCheck = () => {
  const { push } = useHistory();
  const { productRef } = useParams();

  if (productRef !== process.env.DEFAULT_PRODUCT_REF) {
    return push(compile(ROUTES.PRODUCT)({ productRef: process.env.DEFAULT_PRODUCT_REF }));
  }
};

export const QUOTE_STATUSES = {
  ARCHIVED: "Archived",
  BOUND: "Bound",
  CANCELLED: "Cancelled",
  DECLINED: "Declined",
  REJECTED_NOT_TAKEN_UP: "Not Taken Up",
  SUBMITTED: "Submitted",
};

export default QUOTE_STATUSES;

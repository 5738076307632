import Tippy from "@tippyjs/react";
import clsx from "clsx";
import React from "react";
import { fullNameFormatter } from "../../../formatters";
import { getColorFromId } from "../../helpers/getColorFromId";
import { useAuth } from "../../hooks";
import useConnections from "../../hooks/useConnections";

const Avatar = ({ fullName, initials, className }) => (
  <Tippy delay={250} content={<span className="text-xs">{fullName} is viewing this document</span>}>
    <div
      className={clsx(
        "w-10 h-10 ml-2",
        "rounded-full text-white font-semibold cursor-pointer",
        "flex items-center justify-center",
        className
      )}
    >
      {initials}
    </div>
  </Tippy>
);

const Avatars = () => {
  const activeUsers = useConnections();
  const { users, user } = useAuth();

  return (
    <div className="flex ">
      {(activeUsers || [])
        .filter((activeUserId) => activeUserId !== user.id)
        .map((activeUserId) => {
          const fullName = fullNameFormatter(users.find((u) => u.sub === activeUserId));
          const bg = getColorFromId(activeUserId);
          const initials = fullName
            .split(" ")
            .map((i) => i[0])
            .join("")
            .toUpperCase();

          return (
            <Avatar key={activeUserId} className={bg} initials={initials} fullName={fullName} />
          );
        })}
    </div>
  );
};

export default Avatars;

import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ACTIONS, QUOTE_TYPES } from "../../constants";
import { currencyFormatter } from "../../formatters";
import ajv from "../../helpers/ajv";
import { resolveSchema } from "../../helpers/resolveSchema";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import Table from "../components/Table";
import mapChildren from "../helpers/mapChildren";
import { useContractQuery, useQuoteQuery } from "../hooks";
import { getFinalAdjustmentPremiumData } from "../../projects/mel/adjustablePremium";

const { Body, Row, Data } = Table;

const getApRpLabel = (aprp: number): string => (aprp >= 0 ? "Amount due" : "Amount owed");
const getApRpValue = (quoteData: any, endorsementData: any): number => {
  const { endorsementPremiumAmount } = getFinalAdjustmentPremiumData(quoteData, endorsementData);
  return Math.abs(endorsementPremiumAmount > 0 ? endorsementPremiumAmount : 0);
};

const ModalForm = ({ callback, handleClose, quoteData, contractData, schemaData, endorsementData }) => {
  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();
  const [formValues, setFormValues] = useState(quoteData.submission.quote);
  const [isBindConfirmed, setBindConfirmed] = useState(false);
  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  if (quoteData.type === QUOTE_TYPES.COMMERCIAL) {
    set(resolvedSchema, "properties.effective_from.ui:component:readonly", true);
  }

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const res = await updateQuote({
      contractId,
      data: { type: ACTIONS.BIND_ENDORSEMENT_QUOTE, payload: { effective_from: formValues.effective_from } },
      endorsementId,
      productRef,
      quoteId,
    });

    handleClose();
    callback(get(res, "data.data"));
  };

  return (
    <div className="p-6">
      <Table className="mb-8">
        <Body>
          <Row className="border-b border-gray-500 last:border-0">
            <Data>Limit required</Data>
            <Data>{currencyFormatter(endorsementData.submission.quote.limit_required)}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data>Deductible</Data>
            <Data>{currencyFormatter(endorsementData.submission.quote.deductible)}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data className="font-bold">{getApRpLabel(quoteData.rates.aprp)}</Data>
            <Data className="font-bold">
              {currencyFormatter({
                amount: endorsementData.isFinalAdjustmentPremium
                  ? getApRpValue(contractData.originalQuote, endorsementData)
                  : Math.abs(quoteData.rates.aprp),
              })}
            </Data>
          </Row>
        </Body>
      </Table>

      <Form onSubmit={handleSubmit}>
        {mapChildren({
          ctx: { contractData: endorsementData },
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        {isValid && (
          <Banner
            key={formValues.inceptionDate}
            className="mb-8"
            color="orange"
            headingText="Are you sure you want to bind this endorsement? This cannot be undone."
          >
            <InputCheckbox
              labelText=" I understand"
              name="confirm_bind"
              onChange={() => setBindConfirmed(!isBindConfirmed)}
              isChecked={isBindConfirmed}
            />
          </Banner>
        )}

        <div className="flex">
          <Button className="mr-4" isDisabled={!isValid || !isBindConfirmed || isUpdating} kind="primary" type="submit">
            Bind endorsement
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const BindEndoQuoteModal = ({ handleClose, callback }) => {
  const { quoteData, isLoading: isQuoteLoading, schemaData } = useQuoteQuery();
  const { endorsementData, contractData, isLoading: isContractLoading } = useContractQuery();
  const isLoading = isQuoteLoading || isContractLoading;

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && "Bind endorsement"}
      isLoading={isLoading}
      clearParamsOnClose
    >
      <ModalForm
        callback={callback}
        contractData={contractData}
        endorsementData={endorsementData}
        handleClose={handleClose}
        quoteData={quoteData}
        schemaData={schemaData?.properties?.BindEndoQuoteForm}
      />
    </Modal>
  );
};

BindEndoQuoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default BindEndoQuoteModal;

import clsx from "clsx";
import localforage from "localforage";
import reverse from "lodash.reverse";
import sortBy from "lodash.sortby";
import moment from "moment";
import { compile } from "path-to-regexp";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { Product, ProductRef, SchemaRef, Template } from "../../@types/types";
import { ROUTES } from "../../constants";
import { dateTimeFormatter } from "../../formatters";
import * as api from "../api";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import Status from "../components/Status";
import { useStickyState } from "../hooks";

const getColorFromId = (schemaRef: SchemaRef) => {
  if (schemaRef === "cargo-us-annual") {
    return "blue";
  }

  if (schemaRef === "cargo-us-single") {
    return "green";
  }

  if (schemaRef === "mel") {
    return "green";
  }

  return "gray";
};

const Tab = ({ id, isSelected, onClick }) => (
  <div
    className={clsx(
      "cursor-pointer border-b-2 border-transparent px-3 py-2 flex -my-px",
      isSelected && "border-blue-500",
    )}
    onClick={onClick}
  >
    {id}
  </div>
);

const Tabs = ({ children }) => {
  const first = React.Children.toArray(children).map((child) => child?.props?.id)[0];
  const [selectedTab, setSelectedTab] = useStickyState(first, "templateTabs");
  const render = React?.Children?.toArray(children)?.find((c) => selectedTab === c?.props?.id)?.props?.render;
  const isFn = render && render instanceof Function;

  return (
    <div>
      <div>
        <div className="pl-4 pt-4 flex border-b border-gray-300">
          {React.Children.toArray(children).map((child) => {
            const isSelected = selectedTab === child.props.id;

            return React.cloneElement(child, {
              ...child.props,
              isSelected,
              key: child.props.id,
              onClick: () => setSelectedTab(child.props.id),
            });
          })}
        </div>
      </div>
      <div className="relative p-4">{isFn ? render() : render}</div>
    </div>
  );
};

const getTemplateKey = (id?: string) => `${process.env.CLIENT_REF}#${process.env.DEFAULT_PRODUCT_REF}#template${id ? "#" + id : ""}`;
const starredKey = `${process.env.CLIENT_REF}#${process.env.DEFAULT_PRODUCT_REF}#starred_templates`;

const DebuggerSelectTemplateModal = ({ handleClose, schemaVersion }) => {
  const queryClient = useQueryClient();

  const [templates, setTemplates] = useState<Template[]>([]);
  const [starredIds, setStarredIds] = useState<string[]>([]);
  const { push } = useHistory();
  const { productRef } = useParams<{ productRef: ProductRef }>();

  const productQuery = useQuery(["product", { productRef }], api.getProduct);
  const productData = (productQuery?.data?.data?.data || {}) as Product;

  const sharedTemplatesQuery = useQuery(["sharedTemplates"], api.getTemplates);
  const sharedTemplates = (sharedTemplatesQuery?.data?.data?.data || []) as Template[];

  const shareTemplateQuery = useMutation((data) => api.createTemplate({ data }), {
    onSuccess: () => queryClient.invalidateQueries("sharedTemplates"),
  });

  const deleteTemplateQuery = useMutation((id) => api.deleteTemplate({ id }), {
    onSuccess: () => queryClient.invalidateQueries("sharedTemplates"),
  });

  useEffect(async () => {}, []);

  useEffect(async () => {
    const keys = await localforage.keys();
    const filteredKeys = keys.filter((item) => item.includes(getTemplateKey()));

    if (productData) {
      await Promise.all(
        filteredKeys.map(async (item) => {
          const template = (await localforage.getItem(item)) as Template;
          const newTemplate = { ...template, schemaRef: template.schemaRef || productData?.schemas?.[0]?.ref };
          await localforage.setItem(getTemplateKey(newTemplate.id), newTemplate);
        }),
      );

      const mappedTemplates = (await Promise.all(filteredKeys.map((item) => localforage.getItem(item)))) as Template[];
      const sortedTemplates = reverse(sortBy(mappedTemplates, (item) => item.createdAt));

      setTemplates(sortedTemplates);
    }
  }, [productData]);

  useEffect(async () => {
    const items = (await localforage.getItem<string[]>(starredKey)) || [];

    setStarredIds(items);
  }, []);

  const handleRemove = async (id) => {
    await localforage.removeItem(getTemplateKey(id));

    setTemplates(templates.filter((item) => item.id !== id));
  };

  // Parse template data to rewrite dates to be relative to the creation date, e.g. set inception date to be relative
  // to the moment of creation of the template rather than absolute. This prevents the template from going invalid
  // because dates can trigger referrals.
  const parseTemplate = (template: Template) => {
    const todayMoment = moment.utc().startOf("day");
    const createdAtMoment = moment.utc(template.createdAt).startOf("day");
    const diff = todayMoment.diff(createdAtMoment, "days");
    const isoRe = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

    const modifiedData = JSON.parse(JSON.stringify(template.data), (k, v) => {
      if (typeof v === "string" && isoRe.test(v)) {
        const oldDate = moment.utc(v).startOf("day");
        const newDate = oldDate.add(diff, "days").toISOString();

        return newDate;
      }

      return v;
    });

    return { ...template, data: modifiedData };
  };

  const handleOpen = async (template: Template) => {
    const newTemplate = parseTemplate(template);

    handleClose();
    push({
      pathname: compile(ROUTES.CONTRACT_NEW)({ productRef }),
      search: `?schemaRef=${template.schemaRef}`,
      state: newTemplate,
    });
  };

  const handleShare = async (id: string) => {
    const item = await localforage.getItem(getTemplateKey(id));

    shareTemplateQuery.mutate(item);
  };

  const handleDeleteSharedTemplate = (id: string) => {
    deleteTemplateQuery.mutate(id);
  };

  const handleStar = async (id: string) => {
    const nextStarredIds = starredIds.includes(id) ? starredIds.filter((item) => item !== id) : [...starredIds, id];

    await localforage.setItem(starredKey, nextStarredIds);
    setStarredIds(nextStarredIds);
  };

  const starredSharedTemplates = sharedTemplates.filter((item) => starredIds.includes(item.id));
  const unstarredSharedTemplates = sharedTemplates.filter((item) => !starredIds.includes(item.id));
  const finalSharedTemplates = [...starredSharedTemplates, ...unstarredSharedTemplates];

  const starredTemplates = templates.filter((item) => starredIds.includes(item.id));
  const unstarredTemplates = templates.filter((item) => !starredIds.includes(item.id));
  const finalTemplates = [...starredTemplates, ...unstarredTemplates];

  return (
    <Modal handleClose={handleClose} headingText="Create from template">
      <Tabs>
        <Tab
          id="Own"
          render={
            <div className="p-4 max-h-160 overflow-scroll">
              {!finalTemplates.length && (
                <div className="text-center p-16">
                  No own templates available. Create templates first with Save template button.
                </div>
              )}

              {finalTemplates.map((template) => {
                const isIncompatible =
                  template?.schemaVersion !== undefined
                    ? template?.schemaVersion?.split("-")?.[0] !== schemaVersion?.split("-")?.[0]
                    : false;
                const mightBeIncompatible =
                  template?.schemaVersion === undefined ||
                  (template?.schemaVersion !== undefined &&
                    template?.schemaVersion?.split("-")?.[1] !== schemaVersion?.split("-")?.[1]);

                return (
                  <div key={template.id} className="p-4 border-b border-gray-200 hover:bg-gray-100 last:border-0">
                    <div className="flex justify-between">
                      <div className="flex items-center max-w-sm">
                        <Icon
                          name={starredIds.includes(template.id) ? "star-full" : "star-empty"}
                          className={clsx("w-4 mr-4 cursor-pointer", {
                            "text-gray-500": !starredIds.includes(template.id),
                            "text-yellow-500": starredIds.includes(template.id),
                          })}
                          onClick={() => handleStar(template.id)}
                        />

                        <div>
                          <div className="mb-2 flex items-center">
                            <div className="mr-3">{template.name}</div>
                            <Status
                              statusText={template.schemaRef}
                              kind="small"
                              color={getColorFromId(template.schemaRef)}
                            />
                          </div>
                          {template.createdBy && (
                            <div className="text-sm text-gray-600">
                              Created by {template.createdBy.fullName} on {dateTimeFormatter(template.createdAt)}
                            </div>
                          )}
                          {template.description && (
                            <div className="text-sm text-gray-600">Description: {template.description}</div>
                          )}

                          {isIncompatible && (
                            <div className="text-sm text-gray-600">
                              🚫 Warning: this template is incompatible with the latest version of the schema.
                            </div>
                          )}

                          {mightBeIncompatible && (
                            <div className="text-sm text-gray-600">
                              ⚠️ Warning: this template might be incompatible with the latest version of the schema.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <Button
                          className="h-10 ml-2"
                          size="small"
                          kind="danger-secondary"
                          onClick={() => handleRemove(template.id)}
                        >
                          Delete
                        </Button>

                        <Button
                          className="h-10 ml-2"
                          size="small"
                          onClick={() => handleShare(template.id)}
                          isDisabled={
                            (shareTemplateQuery.isLoading && shareTemplateQuery?.variables?.id === template.id) ||
                            sharedTemplates.map((item) => item.id).includes(template.id) ||
                            isIncompatible
                          }
                        >
                          {sharedTemplates.map((item) => item.id).includes(template.id) ? "Shared" : "Share"}
                        </Button>

                        <Button
                          className="h-10 ml-2"
                          size="small"
                          onClick={() => handleOpen(template)}
                          isDisabled={isIncompatible}
                        >
                          Open
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />

        <Tab
          id="Shared"
          render={
            <div className="p-4 max-h-160 overflow-scroll">
              {!finalSharedTemplates.length && (
                <div className="text-center p-16">
                  No shared templates available. First create a template, then upload it to share it with others.
                </div>
              )}

              {finalSharedTemplates.map((template) => {
                const isIncompatible =
                  template?.schemaVersion !== undefined
                    ? template?.schemaVersion?.split("-")?.[0] !== schemaVersion?.split("-")?.[0]
                    : false;
                const mightBeIncompatible =
                  template?.schemaVersion === undefined ||
                  (template?.schemaVersion !== undefined &&
                    template?.schemaVersion?.split("-")?.[1] !== schemaVersion?.split("-")?.[1]);

                return (
                  <div key={template.id} className="p-4 border-b border-gray-200 hover:bg-gray-100 last:border-0">
                    <div className="flex justify-between">
                      <div className="flex items-center max-w-sm">
                        <Icon
                          name={starredIds.includes(template.id) ? "star-full" : "star-empty"}
                          className={clsx("w-4 mr-4 cursor-pointer", {
                            "text-gray-500": !starredIds.includes(template.id),
                            "text-yellow-500": starredIds.includes(template.id),
                          })}
                          onClick={() => handleStar(template.id)}
                        />

                        <div>
                          <div className="mb-2 flex items-center">
                            <div className="mr-3">{template.name}</div>
                            <Status
                              statusText={template.schemaRef}
                              kind="small"
                              color={getColorFromId(template.schemaRef)}
                            />
                          </div>

                          {template.createdBy && (
                            <div className="text-sm text-gray-600">
                              Created by {template.createdBy.fullName} on {dateTimeFormatter(template.createdAt)}
                            </div>
                          )}
                          {template.description && (
                            <div className="text-sm text-gray-600">Description: {template.description}</div>
                          )}
                          {isIncompatible && (
                            <div className="text-sm text-gray-600">
                              🚫 Warning: this template is incompatible with the latest version of the schema.
                            </div>
                          )}

                          {mightBeIncompatible && (
                            <div className="text-sm text-gray-600">
                              ⚠️ Warning: this template might be incompatible with the latest version of the schema.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <Button
                          className="h-10 ml-2"
                          size="small"
                          kind="danger-secondary"
                          onClick={() => handleDeleteSharedTemplate(template.id)}
                          isDisabled={deleteTemplateQuery.isLoading}
                        >
                          Delete
                        </Button>

                        <Button
                          className="h-10 ml-2"
                          size="small"
                          onClick={() => handleOpen(template)}
                          isDisabled={isIncompatible}
                        >
                          Open
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />
      </Tabs>
    </Modal>
  );
};

export default DebuggerSelectTemplateModal;

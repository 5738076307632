import AWS from "aws-sdk";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import get from "lodash.get";
import { ClientRef, Contract, ProductRef } from "../../@types/types";
import { NotFoundError } from "../../helpers/errors";
import { ddbRecursiveQuery } from "./helpers";
import { ROLES } from "../../constants";

const getKey = ({
  contractId,
  endorsementId,
  productRef,
}: {
  contractId: string;
  endorsementId?: string;
  productRef: ProductRef;
}) => {
  const clientRef = process.env.CLIENT_REF as ClientRef;

  if (endorsementId) {
    return {
      pk: `${clientRef}#${productRef}#contract#${contractId}`,
      sk: `endorsement#${endorsementId}`,
    };
  }

  return {
    pk: `${clientRef}#${productRef}`,
    sk: `contract#${contractId}`,
  };
};

export const createContract = ({
  contractId,
  data,
  endorsementId,
  productRef,
}: {
  contractId: string;
  data: Contract;
  endorsementId?: string;
  productRef: ProductRef;
}): Promise<DocumentClient.PutItemOutput> => {
  const docClient = new AWS.DynamoDB.DocumentClient();
  const TableName = process.env.TABLE_NAME;

  if (!TableName) {
    throw new Error("Missing env var: TABLE_NAME");
  }

  return docClient
    .put({
      TableName,
      Item: {
        ...getKey({ productRef, contractId, endorsementId }),
        data,
      },
    })
    .promise();
};

export const updateContract = ({
  contractId,
  data,
  endorsementId,
  productRef,
}: {
  contractId: string;
  data: Contract;
  endorsementId?: string;
  productRef: ProductRef;
}): Promise<DocumentClient.UpdateItemOutput> => {
  const docClient = new AWS.DynamoDB.DocumentClient();
  const TableName = process.env.TABLE_NAME;

  if (!TableName) {
    throw new Error("Missing env var: TABLE_NAME");
  }

  return docClient
    .update({
      TableName,
      Key: getKey({ productRef, contractId, endorsementId }),
      UpdateExpression: "set #data = :newdata",
      ExpressionAttributeNames: {
        "#data": "data",
      },
      ExpressionAttributeValues: {
        ":newdata": data,
      },
    })
    .promise();
};

export const getContracts = async ({
  authorisedTenantIds,
  productRef,
  role,
  userId,
}: {
  authorisedTenantIds: string[];
  productRef: ProductRef;
  role: string;
  userId: string;
}): Promise<{ Items: any[] }> => {
  const clientRef = process.env.CLIENT_REF as ClientRef;

  const docClient = new AWS.DynamoDB.DocumentClient();
  const TableName = process.env.TABLE_NAME;

  if (!TableName) {
    throw new Error("Missing env var: TABLE_NAME");
  }

  const result = await ddbRecursiveQuery(docClient, {
    TableName,
    KeyConditionExpression: "pk = :productid and begins_with(sk, :contractkey)",
    ExpressionAttributeValues: {
      ":productid": `${clientRef}#${productRef}`,
      ":contractkey": "contract#",
    },
  });

  let finalResult = result.filter((c) => authorisedTenantIds.includes(c.data.tenantId));

  if (productRef === "mc" && role !== ROLES.ADMIN) {
    // filter Sure contracts by user id
    finalResult = finalResult?.filter((contract) => contract?.data?.createdBy?.id === userId);
  }

  return { Items: finalResult };
};

export const getEndorsements = ({
  contractId,
  productRef,
}: {
  contractId: string;
  productRef: ProductRef;
}): Promise<AWS.DynamoDB.DocumentClient.QueryOutput> => {
  const clientRef = process.env.CLIENT_REF as ClientRef;

  const docClient = new AWS.DynamoDB.DocumentClient();
  const TableName = process.env.TABLE_NAME;

  if (!TableName) {
    throw new Error("Missing env var: TABLE_NAME");
  }

  return docClient
    .query({
      TableName,
      KeyConditionExpression: "pk = :pk and begins_with(sk, :sk)",
      ExpressionAttributeValues: {
        ":pk": `${clientRef}#${productRef}#contract#${contractId}`,
        ":sk": "endorsement#",
      },
    })
    .promise();
};

export const getContract = async ({
  authorisedTenantIds = [],
  contractId,
  endorsementId,
  productRef,
  role,
  userId,
}: {
  authorisedTenantIds: string[];
  contractId: string;
  endorsementId?: string;
  productRef: ProductRef;
  role: string;
  userId: string;
}): Promise<AWS.DynamoDB.DocumentClient.GetItemOutput> => {
  const docClient = new AWS.DynamoDB.DocumentClient();
  const TableName = process.env.TABLE_NAME;

  if (!TableName) {
    throw new Error("Missing env var: TABLE_NAME");
  }

  const params = {
    TableName,
    Key: getKey({ productRef, contractId, endorsementId }),
  };

  const result = await docClient.get(params).promise();

  if (!authorisedTenantIds.includes(get(result, "Item.data.tenantId"))) {
    throw new NotFoundError();
  }
  if (productRef === "mc" && role !== ROLES.ADMIN) {
    // filter Sure contracts by user id
    if (result?.Item?.data?.createdBy?.id !== userId) {
      throw new NotFoundError();
    }
  }

  return result;
};

export const getNextEndorsementRef = async ({
  contractId,
  productRef,
}: {
  contractId: string;
  productRef: ProductRef;
}): Promise<string> => {
  const endorsements = await getEndorsements({ productRef, contractId });
  const count = endorsements.Count as number;

  return `E${(count + 1).toString().padStart(3, "0")}`;
};

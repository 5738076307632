import React from "react";
import PropTypes from "prop-types";

const Alert = ({ children, background }) => <div className={`p-4 text-center ${background}`}>{children}</div>;

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
};

Alert.defaultProps = {
  background: "",
};

export default Alert;

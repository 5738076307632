import React, { FC } from "react";
import { asCurrency, asPercent } from "../helpers";
import { parseSubmission } from "../parseSubmission";
import { PolicyDocumentProps } from "../types";

export const Annual_info_provided_to_underwriters: FC<PolicyDocumentProps> = ({ quote }) => {
  const { storage, insured, ...parsedSubmission } = parseSubmission(quote.submission, "cargo-us-annual");
  const storages = storage?.storages;

  return (
    <section aria-labelledby="informationProvidedToUnderwriters">
      <h1 id="informationProvidedToUnderwriters">INFORMATION PROVIDED TO UNDERWRITERS</h1>

      <p>
        The following information has been provided to underwriters for the purposes of providing a quotation for Marine
        Cargo Insurance.
      </p>

      <p>You have confirmed that ….</p>

      <p>All monetary amounts and premium payments will be in USD</p>

      <p>
        You are a {insured.typeOfCustomer} type of customer with{" "}
        {insured?.companyTurnover?.amount !== undefined && asCurrency(insured.companyTurnover.amount)} turnover.
      </p>

      {!insured.hadInsuranceRefused && (
        <p>You have never had any insurance refused, declined or special terms imposed.</p>
      )}

      {insured.hadInsuranceRefused && (
        <>
          <p>
            You have never had any insurance refused, declined or special terms imposed or if you have then the reason
            given below is acceptable to underwriters and we are able to provide a quote.
          </p>

          <p>The reason provided as stated below:</p>

          <p>“{insured.insuranceRefusedReason}”</p>
        </>
      )}

      <p>Your premium and Loss record is as follows:
        {insured?.lossHistory === undefined && " None"}
      </p>

      {insured?.lossHistory !== undefined && (
        <table className="styled nested" data-testid="lossHistory">
          <colgroup>
            <col width="20%" />
            <col width="40%" />
            <col width="40%" />
          </colgroup>
          <thead>
            <tr>
              <th>Year</th>
              <th>Premium</th>
              <th>Losses</th>
            </tr>
          </thead>
          <tbody>
            {insured?.lossHistory?.map((lossHistory, index) => (
              <tr key={index.toString()}>
                <td>{lossHistory?.year}</td>
                <td>{lossHistory?.premium?.amount !== undefined && asCurrency(lossHistory.premium.amount)}</td>
                <td>{lossHistory?.loss?.amount !== undefined && asCurrency(lossHistory.loss.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {parsedSubmission?.contract?.type === "annual" && <p>You require an annual policy.</p>}

      {parsedSubmission?.contract?.type === "contract" && (
        <p>
          You require cover for a specific contract of group of shipments for less or greater than a one year period.
        </p>
      )}

      {parsedSubmission?.contract?.type === "single" && <p>You require cover for a one off shipment.</p>}

      <p>
        You have stated that the type of cargo that you wish to insure is{" "}
        {parsedSubmission?.cargo?.cargos?.map((cargo) => cargo.type).join(", ")} and is packed in twenty or forty foot
        ISO containers or if by Road only or Rail only or Airfreight or Post that it is suitably packed or protected for
        transit.
      </p>

      <p>You have stated that the condition of your cargo is {parsedSubmission?.cargo?.condition}</p>

      {parsedSubmission?.cargo?.cargos?.some((cargo) => cargo.tempControl === "Frozen below minus 15 Centigrade") && (
        <p>
          You have stated that the cargo will be temperature controlled and is shipped at below 15 degrees Centigrade
        </p>
      )}

      <p>
        The Basis of Valuation for claims under your policy, for limits and for declarations of cargo values submitted
        is {parsedSubmission.cargo.basisOfValuation}
        {parsedSubmission.cargo.basisOfValuationPercentage !== undefined &&
          ` ${
            (parsedSubmission?.cargo?.basisOfValuationPercentage ?? 0) * 100
          }% plus the addition of duty Excise, Surcharge and/or Landing and similar charges`}
        .
      </p>

      {(parsedSubmission.shipment.inlandTransit ||
        parsedSubmission.exhibition.hasExhibitions ||
        parsedSubmission.samples.hasSamples ||
        storage.hasStorages) && (
        <p>
          Different Basis of Valuations apply in respect of Inland Transits, Samples or goods to/from or on Exhibition
          or goods in agreed storage locations as per the policy wording
        </p>
      )}

      <p>You have stated that your Conveyance types and limits required per conveyance are as follows</p>

      {parsedSubmission?.conveyance?.conveyances?.some((conveyance) => !conveyance.aovTransits) && (
        <table className="styled nested" data-testid="conveyances">
          <colgroup>
            <col width="20%" />
            <col width="auto" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>Conveyance Number</th>
              <th>Conveyance Type</th>
              <th>Limit</th>
              <th>Percentage of Turnover</th>
            </tr>
          </thead>
          <tbody>
            {parsedSubmission?.conveyance?.conveyances
              ?.filter((conveyance) => !conveyance.aovTransits)
              ?.map((conveyance) => (
                <tr key={conveyance?.seqNumber?.toString()}>
                  <td>{conveyance.seqNumber}</td>
                  <td>
                    {conveyance.type === "Sea" && (
                      <>
                        {conveyance.type} {conveyance.containerType}
                      </>
                    )}
                    {conveyance.type !== "Sea" && <>{conveyance.type}</>}
                  </td>
                  <td>{conveyance?.limit?.amount !== undefined && asCurrency(conveyance.limit.amount)}</td>
                  <td>{conveyance.turnoverPercentage !== undefined && asPercent(conveyance.turnoverPercentage)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {parsedSubmission?.conveyance?.conveyances?.some((conveyance) => conveyance.aovTransits) && (
        <table className="styled nested" data-testid="aovConveyances">
          <colgroup>
            <col width="20%" />
            <col width="auto" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>Conveyance Number</th>
              <th>Conveyance Type</th>
              <th>Any Own Vehicle Limit</th>
              <th>AOV Percentage of Turnover</th>
            </tr>
          </thead>
          <tbody>
            {parsedSubmission?.conveyance?.conveyances
              ?.filter((conveyance) => conveyance.aovTransits)
              ?.map((conveyance) => (
                <tr key={conveyance?.seqNumber?.toString()}>
                  <td>{conveyance.seqNumber}</td>
                  <td>{conveyance.type}</td>
                  <td>
                    {conveyance?.ownVehicleLimit?.amount !== undefined && asCurrency(conveyance.ownVehicleLimit.amount)}
                  </td>
                  <td>
                    {conveyance?.ownVehicleTurnoverPercentage !== undefined &&
                      asPercent(conveyance.ownVehicleTurnoverPercentage)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <p>You have stated that you require cover for transits within the following geographical areas</p>

      <table className="styled nested" data-testid="shipments">
        <colgroup>
          <col width="20%" />
          <col width="auto" />
          <col width="20%" />
          <col width="20%" />
        </colgroup>
        <thead>
          <tr>
            <th>Shipment Number</th>
            <th>Geographical Area</th>
            <th>Own Value</th>
            <th>Contingent Value</th>
          </tr>
        </thead>
        <tbody>
          {parsedSubmission?.shipment?.shipments?.map((shipment, index) => (
            <tr key={index?.toString()}>
              <td>{shipment.seqNumber}</td>
              <td>{shipment.country || shipment.region}</td>
              <td>{shipment?.ownTurnover?.amount !== undefined && asCurrency(shipment?.ownTurnover?.amount)}</td>
              <td>
                {shipment?.contingentTurnover?.amount !== undefined && asCurrency(shipment?.contingentTurnover?.amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {!parsedSubmission.exhibition.hasExhibitions && (
        <p>You have stated you do not require cover for goods on exhibition.</p>
      )}

      {parsedSubmission.exhibition.hasExhibitions && (
        <>
          <p>You have stated that you require cover for good in transit to exhibitions as follows</p>

          <table className="styled nested" data-testid="exhibitions">
            <colgroup>
              <col width="20%" />
              <col width="auto" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>Exhibition Number</th>
                <th>Exhibition Location</th>
                <th>Exhibition Duration</th>
                <th>Limit</th>
              </tr>
            </thead>
            <tbody>
              {parsedSubmission?.exhibition?.exhibitions?.map((exhibition, index) => (
                <tr key={index.toString()}>
                  <td>{exhibition.number}</td>
                  <td>{exhibition.region}</td>
                  <td>{exhibition.duration}</td>
                  <td>{exhibition?.limit?.amount !== undefined && asCurrency(exhibition.limit.amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {!parsedSubmission.samples.hasSamples && <p>You have stated you do not require cover for samples</p>}

      {parsedSubmission.samples.hasSamples && (
        <p>
          You have stated that you require cover for Samples for {parsedSubmission.samples.numVehicles} vehicles upto a
          limit of{" "}
          {parsedSubmission?.samples?.limit?.amount !== undefined && asCurrency(parsedSubmission.samples.limit.amount)}{" "}
          per vehicle
        </p>
      )}

      {storage.hasStorages && (
        <>
          <p>You have requested cover for storage at your {storage.storages?.length} location(s) as follows:</p>

          <table className="styled nested" data-testid="storages">
            <colgroup>
              <col width="auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row" id="storageAddress">
                  Address
                </th>
                {storages?.map(({ formattedAddress }, index) => (
                  <td aria-labelledby="storageAddress" key={index.toString()}>
                    {formattedAddress}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLimitPerLocation">
                  Limit per location
                </th>
                {storages?.map(({ limit }, index) => (
                  <td aria-labelledby="storageLimitPerLocation" key={index.toString()}>
                    {limit?.amount !== undefined && asCurrency(limit.amount)}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageAverageAmountInStore">
                  Average Amount in Store
                </th>
                {storages?.map(({ averageInStorePercentage }, index) => (
                  <td aria-labelledby="storageAverageAmountInStore" key={index.toString()}>
                    {averageInStorePercentage !== undefined && asPercent(averageInStorePercentage)}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationType">
                  Storage Location Type
                </th>
                {storages?.map(({ locationType }, index) => (
                  <td aria-labelledby="storageLocationType" key={index.toString()}>
                    {locationType}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageAgeOfBuilding">
                  Age of Building
                </th>
                {storages?.map(({ buildingAge }, index) => (
                  <td aria-labelledby="storageAgeOfBuilding" key={index.toString()}>
                    {buildingAge}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageBuildingEnclosedHasARoof">
                  Building Enclosed & has a Roof?
                </th>
                {storages?.map(({ hasNoRoof }, index) => (
                  <td aria-labelledby="storageBuildingEnclosedHasARoof" key={index.toString()}>
                    {hasNoRoof !== undefined ? (!hasNoRoof ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageComplyWithLocalConstructionRegulations">
                  Comply with local construction regulations?
                </th>
                {storages?.map(({ notStandardsCompliant }, index) => (
                  <td aria-labelledby="storageComplyWithLocalConstructionRegulations" key={index.toString()}>
                    {notStandardsCompliant !== undefined ? (!notStandardsCompliant ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageFloorConstruction">
                  Floor Construction
                </th>
                {storages?.map(({ notStandardsCompliant, floorConstruction }, index) => (
                  <td aria-labelledby="storageFloorConstruction" key={index.toString()}>
                    {notStandardsCompliant ? floorConstruction : "N/A"}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageWallConstruction">
                  Wall Construction
                </th>
                {storages?.map(({ notStandardsCompliant, wallConstruction }, index) => (
                  <td aria-labelledby="storageWallConstruction" key={index.toString()}>
                    {notStandardsCompliant ? wallConstruction : "N/A"}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageRoofConstruction">
                  Roof Construction
                </th>
                {storages?.map(({ notStandardsCompliant, roofConstruction }, index) => (
                  <td aria-labelledby="storageRoofConstruction" key={index.toString()}>
                    {notStandardsCompliant ? roofConstruction : "N/A"}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageHaveCompositePanels">
                  Have Composite Panels?
                </th>
                {storages?.map(({ compositePanels }, index) => (
                  <td aria-labelledby="storageHaveCompositePanels" key={index.toString()}>
                    {compositePanels !== undefined ? (compositePanels ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageProcessingCarriedOutAtLocation">
                  Processing carried out at location?
                </th>
                {storages?.map(({ isProcessingAtLocation }, index) => (
                  <td aria-labelledby="storageProcessingCarriedOutAtLocation" key={index.toString()}>
                    {isProcessingAtLocation !== undefined ? (isProcessingAtLocation ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageHotworkKilnProcessingOrWelding">
                  Hotwork, Kiln processing or Welding?
                </th>
                {storages?.map(({ isHotworkCarriedOut, isProcessingAtLocation }, index) => (
                  <td aria-labelledby="storageHotworkKilnProcessingOrWelding" key={index.toString()}>
                    {isProcessingAtLocation ? (isHotworkCarriedOut ? "Yes" : "No") : "N/A"}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationMultiTenure">
                  Location Multi tenure?
                </th>
                {storages?.map(({ multiTenure }, index) => (
                  <td aria-labelledby="storageLocationMultiTenure" key={index.toString()}>
                    {multiTenure !== undefined ? (multiTenure ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationOnFloodPlain">
                  Storage Location on Flood Plain?
                </th>
                {storages?.map(({ isOnFloodPlain }, index) => (
                  <td aria-labelledby="storageLocationOnFloodPlain" key={index.toString()}>
                    {isOnFloodPlain !== undefined ? (isOnFloodPlain ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationExperiencedFloodLosses">
                  Location experienced flood losses?
                </th>
                {storages?.map(({ isOnFloodPlain, hasFloodClaim }, index) => (
                  <td aria-labelledby="storageLocationExperiencedFloodLosses" key={index.toString()}>
                    {isOnFloodPlain ? (hasFloodClaim ? "Yes" : "No") : "N/A"}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationEntirelySprinklered">
                  Location entirely sprinklered?
                </th>
                {storages?.map(({ noSprinklers }, index) => (
                  <td aria-labelledby="storageLocationEntirelySprinklered" key={index.toString()}>
                    {noSprinklers !== undefined ? (!noSprinklers ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageFireAndSecurityAlarms">
                  Fire and Security Alarms?
                </th>
                {storages?.map(({ alarmsSecurity }, index) => (
                  <td aria-labelledby="storageFireAndSecurityAlarms" key={index.toString()}>
                    {alarmsSecurity !== undefined ? (alarmsSecurity ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationMonitored">
                  Location Monitored?
                </th>
                {storages?.map(({ alarmsNotMonitored }, index) => (
                  <td aria-labelledby="storageLocationMonitored" key={index.toString()}>
                    {alarmsNotMonitored !== undefined ? (!alarmsNotMonitored ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageAlarmMaintenanceContract">
                  Alarm Maintenance Contract?
                </th>
                {storages?.map(({ alarmsNoMaintenance }, index) => (
                  <td aria-labelledby="storageAlarmMaintenanceContract" key={index.toString()}>
                    {alarmsNoMaintenance !== undefined ? (!alarmsNoMaintenance ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storageLocationHasPhysicalSecurity">
                  Location has Physical Security?
                </th>
                {storages?.map(({ noPhysicalSecurity }, index) => (
                  <td aria-labelledby="storageLocationHasPhysicalSecurity" key={index.toString()}>
                    {noPhysicalSecurity !== undefined ? (!noPhysicalSecurity ? "Yes" : "No") : ""}
                  </td>
                ))}
              </tr>

              <tr>
                <th scope="row" id="storagePhysicalSecurityTypesApplicable">
                  Physical Security types applicable
                </th>
                {storages?.map(({ physicalSecurityTypes }, index) => (
                  <td aria-labelledby="storagePhysicalSecurityTypesApplicable" key={index.toString()}>
                    {physicalSecurityTypes !== undefined && physicalSecurityTypes?.join(", ")}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </>
      )}

      {parsedSubmission.cargo.iccClause && (
        <p>
          You have selected that you require a basic named perils level of coverage based upon the ICC C). Full details
          of the coverage is shown in the Summary of Coverage
        </p>
      )}

      <p>You have the deductibles as below and terms reflect this request.</p>

      <table className="styled nested" data-testid="cargos">
        <colgroup>
          <col width="auto" />
          <col width="20%" />
        </colgroup>
        <thead>
          <tr>
            <th>Cargo Type</th>
            <th>Cargo Deductible</th>
          </tr>
        </thead>
        <tbody>
          {parsedSubmission?.cargo?.cargos?.map((cargo, index) => (
            <tr key={index?.toString()}>
              <td>{cargo.type}</td>
              <td>{cargo?.deductible?.amount !== undefined && asCurrency(cargo.deductible.amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {storages?.some((storage) => storage.perils?.some((peril) => peril.level !== "None")) && (
        <table className="styled nested" data-testid="perils">
          <colgroup>
            <col width="20%" />
            <col width="auto" />
            <col width="30%" />
          </colgroup>
          <thead>
            <tr>
              <th>Storage State</th>
              <th>Storage Limit</th>
              <th>Quake Deductible</th>
              <th>Windstorm Deductible</th>
            </tr>
          </thead>
          <tbody>
            {storages?.map((storage, index) => (
              <tr key={index.toString()}>
                <td>{storage.address?.state}</td>
                <td>{storage?.limit?.amount !== undefined && asCurrency(storage.limit.amount)}</td>
                <td>
                  {storage.perils?.find((peril) => peril.type === "Quake" && peril.level === "High") &&
                    "20% of Storage Limit Deductible minimum US$5,000 maximum US$50,000"}
                  {storage.perils?.find((peril) => peril.type === "Quake" && peril.level === "Medium") &&
                    "10% of Storage Limit Deductible minimum US$5,000 maximum US$25,000"}
                </td>
                <td>
                  {storage.perils?.find(
                    (peril) =>
                      (peril.type === "Windstorm" && peril.level === "High") ||
                      (peril.type === "Inland Wind" && peril.level === "High"),
                  ) && "10% of Storage Limit Deductible minimum US$5,000 maximum US$25,000"}

                  {storage.perils?.find(
                    (peril) =>
                      (peril.type === "Windstorm" && peril.level === "Medium") ||
                      (peril.type === "Inland Wind" && peril.level === "Medium"),
                  ) && "5% of Storage Limit Deductible minimum US$5,000 maximum US$15,000"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

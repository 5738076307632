import React, { FC } from "react";
import { parseSubmission } from "../parseSubmission";
import { PolicyDocumentProps } from "../types";

export const Annual_standard: FC<PolicyDocumentProps> = ({ quote }) => {
  const parsedSubmission = parseSubmission(quote.submission, "cargo-us-annual");

  return (
    <section aria-labelledby="standard">
      <h1 id="standard">STANDARD MARINE CARGO INSURANCE CONDITIONS</h1>

      <p>
        <b>INSURING TERMS</b>
        <br />
        {parsedSubmission.cargo.condition === "Used" || parsedSubmission.cargo.iccClause
          ? "Institute Cargo Clauses (C) CL384 dated 1.1.2009 extended to include the risks of theft or non-delivery of an entire consignment or full container, trailer or vehicle load."
          : "Institute Cargo Clauses (A) CL 382 dated 1.1.2009 and/or Institute Cargo Clauses (Air) CL 387 dated 1.1.2009 as applicable."}
      </p>

      {parsedSubmission.cargo.cargos?.some((cargo) => cargo.tempControl === "Frozen below minus 15 Centigrade") && (
        <p>
          Institute Frozen Foods Clauses (A) – 24 Breakdown applies for frozen goods below -15 (minus fifteen) degrees
          Celsius or 5 (five) degrees Fahrenheit
        </p>
      )}

      {parsedSubmission.cargo.condition === "Used" && <p>Subject also to the Second Hand Replacement Clause.</p>}

      <p>
        Institute War Clauses (Cargo) CL 385 dated 1.1.2009 and/or Institute War Clauses (Air Cargo) CL 388 dated
        1.1.2009 or Institute War Clauses (sendings by Post) CL390 dated 1.3.2009 as applicable.
        <br />
        Institute Strikes Clauses (Cargo) CL 386 dated 1.1.2009 and/or Institute Strikes Clauses (Air Cargo) CL 389
        dated 1.1.2009 as applicable.{" "}
        {parsedSubmission.cargo.cargos?.some((cargo) => cargo.tempControl === "Frozen below minus 15 Centigrade") &&
          "Institute Strikes Clauses (Frozen Food) applies for frozen goods below -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit"}
      </p>

      <p>Certain Countries are excluded from War and Strikes coverage as per the War and Strikes section</p>

      <p>
        <b>ACQUIRED COMPANIES CLAUSE</b> <br />
        The Assured named in the Schedule includes any company, subsidiary company or firm formed, purchased or
        otherwise acquired by the Assured during the period of insurance, provided that the Assured :<br />
        a&#41; Advise Underwriters of their interest in the company or firm within thirty (30) days from the date of
        signing the purchase contract or date of formation.
        <br />
        b&#41; Hold a controlling interest in the company or firm or have agreed to accept responsibility for insurance.
        <br />
        c&#41; Declare to Underwriters the turnover or value of sending’s, type of goods to be insured, past loss
        history, and then agree to any additional conditions and pay any additional premium required by Underwriters.
        <br />
      </p>

      <p>Excluding any company outside of the Assureds domicile unless agreed by Underwriters in advance.</p>

      <p>
        <b>CARGO ISM ENDORSEMENT (JC98/019)</b>
        <br />
        Applicable to shipments on board Ro-Ro passenger ferries.
        <br />
        Applicable with effect from 1st July 1998 to shipments on board:
      </p>

      <p>
        (1) passenger vessels transporting more than twelve passengers, and
        <br />
        (2) oil tankers, chemical tankers, gas carriers, bulk carriers and cargo high speed craft of 500 gt or more.
      </p>

      <p>
        Applicable with effect from 1st July 2002 to shipments on board all other cargo ships and mobile offshore
        drilling units of 500 gt or more.
      </p>

      <p>
        In no case shall this insurance cover loss, damage or expense where the subject matter insured is carried by a
        vessel that is not I.S.M. Code certified or whose owners or operators do not hold an I.S.M. Code Document of
        Compliance when, at the time of loading of the subject matter insured on board the vessel, the Assured were
        aware, or in the ordinary course of business should have been aware:
      </p>

      <p>
        (a) Either that such vessel was not certified in accordance with the I.S.M. Code.
        <br />
        (b) Or that a current Document of Compliance was not held by her owners or operators as required under the SOLAS
        Convention 1974 as amended.
      </p>

      <p>
        This exclusion shall not apply where this insurance has been assigned to the party claiming hereunder who has
        bought or agreed to buy the subject matter insured in good faith under a binding contract.
      </p>

      <p>
        <b>CARGO ISM FORWARDING CHARGES CLAUSE</b> (For use only with JCC Cargo ISM Endorsement JC98/019)
        <br /> In consideration of an additional premium to be agreed, this insurance is extended to reimburse the
        Assured, up to the limit of the sum insured for the voyage, for any extra charges properly and reasonably
        incurred in unloading, storing and forwarding the subject-matter to the destination to which it is insured
        hereunder following release of cargo from a vessel arrested or detained at or diverted to any other port or
        place (other than the intended port of destination) where the voyage is terminated due either
        <br />
        (a) to such vessel not being certified in accordance with the ISM Code or
        <br />
        (b) to a current Document of Compliance not being held by her owners or operators
        <br />
        as required under the SOLAS Convention 1974 as amended.
      </p>

      <p>
        This clause, which does not apply to General Average or Salvage or Salvage Charges, is subject to all other
        terms, conditions and exclusions contained in the policy and to JCC Cargo ISM Endorsement JC 98/019.
      </p>

      <p>
        <b>CHANGE OF DESTINATION / DEVIATION / DELAY</b>
        <br />
        In case of voluntary change of destination and/or deviation and/or delay within the Assured’s control, the
        insured goods are held covered hereunder subject to the Assured reporting, as soon as possible, all such events
        to Underwriters. In case of short early discharge shipment in whole or part by the vessel reported for insurance
        hereunder, Underwriters agree to hold the Assured covered against the risks insured hereunder until arrival at
        the final destination to which the goods are insured or until the goods are no longer at the risk of the
        Assured, whichever may first occur.
      </p>

      <p>
        <b>COMMUNICABLE DISEASE EXCLUSION (JC2020-011)</b>
        <br />
        1. Notwithstanding any provision to the contrary within this insurance, this insurance does not insure any loss,
        damage, liability, claim, cost or expense of whatsoever nature caused by, contributed to by, resulting from,
        arising out of, or in connection with a Communicable Disease or the fear or threat (whether actual or perceived)
        of a Communicable Disease regardless of any other cause or event contributing concurrently or in any other
        sequence thereto.
        <br />
        2. As used herein, a Communicable Disease means any disease which can be transmitted by means of any substance
        or agent from any organism to another organism where:
        <br />
        2.1. the substance or agent includes, but is not limited to, a virus, bacterium, parasite or other organism or
        any variation thereof, whether deemed living or not, and
        <br />
        2.2. the method of transmission, whether direct or indirect, includes but is not limited to, airborne
        transmission, bodily fluid transmission, transmission from or to any surface or object, solid, liquid or gas or
        between organisms, and
        <br />
        2.3. the disease, substance or agent can cause or threaten bodily injury, illness, damage to human health, human
        welfare or property.
        <br />
      </p>

      <p>
        <b>COMPUTER MILLENNIUM CLAUSE (JC98-024)</b>
        <br />
        In no case shall this insurance cover any loss, damage, expense or liability of whatsoever nature which might
        otherwise be recoverable under this insurance arising out of or in any way connected with, whether directly or
        indirectly, the use or operation of any computer, computer system, computer software, programme or process or
        any electronic system where any such loss, damage, expense or liability arises, whether directly or indirectly,
        as a consequence of (i) the date change to the year 2000 or any other date change and/or (ii) any change or
        modification of or to such computer, computer system, computer software, programme or process or any electronic
        system in relation to such date change.
      </p>

      <p>
        This exclusion does not apply to:
        <br />
        1. Claims for loss of or damage to the subject matter insured reasonably attributable to
        <br />
        a. fire or explosion
        <br />
        b. vessel or craft being stranded grounded sunk or capsized
        <br />
        c. overturning or derailment of land conveyance
        <br />
        d. collision or contact of vessel craft aircraft or conveyance with any external object other than water
        <br />
        e. total loss of aircraft in flight
        <br />
        f. discharge of cargo at a port of distress
        <br />
        g. total loss of any package lost overboard or dropped whilst loading on to, or unloading from, vessel craft or
        aircraft
        <br />
        h. general average sacrifice
        <br />
        i. jettison or washing overboard
        <br />
        j. entry of sea lake or river water into vessel craft hold conveyance liftvan or place of storage
        <br />
        2. General average and salvage charges, adjusted or determined according to the contract of affreightment and/or
        the governing law and practice, incurred to avoid or in connection with the avoidance of loss from any cause
        except those excluded elsewhere in this insurance.
        <br />
      </p>

      <p>Subject always to the terms, conditions, limits and exclusions contained elsewhere in this policy.</p>

      <p>
        <b>CONTINGENT INTEREST CLAUSE</b>
        <br />
        Where annual turnover estimates are provided by the Assured and accepted by Underwriters, this insurance is
        extended to cover the Assured’s contingent interest in any goods for which they have no responsibility to insure
        under the terms of sale or purchase.
      </p>

      <p>
        Underwriters agree to provide cover subject to Policy terms, but only to the extent that the Assured is unable
        to recover any loss or damage from the insurance effected by the buyer or seller, as applicable.
      </p>

      <p>
        Where interest in the goods reverts to the Assured during transit for any reason, the goods will be covered
        continuously, including any reasonable period whilst awaiting re-sale or return and any additional transit
        consequent upon such resale or return.
      </p>

      <p>Provided always that:</p>

      <ul>
        <li>
          prompt notice is given to Underwriters of any incident that might result in this extension becoming operative;
        </li>
        <li>the existence of this extension is not disclosed to any other party having an interest in the goods;</li>
        <li>
          the Assured takes all reasonable steps to enforce the terms of the underlying sale or purchase contract;
        </li>
        <li>
          all rights and benefits of any action that the Assured possesses or acquires against third parties are
          subrogated to Underwriters.
        </li>
      </ul>

      <p>This extension is not deemed to be double insurance.</p>

      <p>
        <b>CONTRACT (RIGHTS OF THIRD PARTIES) ACT 1999 EXCLUSION CLAUSE</b>
        <br />
        The provisions of the Contracts (Rights of Third Parties) Act 1999 do not apply to this insurance or to any
        certificate(s) of insurance hereunder. Neither this nor any certificates issued hereunder confer any benefits on
        any third parties. No third party may enforce any term of this insurance or of any certificate issued hereunder.
        This clause shall not affect the rights of the Assured (as assignee or otherwise) or the rights of any loss
        payee.
      </p>

      <p>
        <b>CRAFT CLAUSE</b>
        <br />
        Including transit by craft and/or lighter to and from the vessel. Each craft and/or lighter to be deemed a
        separate insurance. Also to cover any special or supplementary lighterage. The Assured are not to be prejudiced
        by any agreement exempting lightermen from liability.
      </p>

      <p>
        <b>CUTTING CLAUSE</b>
        <br />
        Applicable to items where dimensions are not imperative. In the event of damage or breakage caused by an insured
        peril it is agreed that the damaged or broken length or portion shall be cut off, the remaining length or
        portion to be considered as sound and the Underwriters only to be liable for the insured value of the length or
        portion which has been lost by being broken off or cut off and for the cost of cutting.
      </p>

      <p>
        <b>DEBRIS REMOVAL CLAUSE</b>
        <br />
        This insurance is extended to cover, in addition to any other amount recoverable under this insurance, extra
        expenses reasonably incurred by the Assured for the removal and disposal of debris of the subject matter
        insured, or part thereof, by reason of damage thereto caused by an insured risk, but excluding absolutely:
        <br />
        - any expenses incurred in consequence of or to prevent or mitigate pollution or contamination, or any threat or
        liability thereof
        <br />
        - the cost of removal of cargo from any vessel or craft
        <br />
      </p>

      <p>
        In no case shall the insurers be liable under this clause for more than 10% in all of the proportionate insured
        value under this policy of the damaged subject-matter removed.
      </p>

      <p>
        <b>DELIBERATE DAMAGE POLLUTION HAZARD CLAUSE</b>
        <br />
        This insurance is also extended to cover, but only while the subject-matter insured is on board a waterborne
        conveyance, loss of or damage to said property directly caused by governmental authorities acting for the public
        welfare to prevent or mitigate a pollution hazard or threat thereof, provided that the accident or occurrence
        creating the situation which required such governmental action would have resulted in a recoverable claim under
        the Policy (subject to all of its terms, conditions and warranties) if the property insured would have sustained
        physical loss or damage as a direct result of such accident or occurrence. This clause shall not increase the
        Limits of Liability provided for elsewhere herein.
      </p>

      <p>
        <b>DRIED FOODSTUFFS IN BAGS & SACKS</b>
        <br />
        Excluding Heating and Sweating Excluding Infestation arising from weevil, grub or web. Excluding Rejection
        Risks. Excluding any Natural loss in Weight. Warranted shipped in ventilated container(s)
      </p>

      <p>
        <b>ELECTRICAL AND MECHANICAL DERANGEMENT</b>
        <br />
        Excluding loss or damage due to mechanical, electrical or electronic breakdown or derangement unless caused by a
        peril insured against under the terms of this policy and there is evidence of external damage.
      </p>

      <p>
        <b>GENERAL AVERAGE</b>
        <br />
        For the purpose of claims for General Average contributions and Salvage charges recoverable hereunder, the
        subject matter insured shall be deemed to be insured for its full contributory value.
      </p>

      <p>
        <b>HELD COVERED</b>
        <br />
        It is necessary for the assured or their Representatives or their Agents, when they become aware of an event
        which is held covered under this insurance, to give prompt notice to the Insurers via their representatives or
        agents.
      </p>

      <p>
        <b>ILLEGAL PAYMENTS</b>
        <br />
        No payment shall be made under this insurance which is prohibited by national law or international agreement or
        convention. In the event that local country legislation decrees insurance must be effected locally then it is
        the responsibility of the claimant to ensure that appropriate dispensation or licences are in place otherwise
        underwriters reserve the right to withhold any claims payment which is in breach of such legislation.
      </p>

      <p>
        <b>INSOLVENCY EXCLUSION ENDORSEMENT</b>
        <br />
        The exclusion of loss damage or expense arising from insolvency or financial default of the owners managers
        charterers or operators of the vessel or aircraft (as applicable) contained within the Institute Clauses
        incorporated herein is amended to read
        <br />
        loss damage or expense proximately caused by insolvency or financial default of the owners managers charterers
        or operators of the vessel or aircraft.
      </p>

      <p>But, in any event, such exclusion shall not apply where:</p>

      <ul style={{ listStyleType: "disc", marginLeft: "2em" }}>
        <li>
          <p>
            prior to loading of the subject matter insured on board the vessel or aircraft, all reasonable practicable
            and prudent measures have been taken by the Assured, their servants or agents, to establish the financial
            reliability of the party in default; or
          </p>
        </li>
        <li>
          <p>
            the insurance has been assigned to the party claiming hereunder, who has bought or agreed to buy the subject
            matter insured in good faith and without notice of such insolvency or financial default; or
          </p>
        </li>
        <li>
          <p>
            the Assured has purchased the subject matter insured on terms under which the supplier or their servants or
            agents have been responsible for arranging the carriage.
          </p>
        </li>
      </ul>

      <p>
        <b>INSTITUTE CLASSIFICATION CLAUSE</b>
        <br />
        This insurance is subject to the Institute Classification Clause CL354 dated 1.1.2001 as follows:
        <br />
        QUALIFYING VESSELS
        <br />
        1. This insurance and the marine transit rates as agreed in the policy or open cover apply only to cargoes
        and/or interests carried by mechanically selfpropelled vessels of steel construction classed with a
        Classification Society which is:
        <br />
      </p>

      <p>
        1.1 a Member or Associate Member of the International Association of Classification Societies (IACS*), or
        <br />
        1.2 a National Flag Society as defined in Clause 4 below, but only where the vessel is engaged exclusively in
        the coastal trading of that nation (including trading on an inter-island route within an archipelago of which
        that nation forms part).
        <br />
      </p>

      <p>
        Cargoes and/or interests carried by vessels not classed as above must be notified promptly to underwriters for
        rates and conditions to be agreed.
      </p>

      <p>
        Should a loss occur prior to such agreement being obtained cover may be provided but only if cover would have
        been available at a reasonable commercial market rate on reasonable commercial market terms.
      </p>

      <p>
        AGE LIMITATION
        <br />
        2. Cargoes and/or interests carried by Qualifying Vessels (as defined above) which exceed the following age
        limits will be insured on the policy or open cover conditions subject to an additional premium to be agreed.
        <br />
      </p>

      <p>Bulk or combination carriers over 10 years of age or other vessels over 15 years of age unless they:</p>

      <p>
        2.1 have been used for the carriage of general cargo on an established and regular pattern of trading between a
        range of specified ports, and do not exceed 25 years of age, or
        <br />
        2.2 were constructed as containerships, vehicle carriers or double-skin open-hatch gantry crane vessels (OHGCs)
        and have been continuously used as such on an established and regular pattern of trading between a range of
        specified ports, and do not exceed 30 years of age.
        <br />
      </p>

      <p>
        CRAFT CLAUSE
        <br />
        3. The requirements of this Clause do not apply to any craft used to load or unload the vessel within the port
        area.
      </p>

      <p>
        NATIONAL FLAG SOCIETY
        <br />
        4. A National Flag Society is a Classification Society which is domiciled in the same country as the owner of
        the vessel in question which must also operate under the flag of that country.
      </p>

      <p>
        PROMPT NOTICE
        <br />
        5. Where this insurance requires the assured to give prompt notice to the Underwriters, the right to cover is
        dependent upon compliance with that obligation.
      </p>

      <p>
        LAW AND PRACTICE
        <br />
        6. This insurance is subject to English law and practice.
      </p>

      <p>
        * For a current list of IACS Members and Associate Members please refer to the IACS website at www.iacs.org.uk
      </p>

      <p>
        <b>
          INSTITUTE RADIOACTIVE CONTAMINATION CHEMICAL, BIOLOGICAL, BIO-CHEMICAL, ELECTROMAGNETIC WEAPONS EXCLUSION
          CLAUSE
        </b>
        <br />
        This clause shall be paramount and shall override anything contained in this insurance inconsistent therewith.
        <br />
        1. In no case shall this insurance cover loss damage liability or expense directly or indirectly caused by or
        contributed to by or arising from <br />
        1.1 ionising radiations from or contamination by radioactivity from any nuclear fuel or from any nuclear waste
        or from the combustion of nuclear fuel
        <br />
        1.2 the radioactive, toxic, explosive or other hazardous or contaminating properties of any nuclear
        installation, reactor or other nuclear assembly or nuclear component thereof
        <br />
        1.3 any weapon or device employing atomic or nuclear fission and/or fusion or other like reaction or radioactive
        force or matter <br />
        1.4 the radioactive, toxic, explosive or other hazardous or contaminating properties of any radioactive matter.
        The exclusion in this sub-clause does not extend to radioactive isotopes, other than nuclear fuel, when such
        isotopes are being prepared, carried, stored, or used for commercial, agricultural, medical, scientific or other
        similar peaceful purposes.
        <br />
        1.5 any chemical, biological, bio-chemical or electromagnetic weapon.
        <br />
        10/11/03
        <br />
        CL370
        <br />
      </p>

      <p>
        <b>ITEMS IN NON-WATERPROOF CRATES OR PACKAGING</b>
        <br />
        Excluding Rust, Oxidation, Discoloration, Wetting, Staining and the Cost of Repainting as applicable.
      </p>

      <p>
        <b>LABELS CLAUSE</b>
        <br />
        In case of damage affecting labels, capsules or wrappers the Underwriters, if liable therefor under the terms of
        this Policy, shall not be liable for more than an amount sufficient to pay the cost of new labels, capsules or
        wrappers, and the cost of reconditioning the goods, but in no event shall the insurers be liable for more than
        the insured value of the damaged merchandise.
      </p>

      <p>
        <b>LAW AND PRACTICE</b> – UK risks
        <br />
        This insurance is subject to English law and practice and to the exclusive jurisdiction of the English Courts
        sitting in London.
      </p>

      <p>
        <b>LAW AND PRACTICE</b> – Non UK risks
        <br />
        This insurance is subject to English law and practice.
      </p>

      <p>
        <b>LIABILITY OF CARRIERS BAILEES OR OTHER THIRD PARTIES</b>
        <br />
        It is the duty of the Assured and their Agents, in all cases, to take such measures as may be reasonable for the
        purpose of averting or minimising a loss to ensure that all rights against Carriers, Bailees or other third
        parties are properly preserved and exercised. In particular, the Assured and / or their Agents are required:
        <br />
        1. To claim immediately on the Carriers, Port Authorities or other Bailees for any missing package.
        <br />
        2. In no circumstances, except under written protest, to give clean receipts where goods are in doubtful
        condition.
        <br />
        3. When delivery is made by container, to ensure that the container and its seals are examined immediately by
        their responsible official. If the container is delivered damaged or with seals broken or missing or with seals
        other than stated in the shipping documents, to clause the delivery receipt accordingly and retain all defective
        or irregular seals for subsequent identification. <br />
        4. To apply immediately for survey by Carriers’ or other Bailees, Representatives if any loss or damage be
        apparent and claim on the Carriers or other Bailees for any actual loss or damage found at such survey.
        <br />
        5. To give notice in writing to the Carriers or other Bailees within three days of delivery if the loss or
        damage was not apparent at the time of taking delivery.
        <br />
      </p>

      <p>
        Note: The Consignees or their agents are recommended to make themselves familiar with the regulations of the
        Port Authorities at the port of discharge.
      </p>

      <p>
        <b>LOSS OF DATA</b>
        <br />
        In respect of electronic or computerised items: Including loss or damage for installed or other included
        software such as operating systems or office suites but excluding claims for loss of data or any other
        proprietary information.
      </p>

      <p>
        <b>MARINE CYBER EXCLUSION</b>
        <br />
        This clause shall be paramount and shall override anything in this insurance inconsistent therewith.
        <br />
        1 In no case shall this insurance cover any loss, damage, liability or expense directly or indirectly caused by,
        contributed to by or arising from: 1.1 the failure, error or malfunction of any computer, computer system,
        computer software programme, code, or process or any other electronic system, or
        <br />
        1.2 the use or operation, as a means for inflicting harm, of any computer, computer system, computer software
        programme, malicious code, computer virus or process or any other electronic system.
        <br />
      </p>

      <p>
        LMA5402
        <br />
        11 November 2019
        <br />
      </p>

      <p>
        <b>NO SURVEY CLAUSE</b>
        <br />
        No survey required on claims unlikely to exceed US$1,500 or equivalent in any other currency.
      </p>

      <p>
        <b>NON CONTRIBUTION CLAUSE</b>
        <br />
        This insurance does not cover any loss or damage which at the time of happening of such loss or damage is
        insured by or would but for the existence of this Policy, be insured by any other existing Policy or Policies
        except in respect of any excess beyond the amount which would have been payable under such other Policy or
        Policies had this Insurance not been effected. In the event that the Assured shall also have placed specific
        insurance at any location covered hereunder, this insurance shall only be held for the excess amount of loss
        over that which shall be collectible from other insurance.
      </p>

      <p>
        <b>ON DECK SHIPMENTS</b>
        <br />
        Unless in fully enclosed or open top containers on deck shipments are insured subject to the deletion of the
        Institute Cargo Clauses (A) and replaced with Institute Cargo Clauses (C) CL 384 dated 1.1.2009 extended to
        include the risks of washing overboard and theft or non-delivery of an entire consignment or full container,
        trailer or vehicle load.
      </p>

      <p>
        <b>ONUS OF PROOF</b>
        <br />
        The onus of proof rests with the Assured to show that loss of or damage to the subject matter insured occurred
        within the certificate of marine cargo insurance period of cover and occurred within the scope and ambit of the
        Policy.
      </p>

      <p>
        <b>OPEN TOP CONTAINERS</b>
        <br />
        In respect of good in open top containers: Excluding claims for loss or damage to any part or parts of the
        subject matter that protrude outside of the boundaries of the top of an open top container unless following a
        peril insured under the terms of the Institute Cargo Clauses (B) CL 383 dated 1.1.2009
      </p>

      <p>
        <b>PAIRS AND SETS CLAUSE</b>
        <br />
        Where any insured item consists of articles in a pair or set this policy is not to pay more than the value of
        any particular part or parts which may be lost without reference to any special value which such article or
        articles may have as part of such pair or set, nor more than a proportionate part of such pair or set.
      </p>

      <p>
        <b>PROCESS CLAUSE</b>
        <br />
        Excluding loss and/or damage to the subject-matter hereby insured caused by processing, errors in processing
        and/or as a direct result of being worked upon unless due to an external cause otherwise covered by this policy.
      </p>

      <p>
        <b>RECONDITIONED GOODS and / or REFURBISHED USED GOODS</b>
        <br />
        Warranted that the Interest insured is fully refurbished / reconditioned to a new standard. Excluding Rust,
        Oxidation, Discoloration, Wetting, Staining, Scratching, Bruising Chipping, Denting, Marring and the Cost of
        Repainting as applicable unless caused by an Insured peril. Subject to the Secondhand Replacement Clause as
        above.
      </p>

      <p>
        <b>REPLACEMENT CLAUSE</b>
        <br />
        Subject to the Institute Replacement Clause CL 161 dated 1.1.34 and/or the Secondhand Replacement Clause as
        below, as applicable.
      </p>

      <p>
        <b>RETURN SHIPMENTS CLAUSE</b>
        <br />
        This Policy of insurance is extended to cover, at Policy terms and conditions, shipments of returned goods which
        have not been delivered to the final consignee and which have been continuously covered hereunder, provided such
        goods remain in their original approved overseas packing and the Assured warrants to report all such shipments
        as soon as practicable after they have knowledge of the refusal. Shipments returned to the Assured by the
        consignee shall be subject to the same terms, conditions and rate under which such shipments were insured under
        this Policy while in transit to such consignees. Returned merchandise other than as defined above is insured
        subject to the Institute Cargo Clauses (C) CL 384 dated 1.1.2009 at rates to be agreed by Underwriters.
      </p>

      <p>
        <b>SANCTION LIMITATION AND EXCLUSION CLAUSE</b>
        <br />
        No (re)insurer shall be deemed to provide cover and no (re)insurer shall be liable to pay any claim or provide
        any benefit hereunder to the extent that the provision of such cover, payment of such claim or provision of such
        benefit would expose that (re)insurer to any sanction, prohibition or restriction under United Nations
        resolutions or the trade or economic sanctions, laws or regulations of the European Union, United Kingdom or
        United States of America.
        <br />
        JC2010/014 11 August 2010
        <br />
      </p>

      <p>
        <b>SECONDHAND REPLACEMENT CLAUSE – applicable to Secondhand or Used goods</b>
        <br />
        In the event of a claim for loss of or damage to any part or parts of the subject matter insured, in consequence
        of a peril covered by the policy, the amount recoverable hereunder shall not exceed such proportion of the cost
        of replacement of the part or parts lost or damaged as the insured value bears to the value of a new machine
        plus additional charges for forwarding and refitting the new part or parts if incurred. Provided always that in
        no case shall the liability of Underwriters exceed the insured value of the complete article.
      </p>

      <p>
        <b>SERVICE OF SUIT CLAUSE</b> - In respect of all US States other than California (for California see below){" "}
        <br />
        This insurance is subject to the Institute Service of Suit Clause (USA) CL 355 dated 1/11/92 as follows: <br />
        It is agreed that in the event of the failure of the Underwriters severally subscribing this insurance (the
        Underwriters) to pay any amount claimed to be due hereunder, the Underwriters, at the request of the Assured,
        will submit to the jurisdiction of a court of competent jurisdiction within the United States of America.
        <br />
        Notwithstanding any provision elsewhere in this insurance relating to jurisdiction, it is agreed that the
        Underwriters have the right to commence an action in any court of competent jurisdiction in the United States of
        America, and nothing in this clause constitutes or should be understood to constitute a waiver of the
        Underwriters’ rights to remove an action to a United States Federal District Court or to seek remand therefrom
        or to seek a transfer of any suit to any other court of competent jurisdiction as permitted by the laws of the
        United States of America or any state therein. <br />
        Subject to the Underwriters’ rights set forth above: <br />
        (a) It is further agreed that the Assured may serve process upon any senior partner in the firm of: Mendes &
        Mount (Attorneys), 750 Seventh Avenue, New York, N.Y. 10019-6829 and that in any suit instituted against any one
        of them upon this contract the Underwriters will abide by the final decision of the Court or of any Appellate
        Court in the event of an appeal.
        <br />
        (b) The above-named are authorized and directed to accept service of process on behalf of Underwriters in any
        such suit and/or upon the request of the Assured to give a written undertaking to the Assured that they will
        enter a general appearance upon the Underwriters’ behalf in the event such a suit shall be instituted.
        <br />
        (c) The right of the Assured to bring suit as provided herein shall be limited to a suit brought in its own name
        and for its own account. For the purpose of suit as herein provided the word Assured includes any mortgagee
        under a ship mortgage which is specifically named as a loss payee in this insurance and any person succeeding to
        the rights of any such mortgagee.
        <br />
        (d) Further, pursuant to any statute of any state, territory or district of the United States of America which
        makes provision therefor, Underwriters hereby designate the Superintendent, Commissioner or Director of
        Insurance or other officer specified for that purpose in the statute, or his successor or successors in office
        (the Officer), as their true and lawful attorney upon whom may be served any lawful process in any action, suit
        or proceeding instituted by or on behalf of the Assured or any beneficiary hereunder arising out of this
        contract of insurance, and hereby designate the above named as the person to whom the Officer is authorized to
        mail such process or a true copy thereof. <br />
        If this clause is attached to a contract of reinsurance the terms insurance and Assured shall mean reinsurance
        and Reassured respectively.
        <br />
        1/11/92
        <br />
        CL355
        <br />
        For service of process in California only:
        <br />
        It is agreed that the Assured may serve process upon any senior partner in the firm of: FLWA Service Corp. c/o
        Foley & Lardner LLP, 555 California Street, Suite 1700, San Francisco, CA 94104-1520
        <br />
      </p>

      <p>
        <b>STONE, MARBLE, SLATE, GRANITE AND SIMILAR SLABS</b>
        <br />
        Warranted slabs are individually separated and suitably packed or protected for transit in crates or cases.
        Excluding breakage, chipping, splitting or cracking unless caused by an accident to the carrying conveyance or
        dropping whilst loading or unloading.
      </p>

      <p>
        <b>SUE AND LABOUR CLAUSE</b>
        <br />
        In case of any imminent or actual loss or misfortune, it shall be lawful and necessary to and for the Insured,
        his or their factors, servants and assigns, to sue, labour and travel for, in and about the defense, safeguard
        and recovery of the said goods and merchandise, or any part thereof, without prejudice to this insurance, to the
        charges whereof, Underwriters will contribute according to the rate and quantity of the sum hereby insured; nor
        shall the acts of the Insured or Underwriters, in recovering, saving and preserving the property insured, in
        case of disaster, be considered a waiver or an acceptance of abandonment.
      </p>

      {parsedSubmission.cargo.cargos?.some((cargo) => cargo.group !== 7) && (
        <p>
          <b>TEMPERATURE VARIATION</b>
          <br />
          Does not apply to goods shipped at -15 (minus fifteen) degrees Celsius or 5 (five) degrees Fahrenheit:
          <br />
          Excluding claims for loss, damage or change in the nature of the subject matter insured caused by variation in
          temperature unless caused by a peril insured under the terms of the Institute Cargo Clauses (B) CL383 dated
          1.1.2009.
        </p>
      )}

      <p>
        <b>TERMINATION OF TRANSIT CLAUSE (TERRORISM)</b>
        This clause shall be paramount and shall override anything contained in this insurance inconsistent therewith.
        <br />
        Notwithstanding any provision to the contrary contained in this Policy or the Clauses referred to therein, it is
        agreed that in so far as this Policy covers loss of or damage to the subject-matter insured caused by any
        terrorist or any person acting from a political motive, such cover is conditional upon the subject-matter
        insured being in the ordinary course of transit and, in any event, SHALL TERMINATE: either
        <br />
        1.1 As per the transit clauses contained within the Policy, or
        <br />
        1.2 on delivery to the Consignee’s or other final warehouse or place of storage at the destination named herein,
        <br />
        1.3 on delivery to any other warehouse or place of storage, whether prior to or at the destination named herein,
        which the Assured elect to use either for storage other than in the ordinary course of transit or for allocation
        or distribution, or
        <br />
        1.4 in respect of marine transits, on the expiry of 60 days after completion of discharge overside of the goods
        hereby insured from the oversea vessel at the final port of discharge,
        <br />
        1.5 in respect of air transits, on the expiry of 30 days after unloading the subject-matter insured from the
        aircraft at the final place of discharge, whichever shall occur first
        <br />
      </p>

      <p>
        If this Policy or the Clauses referred to therein specifically provide cover for inland or other further
        transits following on from storage, or termination as provided for above, cover will re-attach, and continues
        during the ordinary course of that transit terminating again in accordance with clause 1.
        <br />
        This clause is subject to English law and practice.
      </p>

      <p>
        <b>UNPACKED / UNPROTECTED ITEMS </b>
        <br />
        Excluding Rust, Oxidation, Discoloration, Wetting, Staining, Scratching, Bruising, Chipping, Denting, Marring
        and the Cost of Repainting as applicable.
      </p>

      <p>
        <b>WAREHOUSING / FORWARDING CHARGES</b>
        <br />
        Provided that a claim is recoverable from Underwriters and notwithstanding any average warranty contained
        herein, Underwriters agree to pay any landing, warehousing, forwarding or other expenses and/or particular
        charges should same be incurred, as well as any partial loss arising from transhipment. Also to pay the insured
        value of any package, piece or unit totally lost in loading, transhipment and/or discharge. Also to pay for any
        loss or damage to the interest insured which may be reasonably attributed to discharge of cargo at port of
        distress.
      </p>

      <p>
        <b>YACHTS AND OTHER BOATS </b>
        <br />
        Warranted new and transported in fully enclosed containers or trucks. Excluding loss or damage to accessories or
        portable items unless declared prior to shipment and they are suitably packed or protected for transit.
        Excluding theft or pilferage of audio, electronic communication or display equipment, entertainment or computer
        or navigational equipment and similar items unless stolen with the craft. Warranted that unpacked craft are
        suitably secured to a trailer or cradle.
      </p>
    </section>
  );
};

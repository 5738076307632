import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import sumBy from "lodash.sumby";
import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import Form from "../components/Form";
import H3 from "../components/H3";
import InputPercentage from "../components/InputPercentage";
import Modal from "../components/Modal";
import { useContractQuery, useTenantsQuery } from "../hooks";
import { isContractConsortium } from "../../helpers/consortium";
import { getConsortiumCommission } from "../../constants/consortium";
import { getTenantCommissionsFrom } from "../../services/aws/tenants";

const ChangeCommissionModalForm = ({ handleClose }) => {
  const { contractId, productRef, contractData, updateContract, isUpdating } = useContractQuery();
  const { tenants } = useTenantsQuery();

  const [formValues, setFormValues] = useState(contractData.brokerCommissions.commissionBreakdown);

  const commissionSum = sumBy(formValues, "commission");
  const brokerCommissionCap = contractData.brokerCommissions.commissionCap;

  const commissionSumAboveCap = commissionSum > brokerCommissionCap;
  const errorMessageTotal = `Total may not exceed ${brokerCommissionCap}%`;

  const onChange = (value, name) => {
    const clonedFormValues = cloneDeep(formValues);
    set(clonedFormValues, name, { ...clonedFormValues[name], commission: value || 0 });
    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await updateContract({
      contractId,
      productRef,
      data: {
        type: ACTIONS.UPDATE_CONTRACT_COMMISSION,
        payload: { commissionBreakdown: formValues },
      },
    });
    handleClose();
  };

  // The following logic is based on the following requirements:
  // https://trello.com/c/nwD4y2lb/272-new-consortium-brokers#comment-66b35b5ea699ca84021356a1
  //
  // In the scenario of the screenshot; “Price Forbes & Limited” commission would be limited to 22.5% as the quote has been provided via the Consortium path;
  // the Producing agent “Trident Broker” has 0% defined in console. So I would expect Trident to display 0% and Price Forbes to display the remaining 22.5%

  // In a separate scenario, a Producing agent “Woodlands Broker” has 20% defined in console. So I would expect Woodlands to display 20%
  // and Price Forbes to display the remaining 2.5%

  const isConsortium = useMemo(() => {
    return isContractConsortium(contractData);
  }, [contractData]);

  const tenantId = contractData.tenantId;

  const umr = contractData.placingBroker.umr;

  const consortiumCommission = useMemo(() => {
    return isConsortium ? getConsortiumCommission(umr) : undefined;
  }, [isConsortium, umr]);

  const currentCommissionBreakdown = useMemo(() => {
    return getTenantCommissionsFrom(tenants, tenantId, [], isConsortium, consortiumCommission);
  }, [tenants, tenantId, isConsortium, consortiumCommission]);

  return (
    <div className="p-6">
      <H3>Broker commission split</H3>
      <div className="text-gray-700 text-sm mb-6">Total may not exceed {brokerCommissionCap}%</div>
      <Form onSubmit={handleSubmit}>
        {formValues.map((f, index) => {
          const currentCommissionCap = currentCommissionBreakdown?.commissionBreakdown?.find((tenant) => tenant.id === f.id)?.commission;
          const brokerCommissionCap = currentCommissionCap >= 0 ? currentCommissionCap : f.commission;

          return (
            <InputPercentage
              key={`broker-commission-split-input-percentage-${index}`}
              className="mb-8"
              decimalScale={2}
              isRequired
              labelText={f.name}
              name={index}
              value={f.commission}
              onChange={onChange}
              errors={
                f.commission > brokerCommissionCap
                  ? `The ${f.name} commission may not be greater than ${brokerCommissionCap}%`
                  : undefined
              }
            />
          );
        })}
        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={isUpdating || formValues.some((f) => isNaN(f.commission)) || commissionSumAboveCap}
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const ChangeCommissionModal = ({ handleClose }) => (
  <Modal handleClose={handleClose} headingText="Amend commission" isLoading={false}>
    <ChangeCommissionModalForm handleClose={handleClose} />
  </Modal>
);

ChangeCommissionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ChangeCommissionModal;

import React, { FC } from "react";

export const Annual_Claims: FC = () => (
  <section aria-labelledby="claimsHandlingAndNotification">
    <h1 id="claimsHandlingAndNotification">CLAIMS HANDLING AND NOTIFICATION</h1>

    <p>
      In the event of an incident that may give rise to a claim under this Policy where such claim would be, or likely
      to be in excess of the applicable deductible the Assured and/or the brokers must give immediate notification to
      the following: -
    </p>

    <p>
      1. Claims in <b>USA, Central America and South America</b>
      <br />
      please contact
      <br />
      <br />
      Crawford & Co.
      <br />
      600 Reed Road, Suite 203C,
      <br />
      Broomall,
      <br />
      Philadelphia, USA
      <br />
      PA 19008
      <br />
      Telephone: +1 844 869 2535 (24 hour helpline)
      <br />
      FAX: +1 866 286 3711
      <br />
      Email: forwarderlink.us@us.crawco.com
    </p>

    <p>
      2. Claims in <b>all other countries</b>
      <br />
      please contact
      <br />
      Crawford & Co.
      <br />
      8 Exchange Quay,
      <br />
      Manchester,
      <br />
      England M5 3EJ
      <br />
      Telephone: +44 (0) 161 674 0050 (24 hour helpline)
      <br />
      FAX: +44 (0) 161 476 8840
      <br />
      Email: forwarderlink.uk@crawco.co.uk
    </p>

    <p>Crawford & Co. are authorised by Underwriters to:-</p>
    <ul style={{ listStyleType: "disc", marginLeft: "2em" }}>
      <li>Receive such instructions at the addresses shown above</li>
      <li>Handle the claim(s) or investigate the incident that may give rise to a claim</li>
      <li>Appoint when so required the necessary expert including but not limited to a surveyor or lawyer</li>
    </ul>

    <p>
      <b>DOCUMENTATION OF CLAIMS</b> <br />
      To enable claims to be dealt with promptly and to avoid prejudicing your claim under the Policy, you are required
      to submit all available supporting documents to Underwriters or our agents as soon as possible, including when
      applicable:
    </p>

    <p>
      1. Transit Documents, including Original Bills of Lading, Charter Parties, Air Waybills or Contracts of Carriage
      <br />
      2. The Original Certificate of Marine Cargo insurance if applicable
      <br />
      3. Original or copy invoices, shipping specification together with weight notes, indicating quantity and value
      <br />
      4. Confirmation of contract of carriage and/or trading conditions
      <br />
      5. Packing list (if applicable)
      <br />
      6. All correspondence exchanged with the carriers and other parties regarding their liability for the loss or
      damage.
      <br />
      7. Claused delivery note
      <br />
      8. Statement of events
      <br />
      9. Survey report or other documentary evidence to the show extent of loss or damage
      <br />
    </p>

    <p>
      <b>FRAUDULENT CLAIMS</b>
      <br />
      If any claim is fraudulent or false or intentionally exaggerated (whether ultimately material or not) in any
      respect, Underwriters may refuse to pay the whole or part of the claim to the extent permitted by law, and also
      may be entitled to void or cancel the Policy.
    </p>
  </section>
);

import React, { useRef } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";

const UploadButton = ({ onChange, isDisabled }) => {
  const inputFile = useRef();

  return (
    <div className="mr-2">
      <input
        className="sr-only"
        disabled={isDisabled}
        id="uploadFile"
        onChange={onChange}
        type="file"
        ref={inputFile}
      />

      <Button
        kind="secondary"
        type="button"
        className="h-10 px-2 "
        isDisabled={isDisabled}
        onClick={() => inputFile.current.click()}
      >
        <Icon name="paperclip" className="cursor-pointer w-4 fill-current text-gray-700" />
      </Button>
    </div>
  );
};

export default UploadButton;

import { CargoPremium } from "../types";

export const premiumCommissionCalculation = (
  name: "Transit premium" | "Storage premium" | "Exhibitions premium" | "Samples premium",
  premium: number,
  commission?: number,
  tax?: number,
): CargoPremium => {
  const grossPremium = commission !== undefined ? (premium / (100 - commission)) * 100 : undefined;
  const commissionAmount =
    commission !== undefined && grossPremium !== undefined ? grossPremium * (commission / 100) : undefined;

  if (tax === undefined) {
    tax = 0;
  }

  const grossPremiumPlusTax = grossPremium !== undefined ? grossPremium + tax : undefined;

  return {
    name,
    grossPremium: grossPremium,
    commissionAmount: commissionAmount,
    netPremium: premium,
    tax,
    grossPremiumPlusTax,
  };
};

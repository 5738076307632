/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import React from "react";
import { v4 as uuid } from "uuid";
import Icon from "../../components/Icon";
import { useTimeAgo } from "../../hooks";

const splitContent = (message) =>
  (message.content || "")
    .split("\n")
    .filter(Boolean)
    .map((str) => <p key={uuid()}>{str}</p>);

const Message = ({ message, user, handleFileClick }) => {
  const isOwn = message.createdBy.id === user.id;
  const timestamp = new Date(message.createdAt).getTime();
  const timeago = useTimeAgo(timestamp);
  const isFile = message.contentType === "file";

  return (
    <div key={message.id} className={clsx("mb-6", { "mr-4": !isOwn, "ml-4": isOwn })}>
      <div className="text-xs text-gray-700 mb-1">
        <span className="font-semibold"> {message.createdBy.fullName} </span>{" "}
        {isFile ? "uploaded a file" : "wrote"} {timeago}
      </div>

      <div
        className={clsx("text-sm px-3 py-2 rounded-lg", {
          "bg-gray-300": !isOwn,
          "bg-blue-700 text-white": isOwn,
        })}
      >
        {!isFile && splitContent(message)}

        {isFile && (
          <div className="flex cursor-pointer" onClick={() => handleFileClick(message)}>
            <div className="flex items-center mr-2">
              <Icon
                name="paperclip"
                className={clsx("w-6 fill-current", {
                  "text-gray-700": !isOwn,
                  "text-white": isOwn,
                })}
              />
            </div>
            <div>
              <div className="truncate w-64">{message.content.fileName}</div>
              <div className="text-xs">{message.content.documentSize}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;

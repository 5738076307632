// https://docs.google.com/spreadsheets/d/13mx2AJoAgfPsJrQRW0P_FsgDgQ1UFxy5/edit#gid=575545180

import { getDaysInYear } from "date-fns";
import { RaterStep } from "../../../@types/types";
import { inRange } from "../../../helpers/inRange";
import { asCurrency, asFloat, asPercent, assertIsDefined } from "../helpers";
import parseISO from "date-fns/parseISO";
import { parseSubmission } from "../parseSubmission";
import table10b from "../refData/table10b";
import table12 from "../refData/table12";
import table5a from "../refData/table5a";
import {
  CargoCondition,
  CargoDeductible,
  CargoDeductibleAmount,
  CargoGroup,
  CargoRaterOutput,
  ContainerType,
  Conveyance,
  ConveyanceType,
  ExhibitionDuration,
  ExhibitionLimitAmount,
  ExhibitionNumber,
  ICCClause,
  Peril,
  PerilLevel,
  PerilType,
  Region,
  RiskType,
  USState,
} from "../types";
import { premiumCommissionCalculation } from "./helpers";

/** Table 1: Domicile Rate	*/
export const getDomicileFactor = (domicile: Region): number => {
  if (domicile === "Western Europe") return 1.0;
  if (domicile === "USA & Canada") return 1.5;
  if (domicile === "Asia & Pacific including Australasia") return 2.0;
  if (domicile === "Eastern Europe & Russia West of Urals") return 2.5;
  if (domicile === "Middle East") return 2.5;
  if (domicile === "Caribbean") return 3.0;
  if (domicile === "South & Central America") return 3.0;
  if (domicile === "Former USSR and Russia East of Urals") return 3.0;
  if (domicile === "India and sub continent") return 3.0;
  if (domicile === "Africa") return 4.0;

  throw new Error(`Unable to find domicile factor. Received: ${JSON.stringify({ domicile })}`);
};

/** Table 2: Conveyance Rate */
export const getConveyanceFactor = (conveyanceType: ConveyanceType, containerType?: ContainerType): number => {
  if (conveyanceType === "Post") return 0;
  if (conveyanceType === "Sea" && containerType === "FCL") return 4;
  if (conveyanceType === "Sea" && containerType === "LCL") return 6.5;
  if (conveyanceType === "Air only") return 3;
  if (conveyanceType === "Road only") return 1.5;
  if (conveyanceType === "Rail only") return 1.5;

  throw new Error(`Unable to find conveyance factor. Received: ${JSON.stringify({ conveyanceType, containerType })}`);
};

/** Table 2b: Own Vehicle Road Conveyance */
export const getOwnVehicleRoadConveyanceFactor = (turnover: number): number => {
  if (inRange(turnover, 0, 20)) return 1.65;
  if (inRange(turnover, 21, 40)) return 1.8;
  if (inRange(turnover, 41, 60)) return 1.95;
  if (inRange(turnover, 61, 80)) return 2.1;
  if (inRange(turnover, 81, 100)) return 2.3;

  throw new Error(`Unable to find own vehicle road conveyance factor. Received: ${JSON.stringify({ turnover })}`);
};

/** Table 3: Conveyance Limit factor - no own vehicles */
export const getConveyanceLimitFactor = (limit: number): number => {
  if (inRange(limit, 0, 50_000)) return 0.85;
  if (inRange(limit, 50_001, 100_000)) return 2;
  if (inRange(limit, 100_001, 250_000)) return 6;
  if (inRange(limit, 250_001, 500_000)) return 14;
  if (inRange(limit, 500_001, 750_000)) return 20;
  if (inRange(limit, 750_001, 1_000_000)) return 25;

  throw new Error(`Unable to find conveyance limit (no own vehicles) factor. Received: ${JSON.stringify({ limit })}`);
};

/** Table 4: Client Score Loading Factor */
export const getClientScoreLoadingFactor = (score: number): number => {
  if (score === 0) return 0;
  if (score === 1) return 1.1;
  if (score === 2) return 1.2;
  if (score === 3) return 1.3;
  if (score === 4) return 1.4;
  if (score === 5) return 1.5;
  if (score === 6) return 1.6;
  if (score === 7) return 1.7;
  if (score === 8) return 1.8;
  if (score === 9) return 1.9;
  if (score === 10) return 2;
  if (score === 11) return 2.1;
  if (score === 12) return 2.2;
  if (score === 13) return 2.3;
  if (score === 14) return 2.4;
  if (score === 15) return 2.5;
  if (score === 16) return 2.6;
  if (score === 17) return 2.7;
  if (score === 18) return 2.8;
  if (score === 19) return 2.9;
  if (score === 20) return 3;
  if (score === 21) return 3.1;
  if (score === 22) return 3.2;
  if (score === 23) return 3.3;
  if (score === 24) return 3.4;
  if (score === 25) return 3.5;
  if (score === 26) return 3.6;
  if (score === 27) return 3.7;
  if (score === 28) return 3.8;
  if (score === 29) return 3.9;
  if (score === 30) return 4;
  if (score === 31) return 4.1;
  if (score === 32) return 4.2;
  if (score === 33) return 4.3;
  if (score === 34) return 4.4;
  if (score === 35) return 4.5;
  if (score === 36) return 4.6;
  if (score === 37) return 4.7;
  if (score === 38) return 4.8;
  if (score === 39) return 4.9;
  if (score === 40) return 5;

  throw new Error(`Unable to find client score loading factor. Received: ${JSON.stringify({ score })}`);
};

/** Table 5: Cargo Type Factor */
export const getCargoTypeFactor = (group: CargoGroup): number => {
  if (group === 1) return 1;
  if (group === 2) return 1.25;
  if (group === 3) return 1.45;
  if (group === 4) return 8;
  if (group === 5) return 12.5;
  if (group === 1234) return 7.75;
  if (group === 7) return 4.85;

  throw new Error(`Unable to find cargo type factor. Received: ${JSON.stringify({ group })}`);
};

/** Table 5a: Cargo Deductible Factor */
export const getCargoDeductibleFactor = (group: CargoGroup, deductible: CargoDeductible): number => {
  const index = [250, 500, 750, 1_000, 1_500, 2_000, 2_500, 5_000].findIndex((item) => item === deductible?.amount);
  const result = table5a.find((item) => item[0] === group)?.[index + 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find cargo deductible factor. Received: ${JSON.stringify({ group, deductible })}`);
};

/** Table 5a: Cargo Default Deductible */
export const getCargoDefaultDeductible = (group: CargoGroup): CargoDeductibleAmount => {
  if (group === 1) return 250;
  if (group === 2) return 500;
  if (group === 3) return 750;
  if (group === 4) return 750;
  if (group === 5) return 500;
  if (group === 1234) return 500;
  if (group === 7) return 500;

  throw new Error(`Unable to find cargo default deductible. Received: ${JSON.stringify({ group })}`);
};

/** Table 6: Own Cargo Factor */
export const getOwnCargoFactor = (domicile: Region, ownDomicile: Region): number => {
  if (domicile === ownDomicile) return 0.000251;
  if (domicile === "Western Europe") return 0.00045;
  if (domicile === "USA & Canada") return 0.00055;
  if (domicile === "Eastern Europe & Russia West of Urals") return 0.00075;
  if (domicile === "Middle East") return 0.00065;
  if (domicile === "Africa") return 0.0019;
  if (domicile === "South & Central America") return 0.00095;
  if (domicile === "Asia & Pacific including Australasia") return 0.000575;
  if (domicile === "Caribbean") return 0.00095;
  if (domicile === "Former USSR and Russia East of Urals") return 0.00135;
  if (domicile === "India and sub continent") return 0.00145;

  throw new Error(`Unable to find own cargo factor. Received: ${JSON.stringify({ domicile, ownDomicile })}`);
};

/** Table 7: Volume Multiplier */
export const getVolumeMultiplier = (turnover: number): number => {
  if (turnover === 0) return 0;
  if (inRange(turnover, 1, 200_000)) return 1.1;
  if (inRange(turnover, 200_001, 500_000)) return 1;
  if (inRange(turnover, 500_001, 1_000_000)) return 0.9;
  if (inRange(turnover, 1_000_001, 2_000_000)) return 0.8;
  if (inRange(turnover, 2_000_001, 5_000_000)) return 0.7;
  if (inRange(turnover, 5_000_001, 10_000_000)) return 0.6;
  if (inRange(turnover, 10_000_001, 50_000_000)) return 0.5;

  throw new Error(`Unable to find volume multiplier. Received: ${JSON.stringify({ turnover })}`);
};

/** Table 8: Contingent Cargo Factor */
export const getContingentCargoFactor = (domicile: Region, ownDomicile: Region): number => {
  if (domicile === ownDomicile) return 0.0001004;
  if (domicile === "Western Europe") return 0.00018;
  if (domicile === "USA & Canada") return 0.00022;
  if (domicile === "Eastern Europe & Russia West of Urals") return 0.00038;
  if (domicile === "Middle East") return 0.00026;
  if (domicile === "Africa") return 0.00086;
  if (domicile === "South & Central America") return 0.00055;
  if (domicile === "Asia & Pacific including Australasia") return 0.00023;
  if (domicile === "Caribbean") return 0.00054;
  if (domicile === "Former USSR and Russia East of Urals") return 0.0007;
  if (domicile === "India and sub continent") return 0.00086;

  throw new Error(`Unable to find contingent cargo factor. Received: ${JSON.stringify({ domicile, ownDomicile })}`);
};

/** Table 9: Postal Shipment Factor */
export const getPostalShipmentFactor = (turnover: number): number => {
  if (inRange(turnover, 0, 20)) return 3.3;
  if (inRange(turnover, 21, 40)) return 6.5;
  if (inRange(turnover, 41, 60)) return 13;
  if (inRange(turnover, 61, 80)) return 25.5;
  if (inRange(turnover, 81, 100)) return 32;

  throw new Error(`Unable to find postal shipment factor. Received: ${JSON.stringify({ turnover })}`);
};

/** Table 10: Storage Rate Table */
export const getStorageFactor = (domicile: Region, ownDomicile: Region): number => {
  if (domicile === ownDomicile) return 0.00175;
  if (domicile === "Western Europe") return 0.00175;
  if (domicile === "USA & Canada") return 0.00175;
  if (domicile === "Eastern Europe & Russia West of Urals") return 0.00275;
  if (domicile === "Middle East") return 0.0035;
  if (domicile === "Africa") return 0.0075;
  if (domicile === "South & Central America") return 0.0045;
  if (domicile === "Asia & Pacific including Australasia") return 0.00225;
  if (domicile === "Caribbean") return 0.005;
  if (domicile === "Former USSR and Russia East of Urals") return 0.005;
  if (domicile === "India and sub continent") return 0.006;

  throw new Error(`Unable to find storage factor. Received: ${JSON.stringify({ domicile, ownDomicile })}`);
};

/** Table 10a: Storage Discount Table */
export const getStorageDiscount = (averageStorage: number): number => {
  if (inRange(averageStorage, 0, 0.1)) return 0.35;
  if (inRange(averageStorage, 0.11, 0.39)) return 0.23;
  if (inRange(averageStorage, 0.4, 0.59)) return 0.18;
  if (inRange(averageStorage, 0.6, 0.79)) return 0.13;
  if (inRange(averageStorage, 0.8, 0.89)) return 0.05;
  if (inRange(averageStorage, 0.9, 1)) return 0;

  throw new Error(`Unable to find storage discount. Received: ${JSON.stringify({ averageStorage })}`);
};

/** Table 10b: US State Peril Table */
export const getUSStatePeril = (usState: USState): Peril[] => {
  const row = table10b.find((item) => item[0] === usState);

  if (row)
    return [
      { type: "Quake", level: row[2] },
      { type: "Windstorm", level: row[3] },
      { type: "Inland Wind", level: row[4] },
    ];

  throw new Error(`Unable to find US state peril. Received: ${JSON.stringify({ usState })}`);
};

/** Table 10c: Storage Perils Rate Table */
export const getStoragePerilsRate = (peril: PerilType, riskLevel: PerilLevel): number => {
  if (riskLevel === "None") return 0;
  if (peril === "Quake" && riskLevel === "High") return 0.65;
  if (peril === "Quake" && riskLevel === "Medium") return 0.43;
  if (peril === "Windstorm" && riskLevel === "High") return 0.95;
  if (peril === "Windstorm" && riskLevel === "Medium") return 0.55;
  if (peril === "Inland Wind" && riskLevel === "High") return 0.95;
  if (peril === "Inland Wind" && riskLevel === "Medium") return 0.45;

  throw new Error(`Unable to find storage perils rate. Received: ${JSON.stringify({ peril, riskLevel })}`);
};

/** Table 11: Cargo Condition Factor */
export const getCargoConditionFactor = (cargoCondition: CargoCondition, iccClause?: ICCClause): number => {
  if (iccClause && (cargoCondition === "New" || cargoCondition === "Fully reconditioned to a new standard")) return 0.5;
  if (cargoCondition === "New") return 1;
  if (cargoCondition === "Fully reconditioned to a new standard") return 1;
  if (cargoCondition === "Used") return 0.5;

  throw new Error(`Unable to find cargo condition factor. Received: ${JSON.stringify({ cargoCondition, iccClause })}`);
};

/** Table 12: Exhibitions Base Premium */
export const getExhibitionsBasePremium = (
  domicile: Region,
  ownDomicile: Region,
  exhibitionNumber: ExhibitionNumber,
): number => {
  const region = domicile === ownDomicile ? "Own Domicile" : domicile;
  const result = table12.find((item) => item[0] === region)?.[exhibitionNumber];

  if (result !== undefined) return result;

  throw new Error(
    `Unable to find exhibitions base premium. Received: ${JSON.stringify({ domicile, ownDomicile, exhibitionNumber })}`,
  );
};

/** Table 12a: Exhibition duration factor */
export const getExhibitionDurationFactor = (exhibitionDuration: ExhibitionDuration): number => {
  if (exhibitionDuration === "< 7 days") return 0.0;
  if (exhibitionDuration === "8-14 days") return 0.05;
  if (exhibitionDuration === "15-30 days") return 0.1;

  throw new Error(`Unable to find exhibition duration factor. Received: ${JSON.stringify({ exhibitionDuration })}`);
};

/** Table 12b: Exhibition Limit factor */
export const getExhibitionLimitFactor = (exhibitionLimit: ExhibitionLimitAmount): number => {
  if (exhibitionLimit === 5_000) return 1;
  if (exhibitionLimit === 10_000) return 1.1;
  if (exhibitionLimit === 20_000) return 1.2;
  if (exhibitionLimit === 25_000) return 1.25;
  if (exhibitionLimit === 30_000) return 1.3;

  throw new Error(`Unable to find exhibition limit factor. Received: ${JSON.stringify({ exhibitionLimit })}`);
};

/** Table 13: Samples Factor */
export const getSamplesFactor = (limit: number): number => {
  if (inRange(limit, 0, 5_000)) return 75;
  if (inRange(limit, 5_001, 10_000)) return 100;
  if (inRange(limit, 10_001, 15_000)) return 135;
  if (inRange(limit, 15_001, 20_000)) return 175;

  throw new Error(`Unable to find samples factor. Received: ${JSON.stringify({ limit })}`);
};

/** Table 14: Claims Adjustment Factor */
export const getClaimsAdjustmentFactor = (lossesPercentage: number): number => {
  if (!Number.isFinite(lossesPercentage)) throw new Error(`Unable to find claims adjustment factor. Invalid input.`);

  if (inRange(lossesPercentage, 0, 0.1)) return 1;
  if (inRange(lossesPercentage, 0.1001, 0.2)) return 1.25;
  if (inRange(lossesPercentage, 0.2001, 0.5)) return 1.5;
  if (inRange(lossesPercentage, 0.5001, 0.75)) return 1.8;
  if (inRange(lossesPercentage, 0.7501, 1)) return 0;

  throw new Error(`Unable to find claims adjustment factor. Received: ${JSON.stringify({ lossesPercentage })}`);
};

export const getClaimsAdjustmentRisk = (losses: number): RiskType => {
  if (inRange(losses, 0, 10)) return "No Risk";
  if (inRange(losses, 10.01, 20)) return "Low Risk";
  if (inRange(losses, 20.01, 50)) return "Medium Risk";
  if (inRange(losses, 50.01, 75)) return "High Risk";
  if (inRange(losses, 75.01, 100)) return "Decline";

  throw new Error(`Unable to find claims adjustment risk. Received: ${JSON.stringify({ losses })}`);
};

export const getRates = (
  submission: unknown,
  commission?: number,
  transitPremiumTax?: number,
  storagePremiumTax?: number,
  exhibitionsPremiumTax?: number,
  samplesPremiumTax?: number,
): CargoRaterOutput => {
  const parsedSubmission = parseSubmission(submission, "cargo-us-annual");
  const { cargo, conveyance, exhibition, samples, shipment, storage, insured } = parsedSubmission;
  const breakdown: RaterStep[] = [];

  const isContractPeriodLessOrGreaterThan12Months = parsedSubmission?.contract?.type === "contract" ?? false;
  const policyPeriodInDays = parsedSubmission?.contract?.policyPeriodInDays ?? 0;
  const policyInceptionYearDays = parsedSubmission?.contract?.inceptionDateIso
    ? getDaysInYear(parseISO(parsedSubmission.contract.inceptionDateIso))
    : 0;

  if (parsedSubmission?.contract?.policyPeriodInDays === undefined) {
    throw new Error(`Annual rater - missing parameter: policyPeriodInDays.`);
  }
  if (parsedSubmission?.contract?.inceptionDateIso === undefined) {
    throw new Error(`Annual rater - missing parameter: inceptionDateIso.`);
  }

  /* Step 1 */
  assertIsDefined(insured.region, "insured.region");

  const domicileFactor = getDomicileFactor(insured.region);

  breakdown.push(["domicileFactor", asFloat(domicileFactor)]);

  /* Step 2 */
  assertIsDefined(conveyance.conveyances, "conveyance.conveyances");

  const conveyanceTotal = conveyance.conveyances.reduce((prev, next: Conveyance) => {
    const {
      type: conveyanceType,
      containerType,
      turnoverPercentage,
      ownVehicleTurnoverPercentage,
      aovTransits,
      seqNumber: seq,
    } = next;

    assertIsDefined(turnoverPercentage, "conveyance.turnoverPercentage");
    assertIsDefined(conveyanceType, "conveyance.type");

    if (conveyanceType === "Road only" && aovTransits) {
      assertIsDefined(ownVehicleTurnoverPercentage, "conveyance.ownVehicleTurnoverPercentage");

      const ownVehicleRoadConveyanceFactor = getOwnVehicleRoadConveyanceFactor(ownVehicleTurnoverPercentage * 100);
      const result = ownVehicleRoadConveyanceFactor * turnoverPercentage;

      breakdown.push([`conveyance #${seq} ownVehicleRoadConveyanceFactor`, asFloat(ownVehicleRoadConveyanceFactor)]);
      breakdown.push([`conveyance #${seq} result`, asFloat(result)]);

      return prev + result;
    }

    const conveyanceFactor = getConveyanceFactor(conveyanceType, containerType);
    const result = conveyanceFactor * turnoverPercentage;

    breakdown.push([`conveyance #${seq} conveyanceFactor`, asFloat(conveyanceFactor)]);
    breakdown.push([`conveyance #${seq} result`, asFloat(result)]);

    return prev + result;
  }, 0);

  breakdown.push([`conveyanceTotal`, asFloat(conveyanceTotal)]);

  /* Step 3 */
  const conveyanceLimits =
    conveyance?.conveyances?.map((conveyance: Conveyance) => {
      if (conveyance.aovTransits) {
        assertIsDefined(conveyance?.ownVehicleLimit?.amount, "conveyance.ownVehicleLimit.amount");

        return conveyance?.ownVehicleLimit?.amount;
      } else {
        assertIsDefined(conveyance?.limit?.amount, "conveyance.limit.amount");

        return conveyance?.limit?.amount;
      }
    }) || [];

  const maxConveyanceLimit = Math.max(...conveyanceLimits);
  const conveyanceLimitFactor = getConveyanceLimitFactor(maxConveyanceLimit);

  breakdown.push(["conveyanceLimitFactor", asFloat(conveyanceLimitFactor)]);

  /* Step 4 */
  assertIsDefined(conveyanceTotal, "conveyanceTotal");

  const clientRiskScore = domicileFactor + conveyanceTotal + conveyanceLimitFactor;

  breakdown.push(["clientRiskScore", asFloat(clientRiskScore)]);

  /* Step 5 */
  const clientScoreLoadingFactor = getClientScoreLoadingFactor(Math.floor(clientRiskScore));

  breakdown.push(["clientScoreLoadingFactor", asFloat(clientScoreLoadingFactor)]);

  /* Step 6 */
  assertIsDefined(cargo.cargos, "cargo.cargos");

  const cargoTypeFactorTotal = cargo.cargos.reduce((acc, cargo) => {
    const { group, percentage, deductible, isExcluded, seqNumber } = cargo;

    if (!isExcluded) {
      const cargoTypeFactor = getCargoTypeFactor(group);
      const cargoDeductibleFactor = getCargoDeductibleFactor(group, deductible);
      const result = cargoTypeFactor * percentage * cargoDeductibleFactor;

      breakdown.push([`cargo #${seqNumber} cargoTypeFactor`, asFloat(cargoTypeFactor)]);
      breakdown.push([`cargo #${seqNumber} cargoDeductibleFactor`, asFloat(cargoDeductibleFactor)]);
      breakdown.push([`cargo #${seqNumber} result`, asFloat(result)]);

      return acc + result;
    }

    return acc;
  }, 0);

  breakdown.push([`cargoTypeFactorTotal`, asFloat(cargoTypeFactorTotal)]);

  /* Step 7 */
  const ownCargoPremiumTotal = shipment?.shipments?.reduce((acc, shipment) => {
    assertIsDefined(insured.region, "insured.region");

    const { region, ownTurnover, seqNumber } = shipment;
    const ownCargoFactor = getOwnCargoFactor(region, insured.region);
    const ownVolumeMultiplier = getVolumeMultiplier(ownTurnover?.amount);
    const shipmentPremium = ownCargoFactor * (ownVolumeMultiplier * ownTurnover?.amount);

    breakdown.push([`shipment #${seqNumber} ownCargoFactor`, ownCargoFactor]);
    breakdown.push([`shipment #${seqNumber} ownVolumeMultiplier`, ownVolumeMultiplier]);
    breakdown.push([`shipment #${seqNumber} shipmentPremium`, asCurrency(shipmentPremium)]);

    return acc + shipmentPremium;
  }, 0);

  /* Step 8 */
  const contingentCargoPremiumTotal = shipment?.shipments?.reduce((acc, shipment) => {
    assertIsDefined(insured.region, "insured.region");

    const { region, contingentTurnover, seqNumber } = shipment;
    const contingentCargoFactor = getContingentCargoFactor(region, insured.region);
    const contingentVolumeMultiplier = getVolumeMultiplier(contingentTurnover?.amount);
    const shipmentContingentPremium = contingentCargoFactor * (contingentVolumeMultiplier * contingentTurnover?.amount);

    breakdown.push([`shipment #${seqNumber} contingentCargoFactor`, contingentCargoFactor]);
    breakdown.push([`shipment #${seqNumber} contingentVolumeMultiplier`, contingentVolumeMultiplier]);
    breakdown.push([`shipment #${seqNumber} shipmentContingentPremium`, asCurrency(shipmentContingentPremium)]);

    return acc + shipmentContingentPremium;
  }, 0);

  /* Step 9 */
  assertIsDefined(ownCargoPremiumTotal, "ownCargoPremiumTotal");
  assertIsDefined(contingentCargoPremiumTotal, "contingentCargoPremiumTotal");

  const shipmentPremium = ownCargoPremiumTotal + contingentCargoPremiumTotal;

  breakdown.push([`ownCargoPremiumTotal`, asCurrency(ownCargoPremiumTotal)]);
  breakdown.push([`contingentCargoPremiumTotal`, asCurrency(contingentCargoPremiumTotal)]);
  breakdown.push([`shipmentPremium`, asCurrency(shipmentPremium)]);

  /* Step 10 */
  const postConveyance = conveyance?.conveyances?.find((conveyance) => conveyance.type === "Post");
  const postFactor =
    postConveyance?.turnoverPercentage !== undefined
      ? getPostalShipmentFactor(postConveyance.turnoverPercentage * 100)
      : 1;

  assertIsDefined(cargoTypeFactorTotal, "cargoTypeFactorTotal");

  const basePremium = shipmentPremium * cargoTypeFactorTotal * clientScoreLoadingFactor * postFactor;

  breakdown.push([`postFactor`, asFloat(postFactor)]);
  breakdown.push([`basePremium`, asCurrency(basePremium)]);

  /* Step 11 */
  const storagePremium =
    storage?.storages?.reduce((acc, storage) => {
      assertIsDefined(storage.limit, "storage.limit");
      assertIsDefined(storage.region, "storage.region");
      assertIsDefined(insured.region, "insured.region");
      assertIsDefined(storage.averageInStorePercentage, "storage.averageInStorePercentage");

      const { address, perils } = storage;
      const storageFactor = getStorageFactor(storage.region, insured.region);
      const storageDiscount = getStorageDiscount(storage.averageInStorePercentage);
      const storagePremium = storage.limit?.amount * storageFactor * (1 - storageDiscount);

      breakdown.push([`storage #${storage.seqNumber} storageFactor`, asPercent(storageFactor)]);
      breakdown.push([`storage #${storage.seqNumber} storageDiscount`, asPercent(storageDiscount)]);
      breakdown.push([`storage #${storage.seqNumber} storagePremium`, asCurrency(storagePremium)]);

      if (address?.country === "United States" && address?.state && perils) {
        const perilsTotal = perils.reduce((accc, { type: peril, level }) => {
          const perilRate = getStoragePerilsRate(peril, level);

          breakdown.push([`storage #${storage.seqNumber} peril ${peril} perilRate`, asPercent(perilRate)]);

          return accc + perilRate;
        }, 0);

        const storagePremiumWithTax = storagePremium * (1 + perilsTotal);

        breakdown.push([`storage #${storage.seqNumber} storagePremiumWithTax`, asCurrency(storagePremiumWithTax)]);

        return acc + storagePremiumWithTax;
      }

      return acc + storagePremium;
    }, 0) || 0;

  breakdown.push([`storagePremium`, asCurrency(storagePremium)]);

  /* Step 12 */
  assertIsDefined(cargo.condition, "cargo.condition");

  const cargoConditionFactor = getCargoConditionFactor(cargo.condition, cargo.iccClause);
  const basePremiumWithCondition = basePremium * cargoConditionFactor;
  const storagePremiumWithCondition = storagePremium * cargoConditionFactor;

  breakdown.push([`cargoConditionFactor`, asFloat(cargoConditionFactor)]);
  breakdown.push([`basePremiumWithCondition`, asCurrency(basePremiumWithCondition)]);
  breakdown.push([`storagePremiumWithCondition`, asCurrency(storagePremiumWithCondition)]);

  /* Step 13 */
  const exhibitionsPremium =
    exhibition.exhibitions?.reduce((acc, next) => {
      const { region, number, duration, limit, seqNumber } = next;

      assertIsDefined(insured.region, "insured.region");
      assertIsDefined(number, "exhibition.number");
      assertIsDefined(limit, "exhibition.limit");

      const exhibitionBasePremium = getExhibitionsBasePremium(region, insured.region, number);
      const exhibitionDurationFactor = getExhibitionDurationFactor(duration);
      const exhibitionLimitFactor = getExhibitionLimitFactor(limit?.amount);
      const exhibitionPremium = exhibitionBasePremium * (1 + exhibitionDurationFactor) * exhibitionLimitFactor;

      breakdown.push([`exhibition #${seqNumber} exhibitionBasePremium`, asCurrency(exhibitionBasePremium)]);
      breakdown.push([`exhibition #${seqNumber} exhibitionDurationFactor`, asPercent(exhibitionDurationFactor)]);
      breakdown.push([`exhibition #${seqNumber} exhibitionLimitFactor`, asFloat(exhibitionLimitFactor)]);
      breakdown.push([`exhibition #${seqNumber} exhibitionPremium`, asCurrency(exhibitionPremium)]);

      return acc + exhibitionPremium;
    }, 0) || 0;

  breakdown.push([`exhibitionsPremium`, asCurrency(exhibitionsPremium)]);

  /* Step 14 */
  let samplesPremium = 0;
  if (samples?.hasSamples && samples?.limit?.amount !== undefined && samples?.numVehicles !== undefined) {
    const samplesFactor = getSamplesFactor(samples.limit.amount);

    samplesPremium = getSamplesFactor(samples.limit.amount) * samples.numVehicles;

    breakdown.push([`samplesFactor`, samplesFactor]);
  }

  breakdown.push([`samplesPremium`, asCurrency(samplesPremium)]);

  /* Step 15 */
  const claimsAdjustmentFactor =
    insured.lossHistoryTotal?.losses !== undefined && insured.lossHistoryTotal.premiums !== undefined
      ? getClaimsAdjustmentFactor(insured.lossHistoryTotal.losses / insured.lossHistoryTotal.premiums)
      : 1;

  let basePremiumAdj = basePremiumWithCondition * claimsAdjustmentFactor;
  let storagePremiumAdj = storagePremiumWithCondition * claimsAdjustmentFactor;
  let exhibitionsPremiumAdj = exhibitionsPremium * claimsAdjustmentFactor;
  let samplesPremiumAdj = samplesPremium * claimsAdjustmentFactor;

  // https://trello.com/c/8b5w0aIq/28-42-contract-premium-calculation
  if (isContractPeriodLessOrGreaterThan12Months) {
    const contractPeriodRadio = policyPeriodInDays / policyInceptionYearDays;

    basePremiumAdj = basePremiumAdj * contractPeriodRadio;
    storagePremiumAdj = storagePremiumAdj * contractPeriodRadio;
    exhibitionsPremiumAdj = exhibitionsPremiumAdj * contractPeriodRadio;
    samplesPremiumAdj = samplesPremiumAdj * contractPeriodRadio;
  }

  const totalPremium = basePremiumAdj + storagePremiumAdj + exhibitionsPremiumAdj + samplesPremiumAdj;

  breakdown.push([`claimsAdjustmentFactor`, asFloat(claimsAdjustmentFactor)]);
  breakdown.push([`basePremiumAdj`, asCurrency(basePremiumAdj)]);
  breakdown.push([`storagePremiumAdj`, asCurrency(storagePremiumAdj)]);
  breakdown.push([`exhibitionsPremiumAdj`, asCurrency(exhibitionsPremiumAdj)]);
  breakdown.push([`samplesPremiumAdj`, asCurrency(samplesPremiumAdj)]);

  /* Step 16 */
  if (totalPremium <= 400) {
    const finalPremium = 400;
    const basePremiumProRata = (basePremiumAdj / totalPremium) * finalPremium;
    const storagePremiumProRata = (storagePremiumAdj / totalPremium) * finalPremium;
    const exhibitionsPremiumProRata = (exhibitionsPremiumAdj / totalPremium) * finalPremium;
    const samplesPremiumProRata = (samplesPremiumAdj / totalPremium) * finalPremium;

    breakdown.push([`basePremiumProRata`, asCurrency(basePremiumProRata)]);
    breakdown.push([`storagePremiumProRata`, asCurrency(storagePremiumProRata)]);
    breakdown.push([`exhibitionsPremiumProRata`, asCurrency(exhibitionsPremiumProRata)]);
    breakdown.push([`samplesPremiumProRata`, asCurrency(samplesPremiumProRata)]);
    breakdown.push([`totalPremium`, asCurrency(totalPremium)]);
    breakdown.push([`finalPremium`, asCurrency(finalPremium)]);

    return {
      basePremium: premiumCommissionCalculation("Transit premium", basePremiumProRata, commission, transitPremiumTax),
      storagePremium: premiumCommissionCalculation("Storage premium", storagePremiumProRata, commission, storagePremiumTax),
      exhibitionsPremium: premiumCommissionCalculation("Exhibitions premium", exhibitionsPremiumProRata, commission, exhibitionsPremiumTax),
      samplesPremium: premiumCommissionCalculation("Samples premium", samplesPremiumProRata, commission, samplesPremiumTax),
      breakdown,
      finalPremium,
      grossPremium: finalPremium,
      totalPremium,
    };
  }

  breakdown.push([`totalPremium`, asCurrency(totalPremium)]);
  breakdown.push([`finalPremium`, asCurrency(totalPremium)]);

  return {
    basePremium: premiumCommissionCalculation("Transit premium", basePremiumAdj, commission, transitPremiumTax),
    storagePremium: premiumCommissionCalculation("Storage premium", storagePremiumAdj, commission, storagePremiumTax),
    exhibitionsPremium: premiumCommissionCalculation("Exhibitions premium", exhibitionsPremiumAdj, commission, exhibitionsPremiumTax),
    samplesPremium: premiumCommissionCalculation("Samples premium", samplesPremiumAdj, commission, samplesPremiumTax),
    breakdown,
    finalPremium: totalPremium,
    grossPremium: totalPremium,
    totalPremium,
  };
};

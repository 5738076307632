import get from "lodash.get";
import uniq from "lodash.uniq";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ACTIONS } from "../../constants";
import * as api from "../api";
import useAuth from "./useAuth";

const useConnections = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const { contractId, productRef } = params;
  const { user, checkPermissions } = useAuth();
  const userId = user.id;
  const [connectionId, setConnectionId] = useState();
  const canView = checkPermissions(ACTIONS.VIEW_CONCURRENT_USERS);

  const connectionsQuery = useQuery(
    ["connections", { contractId, productRef, data: { connectionId, userId } }],
    api.getConnections,
    { refetchInterval: JSON.parse(process.env.CLIENT_CONNECTIONS_INTERVAL) },
  );

  const connectionsData = get(connectionsQuery, "data.data.data", []);

  const { mutateAsync: updateConnections } = useMutation(api.updateConnections, {
    onSuccess: (data) => {
      setConnectionId(data.data.data);
      queryClient.invalidateQueries("connections", { productRef, contractId });
    },
  });

  useEffect(() => {
    const leave = () => {
      if (connectionId) {
        updateConnections({ contractId, productRef, data: { action: "leave", connectionId } });
      }
    };

    if (!connectionId && canView) {
      updateConnections({ contractId, productRef, data: { action: "join", userId } });
    }

    window.onbeforeunload = leave;

    return () => {
      leave();
    };
  }, [userId, contractId, productRef]);

  return uniq(connectionsData.map((item) => item.userId));
};

export default useConnections;

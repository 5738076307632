// https://docs.google.com/spreadsheets/d/18_fqpHjl5yThzZMO9ahm3o3hKP8OkFvv/edit#gid=1540091813

import moment from "moment";
import { Money } from "../../@types/types";
import { inRange } from "../../helpers/inRange";
import { DeductibleAmount, Limit, MelRaterOutput, ParsedSubmission } from "./types";

/* Table 1: Payroll Minimum premium */
const getPayrollMinimumPremium = (payroll: Money, deductible: DeductibleAmount, limit: Limit): number => {
  if (deductible === 0) {
    return getNilDeductiblePremiums(payroll, limit);
  }

  if (payroll?.amount === 0) return 0;
  if (inRange(payroll?.amount, 1, 500_000)) return 3_000;
  if (inRange(payroll?.amount, 500_001, 2_000_000)) return 3_000;
  if (inRange(payroll?.amount, 2_000_001, 3_000_000)) return 11_250;
  if (inRange(payroll?.amount, 3_000_001, 5_000_000)) return 15_750;

  throw new Error(`Unable to find payroll minimum premium. Received: ${JSON.stringify({ payroll })}`);
};

/* Table 1: Payroll Minimum premium */
export const getDefaultDeductible = (payroll: Money): DeductibleAmount => {
  if (payroll?.amount === 0) return 0;
  if (inRange(payroll?.amount, 1, 500_000)) return 2_500;
  if (inRange(payroll?.amount, 500_001, 2_000_000)) return 5_000;
  if (inRange(payroll?.amount, 2_000_001, 3_000_000)) return 10_000;
  if (inRange(payroll?.amount, 3_000_001, 5_000_000)) return 10_000;

  throw new Error(`Unable to find default deductible. Received: ${JSON.stringify({ payroll })}`);
};

/* Table 2: Payroll adjustment factor */
const getPayrollAdjustmentFactor = (payroll: Money): number => {
  if (payroll?.amount === 0) return 0;
  if (inRange(payroll?.amount, 1, 500_000)) return 0;
  if (inRange(payroll?.amount, 500_001, 2_000_000)) return (payroll?.amount - 500_000) * 0.0055;
  if (inRange(payroll?.amount, 2_000_001, 3_000_000)) return (payroll?.amount - 2_000_000) * 0.0045;
  if (inRange(payroll?.amount, 3_000_001, 5_000_000)) return (payroll?.amount - 3_000_000) * 0.004;

  throw new Error(`Unable to find payroll adjustment factor. Received: ${JSON.stringify({ payroll })}`);
};

/* Table 3: Nil Deductible Premiums */
const getNilDeductiblePremiums = (payroll: Money, limit: Limit): number => {
  if (payroll?.amount === 0) return 0;
  if (inRange(payroll?.amount, 1, 500_000) && limit?.amount === 5_000_000) return 5_500;
  if (inRange(payroll?.amount, 1, 500_000)) return 5_000;
  if (inRange(payroll?.amount, 500_001, 2_000_000)) return 5_500;

  throw new Error(`Unable to find nil deductible premiums. Received: ${JSON.stringify({ payroll, limit })}`);
};

/* Table 4: Limit weighting */
const getLimitWeighting = (limit: Limit): number => {
  if (limit?.amount === 1_000_000) return 0;
  if (limit?.amount === 2_000_000) return 0.25;
  if (limit?.amount === 3_000_000) return 0.35;
  if (limit?.amount === 4_000_000) return 0.425;
  if (limit?.amount === 5_000_000) return 0.5;

  throw new Error(`Unable to find premium increase. Received: ${JSON.stringify({ limit })}`);
};

/* Table 4: Limit weighting */
const getAlternativeBasePremium = (limit: Limit): number => {
  if (limit?.amount === 1_000_000) return 0;
  if (limit?.amount === 2_000_000) return 4_000;
  if (limit?.amount === 3_000_000) return 5_000;
  if (limit?.amount === 4_000_000) return 6_000;
  if (limit?.amount === 5_000_000) return 7_500;

  throw new Error(`Unable to find alternative base premium. Received: ${JSON.stringify({ limit })}`);
};

/* Table 5: Deductible adjustment */
const getDeductibleAdjustment = (payroll: Money, deductible: DeductibleAmount): number => {
  if (inRange(payroll?.amount, 0, 500_000)) {
    if (deductible === 0) return 0;
    if (deductible === 2_500) return 0;
    if (deductible === 5_000) return -0.05;
    if (deductible === 10_000) return -0.1;
  }

  if (inRange(payroll?.amount, 500_001, 2_000_000)) {
    if (deductible === 0) return 0;
    if (deductible === 5_000) return 0;
    if (deductible === 10_000) return -0.075;
  }

  if (inRange(payroll?.amount, 2_000_001, 3_000_000)) {
    if (deductible === 5_000) return 0.1;
    if (deductible === 10_000) return 0;
    if (deductible === 20_000) return -0.1;
  }

  if (inRange(payroll?.amount, 3_000_001, 5_000_000)) {
    if (deductible === 5_000) return 0.1;
    if (deductible === 10_000) return 0;
    if (deductible === 20_000) return -0.1;
  }

  throw new Error(`Unable to find deductible adjustment. Received: ${JSON.stringify({ payroll, deductible })}`);
};

/* Table 5: Deductible adjustment */
export const getDeductibleOptions = (payroll: Money): DeductibleAmount[] => {
  if (inRange(payroll?.amount, 0, 500_000)) return [0, 2_500, 5_000, 10_000];
  if (inRange(payroll?.amount, 500_001, 2_000_000)) return [0, 5_000, 10_000];
  if (inRange(payroll?.amount, 2_000_001, 3_000_000)) return [5_000, 10_000, 20_000];
  if (inRange(payroll?.amount, 3_000_001, 5_000_000)) return [5_000, 10_000, 20_000];

  throw new Error(`Unable to find deductible options. Received: ${JSON.stringify({ payroll })}`);
};

export const getRates = (submission: ParsedSubmission, deductible: DeductibleAmount): MelRaterOutput => {
  const { payroll, limit, trialTrips, watercraft, tripra } = submission;

  /* Step 1 */
  const payrollMinimumPremium = getPayrollMinimumPremium(payroll, deductible, limit);

  /* Step 2 */
  const payrollAdjustmentFactor = getPayrollAdjustmentFactor(payroll);

  /* Step 3 */
  const basePremium = payrollMinimumPremium + payrollAdjustmentFactor;

  /* Step 6 */
  const withLimitUplift = basePremium * (1 + getLimitWeighting(limit));

  /* Step 7 */
  const withAltBasePremium = Math.max(withLimitUplift, getAlternativeBasePremium(limit));

  /* Step 8 */
  const withDeductibleAdjustment = withAltBasePremium * (1 + getDeductibleAdjustment(payroll, deductible));

  /* Step 9 */
  const withTrialTrips = trialTrips ? withDeductibleAdjustment * 1.2 : withDeductibleAdjustment;

  /* Step 9.5 */
  const withWatercraft = watercraft ? withTrialTrips + 2_000 : withTrialTrips;

  /* Step 10 */
  const withTripra = tripra ? withWatercraft * 1.05 : withWatercraft;

  return {
    grossPremium: withTripra,
    tripraAmount: withTripra - withWatercraft,
    breakdown: [
      ["payrollMinimumPremium", payrollMinimumPremium],
      ["payrollAdjustmentFactor", payrollAdjustmentFactor],
      ["basePremium", basePremium],
      ["withLimitUplift", withLimitUplift],
      ["withAltBasePremium", withAltBasePremium],
      ["withDeductibleAdjustment", withDeductibleAdjustment],
      ["withTrialTrips", withTrialTrips],
      ["withWatercraft", withWatercraft],
      ["withTripra", withTripra],
    ],
  };
};

export const getRatesWithCommissionOld = (
  grossPremium: number,
  tripraAmount: number,
  defaultPercent: number,
  commission: number,
  ownerCommission: number,
): {
  grossPremium: number;
  tripraAmount: number;
  commissionAmount: number;
  netPremium: number;
  commission: number;
  ownerCommission: number;
  ownerCommissionAmount: number;
} => {
  const grossWithoutTripra = grossPremium - tripraAmount;
  const netPremium = grossWithoutTripra / (1 + defaultPercent);
  const newGrossWithoutTripra = netPremium * (1 + commission);
  const newGrossWithTripra = tripraAmount === 0 ? newGrossWithoutTripra : newGrossWithoutTripra * 1.05;
  const newTripraAmount = newGrossWithTripra - newGrossWithoutTripra;
  const commissionAmount = netPremium * (1 + commission || 0) - netPremium;
  const ownerCommissionAmount = netPremium * (1 + (ownerCommission || 0)) - netPremium;

  return {
    commission: commission,
    commissionAmount: commissionAmount,
    grossPremium: newGrossWithTripra,
    netPremium: netPremium,
    ownerCommission: ownerCommission,
    ownerCommissionAmount: ownerCommissionAmount,
    tripraAmount: newTripraAmount,
  };
};

export const getRatesWithCommission = (
  grossPremium: number,
  tripraAmount: number,
  defaultPercent: number,
  commission: number,
  ownerCommission: number,
): {
  grossPremium: number;
  tripraAmount: number;
  commissionAmount: number;
  netPremium: number;
  commission: number;
  ownerCommission: number;
  ownerCommissionAmount: number;
} => {
  const grossWithoutTripra = grossPremium - tripraAmount;
  const netPremium = grossWithoutTripra * (1 - defaultPercent);
  const newGrossWithoutTripra = netPremium / (1 - commission);
  const newGrossWithTripra = tripraAmount === 0 ? newGrossWithoutTripra : newGrossWithoutTripra * 1.05;
  const newTripraAmount = newGrossWithTripra - newGrossWithoutTripra;
  const commissionAmount = netPremium / (1 - commission || 0) - netPremium;
  const ownerCommissionAmount = commissionAmount * (ownerCommission / commission);

  return {
    commission: commission,
    commissionAmount: commissionAmount,
    grossPremium: newGrossWithTripra,
    netPremium: netPremium,
    ownerCommission: ownerCommission,
    ownerCommissionAmount: ownerCommissionAmount,
    tripraAmount: newTripraAmount,
  };
};

export const getEndoRates = ({
  originalGrossPremium,
  nextEffectiveFrom,
  nextGrossPremium,
  inceptionDate,
  prevEffectiveFrom,
  prevGrossPremium,
  totalPremiumPaid,
  tally,
  isCancellation,
}: {
  originalGrossPremium: number;
  nextGrossPremium?: number;
  nextEffectiveFrom: string;
  inceptionDate: string;
  prevEffectiveFrom?: string;
  prevGrossPremium: number;
  totalPremiumPaid: number;
  tally: number;
  isCancellation?: boolean;
}): {
  aprp: number;
  tally: number;
  totalPremiumPaid: number;
} => {
  const nilPremiumChange = prevGrossPremium === nextGrossPremium;
  const inceptionMoment = moment(inceptionDate).startOf("day");

  const prevEffectiveFromMoment = moment(prevEffectiveFrom || inceptionDate).startOf("day");
  const nextEffectiveFromMoment = moment(nextEffectiveFrom).startOf("day");

  const prevPremiumDays = nextEffectiveFromMoment.diff(prevEffectiveFromMoment, "day");
  const prevPremiumDailyRate = prevGrossPremium / 365;
  const prevPremiumActual = prevPremiumDays * prevPremiumDailyRate;

  const nextPremiumDays = 365 - nextEffectiveFromMoment.diff(inceptionMoment, "day");
  const nextPremiumDailyRate = nextGrossPremium ? nextGrossPremium / 365 : 0;
  const nextPremiumActual = isCancellation ? 0 : nextPremiumDays * nextPremiumDailyRate;

  const totalPremiumDue = nilPremiumChange ? totalPremiumPaid : tally + prevPremiumActual + nextPremiumActual;
  const proposedApRp = totalPremiumDue - totalPremiumPaid;

  const minimumDeposit = originalGrossPremium * 0.25;
  const aprp = totalPremiumDue > minimumDeposit ? proposedApRp : minimumDeposit - totalPremiumPaid;

  return { aprp, tally: tally + prevPremiumActual, totalPremiumPaid: totalPremiumDue };
};

export const getEndoCommRates = ({
  nextEffectiveFrom,
  nextGrossPremium,
  inceptionDate,
  prevEffectiveFrom,
  prevGrossPremium,
  totalPremiumPaid,
  tally,
  aprp,
}: {
  aprp: number;
  nextGrossPremium: number;
  nextEffectiveFrom: string;
  inceptionDate: string;
  prevEffectiveFrom?: string;
  prevGrossPremium: number;
  totalPremiumPaid: number;
  tally: number;
  isCancellation?: boolean;
}): {
  aprp: number;
  tally: number;
  totalPremiumPaid: number;
} => {
  const nilPremiumChange = aprp === 0;
  const inceptionMoment = moment(inceptionDate).startOf("day");

  const prevEffectiveFromMoment = moment(prevEffectiveFrom || inceptionDate).startOf("day");
  const nextEffectiveFromMoment = moment(nextEffectiveFrom).startOf("day");

  const prevPremiumDays = nextEffectiveFromMoment.diff(prevEffectiveFromMoment, "day");
  const prevPremiumDailyRate = prevGrossPremium / 365;
  const prevPremiumActual = prevPremiumDays * prevPremiumDailyRate;

  const nextPremiumDays = 365 - nextEffectiveFromMoment.diff(inceptionMoment, "day");
  const nextPremiumDailyRate = nextGrossPremium ? nextGrossPremium / 365 : 0;
  const nextPremiumActual = nextPremiumDays * nextPremiumDailyRate;

  const totalPremiumDue = nilPremiumChange ? totalPremiumPaid : tally + prevPremiumActual + nextPremiumActual;

  return { aprp, tally: tally + prevPremiumActual, totalPremiumPaid: totalPremiumDue };
};

// https://docs.google.com/spreadsheets/d/1CkzAlcQDDKinmFkZ1ADe9hYBMH4geLxV/edit#gid=811084749

import { inRange } from "../../../helpers/inRange";
import { asCurrency, asFloat, assertIsDefined } from "../helpers";
import { parseSubmission } from "../parseSubmission";
import table2b from "../refData/table2b";
import table3 from "../refData/table3";
import table5a from "../refData/table5a";
import table6 from "../refData/table6";
import {
  CargoDeductible,
  CargoDeductibleAmount,
  CargoGroup,
  CargoRaterOutput,
  ClientTrade,
  ContainerType,
  Conveyance,
  ConveyanceType,
  Exhibition,
  ExhibitionDuration,
  ICCClause,
  Percentage,
  Region,
  RegionRank,
  RiskType,
  VoyageCode,
} from "../types";

/* Table 1a: Own Domicile */
const getDomicileFactor = (domicile: Region): number => {
  if (domicile === "Western Europe") return 1;
  if (domicile === "USA & Canada") return 1;
  if (domicile === "Asia & Pacific including Australasia") return 1.05;
  if (domicile === "Eastern Europe & Russia West of Urals") return 1.15;
  if (domicile === "Middle East") return 1.1;
  if (domicile === "Caribbean") return 1.2;
  if (domicile === "South & Central America") return 1.2;
  if (domicile === "Former USSR and Russia East of Urals") return 1.2;
  if (domicile === "India and sub continent") return 1.5;
  if (domicile === "Africa") return 1.5;

  throw new Error(`Unable to find domicile factor. Received: ${JSON.stringify({ domicile })}`);
};

/* Table 1b: Type of Client */
const getTypeOfClientFactor = (clientTrade: ClientTrade): number => {
  if (clientTrade === "Importer") return 1;
  if (clientTrade === "Exporter") return 1;
  if (clientTrade === "Manufacturer") return 1;
  if (clientTrade === "Trader") return 1.25;
  if (clientTrade === "Private Individual") return 1.5;

  throw new Error(`Unable to find type of client factor. Received: ${JSON.stringify({ clientTrade })}`);
};

/* Table 2a: Voyage Country Region */
const getRegionRank = (region: Region): RegionRank => {
  if (region === "Western Europe") return 1;
  if (region === "USA & Canada") return 1;
  if (region === "Asia & Pacific including Australasia") return 2;
  if (region === "Eastern Europe & Russia West of Urals") return 3;
  if (region === "Middle East") return 2;
  if (region === "Caribbean") return 4;
  if (region === "South & Central America") return 5;
  if (region === "Former USSR and Russia East of Urals") return 5;
  if (region === "India and sub continent") return 6;
  if (region === "Africa") return 6;

  throw new Error(`Unable to find region rank. Received: ${JSON.stringify({ region })}`);
};

/* Table 2b: Voyage Code: */
const getVoyageCode = (regionFrom: RegionRank, regionTo: RegionRank): VoyageCode => {
  const result = table2b?.[regionTo - 1]?.[regionFrom - 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find voyage code. Received: ${JSON.stringify({ regionFrom, regionTo })}`);
};

/* Table 3: Voyage Rate */
const getVoyageRate = (group: CargoGroup, code: VoyageCode): number => {
  assertIsDefined(group, "group");

  const codes = ["A", "B", "C", "D", "E", "F", "G"];
  const result = table3?.[group - 1]?.[codes.indexOf(code)];

  if (result !== undefined) return result;

  throw new Error(`Unable to find voyage rate. Received: ${JSON.stringify({ group, code })}`);
};

/* Table 4: Discount/Load */
const getDiscountLoad = (
  conveyanceType: ConveyanceType,
  iccClause?: ICCClause,
  aovTransits?: boolean,
  containerType?: ContainerType,
): Percentage => {
  if (containerType === "FCL") return -0.25;
  if (conveyanceType === "Air only") return -0.4;
  // TODO: no "land only" conv type
  // if (region === "Land Only") return -0.50;
  if (iccClause) return -0.55;
  if (aovTransits) return 0.25;

  throw new Error(
    `Unable to find discount/load. Received: ${JSON.stringify({
      conveyanceType,
      containerType,
      iccClause,
      aovTransits,
    })}`,
  );
};

/* Table 6: */
const getExhibitionRate = (group: CargoGroup, code: RegionRank): number => {
  assertIsDefined(group, "group");

  const result = table6?.[group - 1]?.[code - 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find exhibition rate. Received: ${JSON.stringify({ group, code })}`);
};

/* Table 6a: */
const getExhibitionsRank = (exhibitionDuration: ExhibitionDuration): Percentage => {
  if (exhibitionDuration === "< 7 days") return 1;
  if (exhibitionDuration === "8-14 days") return 1.35;
  if (exhibitionDuration === "15-30 days") return 2;

  throw new Error(`Unable to find exhibitions rank. Received: ${JSON.stringify({ exhibitionDuration })}`);
};

/* Table 8: Claims Adjustment Factor */
const getClaimsAdjustmentFactor = (losses: number): number => {
  if (inRange(losses, 0, 0.1)) return 1.1;
  if (inRange(losses, 0.1001, 0.2)) return 1.25;
  if (inRange(losses, 0.2001, 0.5)) return 1.5;
  if (inRange(losses, 0.5001, 0.75)) return 1.8;
  if (inRange(losses, 0.7501, 1)) return 0;

  throw new Error(`Unable to find claims adjustment factor. Received: ${JSON.stringify({ losses })}`);
};

/* Table 8: Claims Adjustment Factor */
const getClaimsAdjustmentRisk = (losses: number): RiskType => {
  if (inRange(losses, 0, 10)) return "No Risk";
  if (inRange(losses, 10.01, 20)) return "Low Risk";
  if (inRange(losses, 20.01, 50)) return "Medium Risk";
  if (inRange(losses, 50.01, 75)) return "High Risk";
  if (inRange(losses, 75.01, 100)) return "Decline";

  throw new Error(`Unable to find claims adjustment risk. Received: ${JSON.stringify({ losses })}`);
};

/* Table 9: Interest Group Factor */
const getCargoTypeFactor = (group: CargoGroup): number => {
  if (group === 1) return 1;
  if (group === 2) return 1.05;
  if (group === 3) return 1.1;
  if (group === 4) return 1.15;
  if (group === 5) return 1.2;
  if (group === 1234) return 1.25;
  if (group === 7) return 1.3;

  throw new Error(`Unable to find cargo type factor. Received: ${JSON.stringify({ group })}`);
};

/* Table 9a: Cargo Type / Deductible Factor */
const getCargoDeductibleFactor = (group: CargoGroup, deductible: CargoDeductible): number => {
  const index = [250, 500, 750, 1_000, 1_500, 2_000, 2_500, 5_000].findIndex((item) => item === deductible?.amount);
  const result = table5a.find((item) => item[0] === group)?.[index + 1];

  if (result !== undefined) return result;

  throw new Error(`Unable to find cargo deductible factor. Received: ${JSON.stringify({ group, deductible })}`);
};

/* Table 9a: Cargo Type / Deductible Factor */
export const getCargoDefaultDeductible = (group: CargoGroup): CargoDeductibleAmount => {
  if (group === 1) return 250;
  if (group === 2) return 500;
  if (group === 3) return 750;
  if (group === 4) return 750;
  if (group === 5) return 500;
  if (group === 1234) return 500;
  if (group === 7) return 500;

  throw new Error(`Unable to find cargo default deductible. Received: ${JSON.stringify({ group })}`);
};

/* Table 11: Min Premium */
const getMinPremium = (group: CargoGroup): number => {
  if (group === 1) return 75;
  if (group === 2) return 100;
  if (group === 3) return 150;
  if (group === 4) return 200;
  if (group === 5) return 175;
  if (group === 1234) return 175;
  if (group === 7) return 100;

  throw new Error(`Unable to find min premium. Received: ${JSON.stringify({ group })}`);
};

export const getRates = (submission: unknown): CargoRaterOutput => {
  const parsedSubmission = parseSubmission(submission, "cargo-us-single");
  const { shipment, cargo, conveyance, exhibition, insured } = parsedSubmission;

  /* Step 2 */
  assertIsDefined(shipment.regionFrom, "shipment.regionFrom");
  assertIsDefined(shipment.regionTo, "shipment.regionTo");

  const regionFromRank = getRegionRank(shipment.regionFrom);
  const regionToRank = getRegionRank(shipment.regionTo);
  const voyageCode = getVoyageCode(regionFromRank, regionToRank);

  /* Step 3 */
  const voyageRate = cargo?.cargos?.reduce((prev, next) => prev + getVoyageRate(next.group, voyageCode), 0);

  /* Step 4 */
  assertIsDefined(conveyance?.conveyances?.[0], "conveyance.conveyances.[0]");

  const { iccClause } = cargo;
  const { type: conveyanceType, aovTransits, containerType, insuredValue } = conveyance?.conveyances?.[0] as Conveyance;

  assertIsDefined(conveyanceType, "conveyanceType");
  assertIsDefined(voyageRate, "voyageRate");

  const discount = getDiscountLoad(conveyanceType, iccClause, aovTransits, containerType);
  const withDiscount = voyageRate + voyageRate * discount;

  /* Step 5 */
  assertIsDefined(insured.clientTrade, "insured.clientTrade");

  const clientTradeFactor = getTypeOfClientFactor(insured.clientTrade);
  const transitOutRate = clientTradeFactor * withDiscount;
  let premiumRate = transitOutRate;

  /* Step 6 */
  assertIsDefined(insured.clientTrade, "insured.clientTrade");
  const { hasExhibitions } = exhibition;

  if (hasExhibitions) {
    assertIsDefined(exhibition?.exhibitions?.[0], "exhibition.exhibitions.[0] ");

    const { region, duration, returnBack } = exhibition?.exhibitions?.[0] as Exhibition;

    if (region && duration) {
      const exhibitionRegionRank = getRegionRank(region);
      const exhibitionDurationRank = getExhibitionsRank(duration);
      const exhibitionRate = getExhibitionRate(cargo.cargos?.[0]?.group as CargoGroup, exhibitionRegionRank);
      premiumRate = exhibitionDurationRank * exhibitionRate + transitOutRate;

      if (returnBack) {
        premiumRate = premiumRate + transitOutRate;
      }
    }
  }

  /* Step 7 */
  assertIsDefined(insuredValue?.amount, "insuredValue.amount");

  const withInsuredsValue = (premiumRate / 100) * insuredValue?.amount;

  /* Step 8 */
  const claimsAdjustmentFactor =
    insured?.lossHistoryTotal?.losses && insured?.lossHistoryTotal?.premiums
      ? getClaimsAdjustmentFactor(insured?.lossHistoryTotal.losses / insured?.lossHistoryTotal.premiums)
      : 1;
  const premiumWithClaimsAdjustment = withInsuredsValue * claimsAdjustmentFactor;

  /* Step 9 */
  const cargoTypeFactorTotal = cargo.cargos?.reduce((acc, { group, percentage, deductible, isExcluded }) => {
    if (!isExcluded) {
      const factor = getCargoTypeFactor(group) * percentage * getCargoDeductibleFactor(group, deductible);

      return acc + factor;
    }

    return acc;
  }, 0);

  /* Step 10 */
  assertIsDefined(cargoTypeFactorTotal, "cargoTypeFactorTotal");

  const withDeductibleFactor = premiumWithClaimsAdjustment * cargoTypeFactorTotal;

  /* Step 11 */
  // TODO: should be based on cargo group, table 11
  const finalPremium = withDeductibleFactor <= 150 ? 150 : withDeductibleFactor;

  // TODO: needs updating after the final shape of the single rater output is known
  return {
    grossPremium: finalPremium,
    basePremium: { name: "Transit premium", netPremium: 0 },
    exhibitionsPremium: { name: "Exhibitions premium", netPremium: 0 },
    finalPremium: 0,
    samplesPremium: { name: "Samples premium", netPremium: 0 },
    storagePremium: { name: "Storage premium", netPremium: 0 },
    totalPremium: 0,
    breakdown: [
      ["voyageCode", voyageCode],
      ["voyageRate", asFloat(voyageRate)],
      ["withDiscount", asFloat(withDiscount)],
      ["transitOutRate", asFloat(transitOutRate)],
      ["premiumRate", asFloat(premiumRate)],
      ["withInsuredsValue", asCurrency(withInsuredsValue)],
      ["premiumWithClaimsAdjustment", asCurrency(premiumWithClaimsAdjustment)],
      ["cargoTypeFactorTotal", asFloat(cargoTypeFactorTotal)],
      ["withDeductibleFactor", asCurrency(withDeductibleFactor)],
      ["grossPremium", asCurrency(finalPremium)],
    ],
  };
};

// https://docs.google.com/spreadsheets/d/1XCX3id3FEEiMHmmF2wkucZeW_GAYnvSQJ-1ox_Zrzps/edit#gid=2122343373

import get from "lodash.get";
import unset from "lodash.unset";
import React, { useState } from "react";
import { ACTIONS } from "../../../../constants";
import { adjustablePremiumOptions, asCurrencyCode, getVerbiage } from "../../../../projects/mel/adjustablePremium";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import InputCurrency from "../../../components/InputCurrency";
import InputRadioGroup from "../../../components/InputRadioGroup";
import useForm from "../../../hooks/useForm";
import { useMutateContractQuery } from "../../../hooks/useMutateContractQuery";
import RatesTable from "./RatesTable";
import ReferralDecision_v2 from "./ReferralDecision_v2";
import BespokeEndorsements from "./BespokeEndorsements";
import TotalPremiumAndDeductible from "./TotalPremiumAndDeductible";
import { SubmissionSecurityOptions } from "../../../../projects/mel/types";

const ReferralDecision = ({ className, contractData, parentContractData }) => {
  const { updateContract, contractId, productRef, isUpdating } = useMutateContractQuery({});
  const payrollBreakdown = get(contractData, "submission.payroll.payroll_breakdown", {});
  const renewedFrom = get(contractData, "renewedFrom");
  const isRenewal = !!renewedFrom;
  const renewalEndorsements = get(contractData, "bespoke_endorsements", []);
  const parentEndorsements = get(parentContractData, "bespoke_endorsements", []);

  const [currentEndorsements, setCurrentEndorsements] = useState(
    isRenewal
      ? parentEndorsements.map((parentEndorsement) => ({
          ...parentEndorsement,
          selected: !!renewalEndorsements.find((i) => i.ref === parentEndorsement.ref),
        }))
      : [],
  );

  const handleToggleSelectEndorsement = (index) => () => {
    const newEndorsements = [...currentEndorsements];
    const endorsement = newEndorsements[index];
    newEndorsements.splice(index, 1, { ...endorsement, selected: !endorsement.selected });
    setCurrentEndorsements(newEndorsements);
  };

  const handleChangeEndorsementValue =
    (ref) =>
    ({ amount }) => {
      const newEndorsements = [...currentEndorsements];
      const index = newEndorsements.findIndex((i) => i.ref === ref);
      const endorsement = newEndorsements[index];
      newEndorsements.splice(index, 1, { ...endorsement, amount });
      setCurrentEndorsements(newEndorsements);
    };

  const { getFieldProps, formValues, onSubmit } = useForm({
    initialState: {},
    onBeforeChange: (clonedFormValues, name) => {
      if (name === "premium_type") {
        unset(clonedFormValues, "premium");
        unset(clonedFormValues, "deductible");
        unset(clonedFormValues, "adjustable_premium_option");
        unset(clonedFormValues, "adjustable_premium_type");
        unset(clonedFormValues, "adjustableRates");
      }

      if (name === "adjustable_premium_option") {
        unset(clonedFormValues, "adjustable_premium_type");
        unset(clonedFormValues, "adjustableRates");
      }

      if (name === "adjustable_premium_type") {
        unset(clonedFormValues, "adjustableRates");
        unset(clonedFormValues, "reduce_premium");
        unset(clonedFormValues, "reduced_premium");
      }
    },
    onSubmit: (values) => {
      const verbiage = getVerbiage({ formValues: values, payrollBreakdown });
      const payload = isRenewal
        ? { ...verbiage, bespoke_endorsements: currentEndorsements.filter((i) => i.selected), renewedFrom }
        : verbiage;

      updateContract({
        productRef,
        contractId,
        data: { type: ACTIONS.PROVIDE_COMMERCIAL_PRICE, payload },
      });
    },
  });

  const adjustablePremiumTypes = adjustablePremiumOptions[formValues.adjustable_premium_option];
  const { verbiage, premium } = getVerbiage({ payrollBreakdown, formValues });

  return (
    <Card headingText="Next steps" className={className}>
      <div className="p-6">
        <Form onSubmit={onSubmit} id="decisionForm">
          <InputRadioGroup
            className="mb-8"
            isRequired
            labelText="Security"
            options={SubmissionSecurityOptions}
            {...getFieldProps("security")}
          />

          <InputRadioGroup
            className="mb-8"
            isRequired
            labelText="Premium"
            options={["Flat premium", "Adjustable premium"]}
            {...getFieldProps("premium_type")}
          />

          {formValues.premium_type === "Flat premium" && (
            <>
              {isRenewal && renewalEndorsements.length > 0 && (
                <BespokeEndorsements
                  currentEndorsements={currentEndorsements}
                  handleToggleSelectEndorsement={handleToggleSelectEndorsement}
                  handleChangeEndorsementValue={handleChangeEndorsementValue}
                />
              )}
              <InputCurrency
                className="mb-8"
                isRequired
                labelText={isRenewal ? "Renewal Premium" : "Premium"}
                {...getFieldProps("premium")}
              />

              <InputCurrency className="mb-8" isRequired labelText="Deductible" {...getFieldProps("deductible")} />
              {isRenewal && (
                <TotalPremiumAndDeductible
                  currentEndorsements={currentEndorsements}
                  premium={formValues?.premium?.amount}
                />
              )}
            </>
          )}

          {formValues.premium_type === "Adjustable premium" && (
            <>
              {isRenewal && renewalEndorsements.length > 0 && (
                <BespokeEndorsements
                  currentEndorsements={currentEndorsements}
                  handleToggleSelectEndorsement={handleToggleSelectEndorsement}
                  handleChangeEndorsementValue={handleChangeEndorsementValue}
                />
              )}
              <InputRadioGroup
                className="mb-8"
                isRequired
                labelText="Adjustable premium option"
                options={Object.keys(adjustablePremiumOptions)}
                {...getFieldProps("adjustable_premium_option")}
              />

              {formValues.adjustable_premium_option && (
                <>
                  <InputRadioGroup
                    className="mb-8"
                    isRequired
                    labelText="Adjustable premium type"
                    options={Object.keys(adjustablePremiumTypes)}
                    {...getFieldProps("adjustable_premium_type")}
                  />

                  {formValues.adjustable_premium_type && (
                    <>
                      <InputCurrency
                        className="mb-8"
                        isRequired
                        labelText="Deductible"
                        {...getFieldProps("deductible")}
                      />

                      <RatesTable
                        formValues={formValues}
                        getFieldProps={getFieldProps}
                        payrollBreakdown={payrollBreakdown}
                      />

                      <InputRadioGroup
                        className="mb-8"
                        isRequired
                        labelText="Reduce M&D premium?"
                        options={["Yes", "No"]}
                        {...getFieldProps("reduce_premium")}
                      />

                      {formValues.reduce_premium === "Yes" && (
                        <InputCurrency
                          className="mb-8"
                          isRequired
                          labelText="Actual M&D Premium amount"
                          {...getFieldProps("reduced_premium")}
                        />
                      )}

                      {verbiage && (
                        <>
                          <p className="mb-2">M&D Premium amount:</p>
                          <p className="mb-8 font-semibold">
                            {asCurrencyCode(premium)} {verbiage}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {isRenewal && (
                <TotalPremiumAndDeductible currentEndorsements={currentEndorsements} premium={premium?.amount} />
              )}
            </>
          )}

          <div className="flex">
            <Button className="mr-4" isDisabled={isUpdating} kind="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

const defaultProductRef = process.env.DEFAULT_PRODUCT_REF;

export default defaultProductRef === "mel" ? ReferralDecision : ReferralDecision_v2;

import { SubmissionSecurityConsortium } from "../projects/mel/types";

export const isContractConsortium = (contractData) => {
  const boundQuote = contractData?.originalQuote;
  const defaultQuote = contractData?.quotes
    ?.filter((quote) => quote.status === "Submitted")
    ?.find((quote) => quote.type === "default" || quote.type === "commercial");
  const primaryQuote = !!boundQuote ? boundQuote : defaultQuote;

  const isConsortium = primaryQuote?.submission?.security === SubmissionSecurityConsortium;
  return isConsortium;
};

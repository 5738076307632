import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Banner from "../../components/Banner";

const QuoteBanners = ({ contractData }) => {
  const quotesData = get(contractData, "quotes", []);
  const hasQuotes = quotesData.length > 0;
  return (
    <>
      {process.env.DEFAULT_PRODUCT_REF === "demo" &&
        contractData?.submission?.base?.insured_history_clear === "Yes" && (
          <Banner className="mb-8" color="blue" headingText="No additional exclusions" />
        )}

      {process.env.DEFAULT_PRODUCT_REF !== "mc" && !hasQuotes && (
        <Banner className="mb-8 border  border-gray-400" color="white" headingText="Awaiting underwriter premium">
          No quotes have been created.
        </Banner>
      )}

      {process.env.DEFAULT_PRODUCT_REF === "mc" && !hasQuotes && (
        <Banner className="mb-8 border  border-gray-400" color="white" headingText="Awaiting admin response">
          No quotes have been created.
        </Banner>
      )}
    </>
  );
};

QuoteBanners.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default QuoteBanners;

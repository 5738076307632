import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import React from "react";
import { useQueryClient } from "react-query";
import pickCommaSeparate from "../../../helpers/pickCommaSeparate";
import InputSelectSearchable from "./InputSelectSearchable";

const InputSelectApiConnected = ({
  formValues,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  ...props
}) => {
  const queryClient = useQueryClient();
  const entityData = get(queryClient.getQueryData(["brokers"]), "data", []);

  const handleSelectChange = (id) => {
    const clonedFormValues = cloneDeep(formValues);
    const entity = entityData.find((e) => e.id === id);
    set(clonedFormValues, ownKey, entity.id);
    setFormValues(clonedFormValues);
  };

  const itemFormatter = (value) => {
    const entity = entityData.find((e) => e.id === value);
    const addressElements = ["addressLine1", "addressLine2", "city", "country", "postCode"];

    return (
      <div>
        <div>{entity.name}</div>
        <div className="text-gray-700 text-sm">
          {pickCommaSeparate(entity.address, addressElements)}
        </div>
      </div>
    );
  };

  const formatter = (value) => {
    const entity = entityData.find((e) => e.id === value);

    return entity.name;
  };

  return (
    <InputSelectSearchable
      {...props}
      item={{ enum: entityData.map((entity) => ({ name: entity.name, value: entity.id })) }}
      onChange={handleSelectChange}
      formatter={formatter}
      itemFormatter={itemFormatter}
    />
  );
};

export default InputSelectApiConnected;

import get from "lodash.get";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../api";
import useAuth from "./useAuth";

export const useMessagesQuery = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const params = useParams();
  const { productRef, contractId } = params;

  const mKey = ["messages", { contractId, productRef }, { readId: user.role }];
  const messagesQuery = useQuery(mKey, () => api.getMessages({ queryKey: mKey }), {
    enabled: Boolean(contractId),
    refetchInterval: JSON.parse(process.env.CLIENT_MESSAGES_INTERVAL),
  });

  const { mutateAsync: createMessage, ...createMessageQuery } = useMutation(api.createMessage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(mKey);
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
    },
  });

  const messagesData = get(messagesQuery, "data.data.data", []);
  const unreadCount = get(messagesQuery, "data.data.unreadCount", 0);
  const { isLoading } = messagesQuery;
  const isCreating = createMessageQuery.isLoading;

  return {
    messagesQuery,
    createMessage,
    isCreating,
    isLoading,
    messagesData,
    unreadCount,
  };
};

import { SchemaRef } from "../../../@types/types";
import { CargoRaterOutput } from "../types";
import { getRates as getRatesAnnual } from "./annual";
import { getRates as getRatesSingle } from "./single";

export const getRates = (
  submission: unknown,
  schemaRef: SchemaRef,
  commission?: number,
  transitPremiumTax?: number,
  storagePremiumTax?: number,
  exhibitionsPremiumTax?: number,
  samplesPremiumTax?: number,
): CargoRaterOutput => {
  if (schemaRef === "cargo-us-annual")
    return getRatesAnnual(
      submission,
      commission,
      transitPremiumTax,
      storagePremiumTax,
      exhibitionsPremiumTax,
      samplesPremiumTax,
    );
  if (schemaRef === "cargo-us-single") return getRatesSingle(submission);

  throw new Error(`Missing parameter: ${schemaRef}.`);
};

import React from "react";
import { currencyFormatter } from "../../../formatters";
import InputSelect from "../InputSelect";

const InputSelectCurrency = ({ item, ...props }) => (
  <InputSelect
    {...props}
    options={item.enum.map((value) => ({ name: value, value }))}
    formatter={(value) => currencyFormatter(value)}
  />
);

export default InputSelectCurrency;

import PropTypes from "prop-types";
import React from "react";

const Form = ({ onSubmit, ...props }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit(event);
    }
  };

  return <form onSubmit={handleSubmit} {...props} />;
};

Form.propTypes = {
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: undefined,
};

export default Form;

import clsx from "clsx";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import unset from "lodash.unset";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import Button from "../Button";
import InputField from "../InputField";
import { DMS } from "../../../constants/dms";

const allowedExtensions = DMS.UPLOAD_ALLOWED_FILE_TYPES.split(",");

const InputFile = ({
  className,
  descriptionText,
  errors,
  isDisabled,
  isReadOnly,
  labelText,
  onChange,
  name,
  id,
  item,
  value,
  formValues,
  ownKey,
  setFormValues,
  ...props
}) => {
  const [fileName, setFileName] = useState((value || {}).fileName);
  const [formErrors, setFormErrors] = useState();
  const inputFile = useRef();

  const handleChange = async () => {
    const file = inputFile.current.files[0];

    setFormErrors();

    if (!file) {
      return false;
    }

    const data = {
      fileName: get(file, "name"),
      documentId: uuid(),
    };

    const extension = file.name.split(".").pop();
    const isExtensionAllowed = allowedExtensions.includes(extension);
    const fileSizeInBase64 = (file.size + 814) * 1.37;
    const sizeThreshold = (6000000 - 814) / 1.37;

    if (fileSizeInBase64 > sizeThreshold) {
      const clonedFormValues = cloneDeep(formValues);

      unset(clonedFormValues, ownKey);
      setFormValues(clonedFormValues);

      return setFormErrors("File size is too big.");
    }

    if (!isExtensionAllowed) {
      const clonedFormValues = cloneDeep(formValues);

      unset(clonedFormValues, ownKey);
      setFormValues(clonedFormValues);

      return setFormErrors("The file type is not allowed.");
    }

    setFileName(data.fileName);

    if (onChange) {
      onChange(data, name);
    }

    return true;
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      errors={errors || formErrors}
      {...props}
    >
      <label htmlFor={id || name} className="block relative">
        <input
          className="sr-only"
          disabled={isDisabled}
          id={id || name}
          onChange={handleChange}
          type="file"
          data-uuid={(value || {}).documentId}
          ref={inputFile}
        />
        <div
          className={clsx(
            "aui-input aui-input-file h-12 flex items-center focus:outline-none focus:shadow-outline",
            isDisabled && "aui-input--disabled",
            isReadOnly && "aui-input--readonly",
            errors && "aui-input--errors"
          )}
          tabIndex="0"
          role="button"
        >
          <span className="truncate">{fileName}</span>
        </div>
        <div className="absolute flex items-center inset-y-0 right-0 mr-1">
          <Button className="pointer-events-none h-10" iconName="upload" isDisabled={isDisabled}>
            Browse
          </Button>
        </div>
      </label>
    </InputField>
  );
};

InputFile.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};

InputFile.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  errors: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  labelText: undefined,
  onChange: undefined,
  name: undefined,
  id: undefined,
};

export default InputFile;

import React, { createContext, useContext, useState } from "react";
import AcceptTechnicalPriceModal from "../modals/AcceptTechnicalPriceModal";
import AttachmentsModal from "../modals/AttachmentsModal";
import BindEndoQuoteModal from "../modals/BindEndoQuoteModal";
import BindQuoteModal from "../modals/BindQuoteModal";
import BindQuoteSuccessModal from "../modals/BindQuoteSuccessModal";
import CancelEndorsementModal from "../modals/CancelEndorsementModal";
import ChangeCommissionModal from "../modals/ChangeCommissionModal";
import CreateClauseModal from "../modals/CreateClauseModal";
import CreateEndoWarningModal from "../modals/CreateEndoWarningModal";
import CreateProductModal from "../modals/CreateProductModal";
import CreateSchemaModal from "../modals/CreateSchemaModal";
import DebuggerCreateTemplateModal from "../modals/DebuggerCreateTemplateModal";
import DebuggerSelectTemplateModal from "../modals/DebuggerSelectTemplateModal";
import DeclineContractModal from "../modals/DeclineContractModal";
import DownloadBdxModal from "../modals/DownloadBdxModal";
import EditBespokeModal from "../modals/EditBespokeModal";
import EditClauseModal from "../modals/EditClauseModal";
import EditQuoteLockedWarningModal from "../modals/EditQuoteLockedWarningModal";
import EditQuoteWarningModal from "../modals/EditQuoteWarningModal";
import FlagPolicyModal from "../modals/FlagPolicyModal";
import GenerateManualQuoteModal from "../modals/GenerateManualQuoteModal";
import NotProgressedModal from "../modals/NotProgressedModal";
import NotTakenUpModal from "../modals/NotTakenUpModal";
import ProcessReferralModal from "../modals/ProcessReferralModal";
import ProvideCommercialPriceModal from "../modals/ProvideCommercialPriceModal";
import ReferContractModal from "../modals/ReferContractModal";
import RemovePolicyFlagModal from "../modals/RemovePolicyFlagModal";
import SanctionsCheckFailModal from "../modals/SanctionsCheckFailModal";
import SelectPolicyTypeModal from "../modals/SelectPolicyTypeModal";
import SubmitBespokeEndorsementModal from "../modals/SubmitBespokeEndorsementModal";
import SubmitEndorsementModal from "../modals/SubmitEndorsementModal";
import SubmitFinalAdjustmentPremiumModal from "../modals/SubmitFinalAdjustmentPremiumModal";
import SubmitRenewalBespokeModal from "../modals/SubmitRenewalBespokeModal";
import UnsavedChangesModal from "../modals/UnsavedChangesModal";
import UpdateQuoteCommissionModal from "../modals/UpdateQuoteCommissionModal";

const modalsMap = {
  AcceptTechnicalPriceModal,
  AttachmentsModal,
  BindEndoQuoteModal,
  BindQuoteModal,
  BindQuoteSuccessModal,
  CancelEndorsementModal,
  ChangeCommissionModal,
  CreateClauseModal,
  CreateEndoWarningModal,
  CreateProductModal,
  CreateSchemaModal,
  DebuggerCreateTemplateModal,
  DebuggerSelectTemplateModal,
  DeclineContractModal,
  DownloadBdxModal,
  EditBespokeModal,
  EditClauseModal,
  EditQuoteLockedWarningModal,
  EditQuoteWarningModal,
  FlagPolicyModal,
  GenerateManualQuoteModal,
  NotProgressedModal,
  NotTakenUpModal,
  ProcessReferralModal,
  ReferContractModal,
  ProvideCommercialPriceModal,
  RemovePolicyFlagModal,
  SanctionsCheckFailModal,
  SelectPolicyTypeModal,
  SubmitBespokeEndorsementModal,
  SubmitEndorsementModal,
  SubmitFinalAdjustmentPremiumModal,
  SubmitRenewalBespokeModal,
  UnsavedChangesModal,
  UpdateQuoteCommissionModal,
};

const ModalContext = createContext();

const useModal = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }) => {
  const [modal, setVisibleModal] = useState();
  const [props, setProps] = useState();

  const showModal = (name, nextProps) => {
    setVisibleModal(name);
    setProps(nextProps);
  };
  const closeModal = () => setVisibleModal(undefined);

  return <ModalContext.Provider value={{ modal, showModal, closeModal, props }}>{children}</ModalContext.Provider>;
};

export const ModalSwitch = () => {
  const { modal, closeModal, props } = useModal();
  const Component = modalsMap[modal];

  if (!Component) {
    return false;
  }

  return <Component handleClose={closeModal} {...props} />;
};

export default useModal;

export default [
  ["Alabama", "AL", "None", "High", "None"],
  ["Alaska", "AK", "None", "None", "None"],
  ["Arizona", "AZ", "Medium", "None", "None"],
  ["Arkansas", "AR", "None", "None", "High"],
  ["California", "CA", "High", "None", "None"],
  ["Colorado", "CO", "Medium", "None", "Medium"],
  ["Connecticut", "CT", "None", "Medium", "None"],
  ["Delaware", "DE", "None", "High", "None"],
  ["Florida", "FL", "None", "High", "None"],
  ["Georgia", "GA", "None", "High", "None"],
  ["Hawaii", "HI", "None", "None", "None"],
  ["Idaho", "ID", "High", "None", "None"],
  ["Illinois", "IL", "None", "None", "None"],
  ["Indiana", "IN", "None", "None", "Medium"],
  ["Iowa", "IA", "None", "None", "Medium"],
  ["Kansas", "KS", "None", "None", "High"],
  ["Kentucky", "KY", "None", "None", "Medium"],
  ["Louisiana", "LA", "None", "High", "None"],
  ["Maine", "ME", "None", "Medium", "None"],
  ["Maryland", "MD", "None", "High", "None"],
  ["Massachusetts", "MA", "None", "Medium", "None"],
  ["Michigan", "MI", "None", "None", "None"],
  ["Minnesota", "MN", "None", "None", "Medium"],
  ["Mississippi", "MS", "None", "High", "None"],
  ["Missouri", "MO", "None", "None", "High"],
  ["Montana", "MT", "Medium", "None", "None"],
  ["Nebraska", "NE", "None", "None", "High"],
  ["Nevada", "NV", "Medium", "None", "None"],
  ["New Hampshire", "NH", "None", "Medium", "None"],
  ["New Jersey", "NJ", "None", "High", "None"],
  ["New Mexico", "NM", "Medium", "None", "None"],
  ["New York", "NY", "None", "None", "None"],
  ["North Carolina", "NC", "None", "High", "None"],
  ["North Dakota", "ND", "None", "None", "None"],
  ["Ohio", "OH", "None", "None", "None"],
  ["Oklahoma", "OK", "Medium", "None", "None"],
  ["Oregon", "OR", "Medium", "None", "None"],
  ["Pennsylvania", "PA", "None", "None", "None"],
  ["Rhode Island", "RI", "None", "Medium", "None"],
  ["South Carolina", "SC", "None", "High", "None"],
  ["South Dakota", "SD", "None", "None", "Medium"],
  ["Tennessee", "TN", "None", "None", "Medium"],
  ["Texas", "TX", "None", "High", "None"],
  ["Utah", "UT", "High", "None", "None"],
  ["Vermont", "VT", "None", "None", "None"],
  ["Virginia", "VA", "None", "High", "None"],
  ["Washington", "WA", "High", "None", "None"],
  ["West Virginia", "WV", "None", "None", "None"],
  ["Wisconsin", "WI", "None", "None", "Medium"],
  ["Wyoming", "WY", "Medium", "None", "None"],
] as const;

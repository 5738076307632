import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CargoEvent } from "../../projects/cargo-us/types";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import { useContractQuery, useQuoteQuery } from "../hooks";
import { resolveSchema } from "../../helpers/resolveSchema";
import ajv from "../../helpers/ajv";
import mapChildren from "../helpers/mapChildren";

const BindQuoteModalForm = ({ callback, handleClose, quoteData, schemaData, contractData }) => {
  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();
  const [isBindConfirmed, setBindConfirmed] = useState(false);
  const [isNoKnownClaimsConfirmed, setNoKnownClaimsConfirmed] = useState<boolean | undefined>(undefined);
  const isInceptionBeforeToday = moment
    .utc(contractData.submission.contract.inception_date)
    .isBefore(moment.utc(), "day");

  const [formValues, setFormValues] = useState(contractData.submission);

  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const action: Extract<CargoEvent, { type: "Bind quote" }> = {
      type: "Bind quote",
      payload: {
        noKnownLosses: isNoKnownClaimsConfirmed,
        transitPremiumTax: formValues.taxes.transit_premium_tax.amount,
        storagePremiumTax: formValues?.storage?.storage_premium_tax?.amount,
        exhibitionsPremiumTax: formValues.exhibitions?.exhibitions_premium_tax?.amount,
        samplesPremiumTax: formValues.samples?.samples_premium_tax?.amount,
      },
    };
    const res = await updateQuote({ contractId, data: action, endorsementId, productRef, quoteId });

    handleClose();
    callback(get(res, "data.data"));
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        {isInceptionBeforeToday && (
          <Banner
            className="mb-8 shadow-none  bg-gray-50"
            color="orange"
            headingText="Prior notice - backdated inception date"
          >
            <p className="mb-4">
              The insured warrants no known or reported losses as of:{" "}
              <span className="font-bold">{moment.utc().format("DD/MM/YYYY")}</span>
            </p>

            <InputCheckbox
              isChecked={isNoKnownClaimsConfirmed}
              labelText="I accept"
              name="confirm_no_known_claims"
              onChange={() => setNoKnownClaimsConfirmed(!isNoKnownClaimsConfirmed)}
            />

            <p className="mt-4 text-gray-700 text-sm">
              For a backdated inception date, you cannot bind the policy without agreeing to the warranty of no known
              claims. Please confirm the warranty or change the inception date.
            </p>
          </Banner>
        )}

        <Banner
          className="mb-8 shadow-none  bg-gray-50"
          color="orange"
          headingText="Are you sure you want to bind this quote?"
        >
          <InputCheckbox
            className="mt-4"
            labelText="I understand"
            name="confirm_bind"
            onChange={() => setBindConfirmed(!isBindConfirmed)}
            isChecked={isBindConfirmed}
          />

          <p className="mt-4 text-gray-700 text-sm">This cannot be undone.</p>
        </Banner>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={
              isInceptionBeforeToday
                ? !isValid || !isBindConfirmed || !isNoKnownClaimsConfirmed || isUpdating
                : !isValid || !isBindConfirmed || isUpdating
            }
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const BindQuoteModal_cargo = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading, schemaData } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal handleClose={handleClose} headingText={!isLoading && "Bind quote"} isLoading={isLoading} clearParamsOnClose>
      <BindQuoteModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
        schemaData={{ ...schemaData.properties.BindQuoteForm, definitions: schemaData.definitions }}
      />
    </Modal>
  );
};

BindQuoteModal_cargo.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default BindQuoteModal_cargo;

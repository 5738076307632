import React from "react";
import CardAccordion from "../../components/CardAccordion";
import H3 from "../../components/H3";
import EndorsementPanel from "./EndorsementPanel";
import { useContractQuery } from "../../hooks";

const ContractViewEndorsements = () => {
  const { contractData: parentData, endorsementsData, schemaData } = useContractQuery();

  if (!endorsementsData || endorsementsData.length === 0) {
    return null;
  }
  const activeEndorsementId = endorsementsData[0].id;

  return (
    <>
      <H3 className="mb-8">Endorsements</H3>

      <CardAccordion initialActiveIds={[activeEndorsementId]} key={[activeEndorsementId]}>
        {endorsementsData.map((contractData, index) => (
          <EndorsementPanel
            contractData={contractData}
            parentData={parentData}
            id={contractData.id}
            isCurrent={index === 0}
            key={contractData.id}
            schemaData={schemaData}
          />
        ))}
      </CardAccordion>
    </>
  );
};

export default ContractViewEndorsements;

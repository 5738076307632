import sumBy from "lodash.sumby";

const total100 = {
  validate: (schema, data) => {
    const fields = Array.isArray(schema) ? schema : [schema];
    const dataToSum = Array.isArray(data) ? data : [data];
    const total = fields.reduce((acc, field) => acc + sumBy(dataToSum, field, 0), 0);

    return total === 100;
  },
  errors: false,
};

export default total100;

import PropTypes from "prop-types";
import React from "react";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { useContractQuery } from "../hooks";

const ReferContractModal = ({ handleClose }) => {
  const { updateContract, isUpdating, productRef, contractId, endorsementId } = useContractQuery();

  const handleReferContract = async () => {
    await updateContract({
      productRef,
      contractId,
      endorsementId,
      data: { type: ACTIONS.REFER_CONTRACT },
    });

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Are you sure?">
      <div className="p-6">
        <p className="mb-4">
          This quote will be referred. You can either continue to refer to the Underwriter or cancel
          to stay on this page.
        </p>

        <p className="mb-8">
          If you refer the quote, please use the messaging feature to provide additional information
          for the underwriter.
        </p>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={isUpdating}
            kind="primary"
            onClick={handleReferContract}
          >
            Refer quote
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

ReferContractModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ReferContractModal;

import { get, unset } from "lodash";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import moment from "moment";
import { useEffect } from "react";
import { Broker } from "../../@types/types";

export const useMelEffects = (formValues: any, setFormValues: any, tenant: Broker): void => {
  // set producing agent https://trello.com/c/gludoqcz/186-producing-broker-logic-change
  useEffect(() => {
    if (
      !formValues?.broker_information?.has_producing_agent &&
      !formValues?.broker_information?.producing_agent_id &&
      tenant.type === "producing-broker"
    ) {
      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "broker_information.has_producing_agent", "Yes");
      set(nextFormValues, "broker_information.producing_agent_id", tenant.id);

      setFormValues(nextFormValues);
    }
  }, [formValues?.broker_information?.has_producing_agent]);

  // unset producing agent
  useEffect(() => {
    if (formValues?.broker_information?.has_producing_agent === "No") {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "broker_information.producing_agent_id");
      setFormValues(nextFormValues);
    }
  }, [formValues?.broker_information?.has_producing_agent]);

  // unset surplus lines tax broker
  useEffect(() => {
    if (formValues?.broker_information?.has_surplus_lines_tax_broker) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "broker_information.broker_name");
      unset(nextFormValues, "broker_information.broker_address");
      unset(nextFormValues, "broker_information.broker_licence_number");
      setFormValues(nextFormValues);
    }
  }, [formValues?.broker_information?.has_surplus_lines_tax_broker]);

  // unset rigs
  useEffect(() => {
    if (formValues?.rigs?.does_insured_perform_work_on_rigs) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "rigs.rigs_breakdown");
      unset(nextFormValues, "rigs.insured_will_undertake_work_on_platforms");
      unset(nextFormValues, "rigs.insured_will_not_undertake_work_on_platforms");
      setFormValues(nextFormValues);
    }
  }, [formValues?.rigs?.does_insured_perform_work_on_rigs]);

  // unset insured_will_not_undertake_work_on_platforms
  useEffect(() => {
    if (formValues?.rigs?.rigs_breakdown?.semisubmersibles_or_other_vessels?.next_year?.amount) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "rigs.insured_will_undertake_work_on_platforms");
      unset(nextFormValues, "rigs.insured_will_not_undertake_work_on_platforms");
      setFormValues(nextFormValues);
    }
  }, [formValues?.rigs?.rigs_breakdown?.semisubmersibles_or_other_vessels?.next_year?.amount]);

  // unset diving
  useEffect(() => {
    if (formValues?.diving?.does_insured_engage_in_diving) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "diving.total_divers");
      unset(nextFormValues, "diving.total_tenders");
      unset(nextFormValues, "diving.do_tenders_dive");
      unset(nextFormValues, "diving.type_of_diving");
      setFormValues(nextFormValues);
    }
  }, [formValues?.diving?.does_insured_engage_in_diving]);

  // unset watercraft owns_watercraft
  useEffect(() => {
    if (formValues?.watercraft?.owns_watercraft) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "watercraft.watercraft_transport");
      unset(nextFormValues, "watercraft.separate_insurance");
      unset(nextFormValues, "watercraft.watercraft_has_captain");
      setFormValues(nextFormValues);
    }
  }, [formValues?.watercraft?.owns_watercraft]);

  // unset watercraft separate_insurance
  useEffect(() => {
    if (formValues?.watercraft?.separate_insurance) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "watercraft.watercraft_has_captain");
      setFormValues(nextFormValues);
    }
  }, [formValues?.watercraft?.separate_insurance]);

  // unset watercraft shipbuilding
  useEffect(() => {
    if (formValues?.watercraft?.shipbuilding) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "watercraft.trial_trips");
      unset(nextFormValues, "watercraft.total_trips_per_year");
      setFormValues(nextFormValues);
    }
  }, [formValues?.watercraft?.shipbuilding]);

  // unset watercraft trial_trips
  useEffect(() => {
    if (formValues?.watercraft?.trial_trips) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "watercraft.total_trips_per_year");
      setFormValues(nextFormValues);
    }
  }, [formValues?.watercraft?.trial_trips]);

  // unset current_insurance
  useEffect(() => {
    if (formValues?.current_insurance?.does_the_insured_currently_have_insurance) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "current_insurance.insurer_name");
      unset(nextFormValues, "current_insurance.expiry_date");
      unset(nextFormValues, "current_insurance.limit");
      unset(nextFormValues, "current_insurance.premium");
      setFormValues(nextFormValues);
    }
  }, [formValues?.current_insurance?.does_the_insured_currently_have_insurance]);

  // unset loss_record
  useEffect(() => {
    if (formValues?.loss_record?.has_insured_had_jones_act_claims) {
      const nextFormValues = cloneDeep(formValues);

      unset(nextFormValues, "loss_record.loss_details");
      setFormValues(nextFormValues);
    }
  }, [formValues?.loss_record?.has_insured_had_jones_act_claims]);

  // set expiry date to inception date + 1 year
  useEffect(() => {
    if (formValues?.quote?.inception_date) {
      const newExpiryDate = moment(formValues?.quote?.inception_date).add(1, "year").toISOString();
      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "quote.expiry_date", newExpiryDate);
      setFormValues(nextFormValues);
    }
  }, [formValues?.quote?.inception_date]);

  // sum payroll amounts
  useEffect(() => {
    if (formValues?.payroll?.payroll_breakdown) {
      const nextFormValues = cloneDeep(formValues);

      const total = [
        "payroll.payroll_breakdown.state_act_on_land_dock.next_year.amount",
        "payroll.payroll_breakdown.longshore_on_land_dock.next_year.amount",
        "payroll.payroll_breakdown.longshore_on_watercraft_dockside.next_year.amount",
        "payroll.payroll_breakdown.longshore_on_watercraft_away_from_dock.next_year.amount",
        "payroll.payroll_breakdown.jones_act.next_year.amount",
      ].reduce((prev, next) => {
        const val = get(formValues, next, "") || 0;
        const parsedValue = parseFloat(val);

        return prev + parsedValue;
      }, 0);

      set(nextFormValues, "payroll.total_payroll_all", { amount: total, currency: "USD" });
      setFormValues(nextFormValues);
    }
  }, [JSON.stringify(formValues?.payroll?.payroll_breakdown)]);

  // remap "District of Columbia" to "Washington" as DC is not on known states list
  useEffect(() => {
    if (formValues?.general_information?.address?.state === "District of Columbia") {
      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "general_information.address.state", "Washington");
      setFormValues(nextFormValues);
    }
  }, [formValues?.general_information?.address]);

  // remap "District of Columbia" to "Washington" as DC is not on known states list
  useEffect(() => {
    if (formValues?.broker_information?.broker_address?.state === "District of Columbia") {
      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "broker_information.broker_address.state", "Washington");
      setFormValues(nextFormValues);
    }
  }, [formValues?.broker_information?.broker_address]);
};

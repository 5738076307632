import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CargoEvent } from "../../projects/cargo-us/types";
import Button from "../components/Button";
import Form from "../components/Form";
import Modal from "../components/Modal";
import { useContractQuery, useQuoteQuery } from "../hooks";
import { resolveSchema } from "../../helpers/resolveSchema";
import ajv from "../../helpers/ajv";
import mapChildren from "../helpers/mapChildren";

const ProvideCommercialPriceModalForm = ({ callback, handleClose, quoteData, schemaData, contractData }) => {
  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();

  const [formValues, setFormValues] = useState(contractData.submission);

  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const action: Extract<CargoEvent, { type: "Provide commercial price" }> = {
      type: "Provide commercial price",
      payload: {
        transitPremium: formValues.premiums.transit_premium,
        storagePremium: formValues?.storage?.storage_premium,
        exhibitionsPremium: formValues.exhibitions?.exhibitions_premium,
        samplesPremium: formValues.samples?.samples_premium,

        commission: quoteData?.commission,

        transitPremiumTax: quoteData.rates.basePremium?.tax,
        storagePremiumTax: quoteData.rates.storagePremium?.tax,
        exhibitionsPremiumTax: quoteData.rates.exhibitionsPremium?.tax,
        samplesPremiumTax: quoteData.rates.samplesPremium?.tax,
      },
    };
    await updateQuote({ contractId, data: action, endorsementId, productRef, quoteId });

    handleClose();
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        <div className="flex">
          <Button className="mr-4" isDisabled={!isValid || isUpdating} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const ProvideCommercialPriceModal = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading, schemaData } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal handleClose={handleClose} headingText={!isLoading && "Commercial Price"} isLoading={isLoading} clearParamsOnClose>
      <ProvideCommercialPriceModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
        schemaData={{ ...schemaData.properties.CommercialPriceForm, definitions: schemaData.definitions }}
      />
    </Modal>
  );
};

ProvideCommercialPriceModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ProvideCommercialPriceModal;

import React, { FC } from "react";

export const Annual_Insureds_obligations: FC = () => {
  return (
    <section aria-labelledby="theAssuredsObligations">
      <h1 id="theAssuredsObligations">THE ASSUREDS OBLIGATIONS</h1>

      <p>
        If the Assured do not comply with their obligations, Underwriters may be discharged from liability under the
        Policy.
      </p>

      <p>
        <b>DUTY OF FULL DISCLOSURE</b>
        <br />
        The Assured is under a legal duty to tell Underwriters about every matter which you know or could reasonably be
        expected to know, which is relevant to our decision to accept the risk of insurance, and on what terms.
      </p>

      <p>
        If you fail to comply with this obligation, Underwriters have the option of voiding this Policy from its
        inception. That means we are entitled to treat this Policy as if it had never existed, and the Assured might be
        obliged to pay back monies paid out in respect of claims made hereunder.
      </p>

      <p>
        The Assured must notify Underwriters in writing if there is any material change in circumstances, or in the
        nature of the risk covered by this Policy. The Assured should do so as soon as reasonably practicable. If the
        Assured fails to do so then we may not pay any claim arising after such a change, unless otherwise agreed in
        writing.
      </p>

      <p>
        <b>OBLIGATIONS WHEN MAKING A CLAIM</b>
        <br />
        The Assured must not make a fraudulent, false, or intentionally exaggerated claim. If a claim is fraudulent,
        false, or intentionally exaggerated (whether ultimately material or not) in any respect, Underwriters may refuse
        to pay the whole or part of the claim to the extent permitted by law, and also may be entitled to cancel the
        Policy.
      </p>

      <p>
        <b>DUTY TO TAKE REASONABLE CARE</b>
        <br />
        The Assured must act with reasonable care at all times. The onus of proving that you have fully complied with
        your duty under this clause rests with the Assured.
      </p>

      <h1>CLAIMS</h1>

      <p>
        If the Assured does not comply with their obligations, Underwriters may be discharged from liability under this
        Policy. In the event of a loss covered by the Policy, you must act sensibly, as if your loss was not covered by
        insurance. You must act to minimise the loss and/or damage to the goods and to protect any recovery rights that
        may be available.
      </p>

      <p>
        In the event of any happening or event likely to give rise to a claim under this Policy, you must ensure that
        Underwriters or our claims handling agents Crawford & Co are informed about the event as soon as possible. You
        must also give notice in writing as soon as reasonably practicable. This notice should be given, with full
        particulars, to your insurance broker and Crawford & Company details as below.
      </p>

      <p>You must then take the following steps:</p>

      <p>
        1. Submit as soon as possible all written particulars, supporting documentation and correspondence regarding the
        event including invoices, statements or other documents evidencing the amount being claimed. For further
        information on this obligation, see “DOCUMENTATION OF CLAIMS” below.
      </p>

      <p>
        2. Take reasonable measures to avoid or minimise any loss, damage or expense. Underwriters will pay the costs of
        such measures provided that they are both reasonable and necessary.
      </p>

      <p>
        3. Properly preserve and exercise all rights against carriers, bailees or other third parties, specifically:
      </p>

      <ul style={{ listStyleType: "disc", marginLeft: "2em" }}>
        <li>
          <p>Do not release those parties from liability</p>
        </li>
        <li>
          <p>The Assured must not admit, or promise payment to anyone without Underwriters written consent</p>
        </li>
        <li>
          <p>Deliver to the parties responsible a notice of intention to claim within 3 days of delivery</p>
        </li>
        <li>
          <p>Do not give clean receipts where the goods are in doubtful condition except under written protest</p>
        </li>
        <li>
          <p>If a consignment is delivered damaged or with goods missing mark the delivery note accordingly</p>
        </li>
        <li>
          <p>
            Where delivery is made by container, ensure that the seals are examined immediately by a responsible
            official
          </p>
        </li>
        <li>
          <p>
            If any package is delivered damaged, e.g. torn, wet damaged, note the delivery note accordingly and retain
            all packaging for subsequent inspection. If possible take photographs of the damaged interest
          </p>
        </li>
        <li>
          <p>
            If a container is delivered damaged or with seals broken or missing or with seals other than stated in the
            shipping documentation, mark the delivery note accordingly and retain the seals for subsequent
            identification
          </p>
        </li>
        <li>
          <p>
            Upon discovery of any loss or damage, apply immediately for surveys by the carriers or other bailees to be
            conducted within 3 days of delivery
          </p>
        </li>
      </ul>

      <p>4. Inform the police or appropriate authority as soon as possible after a theft or crime has occurred.</p>

      <p>
        5. In the event of a general average or salvage contribution arising under this Policy, consult Underwriters or
        Underwriters’ nominated settling agent before signing any general average or salvage bond.
      </p>
    </section>
  );
};

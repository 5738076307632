import Tippy from "@tippyjs/react";
import React from "react";
import { dateTimeFormatter } from "../../../formatters";
import H4 from "../../components/H4";
import Icon from "../../components/Icon";

const TooltipDatapointHistory = ({ children, changeHistory }) => {
  if (
    !changeHistory ||
    changeHistory.length === 0 ||
    (changeHistory.length === 1 && changeHistory[0].isCreation)
  ) {
    return children;
  }

  return (
    <Tippy
      content={
        <div>
          <H4 className="mb-2">Change History</H4>
          <ul className="list-disc list-inside">
            {changeHistory.map((item, index) => {
              const value = `${item.value} (${dateTimeFormatter(item.date)})`;

              return (
                <li className="mb-1" key={index.toString()}>
                  {index === 0 ? <span className="font-semibold">{value}</span> : value}
                </li>
              );
            })}
          </ul>
        </div>
      }
    >
      <div className="flex justify-between">
        <div>{children}</div>
        <Icon name="clock" className="w-5 m-1 fill-current text-blue-500" />
      </div>
    </Tippy>
  );
};

export default TooltipDatapointHistory;

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataString = reader.result.split(",")[1];

      resolve(dataString);
    };
    reader.onerror = (error) => reject(error);
  });

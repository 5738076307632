import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const RemovePolicyFlagModal = ({ handleClose, handleConfirm, isLoading }) => (
  <Modal handleClose={handleClose} headingText="Remove flag">
    <div className="p-6 w-160">
      <p className="mb-6">Are you sure you want to remove the flag on this policy?</p>

      <div className="flex">
        <Button kind="primary" onClick={handleConfirm} className="mr-4" isDisabled={isLoading}>
          Remove flag
        </Button>

        <Button kind="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

RemovePolicyFlagModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RemovePolicyFlagModal;

import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ACTIONS } from "../../constants";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import { useContractQuery, useQuoteQuery } from "../hooks";
import BindQuoteModal_cargo from "./BindQuoteModal_cargo";
import BindQuoteModal_mel from "./BindQuoteModal_mel";

const BindQuoteModalForm = ({ callback, handleClose }) => {
  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();

  const [isBindConfirmed, setBindConfirmed] = useState(false);

  const handleSubmit = async () => {
    const res = await updateQuote({
      contractId,
      data: { type: ACTIONS.BIND_QUOTE },
      endorsementId,
      productRef,
      quoteId,
    });

    handleClose();
    callback(get(res, "data.data"));
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        <Banner
          className="mb-8 shadow-none  bg-gray-50"
          color="orange"
          headingText="Are you sure you want to bind this quote? This cannot be undone."
        >
          <InputCheckbox
            labelText="I understand"
            name="confirm_bind"
            onChange={() => setBindConfirmed(!isBindConfirmed)}
            isChecked={isBindConfirmed}
          />
        </Banner>

        <div className="flex">
          <Button className="mr-4" isDisabled={!isBindConfirmed || isUpdating} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const BindQuoteModal = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal handleClose={handleClose} headingText={!isLoading && "Bind quote"} isLoading={isLoading} clearParamsOnClose>
      <BindQuoteModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
      />
    </Modal>
  );
};

BindQuoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default process.env.DEFAULT_PRODUCT_REF === "mel"
  ? BindQuoteModal_mel
  : process.env.DEFAULT_PRODUCT_REF === "cargo-us"
  ? BindQuoteModal_cargo
  : BindQuoteModal;

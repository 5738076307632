export const ROUTES = {
  CONTRACT_EDIT: "/products/:productRef/contracts/:contractId/edit",
  CONTRACT_NEW: "/products/:productRef/new-quote",
  CONTRACT_NOT_FOUND: "/products/:productRef/404",
  CONTRACT_VIEW_AUDIT_LOG: "/products/:productRef/contracts/:contractId/view/audit-log",
  CONTRACT_VIEW_DETAILS: "/products/:productRef/contracts/:contractId/view/details",
  CONTRACT_VIEW_ENDORSEMENTS: "/products/:productRef/contracts/:contractId/view/endorsements",
  CONTRACT_VIEW: "/products/:productRef/contracts/:contractId/view/:tabName?",
  CONTRACT: "/products/:productRef/contracts/:contractId",
  CONTRACTS: "/products/:productRef/policies",
  DASHBOARD: "/dashboard",
  DRAFTS: "/products/:productRef/quotes",
  EDITOR: "/editor",
  ENDORSEMENT_BESPOKE_NEW: "/products/:productRef/contracts/:contractId/new-bespoke-endorsement",
  ENDORSEMENT_EDIT: "/products/:productRef/contracts/:contractId/endorsement/:endorsementId/edit",
  ENDORSEMENT_NEW: "/products/:productRef/contracts/:contractId/new-endorsement",
  FORGOT_PASSWORD: "/forgot-password",
  HOME: "/products/:productRef",
  INBOX: "/products/:productRef/inbox",
  LOGIN: "/login",
  LOGOUT: "/logout",
  NOT_FOUND: "/404",
  PRODUCT: "/products/:productRef",
  RATER_TESTER: "/rater-tester",
  REFERRALS: "/products/:productRef/referrals",
  RENEWAL: "/products/:productRef/contracts/:contractId/renewal",
  RESET_PASSWORD: "/reset-password",
  SETUP_PASSWORD: "/setup-password",
  REGISTER_USER: "/register",
};

export default ROUTES;

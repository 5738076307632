import PropTypes from "prop-types";
import React from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { queryClient } from "../../helpers/queryClient";
import { ModalContextProvider } from "../../hooks/useModal";

const Providers = ({ children }) => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ModalContextProvider>{children}</ModalContextProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parseParams } from "../../helpers/parseParams";

export const useMagicLink = (existingValues) => {
  const { search, state } = useLocation();
  const initialValues = parseParams(search).initial_form_data || state?.data;
  const reviver = (k, v) => (typeof v === "object" || isNaN(v) ? v : parseInt(v, 10));
  const parsedValues = JSON.parse(JSON.stringify(initialValues || {}), reviver);
  const initialFormValues = initialValues ? parsedValues : existingValues;
  const [formValues, setFormValues] = useState(initialFormValues || {});

  useEffect(() => {
    if (state?.data) {
      setFormValues(state?.data);
    }
  }, [state?.data]);

  return { initialFormValues, formValues, setFormValues };
};

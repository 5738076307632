import { compile } from "path-to-regexp";

const contractPath = "products/:productRef/contracts/:contractId";
const endoPath = "endorsements/:endorsementId";

const ENDPOINTS = {
  TEMPLATES: "templates",
  TEMPLATE: compile(`templates/:id`),
  AUDIT: compile(`${contractPath}/audit`),
  CLAUSE: compile("products/:productRef/clauses/:clauseId"),
  CLAUSES: compile("products/:productRef/clauses"),
  BDX: compile("products/:productRef/bdx"),
  CONNECTIONS: compile(`${contractPath}/connections`),
  CONTRACT_CLAUSE: compile(`${contractPath}/clauses/:clauseId`),
  CONTRACT_CLAUSES: compile(`${contractPath}/clauses`),
  ENDORSEMENT_CLAUSE: compile(`${contractPath}/${endoPath}/clauses/:clauseId`),
  ENDORSEMENT_CLAUSES: compile(`${contractPath}/${endoPath}/clauses`),
  CONTRACTS: compile(`${contractPath}?`),
  DOCUMENT: compile(`${contractPath}/documents/document/:documentId`),
  DOCUMENTS: compile(`${contractPath}/documents`),
  ENDO_DOCUMENT: compile(`${contractPath}/${endoPath}/documents/document/:documentId`),
  ENDO_DOCUMENTS_APPLY: compile(`${contractPath}/${endoPath}/documents/apply_endorsement`),
  ENDO_DOCUMENTS: compile(`${contractPath}/${endoPath}/documents`),
  ENDORSEMENT_QUOTES: compile(`${contractPath}/${endoPath}/quotes/:quoteId`),
  ENDORSEMENT: compile(`${contractPath}/${endoPath}`),
  ENDORSEMENTS: compile(`${contractPath}/endorsements`),
  MESSAGES: compile(`${contractPath}/messages`),
  PRODUCING_BROKERS: "tenants?flat=true&type=producing-broker",
  PRODUCTS: compile("products/:productRef?"),
  QUOTES: compile(`${contractPath}/quotes/:quoteId`),
  RATER_CHECK: "rater-check",
  REFERRALS_CHECK: "referrals-check",
  SCHEMAS: compile("schemas/:schemaId?"),
  SNAPSHOTS: compile(`${contractPath}/snapshots`),
  TENANTS: "tenants?flat=true",
  USER: compile("users/:userId"),
  USERS: "users",
};

export default ENDPOINTS;

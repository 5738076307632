import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const ErrorText = ({ children, className }) => (
  <p className={clsx("mt-2 text-sm text-red-900", className)}>{children}</p>
);

ErrorText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ErrorText.defaultProps = {
  children: undefined,
  className: undefined,
};

export default ErrorText;

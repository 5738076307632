import moment from "moment";

const isLT60days = {
  validate: (schem, data) => {
    // console.log(schem, data);
    const end = moment.utc(data?.[schem[1]]);
    const start = moment.utc(data?.[schem[0]]);
    const period = end.diff(start, "days");

    return period < 60;
  },
  errors: false,
};

export default isLT60days;

import { PolicyType } from "aws-sdk/clients/applicationautoscaling";
import get from "lodash.get";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Product, ProductRef } from "../../@types/types";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";

export const useQuoteQuery = () => {
  const { state } = useLocation<{ policyType: PolicyType }>();
  const queryClient = useQueryClient();
  const { location } = useHistory();
  const params = useParams<{ productRef: ProductRef; contractId: string; endorsementId: string }>();
  const queryParams = parseParams(location.search);
  const { productRef, contractId } = params;
  const { quoteId } = queryParams;
  const endorsementId = queryParams.endorsementId || params.endorsementId;
  const enabled = Boolean(contractId);

  const { mutateAsync: updateQuote, ...updateQuoteQuery } = useMutation(api.updateQuote, {
    onSuccess: (data) => {
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("quote", { productRef, contractId });
      queryClient.invalidateQueries("contractClauses");
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId });
      }, 2000);
      setTimeout(() => {
        queryClient.invalidateQueries("snapshot", { productRef, contractId });
      }, 500);
    },
  });

  const { mutateAsync: updateEndoQuote, ...updateEndoQuoteQuery } = useMutation(api.updateEndorsementQuote, {
    onSuccess: (data) => {
      queryClient.setQueryData(["endorsement", { productRef, contractId, endorsementId }], data);
      queryClient.invalidateQueries("audit", { productRef, contractId });
      queryClient.invalidateQueries("contract", { productRef, contractId });
      queryClient.invalidateQueries("contractClauses", { productRef, contractId });
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("endorsements", { productRef, contractId });
      queryClient.invalidateQueries("endoQuote", { productRef, contractId, endorsementId, quoteId });
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId, endorsementId });
      }, 2000);
      setTimeout(() => {
        queryClient.invalidateQueries("snapshot", { productRef, contractId });
      }, 500);
    },
  });

  const productQuery = useQuery(["product", { productRef }], api.getProduct);
  const productData = (productQuery?.data?.data?.data || {}) as Product;

  const cKey = ["contract", { productRef, contractId }];
  const contractQuery = useQuery(cKey, api.getContract, { enabled });
  const contractData = contractQuery?.data?.data?.data || {};

  const schemaId = contractData?.schemaId;
  const schemaQuery = useQuery(["schema", { schemaId }], api.getSchema, { enabled: Boolean(schemaId) });
  const schemaData = schemaQuery?.data?.data?.data?.payload || {};

  const quoteKey = ["quote", { productRef, contractId, quoteId }];
  const quoteQuery = useQuery(quoteKey, api.getQuote, { enabled: Boolean(!endorsementId) });
  const quoteData = get(quoteQuery, "data.data.data", {});

  const key = ["endoQuote", { productRef, contractId, endorsementId, quoteId }];
  const endorsementQuoteQuery = useQuery(key, api.getEndorsementQuote, {
    enabled: Boolean(endorsementId),
  });
  const endorsementQuoteData = get(endorsementQuoteQuery, "data.data.data", {});

  const isLoading =
    quoteQuery.isLoading || schemaQuery.isLoading || productQuery.isLoading || endorsementQuoteQuery.isLoading;

  const isUpdating = updateQuoteQuery.isLoading || updateEndoQuoteQuery.isLoading;

  return {
    contractId,
    endorsementId,
    endorsementQuoteData,
    isLoading,
    isUpdating,
    productData,
    productRef,
    quoteData: endorsementId ? endorsementQuoteData : quoteData,
    quoteId,
    schemaData,
    updateQuote: endorsementId ? updateEndoQuote : updateQuote,
  };
};

export default useQuoteQuery;

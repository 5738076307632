import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputPercentage = ({
  className,
  descriptionText,
  decimalScale = 0,
  errors,
  id,
  isAllowed,
  isReadOnly,
  isRequired,
  labelText,
  name,
  onChange,
  value,
  ...props
}) => {
  const handleChange = ({ floatValue }) => {
    if (onChange) onChange(floatValue, name);
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      errors={errors}
    >
      {!isReadOnly && (
        <div className="relative">
          <div className="absolute inset-y-0 right-0 flex items-center justify-center w-8">%</div>

          <NumberFormat
            allowNegative={false}
            className="text-right"
            customInput={Input}
            decimalScale={decimalScale}
            errors={errors}
            id={id || name}
            isAllowed={isAllowed}
            isNumericString
            isRequired={isRequired}
            name={name}
            onValueChange={handleChange}
            value={value}
          />
        </div>
      )}
      {isReadOnly && <InputReadOnly {...props} defaultValue={value} />}
    </InputField>
  );
};

InputPercentage.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

InputPercentage.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  value: undefined,
};

export default InputPercentage;

import get from "lodash.get";
import moment from "moment";
import React from "react";
import InputCalendar from "../InputCalendar";
import AHInputCalendarSchema from "./AHInputCalendarSchema";

const getDisabledDaysBefore = (schema, ctx) => {
  if ("ui:min:days:is:endorsement_effective_from" in schema) {
    const effectiveFromDate = get(ctx, "contractData.submission.quote.effective_from");

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ("ui:min:days:is:inception_date" in schema) {
    const inceptionDate =
    process.env.DEFAULT_PRODUCT_REF === "cargo-us"
        ? get(ctx, "contractData.submission.contract.inception_date")
        : get(ctx, "contractData.submission.quote.inception_date");

    return moment(inceptionDate).toDate();
  }

  if ("ui:max:days:before:today" in schema) {
    return moment().subtract(schema["ui:max:days:before:today"], "days").toDate();
  }

  if ("ui:min:days:after:today" in schema) {
    return moment().add(schema["ui:min:days:after:today"], "days").toDate();
  }

  return undefined;
};

const getDisabledDaysAfter = (schema, ctx, formValues) => {
  if ("ui:max:days:is:expiry_date" in schema) {
    const expiryDate =
    process.env.DEFAULT_PRODUCT_REF === "cargo-us"
        ? get(ctx, "contractData.submission.contract.expiry_date")
        : get(ctx, "contractData.submission.quote.expiry_date");
    return moment(expiryDate).toDate();
  }

  if ("ui:max:days:after:today" in schema) {
    return moment().add(schema["ui:max:days:after:today"], "days").toDate();
  }

  if ("ui:max:days:is:inception_date:plus:months" in schema) {
    const inceptionDate = formValues?.contract?.inception_date;

    return moment(inceptionDate).add(schema["ui:max:days:is:inception_date:plus:months"], "months").toDate();
  }

  return undefined;
};

const getInitialMonth = (schema, ctx) => {
  if ("ui:min:days:is:endorsement_effective_from" in schema) {
    const effectiveFromDate = get(ctx, "contractData.submission.quote.effective_from");

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ("ui:min:days:is:inception_date" in schema) {
    const inceptionDate =
    process.env.DEFAULT_PRODUCT_REF === "cargo-us"
        ? get(ctx, "contractData.submission.contract.inception_date")
        : get(ctx, "contractData.submission.quote.inception_date");

    return moment(inceptionDate).toDate();
  }

  if ("ui:min:days:after:today" in schema) {
    return moment().add(schema["ui:min:days:after:today"], "days").toDate();
  }

  return undefined;
};

const InputCalendarSchema = ({ item: schema, ctx, value, ...props }) => (
  <InputCalendar
    disableDaysBefore={getDisabledDaysBefore(schema, ctx, props.formValues)}
    disableDaysAfter={getDisabledDaysAfter(schema, ctx, props.formValues)}
    dayPickerProps={{ initialMonth: getInitialMonth(schema, ctx) }}
    value={value}
    {...props}
  />
);

export default process.env.DEFAULT_PRODUCT_REF === "demo" ? AHInputCalendarSchema : InputCalendarSchema;

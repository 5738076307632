import PropTypes from "prop-types";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichText = ({ value, onChange, className, isReadOnly }) => (
  <ReactQuill
    theme="snow"
    value={value}
    onChange={onChange}
    className={className}
    readOnly={isReadOnly || false}
    modules={{
      toolbar: !isReadOnly && [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [false, "center", "right", "justify"] }],
        ["clean"],
      ],
    }}
    formats={["bold", "italic", "underline", "list", "bullet", "indent", "align"]}
  />
);

RichText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

RichText.defaultProps = {
  value: undefined,
  onChange: undefined,
  className: undefined,
};

export default RichText;

import AWS from "aws-sdk";
import get from "lodash.get";
import sumBy from "lodash.sumby";
import { IdentityClient } from "@artificialio/identity-sdk";

const getAll = async () => {
  const env = process.env.ENVIRONMENT;
  if (env !== "testing") {
    let tenants = [];
    const identityClient = new IdentityClient();
    tenants = await identityClient.getTenants();
    return { Items: tenants };
  }

  const docClient = new AWS.DynamoDB.DocumentClient();
  const TableName = process.env.TENANTS_TABLE_NAME;
  if (!TableName) {
    throw new Error("Missing env var: TENANTS_TABLE_NAME");
  }

  return docClient.scan({ TableName }).promise();
};

export const getTenantCommissionsFrom = (
  tenants,
  tenantId,
  tenantLine = [],
  isConsortium = false,
  consortiumCommission = 0,
) => {
  const nextTenantLine = tenantLine;
  const next = tenants.find((t) => t.id === tenantId);
  if (!next) {
    throw new Error("Unable to fetch tenant commissions");
  }

  if (next.type === "placing-broker" && isConsortium) {
    next.commission = consortiumCommission;
  }

  next.commission = parseFloat(next.commission);
  const originalCommission = next.commission;
  next.commission -= sumBy(nextTenantLine, "commission");
  nextTenantLine.push(next);
  if (next.type === "placing-broker") {
    return {
      commissionCap: originalCommission,
      commission: sumBy(nextTenantLine, "commission"),
      commissionBreakdown: nextTenantLine.map(({ id, name, commission }) => ({ id, name, commission })).reverse(),
    };
  }

  return getTenantCommissionsFrom(tenants, next.parentId, nextTenantLine, isConsortium, consortiumCommission);
};

const getPlacingBrokerFor = (tenants, tenantId) => {
  const next = tenants.find((t) => t.id === tenantId);
  if (!next) throw new Error("Unable to find placing broker in broker hierarchy");

  if (next.type === "placing-broker") return next;

  return getPlacingBrokerFor(tenants, next.parentId);
};

export const getCommissionBreakdownFor = async (producingBrokerId, tenants, isConsortium, consortiumCommission) => {
  if (!tenants) {
    tenants = await getAll();
  }

  return getTenantCommissionsFrom(tenants.Items, producingBrokerId, [], isConsortium, consortiumCommission);
};

export const getPlacingBroker = async (tenantId) => {
  const tenants = await getAll();

  return getPlacingBrokerFor(tenants.Items, tenantId);
};

export const getBrokerData = async (tenantId) => {
  const tenants = await getAll();

  return get(tenants, "Items", []).find((tenant) => tenant.id === tenantId);
};

const getHierarchy = (tenants, tenantId, tenantLine = []) => {
  const next = tenants.find((t) => t.id === tenantId);

  if (!next) {
    throw new Error("Unable to figure out tenant hierarchy");
  }

  if (next.type === "placing-broker") {
    return tenantLine.reverse();
  }

  tenantLine.push(next);

  return getHierarchy(tenants, next.parentId, tenantLine);
};

export const getBrokerHierarchy = async (tenantId) => {
  const tenants = await getAll();

  return getHierarchy(get(tenants, "Items", []), tenantId);
};

export const getAllTenants = async () => {
  return await getAll();
};

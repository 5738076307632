import { compile } from "path-to-regexp";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import PageLoader from "../../components/PageLoader";
import { usePrivateRoute } from "../../hooks/usePrivateRoute";
import NavBar from "../NavBar";

const NotFound = () => {
  const { productRef } = useParams();
  const { isLoading } = usePrivateRoute();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <NavBar />

      <div className="text-center mt-64">
        <div className="mb-6 flex justify-center">
          <Icon name="warning" className="w-12 fill-current text-gray-700" />
        </div>

        <p className="text-2xl font-medium mb-8">404: Page not found</p>

        {productRef && (
          <>
            <p className="mb-6">Sorry, that record is not available. </p>
            <Button as={Link} to={compile(ROUTES.CONTRACTS)({ productRef })} kind="primary">
              Go back
            </Button>
          </>
        )}

        {!productRef && (
          <Button
            as={Link}
            to={compile(ROUTES.PRODUCT)({ productRef: process.env.DEFAULT_PRODUCT_REF })}
            kind="primary"
          >
            Go back
          </Button>
        )}
      </div>
    </>
  );
};

export default NotFound;

import React from "react";
import DiffBlock from "./DiffBlock";
import TooltipDatapointHistory from "./TooltipDatapointHistory";

const Datapoint = ({ allowChangesToggle, allowHistoryTooltip, data }) => (
  <div className="whitespace-pre-wrap">
    {allowHistoryTooltip && (data.currFormattedValue || data.currFormattedValue === 0) && (
      <TooltipDatapointHistory changeHistory={data.changeHistory}>{data.currFormattedValue}</TooltipDatapointHistory>
    )}

    {allowChangesToggle && <DiffBlock prev={data.prevFormattedValue} next={data.currFormattedValue} />}
  </div>
);

export default Datapoint;

import React, { FC } from "react";

interface RowProps {
  id?: string;
  title: string;
  dangerouslySetInnerHTML?: { __html: string };
}

export const Row: FC<RowProps> = ({ id, title, children, dangerouslySetInnerHTML }) => (
  <tr>
    <th scope="row" id={id}>
      {title}
    </th>
    <td aria-labelledby={id} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </td>
  </tr>
);

import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import set from "lodash.set";
import NumberFormat from "react-number-format";
import cloneDeep from "lodash.clonedeep";
import { compile } from "path-to-regexp";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Table from "../components/Table";
import {
  adjustablePremiumOptions,
  keysToLabelsMap,
  labelToKeysMap,
  getTotalIndividual,
  getTotalOverall,
} from "../../projects/mel/adjustablePremium.js";
import InputCurrency from "../components/InputCurrency";
import { currencyFormatter } from "../../formatters";
import useForm from "../hooks/useForm";
import InputNumber from "../components/schema/InputNumber";
import Form from "../components/Form";
import { useMutateContractQuery } from "../hooks/useMutateContractQuery";
import { ACTIONS, ROUTES } from "../../constants";
const { Body, Data, Row, Head, Header } = Table;

const SubmitFinalAdjustmentPremiumModal = ({ handleClose, contractData, originalData }) => {
  const originalPayrollBreakdownWhenPolicyFirstBound = get(originalData, "submission.payroll.payroll_breakdown", {});
  const originalPayrollBreakdown = get(contractData, "submission.payroll.payroll_breakdown", {});
  const adjustablePremiumOption = get(contractData, "boundQuote.submission.quote.adjustable_premium_option", "");
  const adjustablePremiumType = get(contractData, "boundQuote.submission.quote.adjustable_premium_type", "");
  const originalAdjustableRates = get(contractData, "boundQuote.submission.quote.adjustableRates", {});
  const adjustablePremiumTypes = adjustablePremiumOptions[adjustablePremiumOption];
  const adjustablePremiumConfig = adjustablePremiumTypes?.[adjustablePremiumType] || [];
  const [payrollTypes = [], overallOrIndividual] = adjustablePremiumConfig;
  const isOverall = overallOrIndividual === "overall";
  const isPeople = adjustablePremiumOption === "Minimum and deposit adjustable on people";
  const { createContract, contractId, productRef } = useMutateContractQuery({});
  const { push } = useHistory();

  const adjustableRates = Object.keys(originalAdjustableRates).reduce((splitAdjustableRates, currentRate) => {
    if (currentRate.indexOf("#") === -1) {
      return { ...splitAdjustableRates, [currentRate]: originalAdjustableRates[currentRate] };
    }
    const splitRateKeys = currentRate.split("#");
    const currentSplitRates = {};
    for (let i = 0; i < splitRateKeys.length; i++) {
      currentSplitRates[splitRateKeys[i]] = originalAdjustableRates[currentRate];
    }
    return { ...splitAdjustableRates, ...currentSplitRates };
  }, {});

  const { onSubmit, isLoading, setFormValues, formValues } = useForm({
    initialState: { ...originalPayrollBreakdown },
    onSubmit: async (payrollBreakdown) => {
      const payload = {
        submission: {
          ...get(contractData, "submission", {}),
          ...get(contractData, "boundQuote.submission", {}),
          payroll: {
            ...get(contractData, "submission.payroll", {}),
            payroll_breakdown: {
              ...get(contractData, "submission.payroll.payroll_breakdown", {}),
              ...payrollBreakdown,
            },
          },
          quote: {
            ...get(contractData, "submission.quote", {}),
            effective_from: new Date().toISOString(),
          },
        },
        isFinalAdjustmentPremium: true,
      };
      await createContract({
        productRef,
        contractId,
        data: { type: ACTIONS.CREATE_SUBMISSION, payload },
      });
      handleClose();
      push(compile(ROUTES.CONTRACT_VIEW_ENDORSEMENTS)({ contractId, productRef }), { isForced: true });
    },
  });

  const handleChange = (name) => (value) => {
    const clonedFormValues = cloneDeep(formValues);
    set(clonedFormValues, name, value.amount ?? value);
    setFormValues(clonedFormValues);
  };

  const splitPayrollTypes = Object.values(keysToLabelsMap);

  const { premiumMoney } = isOverall
    ? getTotalOverall({
        payrollBreakdown: formValues,
        formValues: {
          adjustableRates,
          adjustable_premium_option: adjustablePremiumOption,
          adjustable_premium_type: adjustablePremiumType,
        },
      })
    : getTotalIndividual({
        payrollBreakdown: formValues,
        formValues: {
          adjustableRates,
          adjustable_premium_option: adjustablePremiumOption,
          adjustable_premium_type: adjustablePremiumType,
        },
        splitPayrollTypes,
      });

  return (
    <Modal handleClose={handleClose} headingText="Submit final adjustment premium" isLoading={isLoading}>
      <div className="w-full">
        <Form onSubmit={onSubmit} id="finalAdjustmentPremiumForm">
          <Table className="mb-6">
            <Head>
              <title>Final adjustment premium</title>
              <Header>Payroll type</Header>
              <Header>Original {isPeople ? "people" : "payroll"}</Header>
              <Header>{isPeople ? "Actual people" : "Payroll amount"}</Header>
              {!isOverall && <Header>Adjustable rate</Header>}
            </Head>
            <Body>
              {splitPayrollTypes.map((payrollType) => {
                const payrollKey = labelToKeysMap?.[payrollType]?.[0];

                const originalPayroll = isPeople
                  ? originalPayrollBreakdownWhenPolicyFirstBound?.[payrollKey]?.next_year_employees ?? 0
                  : originalPayrollBreakdownWhenPolicyFirstBound?.[payrollKey]?.next_year?.amount ?? 0;

                const payrollAmount = isPeople
                  ? formValues?.[payrollKey]?.next_year_employees ?? 0
                  : formValues?.[payrollKey]?.next_year?.amount ?? 0;

                return (
                  <Row key={payrollType} className="border-b border-gray-500 last:border-0">
                    <Data className="w-2/5">{payrollType}</Data>

                    <Data className="w-1/5">
                      {isPeople ? (
                        <NumberFormat value={originalPayroll} displayType={"text"} thousandSeparator />
                      ) : (
                        currencyFormatter({
                          amount: originalPayroll,
                          currency: formValues?.[payrollKey]?.next_year?.currency,
                        })
                      )}
                    </Data>

                    <Data className="w-1/5">
                      {isPeople ? (
                        <InputNumber
                          name={`${payrollKey}.next_year_employees`}
                          value={payrollAmount}
                          onChange={handleChange(`${payrollKey}.next_year_employees`)}
                        />
                      ) : (
                        <InputCurrency
                          name={`${payrollKey}.next_year.amount`}
                          value={{
                            amount: payrollAmount,
                            currency: formValues?.[payrollKey]?.next_year?.currency,
                          }}
                          onChange={handleChange(`${payrollKey}.next_year.amount`)}
                        />
                      )}
                    </Data>

                    {!isOverall && (
                      <Data className="w-1/5">
                        {isPeople ? (
                          currencyFormatter(adjustableRates[payrollKey])
                        ) : (
                          <NumberFormat value={adjustableRates[payrollKey]} displayType={"text"} thousandSeparator />
                        )}
                      </Data>
                    )}
                  </Row>
                );
              })}

              {isOverall && (
                <Row className="border-b border-gray-500 last:border-0">
                  <Data className="w-3/5 text-right font-semibold">Overall rate</Data>
                  <Data className="w-1/5">
                    {isPeople
                      ? currencyFormatter(get(adjustableRates, "overall_people"))
                      : `${get(adjustableRates, "overall_payroll")}%`}
                  </Data>
                </Row>
              )}
              <Row className="border-b border-gray-500 last:border-0">
                <Data className="w-3/5 text-right font-semibold">New total premium</Data>
                <Data className="w-1/5">{currencyFormatter(premiumMoney)}</Data>
              </Row>
            </Body>
          </Table>
          <div className="flex p-6">
            <Button kind="primary" type="submit" className="mr-4" isDisabled={isLoading}>
              Submit final adjustment
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

SubmitFinalAdjustmentPremiumModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  contractData: PropTypes.object.isRequired,
  originalData: PropTypes.object.isRequired,
};

export default SubmitFinalAdjustmentPremiumModal;

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ajv from "../../helpers/ajv";
import { resolveRefs, resolveSchema } from "../../helpers/resolveSchema";
import Button from "../components/Button";
import Form from "../components/Form";
import Modal from "../components/Modal";
import mapChildren from "../helpers/mapChildren";
import { useContractQuery } from "../hooks";
import { useMutateContractQuery } from "../hooks/useMutateContractQuery";

const GenerateManualQuoteForm = ({ handleClose, schemaData, contractData }) => {
  const { updateContract, contractId, productRef, isUpdating } = useMutateContractQuery({});
  const [formValues, setFormValues] = useState(contractData.submission.cargo_details);

  resolveRefs(schemaData);

  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await updateContract({
      productRef,
      contractId,
      data: {
        type: "Generate manual quote",
        payload: { submission: { ...contractData.submission, cargo_details: formValues } },
      },
    });

    handleClose();
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        <div className="flex">
          <Button className="mr-4" isDisabled={!isValid || isUpdating} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const GenerateManualQuoteModal = ({ handleClose }) => {
  const { contractData, isLoading, schemaData } = useContractQuery();

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && "Generate quote"}
      isLoading={isLoading}
      clearParamsOnClose
    >
      <GenerateManualQuoteForm
        handleClose={handleClose}
        contractData={contractData}
        schemaData={{
          ...schemaData.properties.ManualQuoteForm.properties.cargo_details,
          definitions: schemaData.definitions,
        }}
      />
    </Modal>
  );
};

GenerateManualQuoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default GenerateManualQuoteModal;

import moment from "moment";
import { compile } from "path-to-regexp";
import React, { memo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { AuditEvent, ProductRef } from "../../../@types/types";
import { ROUTES } from "../../../constants";
import * as api from "../../api";
import Button from "../../components/Button";
import Card from "../../components/Card";
import H3 from "../../components/H3";
import Status from "../../components/Status";
import { downloadDocument } from "../../helpers/downloadDocument";
import { useTimeAgo } from "../../hooks";
import ContractDetails from "../ContractDetails";

const isLocalEnv = process.env.ENVIRONMENT === "local";

const getAutor = (auditEvent: AuditEvent): string => {
  if (auditEvent.type === "DOCUMENT") {
    return auditEvent.data.createdByUserFullName || "";
  }

  if (auditEvent.type === "SNAPSHOT") {
    if (auditEvent.data.next.updatedBy.isSystem) {
      return "The system";
    }

    if ("fullName" in auditEvent.data.next.updatedBy) {
      return auditEvent.data.next.updatedBy.fullName;
    }

    if ("fullName" in auditEvent.data.next.createdBy) {
      return auditEvent.data.next.createdBy.fullName;
    }
  }

  if (auditEvent.type === "RENEWAL") {
    if ("fullName" in auditEvent.data.updatedBy) {
      return auditEvent.data.updatedBy.fullName;
    }

    if ("fullName" in auditEvent.data.createdBy) {
      return auditEvent.data.createdBy.fullName;
    }
  }

  if (auditEvent.type === "MESSAGE") {
    return auditEvent.data.updatedBy.fullName || auditEvent.data.createdBy.fullName;
  }

  return "";
};

const getActivity = (auditEvent: AuditEvent, productRef: ProductRef) => {
  if (auditEvent.type === "DOCUMENT") {
    if (auditEvent.data.documentType === "message_attachment") {
      return "attached a file to a conversation";
    }

    if (auditEvent.data.documentType === "attachment") {
      return "attached a file to a policy";
    }

    return "uploaded a file";
  }

  if (auditEvent.type === "MESSAGE") {
    return "wrote a message";
  }

  if (auditEvent.type === "RENEWAL") {
    let renewal;
    if (productRef === "mc") {
      renewal = auditEvent.data;
    } else {
      const { renewals = [] } = auditEvent.data;
      renewal = renewals[renewals.length - 1];
    }

    return (
      <span>
        created a renewal quote{" "}
        <Link className="text-blue-500" to={compile(ROUTES.CONTRACT_VIEW)({ productRef, contractId: renewal.id })}>
          #{renewal.ref}
        </Link>
      </span>
    );
  }

  if (auditEvent.type === "SNAPSHOT") {
    const label = auditEvent.data.next.type === "endorsement" ? "endorsement" : "policy";

    if (!auditEvent.data.prev || auditEvent.data.next.createdAt === auditEvent.data.next.updatedAt) {
      return `created ${label} #${auditEvent.data.next.ref}`;
    }

    return `changed ${label} #${auditEvent.data.next.ref}`;
  }

  return "";
};

const getStatus = (auditEvent: AuditEvent) => {
  if (auditEvent.type === "SNAPSHOT") {
    return auditEvent.data.next.status;
  }

  return false;
};

const Item = ({
  auditEvent,
  clauseData,
  contractId,
  productRef,
  schemaData,
}: {
  auditEvent: AuditEvent;
  clauseData: any;
  contractId: string;
  productRef: ProductRef;
  schemaData: any;
}) => {
  const author = getAutor(auditEvent);
  const activity = getActivity(auditEvent, productRef);
  const status = getStatus(auditEvent);
  const timestamp = new Date(auditEvent.createdAt).getTime();
  const timeago = useTimeAgo(timestamp);
  const time = moment(timestamp).format("llll");
  const [isContractVisible, setContractVisible] = useState();
  const { mutateAsync: getAttachmentLink, ...getAttachmentLinkQuery } = useMutation(api.getAttachmentLink);

  return (
    <Card
      className="mb-8 "
      headingText={
        <div>
          <span className="text-base">{author}</span>
          <span className="text-base"> {activity} </span>
          <span className="text-base" title={time}>
            {timeago}
          </span>

          {isLocalEnv && auditEvent.type === "SNAPSHOT" && (
            <div className="flex text-gray-800 font-normal text-sm mt-2">
              <div className="mr-1">id: {auditEvent?.data?.next?.id?.split("-")?.[0]} </div>
              <div className="mr-1">&#8226; versionId: {auditEvent?.data?.next?.versionId?.split("-")?.[0]} </div>
              {auditEvent?.data?.next?.parentVersionId && (
                <div className="mr-1">
                  &#8226; parentVersionId: {auditEvent?.data?.next?.parentVersionId?.split("-")?.[0]}{" "}
                </div>
              )}
            </div>
          )}
        </div>
      }
      tag={status && <Status status={status} kind="small" />}
    >
      <>
        {auditEvent.type === "DOCUMENT" && (
          <div className="p-4">
            <div className="mb-4">{auditEvent.data.fileName}</div>
            <Button
              isDisabled={getAttachmentLinkQuery.isLoading}
              kind="secondary"
              className="h-10 px-4"
              onClick={async () => {
                const res = await getAttachmentLink({ contractId, productRef, documentId: auditEvent.data.documentId });
                const { url, fileName } = res?.data?.data;

                downloadDocument(url, fileName);
              }}
            >
              Download
            </Button>
          </div>
        )}

        {auditEvent.type === "MESSAGE" && <div className="p-4">{auditEvent.data.content}</div>}

        {auditEvent.type === "SNAPSHOT" && (
          <div className="p-4 font-medium text-base">
            <Button
              isDisabled={getAttachmentLinkQuery.isLoading}
              kind="secondary"
              className="h-10 px-4"
              onClick={() => setContractVisible(!isContractVisible)}
            >
              {!isContractVisible && "Show details"}
              {isContractVisible && "Hide details"}
            </Button>

            {isContractVisible && (
              <div className="p-4 mt-6 rounded bg-gray-100">
                <ContractDetails
                  clauseData={clauseData}
                  schemaData={schemaData}
                  contractData={auditEvent.data.next}
                  allowChangesToggle
                  snapshotData={auditEvent.data.prev ? [auditEvent.data.prev, auditEvent.data.next] : undefined}
                  isEndorsement={auditEvent.data.next.type === "endorsement"}
                />
              </div>
            )}
          </div>
        )}
      </>
    </Card>
  );
};

const Items = ({
  auditData,
  clauseData,
  contractId,
  productRef,
  schemaData,
}: {
  auditData: AuditEvent[];
  clauseData: any;
  contractId: string;
  productRef: ProductRef;
  schemaData: any;
}) => (
  <div>
    {auditData.map((item) => (
      <Item
        clauseData={clauseData}
        contractId={contractId}
        auditEvent={item}
        key={item.id}
        productRef={productRef}
        schemaData={schemaData}
      />
    ))}
  </div>
);

const areEqual = (prevProps: any, nextProps: any) =>
  JSON.stringify(prevProps.auditData.length) === JSON.stringify(nextProps.auditData.length);

const MemItems = memo(Items, areEqual);

const ContractViewAudit = ({ clauseData, schemaData }) => {
  const { contractId, productRef } = useParams<{ productRef: ProductRef; contractId: string }>();
  const auKey = ["audit", { productRef, contractId }];
  const auditQuery = useQuery(auKey, api.getAudit, {
    enabled: Boolean(contractId),
    refetchInterval: JSON.parse(process.env.CLIENT_AUDIT_INTERVAL as string),
  });
  const auditData = (auditQuery?.data?.data?.data || []) as AuditEvent[];

  return (
    <>
      <H3 className="mb-8">Audit log</H3>

      <MemItems
        auditData={auditData}
        clauseData={clauseData}
        contractId={contractId}
        productRef={productRef}
        schemaData={schemaData}
      />
    </>
  );
};

export default ContractViewAudit;

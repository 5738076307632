/* eslint-disable react/destructuring-assignment */

import get from "lodash.get";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS } from "../../../constants";
import { dateTimeFormatter } from "../../../formatters";
import { stringifyParams } from "../../../helpers/stringifyParams";
import * as api from "../../api";
import BG from "../../components/ButtonGroup";
import CardAccordion from "../../components/CardAccordion";
import Status from "../../components/Status";
import { useAuth, useModal } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import ContractDetails from "../ContractDetails";

const isLocalEnv = process.env.ENVIRONMENT === "local";

const EndorsementPanel = ({ contractData, parentData, isCurrent, schemaData, ...props }) => {
  const createdLabelText = `Created - ${dateTimeFormatter(contractData.createdAt)}`;
  const { productRef, contractId } = useParams();
  const { showModal } = useModal();
  const { location, push } = useHistory();
  const { pathname } = location;
  const endorsementId = contractData.id;
  const { checkPermissions } = useAuth(contractData);

  const { attachmentsData } = useAttachmentQuery({
    productRef,
    contractId,
    endorsementId,
    enabled: Boolean(props.isExpanded),
  });

  const attachmentsLength = get(attachmentsData, "length");
  const attachmentsNum = attachmentsLength > 0 && attachmentsLength;
  const contractStatus = get(contractData, "status");

  const handleAction = (action) => () => {
    push({ pathname, search: stringifyParams({ endorsementId }) });
    action();
  };

  const endoKey = ["endorsement", { productRef, contractId, endorsementId }];
  useQuery(endoKey, api.getEndorsement, { enabled: Boolean(isCurrent) });

  const clauseQuery = useQuery(
    ["endorsementClauses", { productRef, contractId, endorsementId }],
    api.getContractClauses,
    { enabled: Boolean(isCurrent) },
  );
  const clauseData = get(clauseQuery, "data.data.data", []);

  return (
    <CardAccordion.Panel
      headingText={
        <div>
          <div className="flex items-center">
            <div>{contractData?.ref && <>#{contractData?.ref}</>}</div>
            <Status status={contractStatus} className="ml-4" kind="small" />
          </div>

          {isLocalEnv && (
            <div className="flex text-gray-800 font-normal text-sm mt-2">
              <div className="mr-1">id: {contractData?.id?.split("-")?.[0]} </div>
              <div className="mr-1">&#8226; versionId: {contractData?.versionId?.split("-")?.[0]} </div>
              <div className="mr-1">&#8226; parentVersionId: {contractData?.parentVersionId?.split("-")?.[0]} </div>
            </div>
          )}
        </div>
      }
      label={createdLabelText}
      {...props}
    >
      <div className="p-6">
        <div className="p-4 bg-gray-100 rounded">
          {isCurrent && (
            <div className="mb-6 flex justify-end">
              <BG className="ml-4" checkPermissions={checkPermissions}>
                <BG.Button
                  action={ACTIONS.REJECT_CONTRACT_NOT_PROGRESSED}
                  iconName="cross"
                  onClick={handleAction(() => showModal(MODALS.REJECT_NOT_PROGRESSED))}
                />

                <BG.Button
                  action={ACTIONS.REJECT_CONTRACT_NOT_TAKEN_UP}
                  iconName="cross"
                  onClick={handleAction(() => showModal(MODALS.REJECT_NOT_TAKEN_UP))}
                />

                <BG.Button
                  action={ACTIONS.PROCESS_REFERRAL}
                  iconName="double-arrow"
                  onClick={handleAction(() => showModal(MODALS.PROCESS_REFERRAL))}
                />

                <BG.Button
                  action={ACTIONS.REFER_CONTRACT}
                  iconName="double-arrow"
                  onClick={handleAction(() => showModal(MODALS.REFER_CONTRACT))}
                />

                {checkPermissions(ACTIONS.VIEW_CONTRACT_ATTACHMENTS) && (
                  <BG.Button iconName="paperclip" onClick={handleAction(() => showModal(MODALS.ATTACHMENTS))}>
                    Attachments {attachmentsNum && ` (${attachmentsNum})`}
                  </BG.Button>
                )}
              </BG>
            </div>
          )}

          <ContractDetails
            allowChangesToggle
            clauseData={clauseData}
            contractData={contractData}
            defaultShowChangesOnly
            isCurrent={isCurrent}
            isEndorsement
            schemaData={schemaData}
          />
        </div>
      </div>
    </CardAccordion.Panel>
  );
};

export default EndorsementPanel;

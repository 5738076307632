import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ACTIONS, STATUSES } from "../../constants";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputFile from "../components/InputFile";
import Modal from "../components/Modal";
import Table from "../components/Table";
import { downloadDocument } from "../helpers/downloadDocument";
import { toBase64 } from "../helpers/toBase64";
import { useAuth, useContractQuery } from "../hooks";
import { useAttachmentQuery } from "../hooks/useAttachmentQuery";
import * as configCargoUs from "../../projects/cargo-us/config";
import * as configDemo from "../../projects/demo/config";
import * as configMC from "../../projects/mc/config";
import * as configMel from "../../projects/mel/config";

const columns = [
  { path: "fileName", displayName: "Document name", className: "truncate max-w-3xs" },
  { path: "documentSize", displayName: "Size" },
  { path: "download", displayName: "Action", className: "w-40" },
];

const AttachmentsForm = ({ contractData, isEditing }) => {
  const isDraft = contractData.status === STATUSES.DRAFT;
  const isBound = contractData.status === STATUSES.BOUND;
  const isReferred = contractData.status === STATUSES.REFERRED;

  const {
    isUploading,
    getAttachmentLink,
    attachmentsData,
    uploadAttachmentQuery,
    getAttachmentLinkQuery,
  } = useAttachmentQuery();

  const { updateContract, isUpdating, productRef, contractId, endorsementId } = useContractQuery();
  const [selectedDocumentId, setSelectedDocumentId] = useState();

  const [isSuccessBannerVisible, showSuccessBanner] = useState(false);

  const [formErrors, setFormErrors] = useState({ uploadAttachment: undefined });
  const [hasFileSelected, setSelectedFile] = useState(false);
  const { checkPermissions } = useAuth(contractData);

  const hasData = attachmentsData.length > 0;
  const hasError = formErrors.uploadAttachment || uploadAttachmentQuery.status === "error";

  let config;
  const defaultProductRef = process.env.DEFAULT_PRODUCT_REF;
  switch (defaultProductRef) {
    case "cargo-us":
      config = configCargoUs;
      break;
    case "demo":
      config = configDemo;
      break;
    case "mc":
      config = configMC;
      break;
    case "mel":
      config = configMel;
      break;
    default:
      throw new Error(`Missing config settings for ${defaultProductRef}`);
  }

  const handleSubmit = async (event) => {
    const file = event.target.uploadAttachment.files[0];

    if (!file) {
      return false;
    }

    const fileSizeInBase64 = (file.size + 814) * 1.37;
    const sizeThreshold = (6000000 - 814) / 1.37;
    const content = await toBase64(file);

    if (fileSizeInBase64 > sizeThreshold) {
      return setFormErrors({ uploadAttachment: "File size is too big" });
    }

    return uploadAttachmentQuery.mutate(
      {
        productRef,
        contractId,
        endorsementId,
        data: { content, fileName: file.name, documentType: "attachment" },
      },
      {
        onSuccess: () => {
          showSuccessBanner(true);

          if (!isEditing) {
            updateContract({
              productRef,
              contractId,
              endorsementId,
              data: { type: ACTIONS.UPLOAD_CONTRACT_ATTACHMENT },
            });
          }
        },
      }
    );
  };

  const handleChange = () => {
    setSelectedFile(true);

    return setFormErrors({ uploadAttachment: undefined });
  };

  const handleDownloadClick = async ({ documentId }) => {
    setSelectedDocumentId(documentId);

    const res = await getAttachmentLink({ contractId, productRef, documentId });
    const { url, fileName } = get(res, "data.data");

    downloadDocument(url, fileName);
  };

  const renderData = ({ row, column, value }) => {
    if (column.displayName === "Action") {
      const isDisabled = row.documentId === selectedDocumentId && getAttachmentLinkQuery.isLoading;

      return (
        <Button
          isDisabled={isDisabled}
          kind="secondary"
          size="small"
          className="-my-2"
          onClick={() => handleDownloadClick(row)}
        >
          Download
        </Button>
      );
    }

    return value;
  };

  return (
    <div className="p-6">
      {hasFileSelected &&
        !isDraft &&
        !isBound &&
        !isReferred &&
        config.attachments.uploadingADocumentWillTriggerAReferralMessageEnabled && (
          <Banner className="mb-8" color="red" headingText="This quote will be referred.">
            Uploading a document will trigger a referral.
          </Banner>
        )}
      {isSuccessBannerVisible && (
        <Banner
          className="mb-8"
          color="green"
          headingText="Document uploaded successfully."
          cb={() => showSuccessBanner(false)}
        />
      )}

      {checkPermissions(ACTIONS.UPLOAD_CONTRACT_ATTACHMENT) && (
        <Form onSubmit={handleSubmit} onChange={handleChange} className="mb-4">
          <div className="flex">
            <InputFile
              key={attachmentsData}
              errors={
                uploadAttachmentQuery?.error?.response?.data?.error || formErrors?.uploadAttachment
              }
              isDisabled={isUploading}
              isRequired
              labelText="Attach a file"
              name="uploadAttachment"
              className="w-4/5"
            />

            <div className="w-1/5">
              <Button
                className="ml-4 mt-8 whitespace-no-wrap "
                kind="primary"
                type="submit"
                isDisabled={isUploading || isUpdating}
              >
                Upload
              </Button>
            </div>
          </div>

          {!hasError && (
            <p className="text-sm text-gray-900 mt-2">
              Please note: the maximum file upload size is 4.3mb
            </p>
          )}
        </Form>
      )}

      {!hasData && <p className="text-center mt-6 mb-2">No documents were attached…</p>}

      {hasData && (
        <Table columns={columns} rows={attachmentsData} renderData={renderData} isStriped />
      )}
    </div>
  );
};

const AttachmentsModal = ({ handleClose }) => {
  const {
    contractData,
    endorsementData,
    isLoading,
    isEditContractRoute,
    isEditEndorsementRoute,
  } = useContractQuery();

  const isEditing = isEditContractRoute || isEditEndorsementRoute;

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && "Attachments"}
      isLoading={isLoading}
      clearParamsOnClose
    >
      <AttachmentsForm contractData={endorsementData || contractData} isEditing={isEditing} />
    </Modal>
  );
};

AttachmentsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default AttachmentsModal;

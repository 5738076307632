import get from "lodash.get";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import React from "react";
import { ACTIONS, MODALS, QUOTE_STATUSES, QUOTE_TYPES, STATUSES } from "../../../constants";
import BG from "../../components/ButtonGroup";
import H3 from "../../components/H3";
import Toggle from "../../components/Toggle";
import { useModal, useStickyState } from "../../hooks";
import { DevQuoteCard } from "./DevQuoteCard";
import QuoteCard from "./QuoteCard";

const selectRejected = (quote) => quote.status === QUOTE_STATUSES.REJECTED_NOT_TAKEN_UP;
const selectArchived = (quote) => quote.status === QUOTE_STATUSES.ARCHIVED;
const selectSubmitted = (quote) => quote.status === QUOTE_STATUSES.SUBMITTED;
const selectBound = (quote) => quote.status === QUOTE_STATUSES.BOUND;
const selectCancelled = (quote) => quote.status === QUOTE_STATUSES.CANCELLED;
const selectDeclined = (quote) => quote.status === QUOTE_STATUSES.DECLINED;
const selectNotCommercial = (quote) => quote.type !== QUOTE_TYPES.COMMERCIAL;

const isInternalEnv =
  process.env.ENVIRONMENT === "local" ||
  process.env.ENVIRONMENT === "development" ||
  process.env.ENVIRONMENT === "staging";

const Quotes = ({ contractData, isCurrent, isEndorsement, checkPermissions, isProducingBroker, isBroker, schemaData }) => {
  const isPostBind =
    contractData.status === STATUSES.INFORCE ||
    contractData.status === STATUSES.BOUND ||
    contractData.status === STATUSES.CANCELLED ||
    contractData.status === STATUSES.APPLIED;
  let quotesData = get(contractData, "quotes", []);

  if (process.env.DEFAULT_PRODUCT_REF === "cargo-us" && isBroker && contractData.status === STATUSES.REFERRED) {
    quotesData = [];
  }

  let archivedQuotesList = quotesData.filter(selectArchived);
  const submittedQuotes = quotesData.filter(selectSubmitted);
  const sortedSubmittedQuotes = sortBy(submittedQuotes, (q) => q.rates?.deductible);
  const groupedArchivedQuotes = groupBy(archivedQuotesList, (q) => q.type);
  const rejectedQuotes = sortBy(quotesData.filter(selectRejected), (q) => q.rates?.deductible);
  const boundQuotesList = quotesData.filter(selectBound);
  const cancelledQuotes = quotesData.filter(selectCancelled);
  let declinedQuotes = quotesData.filter(selectDeclined);
  let commercialArchivedQuotes = groupedArchivedQuotes[QUOTE_TYPES.COMMERCIAL] || [];
  let otherArchivedQuotes = archivedQuotesList.filter(selectNotCommercial);
  const { showModal } = useModal();
  const [showDebugger, setShowDebugger] = useStickyState(false, "quotesDebugger");
  const QuoteCardComponent = showDebugger && isInternalEnv ? DevQuoteCard : QuoteCard;

  if (process.env.DEFAULT_PRODUCT_REF === "cargo-us" && isBroker) {
    archivedQuotesList = [];
    commercialArchivedQuotes = [];
    otherArchivedQuotes = [];
    declinedQuotes = [];
  }

  if (process.env.DEFAULT_PRODUCT_REF === "mc") {
    archivedQuotesList = [];
    commercialArchivedQuotes = [];
    otherArchivedQuotes = [];
  }

  return (
    <>
      {isPostBind && !isEndorsement && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          heading={
            <div className="mb-4">
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center">
                  <H3>Quote(s)</H3>
                </div>
              </div>
              {isInternalEnv && (
                <Toggle
                  className="pl-6 pb-2"
                  onClick={() => setShowDebugger(!showDebugger)}
                  value={showDebugger}
                  labelText="Debug view"
                />
              )}
            </div>
          }
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={[contractData.originalQuote]}
        />
      )}

      {isPostBind && isEndorsement && boundQuotesList.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          heading={
            <div className="mb-4">
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center">
                  <H3>Quote(s)</H3>
                </div>
              </div>

              {isInternalEnv && (
                <Toggle
                  className="pl-6 pb-2"
                  onClick={() => setShowDebugger(!showDebugger)}
                  value={showDebugger}
                  labelText="Debug view"
                />
              )}
            </div>
          }
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={boundQuotesList}
        />
      )}

      {sortedSubmittedQuotes.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          heading={
            <div className="mb-4">
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center">
                  <H3>Quote(s)</H3>
                </div>
                <div>
                  <BG checkPermissions={checkPermissions}>
                    {process.env.DEFAULT_PRODUCT_REF !== "demo" && !isProducingBroker && isCurrent && !isEndorsement && (
                      <BG.Button
                        action={ACTIONS.UPDATE_CONTRACT_COMMISSION}
                        iconName="create"
                        onClick={() => showModal(MODALS.CHANGE_COMMISSION)}
                      />
                    )}

                    {process.env.DEFAULT_PRODUCT_REF === "cargo-us" &&
                      isCurrent &&
                      !isEndorsement &&
                      checkPermissions(ACTIONS.GENERATE_MANUAL_QUOTE) && (
                        <BG.Button
                          kind="inverted"
                          isDisabled={submittedQuotes.length >= 3}
                          onClick={() => showModal(MODALS.GENERATE_MANUAL_QUOTE)}
                        >
                          Create additional quote
                        </BG.Button>
                      )}
                  </BG>
                </div>
              </div>
              {isInternalEnv && (
                <Toggle
                  className="pl-6 pb-2"
                  onClick={() => setShowDebugger(!showDebugger)}
                  value={showDebugger}
                  labelText="Debug view"
                />
              )}
            </div>
          }
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={sortedSubmittedQuotes}
        />
      )}

      {cancelledQuotes.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          headingText="Cancelled quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={cancelledQuotes}
        />
      )}

      {rejectedQuotes.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          headingText="Rejected quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={rejectedQuotes}
        />
      )}

      {declinedQuotes.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          headingText="Declined quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={declinedQuotes}
        />
      )}

      {commercialArchivedQuotes.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          headingText="Archived underwriter quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={commercialArchivedQuotes}
        />
      )}

      {otherArchivedQuotes.length > 0 && (
        <QuoteCardComponent
          schemaData={schemaData}
          contractData={contractData}
          headingText="Archived quote(s)"
          isCurrent={isCurrent}
          isEndorsement={isEndorsement}
          quotesData={otherArchivedQuotes}
        />
      )}
    </>
  );
};

export default Quotes;

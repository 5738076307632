export default [
  ["Own Domicile", 50, 75, 100, 125, 150],
  ["Western Europe", 65, 80, 90, 100, 115],
  ["USA & Canada", 65, 80, 90, 100, 115],
  ["Eastern Europe & Russia West of Urals", 85, 110, 130, 145, 165],
  ["Middle East", 85, 110, 130, 145, 165],
  ["Africa", 115, 135, 160, 185, 210],
  ["South & Central America", 100, 130, 150, 165, 185],
  ["Asia & Pacific including Australasia", 85, 110, 130, 145, 165],
  ["Caribbean", 100, 130, 150, 165, 185],
  ["Former USSR and Russia East of Urals", 100, 130, 150, 165, 185],
  ["India and sub continent", 115, 135, 160, 185, 210],
] as const;

import React, { FC } from "react";

export const Annual_Security_and_customer_complaints: FC = () => {
  return (
    <section data-testid="security2generalInfo">
      <h1>GENERAL INFORMATION AND NOTICES</h1>

      <p>
        Please read this Policy carefully to ensure that it is in accordance with your requirements and that you
        understand its terms, exclusions and conditions. Contact Underwriters immediately if any corrections are
        necessary.
      </p>

      <p>
        Any enquiries you may have regarding your Policy may be addressed either to the insurance broker who arranged
        the Policy for you or directly to us.
      </p>

      <p>
        Security is provided by Chaucer Insurance Company DAC , the registered office is:
        <br />
        Chaucer Insurance Company DAC
        <br />
        38 & 39 Baggot Street Lower
        <br />
        Dublin
        <br />
        D02 T938
        <br />
        Ireland
        <br />
        T+353 1567 5580
        <br />
      </p>

      <p>
        Web: www.chaucergroup.com
        <br />
        Regulated by the CBI in Ireland
        <br />
      </p>

      <p>CHAUCER INSURANCE COMPANY DAC PRIVACY POLICY STATEMENT:</p>

      <p>
        You have rights in relation to the information we hold about you, including the right to access your
        information. If you wish to exercise your rights, discuss how we use your information or request a copy of our
        full privacy notice(s), please contact us, or the agent or broker that arranged your insurance who will provide
        you with our contact details.
        <br />
        Chaucer Insurance Company Data Protection Notice
      </p>

      <p>
        Who we are:
        <br />
        We are Chaucer Insurance Company DAC Your Insurer as named in Your Schedule. Our registered office is 38 & 39
        Baggot Street Lower, Dublin DO2 T938, Ireland.
      </p>

      <p>
        We provide insurance services to businesses and individuals. The services are provided indirectly through
        managing general agents, brokers and intermediaries.
        <br />
        We use the words Personal Data to describe information about you, and from which you are identifiable.
        <br />
        For the purpose of the General Data Protection Regulation (the GDPR) we are a data controller.
        <br />
        Please note that any information provided to us will be processed by us, our underwriters and our agents in
        compliance with the provisions of Data Protection legislation for the purposes of providing insurance and
        handling claims, if any, which may necessitate providing information to third parties.
        <br />
        We respect your rights in respect of the data we hold on you. We will act without unnecessary delay in dealing
        with your data access requests in respect of the personal data we hold on you.
      </p>

      <p>You have the right to access, erasure, rectification, restriction, portability and objection.</p>

      <p>What Personal Information do we collect from you?</p>

      <p>
        You may give us Personal Data by corresponding with us or through our managing general agents, claims handling
        service providers, other intermediaries, brokers or agents, by phone, e-mail or otherwise. We ask you to
        disclose only as much information as is necessary to provide our products or services or to submit a
        question/suggestion/comment in relation to our website.
      </p>

      <p>What information about you do we obtain from others?</p>

      <p>
        We obtain the information you provide through our managing general agents, claims handling service providers,
        brokers and/or intermediaries.
      </p>

      <p>
        Chaucer Insurance Full Privacy Notice
        <br />
        Our full privacy notice explains in more detail the types of information we hold, how it is used, who we share
        it with and how long it is kept. It also informs you in more detail of the rights you have regarding Your
        Personal data.
      </p>

      <p>
        <b>INSURANCE ACT 2015</b>
        <br />
        In respect of any;
      </p>

      <p>
        a) duty of disclosure
        <br />
        b) effect of warranties
        <br />
        c) effects of acts of fraud
        <br />
      </p>

      <p>
        the rights and obligations applying to the Assured and Underwriters shall be interpreted in accordance with the
        provisions of the Insurance Act 2015
      </p>

      <p>
        <b>AUTHORISATION</b>
        <br />
        The Assured agree that Underwriters may give to and obtain from any other Underwriters, insurance reference
        bureaus and Credit Reporting Agencies:
        <br />
        Insurance claims information and Information relating to the Assured ’s Credit or insurance history.
      </p>

      <p>
        POLICYHOLDER DISCLOSURE
        <br />
        NOTICE OF TERRORISM
        <br />
        INSURANCE COVERAGE
        <br />
        Coverage for acts of terrorism is already included in the policy (including any quotation for insurance) to
        which this notice applies. You should know that, under the policy, any losses caused by certified acts of
        terrorism would be partially reimbursed by the United States under a formula established by federal law. Under
        this formula, the United States pays 85% through 2015; 84% beginning on January 1, 2016; 83% beginning on
        January 1, 2017; 82% beginning on January 1, 2018; 81% beginning on January 1, 2019 and 80% beginning on January
        1, 2020; of covered terrorism losses exceeding the statutorily established deductible paid by the insurer
        providing the coverage. However, your policy may contain other exclusions which might affect your coverage, such
        as exclusion for nuclear events. The term “act of terrorism” means any act that is certified by the Secretary of
        the Treasury, in consultation with the Secretary of Homeland Security and the Attorney General of the United
        States, to be an act of terrorism; to be a violent act or an act that is dangerous to human life, property, or
        infrastructure; to have resulted in damage within the United States, or outside the United States in the case of
        an air carrier or vessel or the premises of a United States mission; and to have been committed by an individual
        or individuals, as part of an effort to coerce the civilian population of the United States or to influence the
        policy or affect the conduct of the United States Government by coercion. The Terrorism Risk Insurance Act, as
        amended, contains a $100 billion cap that limits U.S. Government reimbursement as well as insurers’ liability
        for losses resulting from certified acts of terrorism when the amount of such losses exceeds $100 billion in any
        one calendar year. If the aggregate insured losses for all insurers exceed $100 billion, your coverage may be
        reduced.
      </p>

      <p>
        The portion of your annual premium that is attributable to coverage for certified acts of terrorism as defined
        in the Terrorism Risk Insurance Act of 2002, as amended, is: … at a rate of 0.01% on the sum insured.
      </p>

      <p>
        I ACKNOWLEDGE THAT I HAVE BEEN NOTIFIED THAT UNDER THE TERRORISM RISK INSURANCE ACT OF 2002, AS AMENDED, ANY
        LOSSES CAUSED BY CERTIFIED ACTS OF TERRORISM UNDER MY POLICY COVERAGE WILL BE PARTIALLY REIMBURSED BY THE UNITED
        STATES, SUBJECT TO A $100 BILLION CAP THAT MAY REDUCE MY COVERAGE, AND I HAVE BEEN NOTIFIED OF THE AMOUNT OF MY
        PREMIUM ATTRIBUTABLE TO SUCH COVERAGE.
        <br />
        12 January 2015
        <br />
        LMA9105
        <br />
      </p>

      <p>
        Note: By binding this policy you agree to the acknowledgement within the Policyholder Disclosure Notice of
        Terrorism.
      </p>

      <p>
        <b>CUSTOMER COMPLAINTS PROCEDURE </b>
      </p>

      <p>
        We are dedicated to providing a high-quality service and we want to ensure that we maintain this at all times.
        If you have any questions or concerns about the policy or the handling of a claim please contact your broker
        through whom this policy was arranged.
      </p>

      <p>
        If you wish to make a complaint in respect of your policy, you can do so at any time by referring the matter in
        the first instance to the broker through whom this policy was arranged. If they are unable to resolve your
        complaint, it will be escalated to our Complaints Manager
      </p>

      <p>
        If you wish to make a complaint in respect of a claim, you can do so at any time by referring the matter to the
        appointed claim handler that has been allocated to your claim. Your broker will advise you of their contact
        details. If they are unable to resolve your complaint, it will be escalated to our Complaints Manager
      </p>

      <p>
        Your State Department of Insurance should be contacted only after discussions with the insurer, its agent, or
        representative, have failed to produce a satisfactory resolution.
      </p>

      <p>
        Alternatively, if you are classified as a “micro” or “small commercial” customer, as your insurer is based in
        Ireland, and if you remain dissatisfied after we have considered your complaint, or you have not received a
        decision by the time we have taken 40 working days to consider your complaint, you may be eligible to refer your
        complaint to the Financial Services and Pensions Ombudsman (FSPO), an independent body that adjudicates on
        complaints, at the following address: Financial Services and Pensions Ombudsman 3rd Floor, Lincoln House Lincoln
        Place Dublin 2 Ireland Tel: +353 1 567 7000 Email: info@fspo.ie www.fspo.ie
      </p>

      <p>The complaints handling arrangements above are without prejudice to your rights in law.</p>
    </section>
  );
};

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const DescriptionText = ({ children, className, ...props }) => (
  <p className={clsx("mt-2 text-sm text-gray-900", className)} {...props}>
    {children}
  </p>
);

DescriptionText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DescriptionText.defaultProps = {
  children: undefined,
  className: undefined,
};

export default DescriptionText;

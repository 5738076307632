import React from "react";
import InputCheckbox from "../InputCheckbox";
import InputField from "../InputField";

const InputCheckboxGroup = ({
  className,
  descriptionText,
  errors,
  id,
  isRequired,
  isReadOnly,
  labelText,
  name,
  onChange,
  value,
  item,
}) => {
  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
    >
      {item?.items?.enum?.map((option) => (
        <InputCheckbox
          className="my-6"
          errors={errors}
          id={[name, option].join(".")}
          isChecked={(value || []).includes(option)}
          isRequired={isRequired}
          key={option.value || option}
          labelText={option.labelText || option}
          name={name}
          onChange={(e) => {
            const defaultValue = Array.isArray(value) ? value : [];
            const nextValue = defaultValue.includes(e.target.value)
              ? defaultValue.filter((i) => i !== e.target.value)
              : [...defaultValue, e.target.value];

            return onChange(nextValue, name);
          }}
          value={option.value || option}
          isDisabled={isReadOnly}
          readOnly={isReadOnly}
        />
      ))}
    </InputField>
  );
};

export default InputCheckboxGroup;

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ACTIONS } from "../../constants";
import ajv from "../../helpers/ajv";
import { resolveSchema } from "../../helpers/resolveSchema";
import { calculateProRataFactor } from "../../projects/cargo-us/rater/cancellations";
import Button from "../components/Button";
import Form from "../components/Form";
import Modal from "../components/Modal";
import mapChildren from "../helpers/mapChildren";
import { useContractQuery } from "../hooks";
import { useMutateContractQuery } from "../hooks/useMutateContractQuery";

const CancelEndorsementFormCargoUs = ({ handleClose, schemaData, contractData, setIsCreating }) => {
  const { createContract, contractId, productRef, isCreating } = useMutateContractQuery();
  useEffect(() => {
    setIsCreating(isCreating);
  }, [isCreating]);

  const initialFormValues = {
    cancellation: {
      cancellation_date: undefined,
    },
    transit: {
      refund_transit_premium: {
        amount: undefined,
        currency: "USD",
      },
      refund_transit_premium_tax: {
        amount: undefined,
        currency: "USD",
      },
    },
    storage: {
      refund_storage_premium: {
        amount: undefined,
        currency: "USD",
      },
      refund_storage_premium_tax: {
        amount: undefined,
        currency: "USD",
      },
      ...contractData?.submission?.storage,
    },
    exhibitions: {
      refund_exhibitions_premium: {
        amount: undefined,
        currency: "USD",
      },
      refund_exhibitions_premium_tax: {
        amount: undefined,
        currency: "USD",
      },
      ...contractData?.submission?.exhibitions,
    },
    samples: {
      refund_samples_premium: {
        amount: undefined,
        currency: "USD",
      },
      refund_samples_premium_tax: {
        amount: undefined,
        currency: "USD",
      },
      ...contractData?.submission?.samples,
    },
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  useEffect(() => {
    if (formValues.cancellation?.cancellation_date) {
      const inceptionDateIso = contractData.submission.contract.inception_date;
      const expiryDateIso = contractData.submission.contract.expiry_date;
      const cancellationDateIso = formValues?.cancellation?.cancellation_date;

      const proRataFactor = cancellationDateIso !== undefined ? calculateProRataFactor(inceptionDateIso, expiryDateIso, cancellationDateIso) : 0;

      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "transit.refund_transit_premium.amount", contractData?.boundQuote?.rates?.basePremium?.netPremium * proRataFactor);
      set(nextFormValues, "storage.refund_storage_premium.amount", contractData?.boundQuote?.rates?.storagePremium?.netPremium * proRataFactor);
      set(nextFormValues, "exhibitions.refund_exhibitions_premium.amount", contractData?.boundQuote?.rates?.exhibitionsPremium?.netPremium * proRataFactor);
      set(nextFormValues, "samples.refund_samples_premium.amount", contractData?.boundQuote?.rates?.samplesPremium?.netPremium * proRataFactor);

      setFormValues(nextFormValues);
    }
  }, [formValues.cancellation?.cancellation_date]);

  useEffect(() => {
    const initialTaxValue = contractData?.boundQuote?.rates?.basePremium?.tax;
    const taxValue = formValues?.transit?.refund_transit_premium_tax?.amount;

    if (initialTaxValue !== undefined && taxValue !== undefined && taxValue > initialTaxValue) {
      const clonedFormValues = cloneDeep(formValues);
      set(clonedFormValues, `transit.refund_transit_premium_tax.amount`, initialTaxValue);
      setFormValues(clonedFormValues);
    }
  }, [formValues?.transit?.refund_transit_premium_tax]);

  useEffect(() => {
    const initialTaxValue = contractData?.boundQuote?.rates?.storagePremium?.tax;
    const taxValue = formValues?.storage?.refund_storage_premium_tax?.amount;

    if (initialTaxValue !== undefined && taxValue !== undefined && taxValue > initialTaxValue) {
      const clonedFormValues = cloneDeep(formValues);
      set(clonedFormValues, `storage.refund_storage_premium_tax.amount`, initialTaxValue);
      setFormValues(clonedFormValues);
    }
  }, [formValues?.storage?.refund_storage_premium_tax]);

  useEffect(() => {
    const initialTaxValue = contractData?.boundQuote?.rates?.exhibitionsPremium?.tax;
    const taxValue = formValues?.exhibitions?.refund_exhibitions_premium_tax?.amount;

    if (initialTaxValue !== undefined && taxValue !== undefined && taxValue > initialTaxValue) {
      const clonedFormValues = cloneDeep(formValues);
      set(clonedFormValues, `exhibitions.refund_exhibitions_premium_tax.amount`, initialTaxValue);
      setFormValues(clonedFormValues);
    }
  }, [formValues?.exhibitions?.refund_exhibitions_premium_tax]);

  useEffect(() => {
    const initialTaxValue = contractData?.boundQuote?.rates?.samplesPremium?.tax;
    const taxValue = formValues?.samples?.refund_samples_premium_tax?.amount;

    if (initialTaxValue !== undefined && taxValue !== undefined && taxValue > initialTaxValue) {
      const clonedFormValues = cloneDeep(formValues);
      set(clonedFormValues, `samples.refund_samples_premium_tax.amount`, initialTaxValue);
      setFormValues(clonedFormValues);
    }
  }, [formValues?.samples?.refund_samples_premium_tax]);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;
      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;
      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await createContract({
      productRef,
      contractId,
      data: { type: ACTIONS.CANCEL_ENDORSEMENT, payload: formValues },
    });

    handleClose();
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
          ctx: { contractData },
        })}

        <div className="flex">
          <Button className="mr-4" isDisabled={!isValid || isCreating} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const CancelEndorsementModal_cargo = ({ handleClose }) => {
  const { contractData, isLoading, schemaData } = useContractQuery();
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && `Cancel policy - #${contractData.ref}`}
      isLoading={isLoading || isCreating}
    >
      <CancelEndorsementFormCargoUs
        contractData={contractData}
        handleClose={handleClose}
        schemaData={schemaData.properties.CancelEndorsementForm}
        setIsCreating={setIsCreating}
      />
    </Modal>
  );
};

CancelEndorsementModal_cargo.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CancelEndorsementModal_cargo;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useStickyState } from "../hooks";
import Card from "./Card";
import Icon from "./Icon";

const Panel = ({ children, isExpanded, tag, headingText, label, onClick, isCollapsible }) => (
  <div className="shadow rounded mb-4 bg-white hover:shadow-md transition ease-in-out">
    <div onClick={onClick} className={clsx("p-4 rounded", { "cursor-pointer": isCollapsible })}>
      <div className="flex  justify-between">
        <Card.Heading tag={tag}>{headingText}</Card.Heading>

        <div className="flex items-center">
          <div>{label}</div>

          {isCollapsible && (
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              className="mx-4 fill-current text-blue-600"
            />
          )}
        </div>
      </div>
    </div>

    {isExpanded && <div className="">{children}</div>}
  </div>
);

const CardAccordion = ({
  children,
  className,
  expandAll,
  id,
  initialActiveIds,
  isCollapsible,
  isSticky,
}) => {
  const allIds = React.Children.toArray(children).map(
    (child) => child.props.id || child.props.headingText
  );
  const initialIds = expandAll ? allIds : initialActiveIds;

  const stateHook = isSticky ? useStickyState : useState;
  const [selectedIds, setSelectedIds] = stateHook(initialIds, id);

  const handleItemClick = (itemId, expanded) => (event) => {
    const nextIds = expanded
      ? selectedIds.filter((item) => item !== itemId)
      : [...selectedIds, itemId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  return (
    <div className={clsx(className)}>
      {React.Children.map(children, (child) => {
        const itemId = child.props.id || child.props.headingText;
        const isExpanded = isCollapsible ? selectedIds.includes(itemId) : true;

        return React.cloneElement(child, {
          isCollapsible,
          isExpanded,
          onClick: handleItemClick(itemId, isExpanded),
        });
      })}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  headingText: PropTypes.string,
  isExpanded: PropTypes.bool,
  label: PropTypes.node,
  onClick: PropTypes.func,
  tag: PropTypes.node,
};

Panel.defaultProps = {
  children: undefined,
  headingText: undefined,
  isExpanded: false,
  label: undefined,
  onClick: undefined,
  tag: undefined,
};

CardAccordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  expandAll: PropTypes.bool,
  id: PropTypes.string,
  initialActiveIds: PropTypes.arrayOf(PropTypes.string),
  isCollapsible: PropTypes.bool,
};

CardAccordion.defaultProps = {
  children: undefined,
  className: undefined,
  expandAll: true,
  id: undefined,
  initialActiveIds: [],
  isCollapsible: true,
};

CardAccordion.Panel = Panel;

export default CardAccordion;

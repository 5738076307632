import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as api from "../api";
import Button from "../components/Button";
import Form from "../components/Form";
import InputText from "../components/InputText";
import InputTextarea from "../components/InputTextarea";
import Modal from "../components/Modal";

const NewSchemaModal = ({ handleClose }) => {
  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState({});
  const { mutateAsync: createSchema, ...createSchemaQuery } = useMutation(api.createSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries("schemas");
    },
  });

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await createSchema({ data: formValues });

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Create schema">
      <Form onSubmit={handleSubmit} className="p-6">
        <InputText
          labelText="Schema name"
          name="name"
          value={formValues.name}
          isRequired
          className="mb-8"
          onChange={handleChange}
        />

        <InputTextarea
          labelText="Schema"
          name="payload"
          value={formValues.payload}
          isRequired
          className="mb-8"
          onChange={handleChange}
        />

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={!(formValues.name && formValues.payload) || createSchemaQuery.isLoading}
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </Modal>
  );
};

NewSchemaModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default NewSchemaModal;

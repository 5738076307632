import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const CreateEndoWarningModal = ({ handleClose, handleConfirm, isBespoke }) => (
  <Modal handleClose={handleClose} headingText="Are you sure?">
    <div className="p-6 w-160">
      <p className="mb-4">
        Are you sure you’d like to create {isBespoke ? "a bespoke endorsement" : "an endorsement"}?
      </p>
      <p className="mb-8">
        We will redirect you to the Submission page - please amend the relevant fields to reflect
        the change to the policy you require.
      </p>

      <div className="flex">
        <Button kind="primary" onClick={handleConfirm} className="mr-4">
          Create endorsement
        </Button>

        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </div>
  </Modal>
);

CreateEndoWarningModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default CreateEndoWarningModal;

import get from "lodash.get";
import { compile } from "path-to-regexp";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES } from "../../../constants";
import * as api from "../../api";
import Button from "../../components/Button";
import H2 from "../../components/H2";
import PageLoader from "../../components/PageLoader";
import { useAuth } from "../../hooks";
import useModal, { ModalSwitch } from "../../hooks/useModal";
import { usePrivateRoute } from "../../hooks/usePrivateRoute";
import { useProductCheck } from "../../hooks/useProductCheck";
import NavBar from "../NavBar";

const Dashboard = () => {
  const { checkPermissions, isLocalEnv } = useAuth();
  const productsQuery = useQuery(["products"], api.getProducts);
  const productsData = get(productsQuery, "data.data.data", []);
  const { showModal } = useModal();

  const showCreateProductModal = () => showModal(MODALS.CREATE_PRODUCT);

  const showCreateSchemaModal = () => showModal(MODALS.CREATE_SCHEMA);

  if (productsQuery.isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalSwitch />

      <div className="pt-16 mt-1.5">
        <NavBar />

        <div className="flex justify-between items-center py-6 px-8 mb-4  bg-white shadow">
          <H2>Dashboard</H2>

          <div className="flex">
            {(isLocalEnv || checkPermissions(ACTIONS.CREATE_SCHEMA)) && (
              <Button kind="primary" aria-label="New schema" onClick={showCreateSchemaModal}>
                New schema
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="p-8 pb-64">
        <div className="container pt-20 mx-auto">
          <div className="flex flex-wrap -mx-4">
            {productsData.map((productData) => (
              <Link
                key={productData.id}
                to={compile(ROUTES.PRODUCT)({ productRef: productData.ref })}
              >
                <div className="w-64  m-4 cursor-pointer">
                  <div className="w-full h-64 flex-1 mb-4 text-xl font-semibold p-6 shadow rounded text-gray-900 bg-white hover:shadow-md hover:text-gray-700 transition earse-in-out">
                    {productData.name}
                  </div>
                </div>
              </Link>
            ))}

            {(isLocalEnv || checkPermissions(ACTIONS.CREATE_PRODUCT)) && (
              <div
                className="border-2 border-dashed border-gray-500 text-gray-700 cursor-pointer flex h-64 items-center justify-center m-4 rounded text-base font-semibold w-64 focus:outline-none hover:text-gray-900 hover:bg-gray-300 transition"
                onClick={showCreateProductModal}
                onKeyPress={showCreateProductModal}
                tabIndex={0}
                role="button"
              >
                Create new product
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardPage = () => {
  const { isLoading } = usePrivateRoute();

  useProductCheck();

  if (isLoading) {
    return <PageLoader />;
  }

  return <Dashboard />;
};

export default DashboardPage;

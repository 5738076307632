import get from "lodash.get";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";

export const useAttachmentQuery = (args = {}) => {
  const queryClient = useQueryClient();

  const { location } = useHistory();
  const params = useParams();
  const queryParams = parseParams(location.search);

  const productRef = args.productRef || params.productRef;
  const contractId = args.contractId || params.contractId;
  const endorsementId = args.endorsementId || queryParams.endorsementId || params.endorsementId;

  const atKey = ["attachments", { productRef, contractId, endorsementId }];
  const attachmentsQuery = useQuery(atKey, api.getAttachments, {
    enabled: Boolean(args.enabled !== undefined ? args.enabled : contractId),
  });
  const attachmentsData = get(attachmentsQuery, "data.data.data", []);

  const { mutateAsync: uploadAttachment, ...uploadAttachmentQuery } = useMutation(
    api.uploadAttachment,
    {
      onSuccess: () =>
        queryClient.invalidateQueries("attachments", { productRef, contractId, endorsementId }),
    }
  );

  const { mutateAsync: applyEndoAttachment, ...applyEndoAttachmentQuery } = useMutation(
    api.applyEndoAttachments,
    {
      onSuccess: () =>
        queryClient.invalidateQueries("attachments", { productRef, contractId, endorsementId }),
    }
  );

  const { mutateAsync: getAttachmentLink, ...getAttachmentLinkQuery } = useMutation(
    api.getAttachmentLink
  );
  const { mutateAsync: getDocumentsLink, ...getDocumentsLinkQuery } = useMutation(api.getDocuments);

  const isLoading =
    getAttachmentLinkQuery.isLoading ||
    applyEndoAttachmentQuery.isLoading ||
    getDocumentsLinkQuery.isLoading;

  const isUploading = uploadAttachmentQuery.isLoading;

  return {
    applyEndoAttachment,
    attachmentsData,
    contractId,
    endorsementId,
    getAttachmentLink,
    getAttachmentLinkQuery,
    getDocumentsLink,
    getDocumentsLinkQuery,
    isLoading,
    isUploading,
    productRef,
    uploadAttachment,
    uploadAttachmentQuery,
  };
};

import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MODALS } from "../../constants";
import useModal from "./useModal";

export const useHistoryBlock = ({ isDirty }) => {
  const { block, push, location } = useHistory();
  const { showModal, closeModal } = useModal();
  const unblock = useRef();

  useEffect(() => {
    unblock.current = block((nextLocation) => {
      const { state, pathname } = nextLocation;
      const isForced = state?.isForced;
      const hasLocationChanged = pathname !== location.pathname;

      if (hasLocationChanged && !isForced && isDirty) {
        showModal(MODALS.UNSAVED_CHANGES, {
          handleConfirm: () => {
            closeModal();
            unblock.current();
            push(pathname);
          },
          handleClose: closeModal,
        });

        return false;
      }

      return true;
    });

    return () => unblock.current();
  }, [isDirty, block, push, showModal, closeModal, location]);
};

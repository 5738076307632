import get from "lodash.get";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../api";

export const useDocumentsQuery = ({ contractId, endorsementId, predicate, showEndorsementDocuments }) => {
  const { productRef } = useParams();
  const docsKey = ["documents", { productRef, contractId, endorsementId }];
  const documentsQuery = useQuery(
    docsKey,
    () => api.getDocuments({ productRef, contractId, endorsementId, predicate, showEndorsementDocuments }),
    { enabled: Boolean(contractId), retry: 6 },
  );
  const documentsData = get(documentsQuery, "data", []);
  const { mutateAsync: getDocumentsLink, ...getDocumentsLinkQuery } = useMutation(api.getDocuments);
  const isLoading = documentsQuery.isLoading || getDocumentsLinkQuery.isLoading;
  const isError = documentsQuery.isError || getDocumentsLinkQuery.isError;
  const hasData = documentsData.length > 0;

  return {
    contractId,
    documentsData,
    documentsQuery,
    getDocumentsLink,
    getDocumentsLinkQuery,
    hasData,
    isError,
    isLoading,
    productRef,
  };
};

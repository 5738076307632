import get from "lodash.get";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";

export const useClauseQuery = () => {
  const queryClient = useQueryClient();

  const { location } = useHistory();
  const params = useParams();
  const queryParams = parseParams(location.search);
  const { productRef, contractId } = params;
  const endorsementId = queryParams.endorsementId || params.endorsementId;

  const clsKey = ["clauses", { productRef }];
  const clauseQuery = useQuery(clsKey, api.getClauses);
  const clauseData = get(clauseQuery, "data.data.data", []);

  const cclsKey = ["contractClauses", { productRef, contractId }];
  const contractClauseQuery = useQuery(cclsKey, api.getContractClauses, {
    enabled: Boolean(contractId),
  });
  const contractClauseData = get(contractClauseQuery, "data.data.data", []);

  const { mutateAsync: createClause, ...createClauseQuery } = useMutation(api.createClause);

  const { mutateAsync: createContractClause, ...createContractClauseQuery } = useMutation(
    api.createContractClause,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractClauses");
        queryClient.invalidateQueries("endorsementClauses");
      },
    }
  );

  const { mutateAsync: updateContractClause, ...updateContractClauseQuery } = useMutation(
    api.updateContractClause,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractClauses");
        queryClient.invalidateQueries("endorsementClauses");
      },
    }
  );

  const { mutateAsync: deleteContractClause, ...deleteContractClauseQuery } = useMutation(
    api.deleteContractClause,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractClauses");
        queryClient.invalidateQueries("endorsementClauses");
      },
    }
  );

  const isLoading = clauseQuery.isLoading || contractClauseQuery.isLoading;
  const isCreating = createClauseQuery.isLoading || createContractClauseQuery.isLoading;
  const isUpdating = updateContractClauseQuery.isLoading;
  const isDeleting = deleteContractClauseQuery.isLoading;

  return {
    clauseData,
    contractClauseData,
    contractId,
    createClause,
    createContractClause,
    deleteContractClause,
    endorsementId,
    isCreating,
    isDeleting,
    isLoading,
    isUpdating,
    productRef,
    updateContractClause,
  };
};

export default useClauseQuery;

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const sizes = {
  small: "w-4 h-4 border-2",
  normal: "w-10 h-10 border-4",
};

const Loader = ({ className, size }) => (
  <div
    className={clsx("border-gray-500 rounded-full animate-spin", sizes[size], className)}
    data-testid="loader"
    style={{ borderTopColor: "#868e96" }}
  />
);

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
};

Loader.defaultProps = {
  className: undefined,
  size: "normal",
};

export default Loader;

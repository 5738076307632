import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ROUTES } from "../../constants";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";

export const useMutateContractQuery = (args) => {
  const queryClient = useQueryClient();
  const isRenewalRoute = useRouteMatch(ROUTES.RENEWAL);

  const { location } = useHistory();
  const params = useParams();
  const queryParams = parseParams(location.search);
  const { productRef, contractId } = params;
  const endorsementId =
    (args && args.endorsementId) || queryParams.endorsementId || params.endorsementId;

  const { mutateAsync: createContract, ...createContractQuery } = useMutation(api.createContract, {
    onSuccess: () => {
      queryClient.invalidateQueries("contracts", { productRef });
    },
  });

  const { mutateAsync: updateContract, ...updateContractQuery } = useMutation(api.updateContract, {
    onSuccess: (data) => {
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
      queryClient.invalidateQueries("audit", { productRef, contractId });
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("endorsements", { productRef, contractId });
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId });
      }, 2000);
      setTimeout(() => {
        queryClient.resetQueries("snapshot", { productRef, contractId });
      }, 500);
    },
  });

  const { mutateAsync: createEndorsement, ...createEndorsementQuery } = useMutation(
    api.createEndorsement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contract", { productRef, contractId });
        queryClient.invalidateQueries("endorsements", { productRef, contractId });
      },
    }
  );

  const { mutateAsync: updateEndorsement, ...updateEndorsementQuery } = useMutation(
    api.updateEndorsement,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["endorsement", { productRef, contractId, endorsementId }], data);
        queryClient.invalidateQueries("audit", { productRef, contractId });
        queryClient.invalidateQueries("contract", { productRef, contractId });
        queryClient.invalidateQueries("contracts", { productRef });
        queryClient.invalidateQueries("endorsements", { productRef, contractId });
        setTimeout(() => {
          queryClient.resetQueries("documents", { productRef, contractId, endorsementId });
        }, 2000);
        setTimeout(() => {
          queryClient.resetQueries("snapshot", { productRef, contractId, endorsementId });
        }, 500);
      },
    }
  );

  const isCreating = createContractQuery.isLoading || createEndorsementQuery.isLoading;
  const isUpdating = updateContractQuery.isLoading || updateEndorsementQuery.isLoading;
  const isEndorsement = Boolean(queryParams.endorsementId);

  return {
    contractId,
    createContract: !contractId || isRenewalRoute ? createContract : createEndorsement,
    endorsementId,
    isCreating,
    isRenewalRoute,
    isEndorsement,
    isUpdating,
    productRef,
    updateContract: endorsementId ? updateEndorsement : updateContract,
  };
};

import {
  Address,
  BespokeEndorsement,
  Broker,
  BrokerCommissions,
  IsoDate,
  Money,
  RaterOutput,
  User,
  XContract,
  XQuote,
  YesNo,
} from "../../@types/types";

export type MelRaterOutput = RaterOutput & {
  grossPremium: number;
  tripraAmount: number;
};

export type LimitAmount = 1_000_000 | 2_000_000 | 3_000_000 | 4_000_000 | 5_000_000;

export type Limit = Money & {
  amount: LimitAmount;
};

export type DeductibleAmount = 0 | 2_500 | 5_000 | 10_000 | 20_000;

export type Deductible = Money & {
  amount: DeductibleAmount;
};

export type PremiumType = "Flat premium" | "Adjustable premium";

export type AdjustablePremiumOption =
  | "Minimum and deposit adjustable on payroll"
  | "Minimum and deposit adjustable on people";

// TODO: change this to YesNo
export type KnownLosses = "Yes, no known losses have occurred" | "No, losses have occurred";

export type MelSubmission = {
  broker_information?: {
    has_surplus_lines_tax_broker: YesNo;
    has_producing_agent?: YesNo;
    broker_name?: string;
    producing_agent_id?: string;
  };
  current_insurance?: any;
  diving?: any;
  general_information?: {
    address?: Address;
    insured_name?: string;
    business_type?: BusinessType;
    overwater_operation_description?: string;
    additional_named_insured?: string[];
    are_employees_exposed?: YesNo;
    does_company_require_maintenance?: YesNo;
    total_employees?: number;
    years_in_operation?: number;
    total_employees_exposed?: number;
    max_employees_exposed?: number;
  };
  loss_record?: any;
  payroll?: any;
  quote: {
    additional_premium?: Money;
    adjustable_premium_option?: AdjustablePremiumOption;
    adjustable_premium_type?: string;
    adjustableRates?: { overall_payroll?: number };
    cancellation_date?: string;
    deductible?: Money;
    effective_from?: string;
    expiry_date: string;
    inception_date: string;
    is_bespoke?: YesNo;
    is_job_one_off_contract?: YesNo;
    limit_required: Limit;
    no_known_losses?: YesNo;
    premium_type?: PremiumType;
    premium?: Money;
    reason?: string;
    reduce_premium?: YesNo;
    refund_premium?: Money;
    tripra: YesNo;
    verbiage?: string;
    //
    cancellation_reason?: string;
  };
  rigs?: any;
  watercraft?: any;
};

export type MelCancellationSubmission = {
  cancellation_date?: string;
  no_known_losses?: KnownLosses;
  reason?: string;
};

export const SubmissionSecurityConsortium = "Consortium";
export const SubmissionSecurity100Convex = "100% Convex";

export type SubmissionSecurity = "100% Convex" | "Consortium";
export const SubmissionSecurityOptions = [SubmissionSecurity100Convex, SubmissionSecurityConsortium];

export type FlatPremiumSubmission = {
  deductible: Money;
  premium_type: "Flat premium";
  premium: Money;
  bespoke_endorsements?: BespokeEndorsement[];
  renewedFrom?: string;
  security?: SubmissionSecurity;
};

export type AdjPremiumSubmission = {
  adjustable_premium_option: AdjustablePremiumOption;
  adjustable_premium_type: string;
  adjustableRates: { overall_payroll?: number };
  deductible: Money;
  premium_type: "Adjustable premium";
  premium: Money;
  reduce_premium: YesNo;
  security?: SubmissionSecurity;
  verbiage: string;
  bespoke_endorsements?: BespokeEndorsement[];
  renewedFrom?: string;
};

export type ApRpSubmission = {
  aprp: number;
};

export type ParsedSubmission = {
  areEmployeesExposed?: boolean;
  businessType?: BusinessType;
  daysSinceInception?: number;
  deductible?: Deductible;
  diving?: boolean;
  doesRequireMaintenance?: boolean;
  effectiveFrom?: string;
  expiryDate?: string;
  hasJonesActClaims?: boolean;
  hasJonesActPayroll?: boolean;
  hasOtherPayroll?: boolean;
  hasStateActPayroll?: boolean;
  inceptionDate?: string;
  insuredName?: string;
  jonesActClaimsPayload?: string;
  jonesActPayroll?: Money;
  jonesActPayrollEmployees?: number;
  limit: Limit;
  workingOnPlatforms?: boolean;
  /** @deprecated notWorkingOnPlatforms was replaced by workingOnPlatforms */
  notWorkingOnPlatforms?: boolean;
  oneOffJob?: boolean;
  overwaterOperationDescription?: string;
  ownsWatercraft?: boolean;
  payroll: Money;
  policyPeriodDays?: number;
  security?: SubmissionSecurity;
  separateInsurance?: boolean;
  stateIsTexas?: boolean;
  timeOnboardWatercraft?: boolean;
  totalPayrollNextYear?: Money;
  trialTrips?: boolean;
  tripra: boolean;
  watercraft?: boolean;
  watercraftHasCaptain?: boolean;
  watercraftTransport?: boolean;
};

export type MelQuote = XQuote<
  {
    aprp?: number;
    commission?: number;
    commissionAmount?: number;
    grossPremium: number;
    netPremium?: number;
    ownerCommission?: number;
    ownerCommissionAmount?: number;
    security?: SubmissionSecurity;
    tally?: number;
    totalPremiumPaid?: number;
    tripraAmount: number;
  },
  {
    broker_information?: {
      has_surplus_lines_tax_broker: YesNo;
      broker_name: string;
      broker_address: {
        address_line_1: string;
        address_line_2?: string;
        city: string;
        zipcode: string;
        state: string;
      };
      broker_licence_number?: string;
    };
    security?: SubmissionSecurity;
    quote: {
      adjustable_premium_option?: AdjustablePremiumOption;
      adjustable_premium_type?: string;
      adjustableRates?: { overall_payroll?: number };
      deductible: Money;
      expiry_date: string;
      inception_date: string;
      is_job_one_off_contract?: YesNo;
      limit_required: Limit;
      premium_type?: PremiumType;
      reduce_premium?: YesNo;
      reduced_premium?: Money;
      tripra: YesNo;
      verbiage?: string;
      effective_from?: string;
    };
  }
>;

export type MelContract = XContract<MelQuote, MelSubmission, Broker>;

export type MelMachineSchema = {
  states: {
    Idle: {};
    Draft: {};
    Quoted: {};
    "In progress": {};
    Referred: {};
    "Referred Quoted": {};
    Declined: {};
    Bound: {
      states: {
        Idle: {};
        Draft: {};
        Quoted: {};
        "In progress": {};
        Referred: {};
        "Referred Quoted": {};
        Declined: {};
        Applied: {};
        "Not Taken Up": {};
        "Not Progressed": {};
        "Declined Expired": {};
        Error: {};
      };
    };
    "Not Taken Up": {};
    "Not Progressed": {};
    Cancelled: {};
    Error: {};
  };
};

export type MelErrorMessage = "Invalid submission.";

export type MelContext = {
  brokerCommissions: BrokerCommissions;
  contractData: MelContract;
  contractId: string;
  currentContractData: MelContract;
  documentsData: any[];
  doesParentHaveMaterialEndorsements?: boolean;
  endorsementData?: MelContract;
  endorsementId?: string;
  errorMessage?: MelErrorMessage;
  nextContractData?: MelContract;
  originalBrokerCommissions: BrokerCommissions; // original commission - not changed by Consortium
  productRef: ProductRef;
  quoteId?: string;
  quotesData?: { Count?: number };
  schema?: {
    properties: {
      SubmissionForm: {};
      BindQuoteForm: {};
      NotProgressedForm: {};
      NotTakenUpForm: {};
      DeclinedForm: {};
      EndorsementCancelReferralForm: {};
      EndorsementReferralForm: {};
      BindEndoQuoteForm: {};
      CancelEndorsementForm: {};
      EndorsementSubmissionForm: {};
    };
  };
  tenants: any[];
  tenantId: string;
  user: User;
};

export type CancellationReason =
  | "Alternative product purchased"
  | "Cover no longer required"
  | "Cover overlapped with pre-existing cover"
  | "Other"
  | "Poor service / Complaint"
  | "Product cancelled by Underwriter"
  | "Product cancelled within cooling off period"
  | "Product too expensive"
  | "Product unsuitable / misunderstood"
  | "Not Known";

export type MelEvent =
  | { type: "Create draft submission"; payload: { submission: MelSubmission }; renewedFrom?: string }
  | {
      type: "Quote submission";
      payload: { submission: MelSubmission; bespoke_endorsements?: BespokeEndorsement[]; is_bespoke?: boolean };
      renewedFrom?: string;
    }
  | { type: "Update draft submission"; payload: { submission: MelSubmission } }
  | {
      type: "Update submission";
      payload: { submission: MelSubmission; bespoke_endorsements?: BespokeEndorsement[] };
      renewedFrom?: string;
    }
  | { type: "Decline to quote contract"; payload: { reason: string; note?: string } }
  | { type: "Provide commercial price"; payload: FlatPremiumSubmission | AdjPremiumSubmission }
  | { type: "Provide endo commercial price"; payload: ApRpSubmission }
  | { type: "Bind quote"; payload: { submission: MelSubmission } }
  | {
      type: "Cancel endorsement";
      payload: {
        cancellation_date: IsoDate;
        cancellation_reason: CancellationReason;
        cancellation_reason_other?: string;
        no_known_losses: KnownLosses;
      };
    }
  | { type: "Cancel endorsement manual"; payload: { aprp: number } }
  | { type: "Bind endorsement"; payload: { effective_from: string } }
  | { type: "Edit bespoke"; payload: BespokeEndorsement[] }
  | { type: "Update contract commission"; payload: { commissionBreakdown: Broker[] } }
  | { type: "Edit premium paid flag"; payload: never }
  | { type: "Edit active claim flag"; payload: never }
  // TODO:
  | { type: "Create clause"; payload: any }
  | { type: "Create MTA endorsement"; payload: any }
  | { type: "Create bespoke endorsement"; payload: any }
  | { type: "Submit final adjustment premium"; payload: any }
  | { type: "Create message"; payload: any }
  | { type: "Edit clause"; payload: any }
  | { type: "Process referral"; payload: any }
  | { type: "Refer contract"; payload: any }
  | { type: "Reject contract not progressed"; payload: any }
  | { type: "Reject contract not taken up"; payload: any }
  | { type: "Remove clause"; payload: any }
  | { type: "Upload contract attachment"; payload: any }
  | { type: "Upload message attachment"; payload: any };

export type BusinessType =
  | "Anchor handler"
  | "Boat dealer"
  | "Casino boat operations"
  | "Construction - Bridges"
  | "Construction - Dam or lock"
  | "Construction - Dikes or Revetment"
  | "Construction - Dock, Jetty, Breakwater or Sea Wall"
  | "Construction - Not otherwise classified"
  | "Construction - Pile driving"
  | "Construction - Tunnels"
  | "Contractor - Air conditioning"
  | "Contractor - Cable installation/repair"
  | "Contractor - Carpentry"
  | "Contractor - Caterer"
  | "Contractor - Dredging"
  | "Contractor - Drilling"
  | "Contractor - Electrical"
  | "Contractor - Environmental"
  | "Contractor - Executive Supervisor or Construction Superintendent"
  | "Contractor - Fixtures and fitting installation"
  | "Contractor - IT"
  | "Contractor - Machinery/equipment rental or dealer"
  | "Contractor - Navigation systems software engineer"
  | "Contractor - Not otherwise classified"
  | "Contractor - Oil Spill Response"
  | "Contractor - Painting/sandblasting"
  | "Contractor - Plumbing"
  | "Contractor - Welding"
  | "Diver"
  | "Dock operator"
  | "Engineer consulting"
  | "Engineer"
  | "Fish farm"
  | "Fishery observer"
  | "Fleeting"
  | "Geophysical exploration"
  | "Inspections"
  | "Labour supply company"
  | "Marina operator"
  | "Marine architect"
  | "Marine biologist/conservationist"
  | "Marine life monitoring"
  | "Marine surveyor"
  | "Midstream operations"
  | "Offshore construction/fabrication/decommissioning"
  | "Oil and gas equipment supplier/dealer"
  | "Oil and gas geologist"
  | "Oil and gas lease operator"
  | "Oil and gas logging/surveying"
  | "Oil and gas service contractor"
  | "Other"
  | "Pipeline construction/maintenance/operation"
  | "Port authority"
  | "Port captain"
  | "Project management"
  | "Recreational watersports operator"
  | "Rigger"
  | "Roustabout"
  | "ROV operator"
  | "Salvage operations"
  | "Scientist/researcher"
  | "Security guard"
  | "Ship cleaning"
  | "Ship repairer"
  | "Shipbuilder/oil rig builder"
  | "State/Parish authority"
  | "Stevedore"
  | "Tank cleaning"
  | "Tankerman"
  | "Terminal operator"
  | "Tool pusher"
  | "Vessel owner/operator";

import React from "react";
import InputField from "./InputField";
import InputRadio from "./InputRadio";

const InputRadioGroup = ({
  className,
  descriptionText,
  errors,
  id,
  isRequired,
  isReadOnly,
  labelText,
  name,
  onChange,
  options,
  value,
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    errors={errors}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    name={name}
  >
    {options.map((option) => (
      <InputRadio
        className="my-6"
        descriptionText={option.descriptionText}
        errors={errors}
        id={option.id || [name, option].join(".")}
        isChecked={value ? value === option.value || value === option : undefined}
        isRequired={isRequired}
        key={option.value || option}
        labelText={option.labelText || option}
        name={name}
        onChange={onChange}
        value={option.value || option}
        isDisabled={isReadOnly}
        readOnly={isReadOnly}
      />
    ))}
  </InputField>
);

export default InputRadioGroup;

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const H3 = ({ className, children, ...props }) => (
  <h3 className={clsx("text-lg leading-relaxed font-semibold", className)} {...props}>
    {children}
  </h3>
);

H3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

H3.defaultProps = {
  children: undefined,
  className: undefined,
};

export default H3;

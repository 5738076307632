import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, QUOTE_STATUSES, QUOTE_TYPES, STATUSES } from "../../../constants";
import { stringifyParams } from "../../../helpers/stringifyParams";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import H3 from "../../components/H3";
import Status from "../../components/Status";
import { useAuth, useModal } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import { MCPremium } from "../../../projects/mc/types";
import { currencyFormatter } from "../../../formatters";

const defaultColumns = [
  {
    displayName: "Type",
    id: "type",
    formatter: ({ type, index }) => "Quote",
    extractValue: ({ quoteData, index }) => ({ type: quoteData?.type, index }),
  },
];

const endoColumns = [];

const bySubmitted = (quoteData) => quoteData.status === QUOTE_STATUSES.SUBMITTED;

const QuoteRow = ({
  columns,
  contractData,
  handleBindClick,
  handleQuoteClick,
  isCurrent,
  isExpandable,
  isExpanded,
  quoteData,
  rowIndex,
}) => {
  const isDefault = quoteData.type === QUOTE_TYPES.DEFAULT;
  const isSubmitted = quoteData.status === QUOTE_STATUSES.SUBMITTED;
  const { checkPermissions } = useAuth(contractData);

  return (
    <>
      <tr
        className={clsx(
          "border-b border-gray-300 last:border-b-0 h-16 hover:bg-gray-50 transition",
          isDefault && isSubmitted && "bg-blue-100 font-semibold",
          isExpandable && "cursor-pointer",
        )}
        onClick={isExpandable ? handleQuoteClick(quoteData.id, isExpanded) : undefined}
      >
        {columns.map((column, index) => {
          const { displayName, path, formatter, extractValue } = column;
          const value =
            extractValue instanceof Function
              ? extractValue({ quoteData, contractData, index: rowIndex })
              : get(quoteData, path);
          const formattedValue = formatter instanceof Function ? formatter(value) : value;
          const name = displayName instanceof Function ? displayName({ contractData, quoteData, value }) : displayName;

          return (
            <td key={name} className={index === 0 ? "pl-6" : undefined}>
              {formattedValue ?? value}
            </td>
          );
        })}
        <td>
          <Status kind="small" status={quoteData.status} />
        </td>
        <td className="w-24">
          {isSubmitted && isCurrent && checkPermissions(ACTIONS.BIND_QUOTE) && (
            <Button kind="primary" className="h-10 px-6" onClick={handleBindClick(quoteData.id)}>
              Bind
            </Button>
          )}
        </td>
        {isExpandable && (
          <td>
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              className={clsx("mx-4 fill-current", isExpanded && "text-blue-600")}
            />
          </td>
        )}
      </tr>

      {isExpanded && isExpandable && (
        <tr>
          <td colSpan="6">
            <div>
              <table className="w-full">
                <thead className="text-left h-16">
                  <tr>
                    {contractData?.submission?.base?.scheme_type === "McIndoe" && <th className="pl-6">Band</th>}
                    <th className={contractData?.submission?.base?.scheme_type === "McIndoe" ? "" : "pl-6"}>
                      Level of Indemnity
                    </th>
                    <th className="">Total Premium exc Payable</th>
                    <th className="">IPT at 12%</th>
                    <th className="">Total Premium Payable inc IPT</th>
                  </tr>
                </thead>
                <tbody>
                  {contractData?.submission?.rates?.map((rate: MCPremium, index: number) => (
                    <React.Fragment key={`rates-${quoteData.id}-${index}`}>
                      <tr className="border-b border-gray-300 h-16">
                        {contractData?.submission?.base?.scheme_type === "McIndoe" && (
                          <>
                            <td className="pl-6">{rate.band}</td>
                          </>
                        )}
                        <td
                          className={contractData?.submission?.base?.scheme_type === "McIndoe" ? "pr-6" : "pl-6 pr-6"}
                        >
                          {currencyFormatter({
                            amount: rate.indemnity.amount,
                            currency: "GBP",
                            locale: "en-GB",
                            digits: 2,
                          })}
                        </td>
                        <td className="pr-6">
                          {currencyFormatter({
                            amount: rate.premium_exc_ipt.amount,
                            currency: "GBP",
                            locale: "en-GB",
                            digits: 2,
                          })}
                        </td>
                        <td className="pr-6">
                          {currencyFormatter({
                            amount: rate.ipt.amount,
                            currency: "GBP",
                            locale: "en-GB",
                            digits: 2,
                          })}
                        </td>
                        <td className="pr-6">
                          {currencyFormatter({
                            amount: rate.premium_inc_ipt.amount,
                            currency: "GBP",
                            locale: "en-GB",
                            digits: 2,
                          })}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const QuoteCardSure = ({ contractData, expandAll, heading, headingText, isCurrent, isEndorsement, quotesData }) => {
  const { showModal } = useModal();

  const { location, push } = useHistory();
  const { applyEndoAttachment } = useAttachmentQuery();
  const endorsementId = isEndorsement ? contractData.id : undefined;
  const { productRef, contractId } = useParams() as any;

  const defaultQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.DEFAULT);
  const commercialQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.COMMERCIAL);

  const areSubmitted = quotesData.every(bySubmitted);
  const allIds = quotesData.map((quoteData) => quoteData.id);
  const initialIds = expandAll ? allIds : [areSubmitted && commercialQuote?.id];
  const [selectedIds, setSelectedIds] = useState(initialIds);

  const rest = quotesData.filter((quote) => quote.type !== QUOTE_TYPES.DEFAULT);

  const sortedQuotesData = defaultQuote ? [defaultQuote, ...rest] : rest;
  const contractColumns = areSubmitted ? defaultColumns : defaultColumns;
  const columns = contractColumns;

  const handleQuoteClick = (quoteId, expanded) => (event) => {
    const nextIds = expanded ? selectedIds.filter((id) => id !== quoteId) : [...selectedIds, quoteId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  const handleBindSuccess = async (nextContractData) => {
    if (nextContractData.status === STATUSES.APPLIED) {
      await applyEndoAttachment({ productRef, contractId, endorsementId });
    }

    if (nextContractData.status === STATUSES.BOUND) {
      return showModal(MODALS.BIND_QUOTE_SUCCESS);
    }

    return false;
  };

  const handleBindClick = (quoteId) => (event) => {
    event.stopPropagation();
    push({ pathname: location.pathname, search: stringifyParams({ quoteId, endorsementId }) });

    showModal(isEndorsement ? MODALS.BIND_ENDORSEMENT_QUOTE : MODALS.BIND_QUOTE, {
      callback: handleBindSuccess,
    });
  };

  return (
    <div className="bg-white shadow rounded mb-4">
      {heading}
      {headingText && <H3 className="mb-4 p-4">{headingText}</H3>}
      <div>
        <table className="w-full">
          <thead className="text-left text-sm ">
            <tr className="mb-3 ">
              {columns.map((column, index) => {
                const { displayName, path, extractValue } = column;
                const quoteData = quotesData[0];
                const value =
                  extractValue instanceof Function ? extractValue({ quoteData, contractData }) : get(quoteData, path);
                const name =
                  displayName instanceof Function ? displayName({ contractData, quoteData, value }) : displayName;

                return (
                  <th className={clsx("font-normal", index === 0 && "pl-6")} key={name}>
                    <div className="mb-3">{name}</div>
                  </th>
                );
              })}
              <th />
              <th />
              <th className="w-10" />
            </tr>
          </thead>
          <tbody>
            {(isEndorsement ? quotesData : sortedQuotesData).map((quoteData, index) => (
              <QuoteRow
                columns={columns}
                contractData={contractData}
                handleQuoteClick={handleQuoteClick}
                isCurrent={isCurrent}
                isExpandable={!isEndorsement}
                isExpanded={selectedIds.includes(quoteData.id)}
                key={quoteData.id}
                handleBindClick={handleBindClick}
                quoteData={quoteData}
                rowIndex={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

QuoteCardSure.propTypes = {
  quotesData: PropTypes.array,
};

QuoteCardSure.defaultProps = {
  quotesData: [],
  expandAll: true,
};

export default QuoteCardSure;

import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants";
import { normaliseUser } from "../../helpers/normaliseUser";
import * as api from "../api";

export const usePrivateRoute = () => {
  const [isAuthenticated, setAuthenticated] = useState();
  const { push } = useHistory();
  const options = { enabled: Boolean(isAuthenticated) };
  const usersQuery = useQuery(["users"], api.getUsers, options);
  const tenantsQuery = useQuery(["tenants"], api.getTenants, options);
  const brokerQuery = useQuery(["brokers"], api.getProducingBrokers, options);

  const isLoading = usersQuery.isLoading || tenantsQuery.isLoading || brokerQuery.isLoading;
  const isIdle = usersQuery.isIdle || tenantsQuery.isIdle || brokerQuery.isIdle;

  const init = async () => {
    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      const normalisedUser = normaliseUser(amplifyUser);
      const { email, fullName, role, id } = normalisedUser;

      window.localStorage.setItem("user", JSON.stringify(normalisedUser));

      if (window.FS) {
        window.FS.identify(id, { displayName: fullName, email, role });
      }

      Sentry.configureScope((scope) => {
        scope.setUser({ email, id, username: fullName, role });
      });

      setAuthenticated(normalisedUser);
    } catch (err) {
      setAuthenticated(false);
      push(ROUTES.LOGIN);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return { isLoading: isIdle || isLoading };
};

export default usePrivateRoute;

import PropTypes from "prop-types";
import React from "react";
import { ACTIONS } from "../../../constants";
import Card from "../../components/Card";
import InputCheckbox from "../../components/InputCheckbox";
import { useAuth } from "../../hooks";

const FlagPolicy = ({ handleAction, contractData, isLoading }) => {
  const { checkPermissions } = useAuth(contractData);

  return (
    <Card headingText="Policy actions">
      <div className="px-4 py-6 -mx-1">
        {(checkPermissions(ACTIONS.EDIT_CLAIM_FLAG) || checkPermissions(ACTIONS.VIEW_CLAIM_FLAG)) && (
          <div className="mb-4">
            <InputCheckbox
              labelText="Active claim against policy"
              name="activeClaim"
              isChecked={contractData?.flags?.activeClaim}
              isDisabled={isLoading || !checkPermissions(ACTIONS.EDIT_CLAIM_FLAG)}
              onChange={(event) => handleAction({ type: ACTIONS.EDIT_CLAIM_FLAG, isChecked: event.target.checked })}
            />
          </div>
        )}

        {(checkPermissions(ACTIONS.EDIT_PREMIUM_PAID_FLAG) || checkPermissions(ACTIONS.VIEW_PREMIUM_PAID_FLAG)) && (
          <div>
            <InputCheckbox
              labelText="Premium paid"
              name="premiumPaid"
              isChecked={contractData?.flags?.premiumPaid}
              isDisabled={isLoading || !checkPermissions(ACTIONS.EDIT_PREMIUM_PAID_FLAG) || contractData.premiumPaid}
              onChange={(event) =>
                handleAction({ type: ACTIONS.EDIT_PREMIUM_PAID_FLAG, isChecked: event.target.checked })
              }
            />
          </div>
        )}
      </div>
    </Card>
  );
};

FlagPolicy.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FlagPolicy;

import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "../../../../formatters";

const TotalPremiumAndDeductible = ({ currentEndorsements, premium }) => {
  const selectedEndorsements = currentEndorsements.filter((i) => i.selected);
  return (
    <>
      <p className="mb-4">
        Total premium
        <span className="float-right">
          {currencyFormatter({
            amount:
              selectedEndorsements?.length > 0
                ? selectedEndorsements.reduce((prev, curr, idx) => {
                    let currentValue = prev + (curr.amount ?? 0);
                    if (idx === selectedEndorsements.length - 1) {
                      currentValue += premium ?? 0;
                    }
                    return currentValue;
                  }, 0)
                : premium,
          })}
        </span>
      </p>
    </>
  );
};

TotalPremiumAndDeductible.propTypes = {
  currentEndorsements: PropTypes.array.isRequired,
  premium: PropTypes.number,
};

TotalPremiumAndDeductible.defaultProps = {
  premium: 0,
};

export default TotalPremiumAndDeductible;

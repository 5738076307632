import React from "react";
import InputText from "../InputText";

const getInputType = (schema) => {
  if ("ui:input-type" in schema) {
    return schema["ui:input-type"];
  }

  if ((schema.type === "string" || schema.type === "number") && "enum" in schema) {
    return undefined;
  }

  if (schema.type === "string") {
    return "text";
  }

  return schema.type;
};

const getPattern = (schema) => {
  return schema["pattern"] || undefined;
};

const InputTextSchema = ({ item: schema, ...props }) => (
  <InputText type={getInputType(schema)} min={schema.minimum} pattern={getPattern(schema)} max={schema.maximum} {...props} />
);

export default InputTextSchema;

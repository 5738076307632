import { MCSubmission } from "./types";
import moment from "moment";
import { ROLES } from "../../constants/roles";
import { SchemaRef } from "../../@types/types";
import { differenceInMonths } from "date-fns";

export const isMCExtendedPolicy = (inceptionDate?: string, expiryDate?: string): boolean => {
  if (!inceptionDate) return false;
  if (!expiryDate) return false;

  return differenceInMonths(new Date(expiryDate), new Date(inceptionDate)) > 24;
};

export const getMCSchemaBasedOnRole = (productRef: ProductRef, role: string): string => {
  let schemaRef: SchemaRef;
  switch (role) {
    case ROLES.SURGEONS:
      schemaRef = "sure-mc-surgeons";
      break;
    case ROLES.HOSPITALS_AND_CLINICS:
      schemaRef = "sure-mc-hospitals-and-clinics";
      break;
    default:
      throw new Error(`getMCSchemaBasedOnRole - incorrect role ${role}`);
  }
  return schemaRef;
};

export const createPolicyReference = (submission: MCSubmission): string => {
  let surgeonsInitials = "";
  if (submission?.base?.first_name && submission?.base?.surname_familyname) {
    surgeonsInitials = `${submission.base.first_name.substring(0, 1).toUpperCase()}${submission.base.surname_familyname
      .substring(0, 1)
      .toUpperCase()}`;
  }

  const inceptionDate = moment(submission.base.inception_date).format("DDMMYY");

  switch (submission?.base?.scheme_type) {
    case "Commitment Plus":
      if (!surgeonsInitials) {
        return "";
      }
      return `SIS/CP/${surgeonsInitials}${inceptionDate}`;
    case "ISAPS":
      if (!surgeonsInitials) {
        return "";
      }
      return `SIS/ISAPS/${surgeonsInitials}${inceptionDate}`;
    case "McIndoe":
      return `SIS/MDC/${inceptionDate}`;
    case "THMG":
      return `SIS/LCHMG/${inceptionDate}`;
    case "Healthier Weight":
      return `SIS/HWB/${inceptionDate}`;
    case "Transform & THG": {
      if (isMCExtendedPolicy(submission.base.inception_date, submission.base.expiry_date)) {
        return `TRANEX/${inceptionDate}`;
      } else {
        return `TRAN/${inceptionDate}`;
      }
    }
    default:
      return "";
  }
};

import get from "lodash.get";
import { currencyFormatter } from "../../formatters";

export const adjustablePremiumOptions = {
  "Minimum and deposit adjustable on payroll": {
    "Adjustable on Gross Overwater Payroll": [
      [
        "Longshore - On land/dock",
        "Longshore - On watercraft dockside",
        "Longshore - On watercraft away from dock",
        "Jones Act",
      ],
      "overall",
      "Adjustable at $overall% on Gross Overwater Payroll",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore on watercraft payroll and Jones Act payroll (overall rate)": [
      ["Longshore - On watercraft (dockside + away from dock)", "Jones Act"],
      "overall",
      "Adjustable at $overall% on Longshore on watercraft payroll and Jones Act payroll",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore on watercraft payroll and Jones Act payroll (individual rate)": [
      ["Longshore - On watercraft (dockside + away from dock)", "Jones Act"],
      "individual",
      "Adjustable at $longshore_on_watercraft_dockside#longshore_on_watercraft_away_from_dock% on Longshore on watercraft payroll and at $jones_act% on Jones Act payroll",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore on watercraft payroll": [
      ["Longshore - On watercraft (dockside + away from dock)"],
      "individual",
      "Adjustable at $longshore_on_watercraft_dockside#longshore_on_watercraft_away_from_dock% on Longshore on watercraft payroll",
      { shouldReferIfJonesActEndorsed: true },
    ],
    "Adjustable on Jones Act payroll": [
      ["Jones Act"],
      "individual",
      "Adjustable at $jones_act% on Jones Act payroll",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore payroll (overall rate)": [
      ["Longshore - On land/dock", "Longshore - On watercraft dockside", "Longshore - On watercraft away from dock"],
      "overall",
      "Adjustable at $overall% on Longshore payroll",
      { shouldReferIfJonesActEndorsed: true },
    ],
    "Adjustable on Longshore on land/dock and Longshore on watercraft and Jones Act": [
      ["Longshore - On land/dock", "Longshore - On watercraft (dockside + away from dock) and Jones Act"],
      "individual",
      "Adjustable at $longshore_on_land_dock% on Longshore on land/dock payroll and at $longshore_on_watercraft_dockside#longshore_on_watercraft_away_from_dock#jones_act% on longshore watercraft payroll and Jones Act payroll",
      { shouldReferIfJonesActEndorsed: false },
    ],
  },
  "Minimum and deposit adjustable on people": {
    "Adjustable on Gross Overwater People": [
      [
        "Longshore - On land/dock",
        "Longshore - On watercraft dockside",
        "Longshore - On watercraft away from dock",
        "Jones Act",
      ],
      "overall",
      "Adjustable at $overall per employee exposed overwater",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore on watercraft people and Jones Act people (overall rate)": [
      ["Longshore - On watercraft (dockside + away from dock)", "Jones Act"],
      "overall",
      "Adjustable at $overall per Longshore on watercraft employee and Jones Act employee",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore on watercraft people and Jones Act people (individual rate)": [
      ["Longshore - On watercraft (dockside + away from dock)", "Jones Act"],
      "individual",
      "Adjustable at $longshore_on_watercraft_dockside#longshore_on_watercraft_away_from_dock per Longshore on watercraft employee and at $jones_act per Jones Act employee",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore on watercraft people": [
      ["Longshore - On watercraft (dockside + away from dock)"],
      "individual",
      "Adjustable at $longshore_on_watercraft_dockside#longshore_on_watercraft_away_from_dock per Longshore on watercraft employee",
      { shouldReferIfJonesActEndorsed: true },
    ],
    "Adjustable on Jones Act people": [
      ["Jones Act"],
      "individual",
      "Adjustable at $jones_act per Jones Act employee",
      { shouldReferIfJonesActEndorsed: false },
    ],
    "Adjustable on Longshore people (overall rate)": [
      ["Longshore - On land/dock", "Longshore - On watercraft dockside", "Longshore - On watercraft away from dock"],
      "overall",
      "Adjustable at $overall per Longshore employee",
      { shouldReferIfJonesActEndorsed: true },
    ],
    "Adjustable on Longshore on land/dock and Longshore on watercraft and Jones Act": [
      ["Longshore - On land/dock", "Longshore - On watercraft (dockside + away from dock) and Jones Act"],
      "individual",
      "Adjustable at $longshore_on_land_dock per Longshore on land/dock employee and at $longshore_on_watercraft_dockside#longshore_on_watercraft_away_from_dock#jones_act per Longshore on watercraft and Jones Act employee",
      { shouldReferIfJonesActEndorsed: false },
    ],
  },
};

export const labelToKeysMap = {
  "Longshore - On land/dock": ["longshore_on_land_dock"],
  "Longshore - On watercraft dockside": ["longshore_on_watercraft_dockside"],
  "Longshore - On watercraft away from dock": ["longshore_on_watercraft_away_from_dock"],
  "Jones Act": ["jones_act"],
  "Longshore - On watercraft (dockside + away from dock)": [
    "longshore_on_watercraft_dockside",
    "longshore_on_watercraft_away_from_dock",
  ],
  "Longshore - On watercraft (dockside + away from dock) and Jones Act": [
    "longshore_on_watercraft_dockside",
    "longshore_on_watercraft_away_from_dock",
    "jones_act",
  ],
};

export const keysToLabelsMap = {
  longshore_on_land_dock: "Longshore - On land/dock",
  longshore_on_watercraft_dockside: "Longshore - On watercraft dockside",
  longshore_on_watercraft_away_from_dock: "Longshore - On watercraft away from dock",
  jones_act: "Jones Act",
};

const isTruthy = (value) => value?.amount === 0 || value?.amount || value === 0 || value;

export const asCurrencyCode = (money) => currencyFormatter({ ...money, currencyDisplay: "code" });

export const getTotalOverall = ({ payrollBreakdown, formValues }) => {
  const { adjustableRates, adjustable_premium_option: option, adjustable_premium_type: type } = formValues;
  const isPeople = option === "Minimum and deposit adjustable on people";
  const adjustablePremiumTypes = adjustablePremiumOptions[option];
  const [eligibleTypes = []] = adjustablePremiumTypes?.[type] || [];
  const payrollKeys = eligibleTypes.flatMap((item) => labelToKeysMap[item]);
  const amounts = payrollKeys.map((key) => {
    const people = payrollBreakdown?.[key]?.next_year_employees || 0;
    const money = payrollBreakdown?.[key]?.next_year?.amount || 0;

    return isPeople ? people : money;
  });

  const amountsSum = amounts.reduce((prev, next) => prev + next, 0);
  const rate = isPeople ? adjustableRates?.overall_people?.amount : adjustableRates?.overall_payroll / 100;
  const amount = amountsSum * rate || 0;

  return { premium: amount, premiumMoney: { amount, currency: "USD" } };
};

export const getTotalIndividual = ({ payrollBreakdown, formValues, splitPayrollTypes }) => {
  const { adjustableRates, adjustable_premium_option: option, adjustable_premium_type: type } = formValues;
  const isPeople = option === "Minimum and deposit adjustable on people";
  const adjustablePremiumTypes = adjustablePremiumOptions[option];
  const [originalEligibleTypes = []] = adjustablePremiumTypes?.[type] || [];
  const eligibleTypes = splitPayrollTypes ?? originalEligibleTypes;
  const payrollKeys = eligibleTypes.map((item) => labelToKeysMap[item]);
  const amounts = payrollKeys.map((arr) =>
    arr.reduce((prev, next) => {
      const people = payrollBreakdown?.[next]?.next_year_employees || 0;
      const money = payrollBreakdown?.[next]?.next_year?.amount || 0;

      return isPeople ? people + prev : money + prev;
    }, 0),
  );

  const amount =
    amounts.reduce((prev, next, index) => {
      const money = adjustableRates?.[payrollKeys[index].join("#")];
      const people = adjustableRates?.[payrollKeys[index].join("#")]?.amount || 0;
      const peopleRate = people * next + prev;
      const moneyRate = money ? (money / 100) * next + prev : 0 + prev;

      return isPeople ? peopleRate : moneyRate;
    }, 0) || 0;

  return { premium: amount, premiumMoney: { amount, currency: "USD" } };
};

export const getVerbiage = ({ payrollBreakdown, formValues }) => {
  const {
    adjustableRates,
    adjustable_premium_option: option,
    adjustable_premium_type: type,
    reduce_premium: reducePremium,
    reduced_premium: reducedPremiumMoney,
    premium_type: premiumType,
  } = formValues;
  const isFlatPremium = premiumType === "Flat premium";
  const isReducedPremium = reducePremium === "Yes";
  const isPeople = option === "Minimum and deposit adjustable on people";
  const adjustablePremiumTypes = adjustablePremiumOptions[option];
  const [eligibleTypes = [], split, template] = adjustablePremiumTypes?.[type] || [];
  const isOverall = split === "overall";
  const { premiumMoney } = isOverall
    ? getTotalOverall({ payrollBreakdown, formValues })
    : getTotalIndividual({ payrollBreakdown, formValues });
  const payrollKeys = eligibleTypes.map((item) => labelToKeysMap[item]);
  const isValid = isOverall
    ? Object.values(adjustableRates || {}).every(isTruthy)
    : payrollKeys.map((a) => adjustableRates?.[a.join("#")]).every(isTruthy);

  let verbiage = template;

  if (isOverall) {
    const overallPeople = asCurrencyCode(adjustableRates?.overall_people);
    const result = isPeople ? overallPeople : adjustableRates?.overall_payroll;

    if (result || result === 0) {
      verbiage = verbiage.replace("$overall", result);
    }
  }

  if (!isOverall) {
    payrollKeys.forEach((arr) => {
      const key = arr.join("#");
      const value = adjustableRates?.[key];
      const result = isPeople ? asCurrencyCode(value) : value;

      if (result || result === 0) {
        verbiage = verbiage.replace(`$${key}`, result);
      }
    });
  }

  if (verbiage === template || isFlatPremium || !isValid) {
    return formValues;
  }

  if (isReducedPremium && reducedPremiumMoney?.amount >= 0) {
    return { ...formValues, premium: reducedPremiumMoney, verbiage };
  }

  return { ...formValues, premium: premiumMoney, verbiage };
};

export const ENDORSEMENT_MATERIAL_REASONS = {
  LIMIT: "Limit has been endorsed.",
  PAYROLL: "Payroll has been endorsed.",
  TRIAL_TRIPS_SELECTION: "Trial Trips selection has been endorsed.",
  TRIPRA: "Tripra has been endorsed.",
  DEDUCTIBLE: "Deductible has been endorsed.",
};

export const getFinalAdjustmentPremiumData = (quoteData, endorsement) => {
  const endorsementPayrollBreakdown = get(endorsement, "submission.payroll.payroll_breakdown", {});
  const adjustablePremiumOption = get(quoteData, "submission.quote.adjustable_premium_option", "");
  const adjustablePremiumType = get(quoteData, "submission.quote.adjustable_premium_type", "");
  const originalAdjustableRates = get(quoteData, "submission.quote.adjustableRates", {});
  const isOverall =
    get(adjustablePremiumOptions, [adjustablePremiumOption, adjustablePremiumType, "1"], "") === "overall";
  const isPeople = adjustablePremiumOption === "Minimum and deposit adjustable on people";

  const adjustableRates = Object.keys(originalAdjustableRates).reduce((splitAdjustableRates, currentRate) => {
    if (currentRate.indexOf("#") === -1) {
      return { ...splitAdjustableRates, [currentRate]: originalAdjustableRates[currentRate] };
    }
    const splitRateKeys = currentRate.split("#");
    let currentSplitRates = {};
    for (let i = 0; i < splitRateKeys.length; i++) {
      const currentSplitRateKey = get(splitRateKeys, [i], "");
      currentSplitRates = { ...currentSplitRates, [currentSplitRateKey]: originalAdjustableRates[currentRate] };
    }
    return { ...splitAdjustableRates, ...currentSplitRates };
  }, {});

  const splitPayrollTypes = Object.values(keysToLabelsMap);

  const formValues = {
    adjustableRates,
    adjustable_premium_option: adjustablePremiumOption,
    adjustable_premium_type: adjustablePremiumType,
  };

  const originalPremiumAmount =
    get(quoteData, ["rates", "grossPremium"], 0) - get(quoteData, ["rates", "tripraAmount"], 0);

  const { premiumMoney: newPremiumMoney } = isOverall
    ? getTotalOverall({
        payrollBreakdown: endorsementPayrollBreakdown,
        formValues,
      })
    : getTotalIndividual({
        payrollBreakdown: endorsementPayrollBreakdown,
        formValues,
        splitPayrollTypes,
      });

  const isReferredQuoted = get(endorsement, "referralReasons", []).includes("Jones Act payroll has been endorsed.");
  const additionalPremium = get(endorsement, ["quotes", "0", "rates", "aprp"], 0);
  let newPremiumAmount = get(newPremiumMoney, "amount", 0);
  if (isReferredQuoted) {
    newPremiumAmount = additionalPremium > 0 ? additionalPremium + originalPremiumAmount : originalPremiumAmount;
  }

  return {
    splitPayrollTypes,
    endorsementPayrollBreakdown,
    isPeople,
    originalPremiumAmount,
    newPremiumAmount,
    endorsementPremiumAmount: newPremiumAmount - originalPremiumAmount,
  };
};

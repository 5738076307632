import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import InputTextarea from "../components/InputTextarea";
import Modal from "../components/Modal";
import { useContractQuery } from "../hooks";

const EditBespokeModal = ({ handleClose, bespoke, endorsementId, contractData }) => {
  const { contractId, isUpdating, productRef, updateContract } = useContractQuery({ endorsementId });
  const [value, setValue] = useState(bespoke.content);

  const handleSubmit = async () => {
    const nextBespokeEndos = contractData?.bespoke_endorsements?.map((bespokeEndo) => {
      if (bespokeEndo.id === bespoke.id) {
        return { ...bespokeEndo, content: value };
      }

      return bespokeEndo;
    });

    await updateContract({
      productRef,
      contractId,
      endorsementId,
      data: { type: ACTIONS.EDIT_BESPOKE, payload: nextBespokeEndos },
    });

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText={`Edit bespoke wording — ${bespoke.ref}`}>
      <div className="p-6 w-full">
        <InputTextarea
          labelText="Please enter your endorsement text. This will form part of the contractual endorsement."
          name="value"
          value={value}
          isRequired
          className="mb-8"
          onChange={(v) => setValue(v.target.value)}
        />
        <Button onClick={handleClose} className="mr-4">
          Cancel
        </Button>
        <Button kind="primary" onClick={handleSubmit} isDisabled={isUpdating}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default EditBespokeModal;

import sortBy from "lodash.sortby";
import React from "react";
import formatErrors from "../../helpers/formatErrors";

const InputMatrix = ({
  formValues,
  item,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  validationErrors,
  MakeComponent,
}) => {
  const sortedRows = sortBy(Object.keys(item.properties), (key) => item.properties[key].qid);

  return (
    <div>
      {sortedRows.map((rowKey) => {
        const rows = item.properties[rowKey].properties;
        const sortedColumns = sortBy(Object.keys(rows), (columnKey) => rows[columnKey].qid);

        return (
          <div key={rowKey}>
            <p className="font-semibold mb-4">{item.properties[rowKey].title}</p>
            <div className="flex -mx-2 justify-between items-end">
              {sortedColumns.map((columnKey) => {
                const inputSchema = {
                  ...item.properties[rowKey].properties[columnKey],
                  key: columnKey,
                };

                const inputKey = [ownKey, rowKey].join(".");

                return (
                  <div className="mx-2 flex-1" key={`${rowKey}${columnKey}`}>
                    <MakeComponent
                      item={inputSchema}
                      parentKey={inputKey}
                      formValues={formValues}
                      parentSchema={item.properties[rowKey]}
                      mapChildren={mapChildren}
                      onChange={onChange}
                      setFormValues={setFormValues}
                      validationErrors={formatErrors(validationErrors)}
                      noErrorLabel
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InputMatrix;

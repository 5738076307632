import React from "react";
import PropTypes from "prop-types";
import H3 from "../../../components/H3";
import InputCheckbox from "../../../components/InputCheckbox";
import InputCurrency from "../../../components/InputCurrency";

const BespokeEndorsements = ({ currentEndorsements, handleToggleSelectEndorsement, handleChangeEndorsementValue }) => (
  <>
    <H3>Bespoke endorsements</H3>
    <p className="pb-4">The broker has specified the following as part of this renewal:</p>
    {currentEndorsements.map((endorsement, index) => (
      <InputCheckbox
        key={endorsement.ref}
        isChecked={endorsement.selected}
        labelText={endorsement.ref}
        descriptionText={endorsement.content}
        className="pb-4"
        onChange={handleToggleSelectEndorsement(index)}
      />
    ))}
    {currentEndorsements
      .filter((i) => i.selected)
      .map((endorsement) => (
        <InputCurrency
          className="mb-8"
          key={endorsement.ref}
          name={endorsement.ref}
          labelText={`Bespoke endorsement - ${endorsement.ref}`}
          value={endorsement.value ?? null}
          onChange={handleChangeEndorsementValue(endorsement.ref)}
        />
      ))}
  </>
);

BespokeEndorsements.propTypes = {
  currentEndorsements: PropTypes.array.isRequired,
  handleToggleSelectEndorsement: PropTypes.func.isRequired,
  handleChangeEndorsementValue: PropTypes.func.isRequired,
};

export default BespokeEndorsements;

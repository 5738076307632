import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import { useContractQuery } from "../hooks";

const SubmitRenewalBespokeForm = ({
  contractData,
  parentContractData,
  formData,
  handleConfirm,
  handleClose,
  isRenewal,
  isRenewalRoute,
}) => {
  const contractBespokeEndos = get(contractData, "bespoke_endorsements", []);
  const [formValues, setFormValues] = useState(
    isRenewal && !isRenewalRoute
      ? contractBespokeEndos.reduce((prev, curr) => {
          prev[curr.id] = true;
          return prev;
        }, {})
      : {},
  );
  const bespokeEndos =
    isRenewal && !isRenewalRoute ? get(parentContractData, "bespoke_endorsements", []) : contractBespokeEndos;

  const handleSubmit = async () => {
    const nextBespokeEndos = bespokeEndos.filter((bespokeEndo) => formValues[bespokeEndo.id]);

    handleClose();
    handleConfirm(formData, { bespoke_endorsements: nextBespokeEndos });
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        <div className="mb-8">
          {bespokeEndos.map((bespokeEndo) => (
            <div className="mb-8" key={bespokeEndo.id}>
              <InputCheckbox
                isChecked={formValues[bespokeEndo.id]}
                labelText={bespokeEndo.ref}
                name={bespokeEndo.ref}
                onChange={() =>
                  setFormValues({ ...formValues, [bespokeEndo.id]: !formValues[bespokeEndo.id] })
                }
              />
              <p className="mt-4">{bespokeEndo.content}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <Button onClick={handleClose}>Cancel</Button>

          <Button className="ml-4" kind="primary" type="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

const SubmitRenewalBespokeModal = ({ handleClose, formData, handleConfirm }) => {
  const { contractData, parentContractData, isLoading, isRenewal, isRenewalRoute } = useContractQuery();

  return (
    <Modal
      handleClose={handleClose}
      headingText="Select bespoke endorsements to include"
      isLoading={isLoading}
    >
      <SubmitRenewalBespokeForm
        contractData={contractData}
        parentContractData={parentContractData}
        isRenewal={isRenewal}
        isRenewalRoute={isRenewalRoute}
        formData={formData}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      />
    </Modal>
  );
};

SubmitRenewalBespokeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default SubmitRenewalBespokeModal;

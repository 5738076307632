import { MCHospitalsClinicsSubmission, MCSurgeonsSubmission, ParsedSubmission } from "./types";

export const parseSubmission = (submission: MCHospitalsClinicsSubmission | MCSurgeonsSubmission): ParsedSubmission => {
  if (!submission) throw new Error("Invalid submission.");

  // admin
  const policyNumber = submission?.base?.policy_number;
  const schemeType = submission?.base?.scheme_type;
  const inceptionDate = submission?.base?.inception_date;
  const expiryDate = submission?.base?.expiry_date;
  const renewalReminder = submission?.base?.renewal_reminder;
  const bindingAuthorityRef = submission?.base?.binding_authority_ref;

  // common
  const phoneNumber = submission.base.phone_number;
  const emailAddress = submission.base.email_address;
  const offerProcedures = submission.base.offer_procedures;
  const procedureType = submission.base.procedure_type;
  const typeOfSurgeries = submission.base.type_of_surgeries;
  const noOfRevisionsPast = submission.base.no_of_revisions_past;

  // surgeons
  const title = submission?.base?.title;
  const firstName = submission?.base?.first_name;
  const surnameFamilyname = submission?.base?.surname_familyname;
  const address = submission?.base?.address;
  const country = submission?.base?.country;
  const surgerySocietiesMember = submission?.base?.surgery_societies_member;
  const practicingPrivileges = submission?.base?.practicing_privileges;
  const proceduresInsureAnnum = submission?.base?.procedures_insure_annum;
  const specialityProcedure = submission?.base?.speciality_procedure;

  // hospitals
  const contactName = submission?.base?.contact_name;
  const nameClinicHosp = submission?.base?.name_clinic_hosp;
  const whereBased = submission?.base?.where_based;
  const noOfLocations = submission?.base?.no_of_locations;
  const noOfProcedures = submission?.base?.no_of_procedures;

  // rater
  const rates = submission?.rates ?? [];

  let insuredName = ""; // for notifications
  if (firstName && surnameFamilyname) {
    insuredName = `${firstName} ${surnameFamilyname}`;
  }
  if (nameClinicHosp) {
    insuredName = nameClinicHosp;
  }

  return {
    // admin
    policyNumber,
    schemeType,
    inceptionDate,
    expiryDate,
    renewalReminder,
    bindingAuthorityRef,
    // common
    insuredName,
    phoneNumber,
    emailAddress,
    offerProcedures,
    procedureType,
    typeOfSurgeries,
    noOfRevisionsPast,
    // surgeons
    title,
    firstName,
    surnameFamilyname,
    address,
    country,
    surgerySocietiesMember,
    practicingPrivileges,
    proceduresInsureAnnum,
    specialityProcedure,
    // hospitals
    contactName,
    nameClinicHosp,
    whereBased,
    noOfLocations,
    noOfProcedures,
    // rater
    rates,
  };
};

import Auth from "@aws-amplify/auth";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { ROUTES } from "../../../constants";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputText from "../../components/InputText";
import useForm from "../../hooks/useForm";
import AuthLayout from "./AuthLayout";

const ForgotPassword = () => {
  const [areInstructionsSent, setInstructionsSent] = useState();
  const { getFieldProps, canSubmit, onSubmit, formError } = useForm({
    initialState: { email: "" },
    onSubmit: async (formValues) => {
      await Auth.forgotPassword(formValues.email.trim());

      setInstructionsSent(true);
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
    }),
  });

  return (
    <AuthLayout>
      {!areInstructionsSent && (
        <>
          <Link to={ROUTES.LOGIN} className="block text-sm text-gray-700 font-semibold mb-4">
            &lt; Return to Log In
          </Link>

          <H3 className="mb-4">Forgotten password?</H3>

          <p className="mb-6">
            It happens to all of us. Just enter the email you used to create the account and we’ll
            send you some instructions to reset your password.
          </p>

          {formError && <Banner className="mb-6" color="red" headingText={formError} />}

          <Form onSubmit={onSubmit}>
            <InputText
              className="mb-6"
              isRequired
              labelText="Email address"
              type="email"
              {...getFieldProps("email")}
            />

            <Button kind="primary" type="submit" isDisabled={!canSubmit}>
              Send reset instructions
            </Button>
          </Form>
        </>
      )}

      {areInstructionsSent && (
        <>
          <H3 className="mb-4">Instructions sent!</H3>

          <p className="mb-4">
            Details on how to reset your password should now be in your inbox. We’ll get you up and
            running again in no time.
          </p>

          <p className="mb-6">You may now close this window.</p>

          <div className="flex">
            <Link to={ROUTES.LOGIN}>
              <Button kind="primary">Return to Login</Button>
            </Link>
          </div>
        </>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;

import React from "react";
import Accordion from "../../components/Accordion";
import { asCurrency } from "../../../projects/cargo-us/helpers";
import Table from "../../components/Table";

const { Body, Data, Row, Head, Header } = Table;

type CargoPremiumParameters = {
  name: string;
  grossPremium?: number;
  commissionAmount?: number;
  netPremium: number;
  tax?: number;
  factor?: number;
};

export const CargoPremium = ({
  name,
  grossPremium,
  netPremium,
  commissionAmount,
  tax,
  factor,
}: CargoPremiumParameters): any => {
  return (
    <>
      <div className="border-b border-gray-300">
        <Accordion isSticky>
          <Accordion.Panel key={name} headingText={name}>
            <Table>
              <Body>
                <Row className="border-b border-gray-300 last:border-0">
                  <Data colSpan="5">
                    <div>
                      <Table className="border border-gray-500">
                        <Head className="bg-gray-50">
                          <Header>Gross Premium</Header>
                          <Header>Commission Amount</Header>
                          <Header>Net Premium</Header>
                          <Header>Tax</Header>
                          <Header>Gross Premium plus Tax</Header>
                        </Head>
                        <Body>
                          <Row className="border-b border-gray-300 last:border-0">
                            <Data>{grossPremium !== undefined ? asCurrency(grossPremium * (factor ?? 1)) : "N/A"}</Data>
                            <Data>{commissionAmount !== undefined ? asCurrency(commissionAmount * (factor ?? 1)) : "N/A"}</Data>
                            <Data>{netPremium !== undefined ? asCurrency(netPremium * (factor ?? 1)) : "N/A"}</Data>
                            <Data>{tax !== undefined ? asCurrency(tax) : "N/A"}</Data>
                            <Data>{grossPremium !== undefined ? asCurrency((grossPremium + (tax ?? 0)) * (factor ?? 1)) : "N/A"}</Data>
                          </Row>
                        </Body>
                      </Table>
                    </div>
                  </Data>
                </Row>
              </Body>
            </Table>
          </Accordion.Panel>
        </Accordion>
      </div>
    </>
  );
};

import React from "react";
import { currencyFormatter } from "../../../../formatters";
import {
  adjustablePremiumOptions,
  getTotalIndividual,
  getTotalOverall,
  labelToKeysMap,
} from "../../../../projects/mel/adjustablePremium";
import InputCurrency from "../../../components/InputCurrency";
import InputPercentage from "../../../components/InputPercentage";
import Table from "../../../components/Table";

const { Body, Data, Head, Header, Row } = Table;

const RatesTable = ({ payrollBreakdown, formValues, getFieldProps }) => {
  const adjustablePremiumTypes = adjustablePremiumOptions[formValues.adjustable_premium_option];
  const config = adjustablePremiumTypes?.[formValues.adjustable_premium_type] || [];
  const [eligibleTypes = [], split] = config;
  const isOverall = split === "overall";
  const isPeople = formValues.adjustable_premium_option === "Minimum and deposit adjustable on people";

  const { premiumMoney } = isOverall
    ? getTotalOverall({ payrollBreakdown, formValues })
    : getTotalIndividual({ payrollBreakdown, formValues });

  return (
    <Table className="mb-6">
      <Head>
        <Header>Payroll types</Header>

        <Header>Amount</Header>

        {!isOverall && <Header>Adjustable rate</Header>}

        <Header>Premium</Header>
      </Head>
      <Body>
        {eligibleTypes.map((eligibleType) => {
          const payrollKeys = labelToKeysMap[eligibleType];
          const amount = payrollKeys.reduce((prev, next) => {
            const nextAmount = payrollBreakdown?.[next]?.next_year?.amount || 0;
            const nextPeople = payrollBreakdown?.[next]?.next_year_employees || 0;

            return (isPeople ? nextPeople : nextAmount) + prev;
          }, 0);

          const rate = isPeople
            ? formValues?.adjustableRates?.overall_people?.amount
            : formValues?.adjustableRates?.overall_payroll / 100;

          const individualRate = isPeople
            ? formValues?.adjustableRates?.[payrollKeys.join("#")]?.amount
            : formValues?.adjustableRates?.[payrollKeys.join("#")] / 100;
          const inputKey = [formValues.adjustable_premium_type, payrollKeys].join(".");

          return (
            <Row key={eligibleType} className="border-b border-gray-500 last:border-0">
              <Data className="w-2/5">{eligibleType}</Data>

              <Data className="w-1/5">
                {isPeople && (amount || 0)}
                {!isPeople && currencyFormatter({ amount: amount || 0, currency: "USD" })}
              </Data>

              {!isOverall && (
                <Data className="w-1/5">
                  {isPeople && (
                    <InputCurrency
                      isRequired
                      key={inputKey}
                      {...getFieldProps(`adjustableRates.${payrollKeys.join("#")}`)}
                    />
                  )}

                  {!isPeople && (
                    <InputPercentage
                      isRequired
                      key={inputKey}
                      decimalScale={2}
                      isAllowed={({ floatValue, value }) => value === "" || floatValue <= 100}
                      {...getFieldProps(`adjustableRates.${payrollKeys.join("#")}`)}
                    />
                  )}
                </Data>
              )}

              <Data className="w-1/5">
                {!isOverall && currencyFormatter({ amount: amount * individualRate || 0 })}
                {isOverall && currencyFormatter({ amount: amount * rate || 0 })}
              </Data>
            </Row>
          );
        })}

        <Row className="border-b border-gray-500 last:border-0">
          <Data className="w-2/5 text-right font-semibold" colSpan={isOverall ? 1 : 3}>
            Overall rate
          </Data>

          {isOverall && (
            <Data className="w-1/5">
              {isPeople && (
                <InputCurrency
                  isRequired
                  key={formValues.adjustable_premium_type}
                  {...getFieldProps("adjustableRates.overall_people")}
                />
              )}

              {!isPeople && (
                <InputPercentage
                  isRequired
                  key={formValues.adjustable_premium_type}
                  isAllowed={({ floatValue, value }) => value === "" || floatValue <= 100}
                  decimalScale={2}
                  {...getFieldProps("adjustableRates.overall_payroll")}
                />
              )}
            </Data>
          )}

          <Data className="w-1/5 font-semibold">{currencyFormatter(premiumMoney)}</Data>
        </Row>
      </Body>
    </Table>
  );
};

export default RatesTable;

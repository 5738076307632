import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const SanctionsCheckFailModal = ({ handleClose, handleConfirm, message }) => (
  <Modal handleClose={handleClose} headingText="Sanctions check - failed">
    <div className="p-6">
      <p className="mb-6">{message}</p>

      <div className="flex">
        <Button onClick={handleClose} className="mr-4">
          Close
        </Button>
      </div>
    </div>
  </Modal>
);

SanctionsCheckFailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default SanctionsCheckFailModal;

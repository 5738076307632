import PropTypes from "prop-types";
import React from "react";
import { ACTIONS } from "../../constants";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { useQuoteQuery } from "../hooks";

const AcceptTechnicalPriceModal = ({ handleClose }) => {
  const { contractId, productRef, quoteId, updateQuote, isUpdating } = useQuoteQuery();

  const handleConfirm = async () => {
    const res = await updateQuote({
      data: { type: ACTIONS.ACCEPT_TECHNICAL_PRICE },
      productRef,
      contractId,
      quoteId,
    });

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Accept technical price">
      <div className="p-6 w-160">
        <p className="mb-6"> Are you sure you’d like to accept the technical price and submit it to the broker?</p>

        <div className="flex">
          <Button kind="primary" onClick={handleConfirm} isDisabled={isUpdating}>
            Accept price
          </Button>

          <Button onClick={handleClose} className="ml-4">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AcceptTechnicalPriceModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default AcceptTechnicalPriceModal;

import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const EditQuoteWarningModal = ({ handleClose, handleConfirm }) => (
  <Modal handleClose={handleClose} headingText="Are you sure?">
    <div className="p-6 w-160">
      <p className="mb-6">
        The quotes on this policy will be archived. Edit submission to get a new quote or cancel to
        stay on this page.
      </p>

      <div className="flex">
        <Button kind="primary" onClick={handleConfirm} className="mr-4">
          Edit submission
        </Button>

        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </div>
  </Modal>
);

EditQuoteWarningModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default EditQuoteWarningModal;

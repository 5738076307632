import { SchemaRef } from "../../@types/types";
import { inRange } from "../../helpers/inRange";
import { onlyUnique } from "./helpers";
import { parseSubmission } from "./parseSubmission";
import countries from "./refData/countries";
import { Conveyance } from "./types";

export const checkReferred = (
  submission: unknown,
  schemaRef: SchemaRef,
): { isReferred: boolean; reasons: string[] } => {
  const { insured, cargo, conveyance, shipment, storage } = parseSubmission(submission, schemaRef);

  const reasons = [];

  if (countries.find((row) => row[1] === insured.country)?.[5]) {
    reasons.push("Referred due to chosen Country");
  }

  if (insured.hadInsuranceRefused) {
    reasons.push("Referred due to claims refused, declined or special terms imposed");
  }

  if (cargo?.cargos?.some((cargo) => cargo.isReferred)) {
    reasons.push("This has been referred due to the type of cargo");
  }

  if (
    conveyance?.conveyances?.some(
      ({ type, limit }: Conveyance) => type === "Post" && limit?.amount !== undefined && limit.amount > 1_000,
    )
  ) {
    reasons.push("Referred due to conveyance type of post and limit is greater than $1000");
  }

  if (
    conveyance?.conveyances?.some(
      ({ type, limit, aovTransits }: Conveyance) =>
        type !== "Post" && !aovTransits && limit?.amount !== undefined && inRange(limit?.amount, 400_000, 1_000_000),
    )
  ) {
    reasons.push("Referred due to limit equal to and greater than $400K, and equal to or less than $1million");
  }

  if (
    conveyance?.conveyances?.some(
      ({ ownVehicleLimit, aovTransits, type }: Conveyance) =>
        type === "Road only" &&
        aovTransits &&
        ownVehicleLimit?.amount !== undefined &&
        inRange(ownVehicleLimit?.amount, 250_000, 1_000_000),
    )
  ) {
    reasons.push(
      "Referred due to own vehicle limit being equal to or greater than $250k or equal to or less than $1million",
    );
  }

  if (shipment?.shipments?.some(({ ownTurnover }) => ownTurnover?.amount > 50_000_000)) {
    reasons.push("Refered due to Geographical Area being greater than 50,000,000");
  }

  if (shipment?.shipments?.some(({ contingentTurnover }) => contingentTurnover?.amount > 50_000_000)) {
    reasons.push("Refered due to Geographical Contingent being greater than 50,000,000");
  }

  if (shipment?.shipments?.some(({ isReferred }) => isReferred)) {
    reasons.push("Referred due to chosen Country");
  }

  if (storage?.storages?.some(({ isOutsideWesternEuropeUsAndCanada }) => isOutsideWesternEuropeUsAndCanada)) {
    reasons.push("Referred due to the storage location being outside of Western Europe and US & Canada");
  }

  if (storage?.storages?.some(({ limit }) => limit?.amount !== undefined && limit.amount >= 250_000)) {
    reasons.push("Referred due to the location limit being greater than or equal to 250,000");
  }

  if (storage?.storages?.some(({ buildingAge }) => buildingAge !== undefined && inRange(buildingAge, 20, 35))) {
    reasons.push("Referred due to building age 20-35");
  }

  if (storage?.storages?.some(({ hasNoRoof }) => hasNoRoof)) {
    reasons.push("Referred due to the building having no fully enclosed roof");
  }

  if (storage?.storages?.some(({ notStandardsCompliant }) => notStandardsCompliant)) {
    reasons.push("Referred due to not complying with local standard construction regulations");
  }

  if (storage?.storages?.some(({ isProcessingAtLocation }) => isProcessingAtLocation)) {
    reasons.push("Referred due to location processing");
  }

  if (storage?.storages?.some(({ multiTenure }) => multiTenure)) {
    reasons.push("Referred due to multi tenure");
  }

  if (storage?.storages?.some(({ isOnFloodPlain }) => isOnFloodPlain)) {
    reasons.push("Referred due to storage being on a flood plain");
  }

  if (storage?.storages?.some(({ noSprinklers }) => noSprinklers)) {
    reasons.push("Referred due to no sprinklers");
  }

  if (storage?.storages?.some(({ alarmsSecurity }) => alarmsSecurity === "Neither")) {
    reasons.push("Referred due to neither security or fire alarms");
  }

  if (storage?.storages?.some(({ alarmsNotMonitored }) => alarmsNotMonitored)) {
    reasons.push("Referred due to no alarms monitored");
  }

  if (storage?.storages?.some(({ alarmsNoMaintenance }) => alarmsNoMaintenance)) {
    reasons.push("Referred due to no alarm maintenence contract");
  }

  if (storage?.storages?.some(({ noPhysicalSecurity }) => noPhysicalSecurity)) {
    reasons.push("Referred due to no physical security");
  }

  if (countries.find((row) => row[0] === shipment.countryTo)?.[5]) {
    reasons.push("__MISSING_REFERRAL_MESSAGE_VOYAGE_TO_COUNTRY__");
  }

  if (countries.find((row) => row[0] === shipment.countryFrom)?.[5]) {
    reasons.push("__MISSING_REFERRAL_MESSAGE_VOYAGE_FROM_COUNTRY__");
  }

  const uniqueReasons = reasons.filter(onlyUnique);

  return { isReferred: uniqueReasons.length > 0, reasons: uniqueReasons };
};

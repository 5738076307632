/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useStickyState from "../hooks/useStickyState";
import Icon from "./Icon";

const Panel = ({ children, isExpanded, tag, headingText, secondaryText, onClick, isTransparent }) => (
  <div
    className={clsx(
      isTransparent
        ? "overflow-hidden"
        : "border border-b-0 last:border-b first:rounded-t-lg last:rounded-b-lg border-gray-300 overflow-hidden",
    )}
  >
    <div
      onClick={onClick}
      className={clsx(
        isTransparent
          ? "p-4 transition duration-150 ease-in-out cursor-pointer select-none"
          : "p-4 hover:bg-gray-200 transition duration-150 ease-in-out bg-gray-100 cursor-pointer select-none",
      )}
    >
      <div className="flex justify-between">
        <div className={clsx("flex items-center", secondaryText ? "w-full" : "")}>
          <div className={clsx("font-medium leading-normal mr-4", secondaryText ? "w-full" : "")}>
            {secondaryText ? (
              <>
                <span className="inline-block w-1/2 pl-2">{headingText}</span>
                <span className="pl-7 w-1/2">{secondaryText}</span>
              </>
            ) : (
              headingText
            )}
          </div>
          <span className="bg-blue-600 rounded-full py-0.5 text-xs text-white font-medium">{tag}</span>
        </div>
        <div className="flex items-center">
          <Icon name={isExpanded ? "chevron-up" : "chevron-down"} className="mx-3 fill-current text-gray-800" />
        </div>
      </div>
    </div>
    {isExpanded && children}
  </div>
);

const Accordion = ({ className, children, initialActiveIds, id, expandAll, isSticky }) => {
  const allIds = React.Children.toArray(children).map((child) => child.props.id || child.props.headingText);
  const initialIds = expandAll ? allIds : initialActiveIds;
  const stateHook = isSticky ? useStickyState : useState;
  const [selectedIds, setSelectedIds] = stateHook(initialIds, id);

  const handleItemClick = (itemId, isExpanded) => (event) => {
    const nextIds = isExpanded ? selectedIds.filter((item) => item !== itemId) : [...selectedIds, itemId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  return (
    <div className={clsx(className)}>
      {React.Children.toArray(children).map((child) => {
        const itemId = child.props.id || child.props.headingText;
        const isExpanded = selectedIds.includes(itemId);

        return React.cloneElement(child, {
          isExpanded,
          onClick: handleItemClick(itemId, isExpanded),
        });
      })}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  headingText: PropTypes.string,
  secondaryText: PropTypes.string,
  isTransparent: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  tag: PropTypes.node,
};

Panel.defaultProps = {
  children: undefined,
  headingText: undefined,
  secondaryText: undefined,
  isTransparent: undefined,
  isExpanded: undefined,
  onClick: undefined,
  tag: undefined,
};

Accordion.propTypes = {
  expandAll: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  initialActiveIds: PropTypes.arrayOf(PropTypes.string),
  isSticky: PropTypes.bool,
};

Accordion.defaultProps = {
  expandAll: true,
  children: undefined,
  className: undefined,
  id: undefined,
  initialActiveIds: [],
  isSticky: false,
};

Accordion.Panel = Panel;

export default Accordion;

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";

const Tag = ({ className, children, text, icon }) => (
  <span
    className={clsx(
      "text-xs font-medium rounded-full px-2 py-0.5",
      !clsx(className).match(/h-(\d+)/) && "",
      className
    )}
  >
    {icon && <Icon name={icon} className="w-3 mr-2" />}
    {children}
    {text}
  </span>
);

Tag.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Tag.defaultProps = {
  children: undefined,
  className: undefined,
  icon: undefined,
  text: undefined,
};

export default Tag;

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const Label = ({ children, className, htmlFor, isRequired }) => (
  <label htmlFor={htmlFor} className={clsx("block", className)}>
    {children}
    {isRequired && <span className="text-red-900"> *</span>}
  </label>
);

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  isRequired: PropTypes.bool,
};

Label.defaultProps = {
  children: undefined,
  className: undefined,
  htmlFor: undefined,
  isRequired: undefined,
};

export default Label;

const TOTAL_100_VALIDATION_ERROR = 'should pass "total100" keyword validation';

const getErrorMessage = (error) => {
  if (error.message === TOTAL_100_VALIDATION_ERROR && error.schema === "cargo_type_percentage") {
    return { ...error, message: "Cargo types should add up to 100%" };
  }

  if (error.message === TOTAL_100_VALIDATION_ERROR && error.schema === "percentage_of_turnover") {
    return { ...error, message: "Conveyance should add up to 100%" };
  }

  if (error.message === TOTAL_100_VALIDATION_ERROR) {
    return { ...error, message: "Diving types should add up to 100%" };
  }

  if (error.keyword === "required") {
    return { ...error, message: `Field is required` };
  }

  // Temporary quickfix
  if (error.keyword === "oneOf") {
    return null;
  }

  return error;
};

const formatErrors = (errors) => {
  if (!errors) {
    return null;
  }

  return errors.map(getErrorMessage).filter(Boolean);
};

export default formatErrors;

import Ajv from "ajv";
import isLT60days from "../keywords/isLT60days";
import total100 from "../keywords/total100";

const ajv = new Ajv({ allErrors: true, verbose: true, coerceTypes: true, useDefaults: true });

ajv.addKeyword("total100", total100);
ajv.addKeyword("isLT60days", isLT60days);

export default ajv;

import React from "react";
import { useRouteMatch } from "react-router-dom";
import Table from "../../components/Table";
import { CargoPremium } from "./CargoPremium";
import { ROUTES } from "../../../constants";

const { Body, Data, Row } = Table;

export const CargoPremiums = ({ quoteData }) => {
  const isEndorsementsRoute = useRouteMatch(ROUTES.CONTRACT_VIEW_ENDORSEMENTS);
  const isCancellation =
    isEndorsementsRoute !== null && quoteData.type === "endorsement" && quoteData.status === "Cancelled";

  return (
    <>
      <div>
        <Table>
          <Body>
            <Row className="border-b border-gray-300 last:border-0">
              <Data className="w-3/5">Commission percentage</Data>
              <Data className="w-2/5">{quoteData?.commission ?? "N/A"}</Data>
            </Row>
          </Body>
        </Table>
      </div>

      {!isCancellation && (
        <>
          <CargoPremium
            name="Transit premium"
            grossPremium={quoteData?.rates?.basePremium?.grossPremium}
            commissionAmount={quoteData?.rates?.basePremium?.commissionAmount}
            netPremium={quoteData?.rates?.basePremium?.netPremium}
            tax={quoteData?.rates?.basePremium?.tax}
          />
          <CargoPremium
            name="Storage premium"
            grossPremium={quoteData?.rates?.storagesPremium?.grossPremium ?? quoteData?.rates?.storagePremium?.grossPremium}
            commissionAmount={quoteData?.rates?.storagesPremium?.commissionAmount ?? quoteData?.rates?.storagePremium?.commissionAmount}
            netPremium={quoteData?.rates?.storagesPremium?.netPremium ?? quoteData?.rates?.storagePremium?.netPremium}
            tax={quoteData?.rates?.storagesPremium?.tax ?? quoteData?.rates?.storagePremium?.tax}
          />
          <CargoPremium
            name="Exhibitions premium"
            grossPremium={quoteData?.rates?.exhibitionsPremium?.grossPremium}
            commissionAmount={quoteData?.rates?.exhibitionsPremium?.commissionAmount}
            netPremium={quoteData?.rates?.exhibitionsPremium?.netPremium}
            tax={quoteData?.rates?.exhibitionsPremium?.tax}
          />
          <CargoPremium
            name="Samples premium"
            grossPremium={quoteData?.rates?.samplesPremium?.grossPremium}
            commissionAmount={quoteData?.rates?.samplesPremium?.commissionAmount}
            netPremium={quoteData?.rates?.samplesPremium?.netPremium}
            tax={quoteData?.rates?.samplesPremium?.tax}
          />
        </>
      )}
      {isCancellation && quoteData?.rates?.proRataFactor && (
        <>
          <CargoPremium
            name="Transit premium"
            grossPremium={quoteData?.rates?.basePremium?.grossPremium}
            commissionAmount={quoteData?.rates?.basePremium?.commissionAmount}
            netPremium={quoteData?.rates?.basePremium?.netPremium}
            tax={quoteData?.rates?.refundTransitPremiumTax}
            factor={quoteData.rates.proRataFactor}
          />
          <CargoPremium
            name="Storage premium"
            grossPremium={quoteData?.rates?.storagesPremium?.grossPremium ?? quoteData?.rates?.storagePremium?.grossPremium}
            commissionAmount={quoteData?.rates?.storagesPremium?.commissionAmount ?? quoteData?.rates?.storagePremium?.commissionAmount}
            netPremium={quoteData?.rates?.storagesPremium?.netPremium ?? quoteData?.rates?.storagePremium?.netPremium}
            tax={quoteData?.rates?.refundStoragePremiumTax}
            factor={quoteData.rates.proRataFactor}
          />
          <CargoPremium
            name="Exhibitions premium"
            grossPremium={quoteData?.rates?.exhibitionsPremium?.grossPremium}
            commissionAmount={quoteData?.rates?.exhibitionsPremium?.commissionAmount}
            netPremium={quoteData?.rates?.exhibitionsPremium?.netPremium}
            tax={quoteData?.rates?.refundExhibitionsPremiumTax}
            factor={quoteData.rates.proRataFactor}
          />
          <CargoPremium
            name="Samples premium"
            grossPremium={quoteData?.rates?.samplesPremium?.grossPremium}
            commissionAmount={quoteData?.rates?.samplesPremium?.commissionAmount}
            netPremium={quoteData?.rates?.samplesPremium?.netPremium}
            tax={quoteData?.rates?.refundSamplesPremiumTax}
            factor={quoteData.rates.proRataFactor}
          />
        </>
      )}
    </>
  );
};

import moment from "moment";
import { useEffect, useState } from "react";

export const useTimeAgo = (timestamp) => {
  const [delta, setDelta] = useState(Date.now() - timestamp);
  const deltaInSeconds = Math.floor(delta / 1000);
  const deltaInMinutes = Math.floor(deltaInSeconds / 60);
  const deltaInHours = Math.floor(deltaInMinutes / 60);

  useEffect(() => {
    const interval = setInterval(() => setDelta(Date.now() - timestamp), 10000);

    return () => clearInterval(interval);
  }, [timestamp]);

  if (deltaInSeconds < 60) {
    return "a few seconds ago";
  }

  if (deltaInMinutes < 2) {
    return "a minute ago";
  }

  if (deltaInMinutes < 60) {
    return `${deltaInMinutes} minutes ago`;
  }

  if (deltaInHours < 2) {
    return "over an hour ago";
  }

  if (deltaInHours < 6) {
    return `few hours ago`;
  }

  return `on ${moment(timestamp).format("DD/MMM/YYYY")}`;
};

export default useTimeAgo;

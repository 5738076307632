/* eslint-disable jsx-a11y/no-autofocus */

import clsx from "clsx";
import Downshift from "downshift";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const sizes = {
  normal: "h-12",
  small: "h-10",
};

const styles = {
  button:
    "bg-white border border-gray-300 px-3 py-2 rounded text-gray-900 h-12 flex justify-between items-center w-full focus:outline-none focus:shadow-outline",
  input: "border-b border-gray-100 px-3 py-2 text-gray-900 w-full focus:outline-none",
  menuInner: "overflow-y-auto max-h-56",
  menuItem: "py-2 px-3 cursor-pointer",
  menuOuter:
    "border-gray-700 border rounded absolute inset-x-0 top-0 z-20 bg-white text-gray-900 mt-16 shadow overflow-hidden",
};

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return { ...changes, inputValue: "" };

    case Downshift.stateChangeTypes.clickButton:
    case Downshift.stateChangeTypes.changeInput:
      return { ...changes, highlightedIndex: 0 };

    default:
      return changes;
  }
};

const InputSelect = ({
  className,
  descriptionText,
  errors,
  formatter,
  id,
  initialSelectedItem,
  isReadOnly,
  isSearchable,
  itemFormatter,
  labelText,
  name,
  onChange,
  onSelect,
  options,
  size,
  value,
  ...props
}) => {
  const getItemToString = (item) => {
    const val = get(item, "value");

    return val || val === 0 ? String(val) : "";
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      name={name}
      errors={errors}
      {...props}
    >
      {isReadOnly && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={
            initialSelectedItem
              ? formatter && initialSelectedItem
                ? formatter(initialSelectedItem)
                : initialSelectedItem
              : formatter && value
              ? formatter(value)
              : value
          }
        />
      )}

      {!isReadOnly && (
        <Downshift
          stateReducer={stateReducer}
          selectedItem={value || ""}
          itemToString={getItemToString}
          onSelect={(item) => {
            if (onChange) onChange(item.value, name);
          }}
        >
          {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            highlightedIndex,
            isOpen,
            inputValue,
            getInputProps,
          }) => {
            const normalized = String(inputValue).toLowerCase();
            const byInputValue = (item) => String(item.name).toLowerCase().includes(normalized);
            const filteredItems = options?.filter(byInputValue);
            const selectableItems = isSearchable ? filteredItems : options;
            const hasSelection = Boolean(value);

            return (
              <div className="relative">
                <button
                  {...getToggleButtonProps()}
                  aria-label={labelText}
                  aria-labelledby={props["aria-labelledby"]}
                  type="button"
                  className={clsx("aui-input aui-input-select", errors && "aui-input--errors", sizes[size])}
                >
                  {!hasSelection && <span className="text-gray-700">Select</span>}

                  {hasSelection && formatter && formatter(value)}

                  {hasSelection && !formatter && value}

                  {!isOpen && <Icon name="chevron-down" className="ml-2 fill-current text-gray-800" />}

                  {isOpen && <Icon name="chevron-up" className="ml-2 fill-current text-blue-700" />}
                </button>

                {isOpen && (
                  <div className={styles.menuOuter} {...getMenuProps()}>
                    {isSearchable && (
                      <input autoFocus className={styles.input} placeholder="Search..." {...getInputProps()} />
                    )}

                    <ul className={styles.menuInner}>
                      {selectableItems?.map((item, index) => {
                        const result =
                          (itemFormatter && itemFormatter(item.value)) ||
                          (formatter && formatter(item.value)) ||
                          item.name;

                        return (
                          <li
                            className={clsx(styles.menuItem, index === highlightedIndex && "bg-gray-100")}
                            index={index}
                            key={JSON.stringify(item.value)}
                            {...getItemProps({ item })}
                          >
                            {result}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            );
          }}
        </Downshift>
      )}
    </InputField>
  );
};

InputSelect.propTypes = {
  "aria-labelledby": PropTypes.string,
  className: PropTypes.string,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  formatter: PropTypes.func,
  id: PropTypes.string,
  initialSelectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isReadOnly: PropTypes.bool,
  isSearchable: PropTypes.bool,
  itemFormatter: PropTypes.func,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  size: PropTypes.oneOf(["small", "normal"]),
};

InputSelect.defaultProps = {
  "aria-labelledby": undefined,
  className: undefined,
  descriptionText: undefined,
  formatter: undefined,
  id: undefined,
  initialSelectedItem: undefined,
  isReadOnly: undefined,
  isSearchable: false,
  itemFormatter: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  onSelect: undefined,
  options: undefined,
  size: "normal",
};

export default InputSelect;

import { useQuery } from "react-query";
import * as api from "../api";

export const useTenantsQuery = (): unknown => {
  const options = { enabled: true };
  const tenantsQuery = useQuery(["tenants"], api.getTenants, options);

  const tenants = tenantsQuery?.data?.data || [];
  const isLoading = tenantsQuery.isLoading;

  return {
    tenants,
    isLoading,
  };
};

export default useTenantsQuery;

import React from "react";
import { ContractStatus } from "../../@types/types";
import Status from "../../client/components/Status";
import { STATUSES } from "../../constants";
import { dateFormatter } from "../../formatters";

export const attachments = {
  uploadingADocumentWillTriggerAReferralMessageEnabled: false,
};

export const columns = [
  {
    className: "w-32",
    displayName: "Reference",
    formatter: (v: string): string => `#${v}`,
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured",
    path: "submission.insured.insured_name",
  },
  {
    displayName: "Broker Name",
    path: "createdBy.organisationName",
  },
  {
    displayName: "Broker Contact",
    path: "createdBy.fullName",
  },
  {
    className: "w-32",
    displayName: "Submitted",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Policy status",
    path: "status",
    formatter: (value: ContractStatus): JSX.Element => <Status status={value} kind="small" />,
  },
  {
    className: "w-32",
    displayName: "Endorsement status",
    path: "state",
    formatter: (value: any): boolean | JSX.Element => {
      if (typeof value !== "string") {
        const status = Object.entries(value)?.[0]?.[1];

        if (status !== STATUSES.IDLE) {
          return <Status status={status} kind="small" />;
        }
      }

      return false;
    },
  },
];

export const inboxColumns = [
  ...columns,
  {
    className: "w-24",
    displayName: "Unread messages",
    path: "messagesCount",
    formatter: (messagesCount: number): JSX.Element => (
      <Status
        className={messagesCount === 0 ? "text-gray-900 bg-gray-200" : "text-red-1000 bg-red-100"}
        kind="small"
        statusText={messagesCount.toString()}
      />
    ),
  },
];

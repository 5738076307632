import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { currencyFormatter } from "../../formatters";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputCurrency = ({
  className,
  decimalScale,
  defaultValue,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  labelText,
  name,
  noErrorLabel,
  onChange,
  value,
  item,
}) => {
  const currency =
  process.env.DEFAULT_PRODUCT_REF === "cargo-us"
      ? "USD"
      : get(value, "currency", get(item, "properties.currency.enum[0]", "USD"));
  const amount = get(value, "amount");
  if (!decimalScale) {
    decimalScale = get(item, "decimalScale", 0);
  }

  // default sure decimal scale
  if (process.env.DEFAULT_PRODUCT_REF === "mc") {
    decimalScale = 2;
  }

  const handleChange = ({ floatValue }) => {
    if (onChange) {
      onChange(floatValue || floatValue === 0 ? { amount: floatValue, currency } : "", name);
    }
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      noErrorLabel={noErrorLabel}
    >
      {!isReadOnly && (
        <div className="relative">
          {currency === "USD" && (
            <div className="absolute inset-y-0 left-0 flex items-center justify-center w-8">$</div>
          )}
          {currency === "GBP" && (
            <div className="absolute inset-y-0 left-0 flex items-center justify-center w-8">£</div>
          )}
          {currency === "EUR" && (
            <div className="absolute inset-y-0 left-0 flex items-center justify-center w-8">€</div>
          )}
          <NumberFormat
            allowNegative={false}
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className="aui-input aui-input-currency"
            customInput={Input}
            decimalScale={decimalScale}
            defaultValue={defaultValue && defaultValue.amount}
            errors={errors}
            fixedDecimalScale
            id={name}
            isNumericString
            maxLength="22"
            name={name}
            onValueChange={handleChange}
            thousandSeparator
            value={amount}
            isRequired={isRequired}
            isAllowed={({ floatValue, value }) => {
              if (value === "" || (!item?.properties?.amount?.maximum && !item?.properties?.amount?.minimum)) {
                return true;
              }

              if (item?.properties?.amount?.maximum) {
                return floatValue <= item?.properties?.amount?.maximum;
              }

              if (item?.properties?.amount?.minimum) {
                return floatValue >= item?.properties?.amount?.minimum;
              }
            }}
          />
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly
          key={JSON.stringify(value)}
          id={id || name}
          name={name}
          errors={errors}
          value={value.amount || value.amount === 0 ? currencyFormatter({ amount: value.amount, currency: value.currency, digits: decimalScale }) : undefined}
        />
      )}
    </InputField>
  );
};

InputCurrency.propTypes = {
  className: PropTypes.string,
  decimalScale: PropTypes.number,
  defaultValue: PropTypes.object,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

InputCurrency.defaultProps = {
  className: undefined,
  decimalScale: 0,
  defaultValue: undefined,
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  value: {},
};

export default InputCurrency;

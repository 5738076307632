import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ACTIONS } from "../../constants";
import ajv from "../../helpers/ajv";
import { resolveSchema } from "../../helpers/resolveSchema";
import Button from "../components/Button";
import Form from "../components/Form";
import Modal from "../components/Modal";
import mapChildren from "../helpers/mapChildren";
import { useContractQuery } from "../hooks";

const NotTakenUpForm = ({ handleClose, schemaData }) => {
  const [formValues, setFormValues] = useState({});
  const { updateContract, isUpdating, productRef, contractId, endorsementId } = useContractQuery();
  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await updateContract({
      contractId,
      data: { type: ACTIONS.REJECT_CONTRACT_NOT_TAKEN_UP, payload: formValues },
      endorsementId,
      productRef,
    });

    handleClose();
  };

  return (
    <div className="p-6">
      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        <div className="flex">
          <Button className="mr-4" isDisabled={!isValid || isUpdating} kind="primary" type="submit">
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const NotTakenUpModal = ({ handleClose }) => {
  const { schemaData, isLoading } = useContractQuery();

  return (
    <Modal handleClose={handleClose} headingText="Not taken up" isLoading={isLoading}>
      <NotTakenUpForm handleClose={handleClose} schemaData={schemaData.properties.NotTakenUpForm} />
    </Modal>
  );
};

NotTakenUpModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default NotTakenUpModal;

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import unset from "lodash.unset";
import React, { useState } from "react";
import { ACTIONS } from "../../../constants";
import ajv from "../../../helpers/ajv";
import { resolveSchema } from "../../../helpers/resolveSchema";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Form from "../../components/Form";
import mapChildren from "../../helpers/mapChildren";
import { useContractQuery } from "../../hooks";

const EndoReferralDecision = ({ className, schemaData, endorsementId }) => {
  const [formValues, setFormValues] = useState({});
  const clonedSchema = cloneDeep(schemaData?.properties?.EndorsementReferralForm);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(clonedSchema);
  const isValid = validate(clonedValues);
  const { updateContract, isUpdating, productRef, contractId } = useContractQuery({ endorsementId });

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);
    const value = args?.[0]?.target?.value || args?.[0];
    const name = args?.[0]?.target?.name || args?.[1];

    if (name === "action") {
      unset(clonedFormValues, "create_quote");
      unset(clonedFormValues, "decline_quote");
      unset(clonedFormValues, "refund_premium");
      unset(clonedFormValues, "additional_premium");
    }

    if (name === "create_quote") {
      unset(clonedFormValues, "refund_premium");
      unset(clonedFormValues, "additional_premium");
    }

    set(clonedFormValues, name, value);
    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    if (formValues?.action === "Decline to quote") {
      return updateContract({
        data: {
          type: ACTIONS.DECLINE_TO_QUOTE_CONTRACT,
          payload: formValues?.decline_quote,
        },
        productRef,
        contractId,
        endorsementId,
      });
    }

    const ap = formValues?.additional_premium?.amount;
    const rp = -formValues?.refund_premium?.amount;
    const aprp = ap || rp || 0;

    return updateContract({
      data: {
        type: ACTIONS.PROVIDE_ENDO_COMMERCIAL_PRICE,
        payload: { aprp },
      },
      productRef,
      contractId,
      endorsementId,
    });
  };

  return (
    <Card headingText="Next steps" className={className}>
      <div className="p-6">
        <Form onSubmit={handleSubmit} id="decisionForm">
          {mapChildren({
            formValues,
            onChange: handleChange,
            parentKey: "",
            parentSchema: resolvedSchema,
            setFormValues,
            validationErrors: validate.errors,
          })}

          {formValues?.action === "Create quote" && formValues?.create_quote && (
            <div className="flex">
              <Button className="mr-4" isDisabled={isUpdating || !isValid} kind="primary" type="submit">
                Create quote
              </Button>
            </div>
          )}

          {formValues?.action === "Decline to quote" && (
            <div className="flex">
              <Button className="mr-4" isDisabled={isUpdating || !isValid} kind="primary" type="submit">
                Decline quote
              </Button>
            </div>
          )}
        </Form>
      </div>
    </Card>
  );
};

export default EndoReferralDecision;

import { QUOTE_TYPES } from "./quoteTypes";
import { STATUSES } from "./statuses";

export const STATUS_TEXT_MAP = {
  [STATUSES.REJECTED_NOT_TAKEN_UP]: "Not taken up",
  [STATUSES.QUOTED]: "Ready to bind",
  [STATUSES.REFERRED_QUOTED]: "Ready to bind",
  [STATUSES.DECLINED]: "Decline to quote",
  [STATUSES.DECLINED_EXPIRED]: "Declined",
  [QUOTE_TYPES.DEFAULT]: "Default",
};

export default STATUS_TEXT_MAP;

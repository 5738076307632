import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, QUOTE_STATUSES, QUOTE_TYPES, STATUSES } from "../../../constants";
import { currencyFormatter } from "../../../formatters";
import { prepareContractDetails } from "../../../helpers/prepareContractDetails";
import { stringifyParams } from "../../../helpers/stringifyParams";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import Status from "../../components/Status";
import Table from "../../components/Table";
import { useAuth, useModal } from "../../hooks";
import { useAttachmentQuery } from "../../hooks/useAttachmentQuery";
import { CargoPremiums } from "./CargoPremiums";

const { Body, Data, Row, Head, Header } = Table;

const bySubmitted = (quoteData) => quoteData.status === QUOTE_STATUSES.SUBMITTED;

const columns = [
  {
    displayName: "Ref",
    id: "type",
    extractValue: ({ quoteData, index }) => quoteData?.ref,
  },
  {
    displayName: "Gross Premium",
    formatter: (amount) => currencyFormatter({ amount, digits: 2 }),
    extractValue: ({ quoteData }) =>
      (quoteData?.rates?.basePremium?.grossPremium ?? 0) +
      (quoteData?.rates?.storagesPremium?.grossPremium ?? 0) /* deprecated */ +
      (quoteData?.rates?.storagePremium?.grossPremium ?? 0) +
      (quoteData?.rates?.exhibitionsPremium?.grossPremium ?? 0) +
      (quoteData?.rates?.samplesPremium?.grossPremium ?? 0),
  },
  {
    displayName: "Tax",
    formatter: (amount) => currencyFormatter({ amount, digits: 2 }),
    extractValue: ({ quoteData }) =>
      (quoteData?.rates?.basePremium?.tax ?? 0) +
      (quoteData?.rates?.storagesPremium?.tax ?? 0) /* deprecated */ +
      (quoteData?.rates?.storagePremium?.tax ?? 0) +
      (quoteData?.rates?.exhibitionsPremium?.tax ?? 0) +
      (quoteData?.rates?.samplesPremium?.tax ?? 0),
  },
];

const QuoteRow = ({
  contractData,
  handleActionClick,
  handleQuoteClick,
  isCurrent,
  isEndorsement,
  isExpandable,
  isExpanded,
  quoteData,
  rowIndex,
  children,
}) => {
  const isSubmitted = quoteData.status === QUOTE_STATUSES.SUBMITTED;
  const { checkPermissions } = useAuth(contractData);

  return (
    <>
      <tr
        className={clsx("border-b border-gray-300 last:border-b-0 h-16 transition", isExpandable && "cursor-pointer")}
        onClick={isExpandable ? handleQuoteClick(quoteData.id, isExpanded) : undefined}
      >
        {columns.map((column, index) => {
          const { displayName, path, formatter, extractValue } = column;
          const value =
            extractValue instanceof Function
              ? extractValue({ quoteData, contractData, index: rowIndex })
              : get(quoteData, path);
          const formattedValue = formatter instanceof Function ? formatter(value) : value;
          const name = displayName instanceof Function ? displayName({ contractData, quoteData, value }) : displayName;

          return (
            <td key={name} className="pl-6">
              {formattedValue ?? value}
            </td>
          );
        })}

        <td>
          <Status kind="small" status={quoteData.status} />
        </td>
        <td>
          <div className="text-right">
            {isSubmitted && isCurrent && checkPermissions(ACTIONS.BIND_QUOTE) && (
              <Button
                kind="primary"
                className="h-10 px-6 ml-1 mr-1"
                onClick={handleActionClick(ACTIONS.BIND_QUOTE)(quoteData.id)}
              >
                Bind
              </Button>
            )}

            {isSubmitted && isCurrent && checkPermissions(ACTIONS.UPDATE_QUOTE_COMMISSION) && (
              <Button
                kind="primary"
                className="h-10 px-6 ml-1 mr-1"
                onClick={handleActionClick(ACTIONS.UPDATE_QUOTE_COMMISSION)(quoteData.id)}
              >
                Amend commission
              </Button>
            )}

            {isSubmitted && isCurrent && checkPermissions(ACTIONS.PROVIDE_COMMERCIAL_PRICE) && (
              <Button
                kind="primary"
                className="h-10 px-6 ml-1 mr-1"
                onClick={handleActionClick(ACTIONS.PROVIDE_COMMERCIAL_PRICE)(quoteData.id)}
              >
                Add Commercial price
              </Button>
            )}

            {isSubmitted && isCurrent && checkPermissions(ACTIONS.ACCEPT_TECHNICAL_PRICE) && (
              <Button
                kind="primary"
                className="h-10 px-6 ml-1 mr-1"
                onClick={handleActionClick(ACTIONS.ACCEPT_TECHNICAL_PRICE)(quoteData.id)}
              >
                Accept
              </Button>
            )}
          </div>
        </td>
      </tr>

      {isExpanded && isExpandable && (
        <tr>
          <td colSpan="6">
            <div>{children}</div>
          </td>
        </tr>
      )}
    </>
  );
};

const QuoteCard_cargo = ({
  contractData,
  expandAll,
  heading,
  headingText,
  isCurrent,
  isEndorsement,
  quotesData,
  schemaData,
}) => {
  const { showModal } = useModal();
  const { location, push } = useHistory();
  const { applyEndoAttachment } = useAttachmentQuery();
  const endorsementId = isEndorsement ? contractData.id : undefined;
  const { productRef, contractId } = useParams();

  const commercialQuote = quotesData.find((quote) => quote.type === QUOTE_TYPES.COMMERCIAL);

  const areSubmitted = quotesData.every(bySubmitted);
  const allIds = quotesData.map((quoteData) => quoteData.id);
  const initialIds = expandAll ? allIds : [areSubmitted && commercialQuote?.id];
  const [selectedIds, setSelectedIds] = useState(initialIds);

  const handleQuoteClick = (quoteId, expanded) => (event) => {
    const nextIds = expanded ? selectedIds.filter((id) => id !== quoteId) : [...selectedIds, quoteId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  const handleBindSuccess = async (nextContractData) => {
    if (nextContractData.status === STATUSES.APPLIED) {
      await applyEndoAttachment({ productRef, contractId, endorsementId });
    }

    if (nextContractData.status === STATUSES.BOUND || nextContractData.status === STATUSES.INFORCE) {
      return showModal(MODALS.BIND_QUOTE_SUCCESS);
    }

    return false;
  };

  const handleActionClick = (action) => (quoteId) => (event) => {
    event.stopPropagation();
    push({ pathname: location.pathname, search: stringifyParams({ quoteId, endorsementId }) });

    if (action === ACTIONS.BIND_QUOTE) {
      showModal(MODALS.BIND_QUOTE, { callback: handleBindSuccess });
    }

    if (action === ACTIONS.ACCEPT_TECHNICAL_PRICE) {
      showModal(MODALS.ACCEPT_TECHNICAL_PRICE);
    }

    if (action === ACTIONS.UPDATE_QUOTE_COMMISSION) {
      showModal(MODALS.UPDATE_QUOTE_COMMISSION);
    }

    if (action === ACTIONS.PROVIDE_COMMERCIAL_PRICE) {
      showModal(MODALS.PROVIDE_COMMERCIAL_PRICE);
    }
  };

  return (
    <div className="bg-white shadow rounded mb-4">
      {heading}
      {headingText && <H3 className="mb-4 p-4">{headingText}</H3>}
      <div>
        <table className="w-full">
          <colgroup>
            <col width="10%" />
            <col width="20%" />
            <col width="15%" />
            <col width="10%" />
            <col width="45%" />
          </colgroup>
          <thead className="text-left text-sm ">
            <tr className="mb-3 ">
              {columns.map((column) => (
                <th className="font-normal pl-6" key={column.displayName}>
                  <div className="mb-3">{column.displayName}</div>
                </th>
              ))}
              <th />
              <th />
              <th className="w-10" />
            </tr>
          </thead>
          <tbody>
            {quotesData.map((quoteData, index) => {
              const result = prepareContractDetails({ contract: quoteData, schema: schemaData });

              return (
                <QuoteRow
                  columns={columns}
                  contractData={contractData}
                  handleActionClick={handleActionClick}
                  handleQuoteClick={handleQuoteClick}
                  isCurrent={isCurrent}
                  isEndorsement={isEndorsement}
                  isExpandable={!isEndorsement}
                  isExpanded={selectedIds.includes(quoteData.id)}
                  key={quoteData.id}
                  quoteData={quoteData}
                  quotesData={quotesData}
                  rowIndex={index}
                >
                  <div className="border-b border-gray-300">
                    {result
                      .filter((section) => section.sectionKey === "cargo_details")
                      .map((section) => (
                        <Table key={section.sectionKey}>
                          <Body>
                            {section.data.map((datapoint) => {
                              if (datapoint.datapointComponent === "SectionRepeater") {
                                return (
                                  <Row key={datapoint.datapointKey} className="border-b border-gray-300 last:border-0">
                                    <Data colSpan="2">
                                      <div>
                                        <Table className="border border-gray-500">
                                          <Head className="bg-gray-50">
                                            {datapoint.columns.map((column) => (
                                              <Header key={column.key}>{column.title}</Header>
                                            ))}
                                          </Head>
                                          <Body>
                                            {datapoint.rows.map((row, rowIndex) => (
                                              <Row
                                                key={rowIndex.toString()}
                                                className="border-b border-gray-300 last:border-0"
                                              >
                                                {row.map((data, columnIndex) => (
                                                  <Data key={columnIndex.toString()}>{data.currFormattedValue}</Data>
                                                ))}
                                              </Row>
                                            ))}
                                          </Body>
                                        </Table>
                                      </div>
                                    </Data>
                                  </Row>
                                );
                              }

                              if (
                                datapoint.datapointKey !== "duty" &&
                                datapoint.datapointKey !== "basis_of_valuation" &&
                                datapoint.datapointKey !== "basis_of_valuation_percentage"
                              ) {
                                return (
                                  <Row key={datapoint.datapointKey} className="border-b border-gray-300 last:border-0">
                                    <Data className="w-3/5">{datapoint.datapointTitle}</Data>
                                    <Data className="w-2/5">{datapoint.currFormattedValue}</Data>
                                  </Row>
                                );
                              }
                            })}
                          </Body>
                        </Table>
                      ))}
                  </div>

                  <CargoPremiums quoteData={quoteData} />
                </QuoteRow>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

QuoteCard_cargo.propTypes = {
  quotesData: PropTypes.array,
};

QuoteCard_cargo.defaultProps = {
  quotesData: [],
};

export default QuoteCard_cargo;

import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../api";
import Button from "../components/Button";
import Form from "../components/Form";
import InputSelect from "../components/InputSelect";
import InputText from "../components/InputText";
import Modal from "../components/Modal";

const CreateProductModal = ({ handleClose }) => {
  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState({});
  const schemasQuery = useQuery(["schemas"], api.getSchemas);
  const schemasData = get(schemasQuery, "data.data.data", []);
  const { mutateAsync: createProduct, ...createProductQuery } = useMutation(api.createProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
  });
  const { isLoading } = schemasData;

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    await createProduct({ data: formValues });

    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      headingText={!isLoading && "Create product"}
      isLoading={isLoading}
    >
      <Form onSubmit={handleSubmit} className="p-6">
        <InputText
          labelText="Product name"
          name="name"
          value={formValues.name}
          isRequired
          className="mb-8"
          onChange={handleChange}
        />

        <InputText
          labelText="Product ref"
          name="ref"
          value={formValues.ref}
          isRequired
          className="mb-8"
          onChange={handleChange}
          descriptionText="Unique product identifier. Cannot be changed."
        />

        <InputSelect
          labelText="Product schema"
          name="schemaId"
          value={(schemasData.find((item) => item.id === formValues.schemaId) || {}).name}
          isRequired
          className="mb-8"
          onChange={handleChange}
          options={schemasData.map((schema) => ({ name: schema.name, value: schema.id }))}
        />

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={
              !(formValues.name && formValues.ref && formValues.schemaId) ||
              createProductQuery.isLoading
            }
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </Modal>
  );
};

CreateProductModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CreateProductModal;

import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import { useEffect, useRef, useState } from "react";

const useForm = ({
  initialState,
  validationSchema,
  onSubmit,
  validationOptions,
  onBeforeChange,
}) => {
  const [isLoading, setLoading] = useState();
  const [formValues, setFormValues] = useState(initialState);
  const [formError, setFormError] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);

  const hasSchema = validationSchema && Object.keys(validationSchema).length > 0;
  const isValid = hasSchema && validationSchema.isValidSync(formValues, validationOptions);
  const canSubmit = !isLoading && isValid;

  const mountedRef = useRef(true);

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);
    const isCheckbox = args[0]?.target?.type === "checkbox";
    const isChecked = args[0]?.target?.checked;
    const value = args[0]?.target?.value ?? args[0];
    const name = args[0]?.target?.name ?? args[1];

    set(clonedFormValues, name, isCheckbox ? isChecked : value);

    if (onBeforeChange) {
      onBeforeChange(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      await onSubmit(formValues, e);

      if (!mountedRef.current) {
        return false;
      }

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return setFormError(get(error, "message"));
    }
  };

  const getFieldProps = (...args) => {
    if (args.length === 1) {
      const [name] = args;

      return { name, value: get(formValues, name, ""), onChange: handleChange };
    }

    return { ...args };
  };

  useEffect(() => {
    if (validationSchema) {
      try {
        validationSchema.validateSync(formValues, validationOptions);
        setValidationErrors([]);
      } catch (error) {
        setValidationErrors(error.errors);
      }
    }
  }, [formValues]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    canSubmit,
    formError,
    formErrors,
    formValues,
    getFieldProps,
    isLoading,
    isValid,
    onSubmit: handleSubmit,
    setFormError,
    setFormErrors,
    setFormValues,
    validationErrors,
  };
};

export default useForm;

// @ts-check

import { get } from "lodash";
import cloneDeep from "lodash.clonedeep";
import React from "react";
import resolveSchema from "../../../helpers/resolveSchema";
import Card from "../Card";
import H4 from "../H4";

const ControlledSectionRepeater = ({
  className,
  formValues,
  id,
  item: schema,
  mapChildren,
  onChange,
  ownKey,
  setFormValues,
  value,
  validationErrors,
  showQuestionKey,
}) => {
  const itemSchema = schema.items.type === "object" ? schema.items : { properties: { [id]: schema.items } };
  const year = new Date().getUTCFullYear();
  const numItemsMax = schema.maximum;
  const numItemsMin = schema.minimum;

  // HACK: for cargo-us, number of loss_history_breakdown is dependant on trading_years
  // https://trello.com/c/OwGZVWyz/87-change-trading-number-of-years
  const initialNumItems = get(formValues, "insured.trading_years");
  const numItems =
    initialNumItems >= numItemsMax ? numItemsMax : initialNumItems < numItemsMin ? numItemsMin : initialNumItems;
  const items = Array.from(Array(numItems).keys());

  return (
    <div className={className}>
      <H4 className="my-8">{schema.title}</H4>

      {items.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const clonedSchema = cloneDeep(itemSchema);

        resolveSchema(clonedSchema, get(formValues, key));

        return (
          <Card
            className="mb-8"
            key={key}
            header={
              <Card.Header className="flex justify-between items-center p-4">
                <div className="font-medium">
                  {schema.items.title} {year - index}
                </div>
              </Card.Header>
            }
          >
            <div className="p-4">
              {mapChildren({
                formValues,
                onChange,
                parentKey: key,
                parentSchema: clonedSchema,
                setFormValues,
                validationErrors,
                showQuestionKey,
                // useParentKey: true,
              })}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default ControlledSectionRepeater;

import { compile } from "path-to-regexp";
import React from "react";
import { useHistory, useParams } from "react-router";
import { ProductRef, SchemaRef } from "../../@types/types";
import { ROUTES } from "../../constants";
import Modal from "../components/Modal";

const isInternalEnv = ["local", "development", "staging"].includes(process.env.ENVIRONMENT);

const SelectPolicyTypeModal = ({ handleClose }) => {
  const { push } = useHistory();
  const { productRef } = useParams<{ productRef: ProductRef }>();

  const handleClick = (schemaRef: SchemaRef) => {
    push({ pathname: compile(ROUTES.CONTRACT_NEW)({ productRef }), search: `?schemaRef=${schemaRef}` });
    handleClose();
  };

  return (
    <Modal handleClose={handleClose} className="max-w-sm">
      <div className="p-10">
        <p className="text-xl text-center mb-6 font-semibold">Select a policy type</p>

        <div>
          <div
            className="flex items-center mb-6 rounded-md bg-blue-50 p-6 text-blue-300 hover:text-blue-500 border-2 border-transparent hover:border-blue-500 cursor-pointer mb-6"
            onClick={() => handleClick("cargo-us-annual")}
          >
            <div className="fill-current mr-6 ">
              <svg width="58" height="62" viewBox="0 0 58 62" xmlns="http://www.w3.org/2000/svg">
                <path d="M29 11C28.7348 11 28.4804 10.8946 28.2929 10.7071C28.1054 10.5196 28 10.2652 28 10V1C28 0.734784 28.1054 0.48043 28.2929 0.292893C28.4804 0.105357 28.7348 0 29 0C29.2652 0 29.5196 0.105357 29.7071 0.292893C29.8946 0.48043 30 0.734784 30 1V10C30 10.2652 29.8946 10.5196 29.7071 10.7071C29.5196 10.8946 29.2652 11 29 11Z" />
                <path d="M12 11C11.7348 11 11.4804 10.8946 11.2929 10.7071C11.1054 10.5196 11 10.2652 11 10V1C11 0.734784 11.1054 0.48043 11.2929 0.292893C11.4804 0.105357 11.7348 0 12 0C12.2652 0 12.5196 0.105357 12.7071 0.292893C12.8946 0.48043 13 0.734784 13 1V10C13 10.2652 12.8946 10.5196 12.7071 10.7071C12.5196 10.8946 12.2652 11 12 11Z" />
                <path d="M46 11C45.7348 11 45.4804 10.8946 45.2929 10.7071C45.1054 10.5196 45 10.2652 45 10V1C45 0.734784 45.1054 0.48043 45.2929 0.292893C45.4804 0.105357 45.7348 0 46 0C46.2652 0 46.5196 0.105357 46.7071 0.292893C46.8946 0.48043 47 0.734784 47 1V10C47 10.2652 46.8946 10.5196 46.7071 10.7071C46.5196 10.8946 46.2652 11 46 11Z" />
                <path d="M32.587 54H3C2.73478 54 2.48043 53.8946 2.29289 53.7071C2.10536 53.5196 2 53.2652 2 53V20C2 19.7348 2.10536 19.4804 2.29289 19.2929C2.48043 19.1054 2.73478 19 3 19H55C55.2652 19 55.5196 19.1054 55.7071 19.2929C55.8946 19.4804 56 19.7348 56 20V40.211C56.7661 40.9983 57.4373 41.8725 58 42.816V8C58 7.46957 57.7893 6.96086 57.4142 6.58579C57.0391 6.21071 56.5304 6 56 6H49V10C49 10.7956 48.6839 11.5587 48.1213 12.1213C47.5587 12.6839 46.7957 13 46 13C45.2043 13 44.4413 12.6839 43.8787 12.1213C43.3161 11.5587 43 10.7956 43 10V6H32V10C32 10.7956 31.6839 11.5587 31.1213 12.1213C30.5587 12.6839 29.7956 13 29 13C28.2044 13 27.4413 12.6839 26.8787 12.1213C26.3161 11.5587 26 10.7956 26 10V6H15V10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10V6H2C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 0 7.46957 0 8V54C0 54.5304 0.210714 55.0391 0.585786 55.4142C0.960859 55.7893 1.46957 56 2 56H33.365C33.0548 55.3543 32.7947 54.6856 32.587 54Z" />
                <path d="M46 38C43.6266 38 41.3066 38.7038 39.3332 40.0224C37.3598 41.3409 35.8217 43.2151 34.9135 45.4078C34.0052 47.6005 33.7676 50.0133 34.2306 52.3411C34.6936 54.6689 35.8365 56.8071 37.5147 58.4853C39.193 60.1635 41.3312 61.3064 43.6589 61.7694C45.9867 62.2324 48.3995 61.9948 50.5922 61.0866C52.7849 60.1783 54.6591 58.6402 55.9776 56.6668C57.2962 54.6935 58 52.3734 58 50C58 46.8174 56.7357 43.7652 54.4853 41.5147C52.2349 39.2643 49.1826 38 46 38ZM51 51H47V55C47 55.2652 46.8947 55.5196 46.7071 55.7071C46.5196 55.8946 46.2652 56 46 56C45.7348 56 45.4804 55.8946 45.2929 55.7071C45.1054 55.5196 45 55.2652 45 55V51H41C40.7348 51 40.4804 50.8946 40.2929 50.7071C40.1054 50.5196 40 50.2652 40 50C40 49.7348 40.1054 49.4804 40.2929 49.2929C40.4804 49.1054 40.7348 49 41 49H45V45C45 44.7348 45.1054 44.4804 45.2929 44.2929C45.4804 44.1054 45.7348 44 46 44C46.2652 44 46.5196 44.1054 46.7071 44.2929C46.8947 44.4804 47 44.7348 47 45V49H51C51.2652 49 51.5196 49.1054 51.7071 49.2929C51.8946 49.4804 52 49.7348 52 50C52 50.2652 51.8946 50.5196 51.7071 50.7071C51.5196 50.8946 51.2652 51 51 51Z" />
              </svg>
            </div>
            <div className="text-gray-900">
              <p className="font-semibold mb-4">Annual</p>
              <p>Annual or Contract Period Cargo Insurance Policy</p>
            </div>
          </div>

          <div
            className={`flex items-center mb-6 rounded-md bg-blue-50 p-6 text-blue-300 border-2 border-transparent cursor-pointer mb-6 ${
              isInternalEnv ? "hover:text-blue-500" : ""
            }`}
            onClick={() => (isInternalEnv ? handleClick("cargo-us-single") : undefined)}
          >
            <div className="fill-current mr-6 ">
              <svg width="58" height="54" viewBox="0 0 58 54" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.868 14.504C41.69 14.192 41.359 14 41 14H7C6.448 14 6 14.448 6 15V28H49.58L41.868 14.504ZM16 22H14C13.448 22 13 21.552 13 21C13 20.448 13.448 20 14 20H16C16.552 20 17 20.448 17 21C17 21.552 16.552 22 16 22ZM26 22H24C23.448 22 23 21.552 23 21C23 20.448 23.448 20 24 20H26C26.552 20 27 20.448 27 21C27 21.552 26.552 22 26 22ZM36 22H34C33.448 22 33 21.552 33 21C33 20.448 33.448 20 34 20H36C36.552 20 37 20.448 37 21C37 21.552 36.552 22 36 22Z" />
                <path d="M5.27603 45.252C6.06003 45.731 6.99203 46 8.00003 46C9.90403 46 11.536 45.09 12.365 43.566C12.878 42.624 13.888 42.039 15 42.039C16.112 42.039 17.122 42.624 17.635 43.565C18.464 45.09 20.096 46 22 46C23.904 46 25.536 45.09 26.365 43.566C26.878 42.624 27.888 42.039 29 42.039C30.112 42.039 31.122 42.624 31.635 43.565C32.464 45.09 34.096 46 36 46C37.904 46 39.536 45.09 40.365 43.566C40.878 42.624 41.888 42.039 43 42.039C44.112 42.039 45.122 42.624 45.635 43.565C46.423 45.013 47.938 45.897 49.72 45.983L57.872 31.49C58.046 31.18 58.043 30.802 57.864 30.495C57.685 30.188 57.355 30 57 30H1.00003C0.672028 30 0.365028 30.161 0.178028 30.43C-0.00897199 30.7 -0.050972 31.044 0.063028 31.351L5.27603 45.252Z" />
                <path d="M57.259 44.034C56.821 43.917 56.361 44.108 56.134 44.499C54.885 46.659 52.535 48 50 48C47.352 48 45.063 46.7 43.878 44.522C43.527 43.878 42.472 43.878 42.121 44.522C40.937 46.7 38.648 48 36 48C33.352 48 31.063 46.7 29.878 44.522C29.527 43.878 28.472 43.878 28.121 44.522C26.937 46.7 24.648 48 22 48C19.352 48 17.063 46.7 15.878 44.522C15.527 43.878 14.472 43.878 14.121 44.522C12.937 46.7 10.648 48 8 48C5.375 48 3.085 46.697 1.875 44.515C1.653 44.116 1.19 43.918 0.75 44.032C0.308 44.146 0 44.544 0 45V53C0 53.552 0.448 54 1 54H57C57.552 54 58 53.552 58 53V45C58 44.547 57.696 44.151 57.259 44.034Z" />
                <path d="M24 12V1C24 0.448 23.552 0 23 0H14C13.448 0 13 0.448 13 1V12H24Z" />
              </svg>
            </div>
            <div className="text-gray-900">
              <p className="font-semibold mb-4">Single trip</p>
              <p>Coming soon ...</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectPolicyTypeModal;
